import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as MyTaskAction from './actions';
import { IServerResponse } from "app/interface";
import { MyTaskService } from "./service";

@Injectable()
export class MyTaskEffect {
    constructor(
        private action: Actions,
        private myTaskService: MyTaskService
    ) { }

    GetAllTasks = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_TASKS),
        switchMap((data: any) => this.myTaskService.getAllTasks(data.payload)),
        map((data: Array<any>) => new MyTaskAction.SetAllTasks(data)
        )
    ));

    GetAssignUserList = createEffect(() => this.action.pipe(
        ofType(type.GET_ASSIGN_USER_LIST),
        switchMap((data: any) => this.myTaskService.getAssignUserList(data.payload)),
        map((data: Array<any>) => new MyTaskAction.SetAssignUserList(data)
        )
    ));

    GetAllProjects = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_PROJECTS),
        switchMap(() => this.myTaskService.getAllProjects()),
        map((data: Array<any>) => new MyTaskAction.SetAllProjects(data)
        )
    ));

    GetTaskById = createEffect(() => this.action.pipe(
        ofType(type.GET_TASK_BY_ID),
        switchMap((data: any) => this.myTaskService.getTaskById(data.payload)),
        map((data: any) => new MyTaskAction.SetTaskById(data)
        )
    ));

    GetTaskChartDataByUserId = createEffect(() => this.action.pipe(
        ofType(type.GET_TASK_CHART_DATA_BY_USER_ID),
        switchMap((data: any) => this.myTaskService.getTaskChartDataByUserId(data.payload)),
        map((data: any) => new MyTaskAction.SetTaskChartDataByUserId(data)
        )
    ));

    GetProjectById = createEffect(() => this.action.pipe(
        ofType(type.GET_PROJECT_BY_ID),
        switchMap((data: any) => this.myTaskService.getProjectById(data.payload)),
        map((data: any) => new MyTaskAction.SetProjectById(data)
        )
    ));

    AddTask = createEffect(() => this.action.pipe(
        ofType(type.ADD_TASK),
        switchMap((data: any) => this.myTaskService.addTask(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.AddTaskResponse(data)
        )
    ));

    AddProject = createEffect(() => this.action.pipe(
        ofType(type.ADD_PROJECT),
        switchMap((data: any) => this.myTaskService.addProject(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.AddProjectResponse(data)
        )
    ));

    UpdateTask = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_TASK),
        switchMap((data: any) => this.myTaskService.updateTask(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.UpdateTaskResponse(data)
        )
    ));

    UpdateTaskStatus = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_TASK_STATUS),
        switchMap((data: any) => this.myTaskService.updateTaskStatus(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.UpdateTaskStatusResponse(data)
        )
    ));
    
    ReassignTask = createEffect(() => this.action.pipe(
        ofType(type.REASSIGN_TASK),
        switchMap((data: any) => this.myTaskService.reassignTask(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.ReassignTaskResponse(data)
        )
    ));
    
    CreateCalendarEvent = createEffect(() => this.action.pipe(
        ofType(type.CREATE_CALENDAR_EVENT),
        switchMap((data: any) => this.myTaskService.createCalendarEvent(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.CreateCalendarEventResponse(data)
        )
    ));

    UpdateProject = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_PROJECT),
        switchMap((data: any) => this.myTaskService.updateProject(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.UpdateProjectResponse(data)
        )
    ));

    DeleteTask = createEffect(() => this.action.pipe(
        ofType(type.DELETE_TASK),
        switchMap((data: any) => this.myTaskService.deleteTask(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.DeleteTaskResponse(data)
        )
    ));

    DeleteProject = createEffect(() => this.action.pipe(
        ofType(type.DELETE_PROJECT),
        switchMap((data: any) => this.myTaskService.deleteProject(data.payload)),
        map((data: IServerResponse) => new MyTaskAction.DeleteProjectResponse(data)
        )
    ));
}
