import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { createSelector, StoreModule } from "@ngrx/store";
import { ClientsEffect } from "./effect";
import { ClientsStateReducer } from "./reducer";
import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([ClientsEffect]),
        StoreModule.forFeature('clients', ClientsStateReducer)
    ]
})

export class ClientsStoreModule { }

export const allClients = (store: any) => store && store.clients && store.clients.allClients;
export const clientById = (store: any) => store && store.clients && store.clients.clientById;
export const mattersByClientId = (store: any) => store && store.clients && store.clients.mattersByClientId;
export const clientsByField = (store: any) => store && store.clients && store.clients.clientsByField;
export const updateClientResponse = (store: any) => store && store.clients && store.clients.updateClientResponse;
export const deleteClientResponse = (store: any) => store && store.clients && store.clients.deleteClientResponse;

export const allClientsWithPreferredContactTypeAndTime = createSelector(
    allClients, helperValues, (list, values) => {
        const preferredContactType = values?.preferredContactType || [];
        return (list || []).map((item) => {
            const preferredContactTypeId = item?.preferredContactType || '';
            const preferredTime = item?.preferredTime || '';
            const formattedHour = preferredTime?.hour?.toString().padStart(2, '0');
            const formattedMinute = preferredTime?.minute?.toString().padStart(2, '0');

            const preferredTimeFormatted = `${formattedHour ? `${formattedMinute ? `${formattedHour}:${formattedMinute}` : ''}` : ''}`;
            let preferredContactTypeValue = preferredContactType.find(({ id }) => id === preferredContactTypeId)?.name || preferredContactTypeId || '-';
            switch (preferredContactTypeId) {
                case "EMAIL":
                    preferredContactTypeValue = `${preferredContactTypeValue} | ${item?.email || "-"}`
                    break;
                case "TEXT":
                case "WHATSAPP": {
                    let contactNumber = item.countryCode;
                    if (contactNumber) {
                        contactNumber += ` ${item.phone}`;
                    } else {
                        contactNumber = item.phone;
                    }
                    preferredContactTypeValue = `${preferredContactTypeValue} | ${contactNumber || "-"}`
                    break;
                }
                case "OTHER": {
                    const otherContactMode = item.otherContactMode;
                    preferredContactTypeValue = `${preferredContactTypeValue} | ${otherContactMode || "-"}`
                    break;
                }
                default:
                    break;
            }
            return {
                ...item,
                preferredTimeFormatted,
                preferredContactTypeValue: preferredContactTypeValue
            }
        })
    }
)

export const clientByFieldWithPreferredContactTypeAndTime = createSelector(
    clientsByField, helperValues, (list, values) => {
        const preferredContactType = values?.preferredContactType || [];
        return (list || []).map((item) => {
            const preferredContactTypeId = item?.preferredContactType || '';
            const preferredTime = item?.preferredTime || '';
            const formattedHour = preferredTime?.hour?.toString().padStart(2, '0');
            const formattedMinute = preferredTime?.minute?.toString().padStart(2, '0');

            const preferredTimeFormatted = `${formattedHour ? `${formattedMinute ? `${formattedHour}:${formattedMinute}` : ''}` : ''}`;
            let preferredContactTypeValue = preferredContactType.find(({ id }) => id === preferredContactTypeId)?.name || preferredContactTypeId || '-';
            switch (preferredContactTypeId) {
                case "EMAIL":
                    preferredContactTypeValue = `${preferredContactTypeValue} | ${item?.email || "-"}`
                    break;
                case "TEXT":
                case "WHATSAPP": {
                    let contactNumber = item.countryCode;
                    if (contactNumber) {
                        contactNumber += ` ${item.phone}`;
                    } else {
                        contactNumber = item.phone;
                    }
                    preferredContactTypeValue = `${preferredContactTypeValue} | ${contactNumber || "-"}`
                    break;
                }
                case "OTHER": {
                    const otherContactMode = item.otherContactMode;
                    preferredContactTypeValue = `${preferredContactTypeValue} | ${otherContactMode || "-"}`
                    break;
                }
                default:
                    break;
            }
            return {
                ...item,
                preferredTimeFormatted,
                preferredContactTypeValue: preferredContactTypeValue
            }
        })
    }
)
