import { Injectable } from "@angular/core";
import { CommonUtil } from "./common.util";
import { v4 as uuidv4 } from 'uuid';

interface IVisualisationData {
    nodes: Array<any>,
    edges: Array<any>
}
interface IVisualisationOptions {
    removeDuplidateNodes?: boolean,
    removeDuplidateEdges?: boolean,
    removeOrphanNodes?: boolean,
    removeOrphanEdges?: boolean,
}

@Injectable()
export class VisualisationUtil {
    defaultOptions: IVisualisationOptions = {
        removeDuplidateNodes: true,
        removeDuplidateEdges: true,
        removeOrphanEdges: true,
        removeOrphanNodes: true
    }

    constructor(
        private commonUtil: CommonUtil
    ) {

    }

    public extractNodesEdges(resp): IVisualisationData {
        let nodes = [];
        let edges = [];
        resp.forEach((item) => {
            nodes.push({
                data: item.data,
                id: item.id,
                level: item.nodeLevel
            })
            if (item.edges) {
                item.edges.forEach((edgeItem) => {
                    edges.push({
                        from: edgeItem.parentId,
                        to: edgeItem.childId
                    })
                })
            }
        })
        return {
            nodes,
            edges
        };
    }

    public formatVisualisationData(data: any, operations: IVisualisationOptions = this.defaultOptions) {
        let cleanedNodes = [];
        let cleanedEdges = [];
        let extractedData = this.extractNodesEdges(data);
        cleanedNodes = operations.removeDuplidateNodes ? this.removeDuplidateNodes(extractedData.nodes) : extractedData.nodes;
        cleanedEdges = operations.removeDuplidateEdges ? this.removeDuplicateEdges(extractedData.edges) : extractedData.edges;

        return {
            nodes: operations.removeOrphanNodes ? this.removeOrphanNodes(cleanedNodes, cleanedEdges) : cleanedNodes,
            edges: operations.removeOrphanEdges ? this.removeBrokenEdges(cleanedNodes, cleanedEdges).map((item) => {
                return { ...item, id: uuidv4() }
            }) : cleanedEdges.map((item) => {
                return { ...item, id: uuidv4() }
            })
        }
    }

    private removeDuplidateNodes(nodes: Array<any>) {
        return nodes.filter((tag, index, array) => array.findIndex((t) => t.id === tag.id) === index);
    }

    private removeDuplicateEdges(edges: Array<any>) {
        return edges.filter((tag, index, array) => array.findIndex((t) => t.from === tag.from && t.to === tag.to) === index);
    }

    private removeOrphanNodes(nodes, edges) {
        return nodes.filter((node) => edges.filter((item) => item.from === node.id || item.to === node.id).length);
    }

    private removeBrokenEdges(nodes, edges) {
        return edges.filter((edge) => nodes.filter((item) => item.id === edge.from || item.id === edge.to).length);
    }
}
