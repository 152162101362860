export enum RowType {
    Data = "data",
    Image = "image",
    Date = "date"
}

export enum TableTemplate {
    HeaderCheckbox = 'headerCheckbox',
    CellCheckbox = "cellCheckbox",
    CellHyperlink = "cellHyperlink",
    Date = "date",
    DateTime = "dateTime",
    Risk = "risk",
    AmountWithCurrency = "amountWithCurrency",
    LongText = 'longText',
    NewLineItemsList = 'newLineItemsList',
    TruncatedText = 'truncatedText',
    TruncatedHyperlink = 'truncatedHyperlink',
    TextInput = 'textInput',
    AmountInput = 'amountInput',
    SelectInput = 'selectInput',
    DateInput = 'dateInput',
    ActionButton = 'actionButton',
    SpeechAndVisionAiActions = 'speechAndVisionAiActions',
    ActionIcons = 'actionIcons'
}

export enum TableEvent {
    Edit = "edit",
    RowSelected = "rowSelected",
    Delete = "delete",
    Search = "search",
    UpdateData = 'updateData',
    Clear = 'clear'
}

export class Rows {
    type: RowType;
    name: string;
    prop: string;
    value: string;
}

export interface TableOptions {
    showLegend?: boolean;
    hideDeleteButton?: boolean;
    showSearch?: boolean;
}

export enum SearchColumnType {
    Text = 'text',
    Date = 'date',
    Select = 'select'
}

export enum IsMeterCharge {
    true = "Yes",
    false = "No",
}

export enum IsMeterVisible {
    true = "Yes",
    false = "No",
}
