export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const SET_ALL_TASKS = "SET_ALL_TASKS";

export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const SET_ALL_PROJECTS = "SET_ALL_PROJECTS";

export const GET_TASK_BY_ID = "GET_TASK_BY_ID";
export const SET_TASK_BY_ID = "SET_TASK_BY_ID";

export const GET_TASK_CHART_DATA_BY_USER_ID = "GET_TASK_CHART_DATA_BY_USER_ID";
export const SET_TASK_CHART_DATA_BY_USER_ID = "SET_TASK_CHART_DATA_BY_USER_ID";

export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID";
export const SET_PROJECT_BY_ID = "SET_PROJECT_BY_ID";

export const ADD_TASK = "ADD_TASK";
export const ADD_TASK_RESPONSE = "ADD_TASK_RESPONSE";

export const ADD_PROJECT = "ADD_PROJECT";
export const ADD_PROJECT_RESPONSE = "ADD_PROJECT_RESPONSE";

export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_RESPONSE = "UPDATE_TASK_RESPONSE";

export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const UPDATE_TASK_STATUS_RESPONSE = "UPDATE_TASK_STATUS_RESPONSE";

export const REASSIGN_TASK = "REASSIGN_TASK";
export const REASSIGN_TASK_RESPONSE = "REASSIGN_TASK_RESPONSE";

export const CREATE_CALENDAR_EVENT = "CREATE_CALENDAR_EVENT";
export const CREATE_CALENDAR_EVENT_RESPONSE = "CREATE_CALENDAR_EVENT_RESPONSE";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_RESPONSE = "UPDATE_PROJECT_RESPONSE";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_RESPONSE = "DELETE_TASK_RESPONSE";

export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_RESPONSE = "DELETE_PROJECT_RESPONSE";

export const GET_ASSIGN_USER_LIST = "GET_ASSIGN_USER_LIST";
export const SET_ASSIGN_USER_LIST = "SET_ASSIGN_USER_LIST";
