import { initialState, MattersState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_MATTERS, SET_MATTER_BY_ID, ADD_MATTER_RESPONSE, UPDATE_MATTER_RESPONSE, DELETE_MATTER_RESPONSE,
    SET_MATTER_CLIENTS_BY_ID,
    SET_MATTER_BY_FIELD,
    MANUAL_UPLOAD_RESPONSE, MANUAL_UPLOAD_OVERRIDE_RESPONSE
} from "./actionTypes";

export function MattersStateReducer(state = initialState, action: Actions): MattersState {
    const { type } = action;

    switch (type) {
        case SET_ALL_MATTERS:
            return Object.assign({}, state, {
                allMatters: action.payload
            });
        case SET_MATTER_BY_FIELD:
            return Object.assign({}, state, {
                mattersByFields: action.payload
            });
        case SET_MATTER_BY_ID:
            return Object.assign({}, state, {
                matterById: action.payload
            });
        case SET_MATTER_CLIENTS_BY_ID:
            return Object.assign({}, state, {
                clients: action.payload
            });
        case ADD_MATTER_RESPONSE:
            return Object.assign({}, state, {
                addMatterResponse: action.payload
            });
        case UPDATE_MATTER_RESPONSE:
            return Object.assign({}, state, {
                updateMatterResponse: action.payload
            });
        case DELETE_MATTER_RESPONSE:
            return Object.assign({}, state, {
                deleteMatterResponse: action.payload
            });
        case MANUAL_UPLOAD_RESPONSE:
            return Object.assign({}, state, {
                manualUploadResponse: action.payload
            })
        case MANUAL_UPLOAD_OVERRIDE_RESPONSE:
            return Object.assign({}, state, {
                manualUploadOverrideResponse: action.payload
            })
        default:
            return state;
    }
}
