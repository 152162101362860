import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_CLIENTS, SET_ALL_CLIENTS, GET_CLIENT_BY_ID, SET_CLIENT_BY_ID,
    UPDATE_CLIENT, UPDATE_CLIENT_RESPONSE, DELETE_CLIENT, DELETE_CLIENT_RESPONSE,
    GET_CLIENTS_BY_FIELD, SET_CLIENTS_BY_FIELD,
    GET_MATTERS_BY_CLIENT_ID,
    SET_MATTERS_BY_CLIENT_ID
} from "./actionTypes"

export class GetAllClients implements Action {
    readonly type = GET_ALL_CLIENTS;
}

export class SetAllClients implements Action {
    readonly type = SET_ALL_CLIENTS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetClientById implements Action {
    readonly type = GET_CLIENT_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetClientById implements Action {
    readonly type = SET_CLIENT_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetMattersByClientId implements Action {
    readonly type = GET_MATTERS_BY_CLIENT_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetMattersByClientId implements Action {
    readonly type = SET_MATTERS_BY_CLIENT_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetClientsByField implements Action {
    readonly type = GET_CLIENTS_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetClientsByField implements Action {
    readonly type = SET_CLIENTS_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class UpdateClient implements Action {
    readonly type = UPDATE_CLIENT;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class UpdateClientResponse implements Action {
    readonly type = UPDATE_CLIENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteClient implements Action {
    readonly type = DELETE_CLIENT;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray
    }
}

export class DeleteClientResponse implements Action {
    readonly type = DELETE_CLIENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions = GetAllClients | SetAllClients | GetClientById | SetClientById |
    UpdateClient | UpdateClientResponse | DeleteClient | DeleteClientResponse |
    GetClientsByField | SetClientsByField | GetMattersByClientId | SetMattersByClientId;
