import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CommunicationService {

    private activeTabSource = new BehaviorSubject<number>(1);

    activeTab$ = this.activeTabSource.asObservable();

    setActiveTab(tabIndex) {
        this.activeTabSource.next(tabIndex);
    }
}
