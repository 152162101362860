import { IServerResponse } from "app/interface";

export interface CalendarState {
    calendarEvent: Array<any>;
    calendarDataById: any;
    currentEvent: any;
    addCalendarEventResponse: IServerResponse;
    updateCalendarEventResponse: IServerResponse;
    syncCalendarEventResponse: IServerResponse;
    deleteCalendarEventResponse: IServerResponse;
}

export const initialState: CalendarState = {
    calendarEvent: [],
    calendarDataById: null,
    currentEvent: null,
    addCalendarEventResponse: null,
    updateCalendarEventResponse: null,
    syncCalendarEventResponse: null,
    deleteCalendarEventResponse: null
}
