import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as MeterAction from './actions';
import { MeterService } from "./service";
import { IServerResponse } from "app/interface";
import { of } from "rxjs";

@Injectable()
export class MeterEffect {
    constructor(
        private action: Actions,
        private MeterService: MeterService
    ) { }

    GetAllMeters = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_METERS),
        switchMap((data: any) => this.MeterService.getAllMeters(data.payload)),
        map((data: Array<any>) => new MeterAction.SetAllMeters(data)
        )
    ));

    GetMETERSByField = createEffect(() => this.action.pipe(
        ofType(type.GET_METER_BY_FIELD),
        switchMap((data: any) => this.MeterService.searchByField(data.payload)),
        map((data: Array<any>) => new MeterAction.SetMetersByField(data)
        )
    ));

    GetMETERById = createEffect(() => this.action.pipe(
        ofType(type.GET_METER_BY_ID),
        switchMap((data: any) => this.MeterService.getMeterById(data.payload)),
        map((data: any) => new MeterAction.SetMeterById(data)
        )
    ));

    UpdateMETER = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_METER),
        switchMap((data: any) => this.MeterService.updateMeter(data.payload)),
        map((data: IServerResponse) => new MeterAction.UpdateMeterResponse(data)
        )
    ));

    ExportMETER = createEffect(() => this.action.pipe(
        ofType(type.METER_EXPORT),
        switchMap(() => this.MeterService.exportMeter().pipe(
            map((data: IServerResponse) => new MeterAction.SetExportResponse(data)),
            catchError((error) => {
                console.error('Export error:', error);
                return of(new MeterAction.SetExportResponse({ error }));
            })
        ))
    ));
}
