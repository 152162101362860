import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as UsersAction from './actions';
import { UsersService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class UsersEffect {
    constructor(
        private action: Actions,
        private usersService: UsersService
    ) { }

    GetAllUsers = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_USERS),
        switchMap(() => this.usersService.getAllUsers()),
        map((data: Array<any>) => new UsersAction.SetAllUsers(data)
        )
    ));

    GetMattersByField = createEffect(() => this.action.pipe(
        ofType(type.GET_USER_BY_FIELD),
        switchMap((data: any) => this.usersService.searchByField(data.payload)),
        map((data) => new UsersAction.SetUsersByField(data))
    ));

    GetUserById = createEffect(() => this.action.pipe(
        ofType(type.GET_USER_BY_ID),
        switchMap((data: any) => this.usersService.getUserById(data.payload)),
        map((data: any) => new UsersAction.SetUserById(data)
        )
    ));

    AddUser = createEffect(() => this.action.pipe(
        ofType(type.ADD_USER),
        switchMap((data: any) => this.usersService.addUser(data.payload)),
        map((data: IServerResponse) => new UsersAction.AddUserResponse(data)
        )
    ));

    AddGuestUser = createEffect(() => this.action.pipe(
        ofType(type.ADD_GUEST_USER),
        switchMap((data: any) => this.usersService.addGuestUser(data.payload)),
        map((data: IServerResponse) => new UsersAction.AddGuestUserResponse(data)
        )
    ));

    UpdateUser = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_USER),
        switchMap((data: any) => this.usersService.updateUser(data.payload)),
        map((data: IServerResponse) => new UsersAction.UpdateUserResponse(data)
        )
    ));

    DeleteUser = createEffect(() => this.action.pipe(
        ofType(type.DELETE_USER),
        switchMap((data: any) => this.usersService.deleteUser(data.payload)),
        map((data: IServerResponse) => new UsersAction.DeleteUserResponse(data)
        )
    ));

    GetUserProfileById = createEffect(() => this.action.pipe(
        ofType(type.GET_USER_PROFILE_BY_ID),
        switchMap((data: any) => this.usersService.getUserProfileById(data.payload)),
        map((data: IServerResponse) => new UsersAction.SetUserProfileById(data))
    ));

    UpdateUserProfileById = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_USER_PROFILE_BY_ID),
        switchMap((data: any) => this.usersService.updateUserProfileById(data.payload)),
        map((data: IServerResponse) => new UsersAction.UpdateUserProfileByIdResponse(data))
    ));
}
