import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as EnquiryAction from './actions';
import { EnquiryService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class EnquiryEffect {
    constructor(
        private action: Actions,
        private enquiryService: EnquiryService
    ) { }

    GetAllEnquiries = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_ENQUIRIES),
        switchMap(() => this.enquiryService.getAllEnquiries()),
        map((data: Array<any>) => new EnquiryAction.SetAllEnquiries(data)
        )
    ));
    
    GetEnquiriesByField = createEffect(() => this.action.pipe(
        ofType(type.GET_ENQUIRY_BY_FIELD),
        switchMap((data: any) => this.enquiryService.searchByField(data.payload)),
        map((data: Array<any>) => new EnquiryAction.SetEnquiriesByField(data)
        )
    ));

    GetEnquiryById = createEffect(() => this.action.pipe(
        ofType(type.GET_ENQUIRY_BY_ID),
        switchMap((data: any) => this.enquiryService.getEnquiryById(data.payload)),
        map((data: any) => new EnquiryAction.SetEnquiryById(data)
        )
    ));

    AddEnquiry = createEffect(() => this.action.pipe(
        ofType(type.ADD_ENQUIRY),
        switchMap((data: any) => this.enquiryService.addEnquiry(data.payload)),
        map((data: IServerResponse) => new EnquiryAction.AddEnquiryResponse(data)
        )
    ));

    UpdateEnquiry = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_ENQUIRY),
        switchMap((data: any) => this.enquiryService.updateEnquiry(data.payload)),
        map((data: IServerResponse) => new EnquiryAction.UpdateEnquiryResponse(data)
        )
    ));

    DeleteEnquiry = createEffect(() => this.action.pipe(
        ofType(type.DELETE_ENQUIRY),
        switchMap((data: any) => this.enquiryService.deleteEnquiry(data.payload)),
        map((data: IServerResponse) => new EnquiryAction.DeleteEnquiryResponse(data)
        )
    ));

    GetEnquiryExtractions = createEffect(() => this.action.pipe(
        ofType(type.GET_ENQUIRY_EXTRACTIONS),
        switchMap((data: any) => this.enquiryService.getEnquiryExtractions(data.payload)),
        map((data: any) => new EnquiryAction.SetEnquiryExtractions(data))
    ))
}
