import { MONTHS } from "app/constants/common.constant"
import { AUDIO_FILE_REGEX } from "./regexPatterns.util"

export const getDatesOfMonth = (month) => {
    const monthIndex = MONTHS.findIndex((item, index) => index === Number(month))

    if (monthIndex !== -1) {
        const newDate = new Date()
        const year = newDate.getFullYear()
        const numberOfDays = new Date(year, monthIndex, 0).getDate()
        const zeroFilledArray = new Array(numberOfDays).fill(0)
        return zeroFilledArray.map((el, index) => index + 1)
    }

    return []
}

export const checkFilesSize = ({ filesObjArr, lowerLimitInMB = 0, upperLimitInMB = 10, _translateService }) => {
    const invalidFiles = []
    const lowerLimit = lowerLimitInMB && lowerLimitInMB * 1000 * 1024
    const upperLimit = upperLimitInMB && upperLimitInMB * 1000 * 1024

    filesObjArr.forEach((fileObj) => {
        let isCurrentFileWithinSizeLimit = true
        const { size, name } = fileObj

        if (lowerLimit && size < lowerLimit) {
            isCurrentFileWithinSizeLimit = false
            invalidFiles.push({ fileName: name, fileError: _translateService.instant("Size is less than", { limit: lowerLimitInMB }) })
        }

        if (upperLimit && size > upperLimit) {
            isCurrentFileWithinSizeLimit = false
            invalidFiles.push({ fileName: name, fileError: _translateService.instant("Size is more than", { limit: upperLimitInMB }) })
        }

        return isCurrentFileWithinSizeLimit
    })

    const invalidFilesLength = invalidFiles.length
    if (invalidFilesLength) {
        console.log("checkFilesSize ~ invalidFiles", invalidFiles)
    }

    return {
        sizeValidity: invalidFilesLength === 0,
        errors: invalidFiles
    }
}

export const getNumberSuffix = (num: number) => {
    if (num > 3 && num < 21) {
        return 'th';
    }
    switch (num % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}

export const validateDate = (day, month) => {
    const dayNumber = parseInt(day)
    const monthIndex = MONTHS.findIndex((item) => item === month)
    if (monthIndex > -1) {
        if (dayNumber > 0) {
            switch (monthIndex) {
                case 0:
                case 2:
                case 4:
                case 6:
                case 7:
                case 9:
                case 11:
                    return dayNumber <= 31;
                case 1:
                    const year = new Date().getFullYear()
                    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0) ? dayNumber <= 29 : dayNumber <= 28;
                case 3:
                case 5:
                case 8:
                case 10:
                    return dayNumber <= 30;
                default:
                    return false;
            }
        }
        return false
    }
    return false
}

export const formatContractStatus = (contractsList: Array<any>) => {
    return contractsList.map(({ contractStatus, ...contractData }) => ({
        ...contractData,
        contractStatus,
        contractStatusToShow:
            contractStatus === 'OUTSTANDING'
                ? 'O/S'
                : contractStatus === 'ACTIVE'
                    ? 'LIVE (with SLA)'
                    : contractStatus === 'PASSIVE'
                        ? 'LIVE (w/o SLA)'
                        : contractStatus
    }))
}

export const getBootstrapTextClassBasedOnPriority = (priority) => {
    switch ((priority).toLowerCase()) {
        case "low":
            return "text-primary";
        case "medium":
            return "text-warning";
        case "high":
            return "text-danger"
        default:
            return "text-primary";
    }
}
export const getTextColorBasedOnBgColor = (bgColorInHex) => {
    var threshold = 149;
    let r: any = 0, g: any = 0, b: any = 0;

    // 3 digits
    if (bgColorInHex.length === 4) {
        r = "0x" + bgColorInHex[1] + bgColorInHex[1];
        g = "0x" + bgColorInHex[2] + bgColorInHex[2];
        b = "0x" + bgColorInHex[3] + bgColorInHex[3];
        // 6 digits
    } else if (bgColorInHex.length === 7) {
        r = "0x" + bgColorInHex[1] + bgColorInHex[2];
        g = "0x" + bgColorInHex[3] + bgColorInHex[4];
        b = "0x" + bgColorInHex[5] + bgColorInHex[6];
    }
    return ((r * 0.299 + g * 0.587 + b * 0.114) > threshold) ? '#000000' : '#ffffff';

}

export const getTaskRiskFromScore = (score) => {
    switch (true) {
        case score < 40:
            return "LOW";
        case score < 65:
            return "MEDIUM";
        case score >= 65:
            return "HIGH";
        default:
            return "NONE";
    }
}
