import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { UsersEffect } from "./effect";
import { UsersStateReducer } from "./reducer";

import { allRoles } from "../roles/store.module";
import { RoleName } from "app/auth/models";
import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([UsersEffect]),
        StoreModule.forFeature('users', UsersStateReducer)
    ]
})

export class UsersStoreModule { }

export const allUsers = (store: any) => store && store.users && store.users.allUsers;
export const allUsersBySearch = (store: any) => store && store.users && store.users.allUsersBySearch;
export const userById = (store: any) => store && store.users && store.users.userById;
export const addUserResponse = (store: any) => store && store.users && store.users.addUserResponse;
export const addGuestUserResponse = (store: any) => store && store.users && store.users.addGuestUserResponse;
export const updateUserResponse = (store: any) => store && store.users && store.users.updateUserResponse;
export const deleteUserResponse = (store: any) => store && store.users && store.users.deleteUserResponse;
export const userProfileById = (store: any) => store && store.users && store.users.userProfileById;
export const updateUserProfileByIdResponse = (store: any) => store && store.users && store.users.updateUserProfileByIdResponse;


export const allUsersWithRoleNamesAndFullName = createSelector(
    allUsersBySearch,
    allRoles,
    helperValues,
    (users, roles, values) => {
        const salutation = values?.salutation || [];
        return (users || []).map((item) => {
            const salutationId = item?.salutation || '';
            const salutationValue = (salutationId && salutationId !== "NONE" && salutationId !== "OTHER") ? salutation.find(({ id }) => id === salutationId)?.name || "" : "";
            return {
                ...item,
                // fullName: `${salutationValue && salutationValue !== 'None' ? `${salutationValue} ` : ''}${item.firstName ? `${item.firstName} ` : ''}${item.lastName ? `${item.lastName}` : ''}`,
                fullName: `${salutationValue ? `${salutationValue} ` : ''}${item.firstName ? `${item.firstName}` : ''}`,
                roleName: (roles || []).find(({ name }) => name === item.role)?.description || RoleName[item.role] || item.role || ""
            }
        })
    }
)
