import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';
import { CommonUtil } from "app/utils/common.util";

@Injectable()
export class MeterService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store,
        private commonUtil: CommonUtil
    ) {
    }

    public getAllMeters(id) {
        return this.http.get(
            this.urlUtil.getUrl('meters', 'getAll').replace('$FIRM_ID', this.commonUtil.getUserDetail('firmId')))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllMeters error =>", e);
                    return of([]);
                })
            )
    }

    public exportMeter() {
        return this.http.get(
            this.urlUtil.getUrl('meters', 'export').replace('$FIRM_ID', this.commonUtil.getUserDetail('firmId')))
            .pipe(
                map((resp: any) => {
                    const { status, data, jsonData, errorMessage } = resp || {};
                    return {
                        status: status,
                        message: data || jsonData || errorMessage || this._translateService.instant("ErrorGeneral")
                    };
                }),
                catchError((e) => {
                    console.log("exportMeters error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    });
                })
            )
    }

    public searchByField(data = null) {
        const { key, value } = data;
        return this.http.get(
            this.urlUtil.getUrl('meters', 'searchByField')
                .replace('$KEY', key)
                .replace('$VALUE', value))
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of(null);
                })
            )
    }

    public getMeterById(id) {
        return this.http.get(
            this.urlUtil.getUrl('meters', 'getById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getMeterById error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }

    public updateMeter(dataObject) {
        return this.http.put(
            this.urlUtil.getUrl('meters', 'update').replace('$ID', dataObject.id), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("updateMeter error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }
}
