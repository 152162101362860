import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { createSelector, StoreModule } from "@ngrx/store";
import { MyTaskEffect } from "./effect";
import { MyTaskStateReducer } from "./reducer";
import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([MyTaskEffect]),
        StoreModule.forFeature('myTask', MyTaskStateReducer)
    ]
})

export class MyTaskStoreModule { }

export const allTasks = (store: any) => store && store.myTask && store.myTask.allTasks;
export const taskById = (store: any) => store && store.myTask && store.myTask.taskById;
export const userList = (store: any) => store && store.myTask && store.myTask.userList;
export const taskChartDataByUserId = (store: any) => store && store.myTask && store.myTask.taskChartDataByUserId;
export const addTaskResponse = (store: any) => store && store.myTask && store.myTask.addTaskResponse;
export const updateTaskResponse = (store: any) => store && store.myTask && store.myTask.updateTaskResponse;
export const updateTaskStatusResponse = (store: any) => store && store.myTask && store.myTask.updateTaskStatusResponse;
export const reassignTaskResponse = (store: any) => store && store.myTask && store.myTask.reassignTaskResponse;
export const createCalendarEventResponse = (store: any) => store && store.myTask && store.myTask.createCalendarEventResponse;
export const deleteTaskResponse = (store: any) => store && store.myTask && store.myTask.deleteTaskResponse;

export const allProjects = (store: any) => store && store.myTask && store.myTask.allProjects;
export const projectById = (store: any) => store && store.myTask && store.myTask.projectById;
export const addProjectResponse = (store: any) => store && store.myTask && store.myTask.addProjectResponse;
export const updateProjectResponse = (store: any) => store && store.myTask && store.myTask.updateProjectResponse;
export const deleteProjectResponse = (store: any) => store && store.myTask && store.myTask.deleteProjectResponse;

export const allTasksWithPriority = createSelector(
    allTasks, helperValues, (list, values) => {
        const priority = values?.priority || [];
        return (list || []).map(item => {
            const priorityId = item?.priority || '';
            return {
                ...item,
                priorityValue: priority.find(({ id }) => id === priorityId)?.name || priorityId || '',
            }
        })
    }
)
