import { Role } from "app/auth/models"

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const DAYS_SHORT = [
    { value: 'Mon', label: 'Monday' },
    { value: 'Tue', label: 'Tuesday' },
    { value: 'Wed', label: 'Wednesday' },
    { value: 'Thu', label: 'Thursday' },
    { value: 'Fri', label: 'Friday' },
    { value: 'Sat', label: 'Saturday' },
    { value: 'Sun', label: 'Sunday' },
];

export const DEFAULT_CURRENCY = "£"

export const LOCAL_STORAGE_ENCRYPTION_KEY = "123qwe123qwe"

export const RISK_MATRIX_COLORS = ["#B7DF42", "#FFD966", "#FFC000", "#FF6B65"];
export const MATTER_DOCUMENT_TYPES = [
    { "groupName": "clientIdDetail", "documentKey": "clientIdDocument" },
    { "groupName": "legalDocuments", "documentKey": "clientCareLetter" },
    { "groupName": "legalDocuments", "documentKey": "termAndCondition" },
    { "groupName": "legalDocuments", "documentKey": "consentForm" },
    { "groupName": "legalDocuments", "documentKey": "cfaDocument" },
    { "groupName": "legalDocuments", "documentKey": "complianceDocument" },
    { "groupName": "legalDocuments", "documentKey": "insuranceDocument" },
    { "groupName": "legalDocuments", "documentKey": "otherDocument" }
];

export const LOCALSTORAGE_DATA = {
    CURRENT_USER: 'currentUser',
    AUTH_DATA: 'authData',
    USER_ACCESS: 'userAccess',
    LANDING_ROUTE: 'landingRoute',
    AUTH_TOKEN: 'authToken'
}

export const COOKIE_KEY = {
    AUTH: 'auth'
}

export const TOASTR_TYPE = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
}

export const INTERVAL_ID_OPTIONS = [
    { id: 1, name: "5 minutes" },
    { id: 2, name: "10 minutes" },
    { id: 3, name: "15 minutes" },
    { id: 4, name: "30 minutes" },
    { id: 5, name: "1 hour" },
    { id: 6, name: "2 hours" },
    { id: 7, name: "4 hours" }
]

export const GENDER_OPTIONS = [
    { name: "Male", id: "Male" },
    { name: "Female", id: "Female" },
    { name: "Others", id: "Others" }
]

export const CONFIRM_DEFAULT_TITLE = "Confirmation";
export const CONFIRM_DEFAULT_MESSAGE = "Are you sure?";

export const CONFIRM_DELETE_TITLE = "Delete Confirmation";
export const CONFIRM_DELETE_MESSAGE = "Are you sure you want to delete selected record(s)?";

export const SOURCE_TYPE_OPTIONS = [
    { id: "FILE", name: "FILE" },
    { id: "URL", name: "URL" }
];

export const SAVE_AS_NOTE_MODULE_OPTIONS = [
    { id: "CASE_MATTER", name: "KaiCase" },
    // { id: "PRE_ACTION_PROTOCOL", name: "Pre Action Protocol" },
    // { id: "MEDICAL_EVIDENCE", name: "Medical Evidence" }
];

export const LEVELS = [
    { id: 'HIGH', name: 'HIGH' },
    { id: 'MEDIUM', name: 'MEDIUM' },
    { id: 'LOW', name: 'LOW' }
];

export const ALLOWED_ROLES_IN_ROLE_BASED_TASK = [Role.MANAGING_PARTNER, Role.PARTNER, Role.LAWYER, Role.PARA_LEGAL, Role.PERSONAL_ASSISTANT]

export const CALENDAR_EVENT_TYPES_OPTIONS = [
    { id: 'MEETING', name: 'Meeting' },
    { id: 'KAI_TASK', name: 'Kai Task' }
];

export const CALENDAR_EVENT_TASK_MODULES_OPTIONS = [
    { id: 'ENQUIRY', name: 'Enquiry' },
    { id: 'MATTER', name: 'KaiCase' },
    { id: 'CLIENT', name: 'Client' },
    { id: 'FIRM', name: 'Firm' },
    { id: 'MISC', name: 'Miscellaneous' },
];

export const TASK_CHART_STATUS_COLORS = {
    Dropped: '#27ae60', // Green
    Delayed: '#f39c12', // Amber
    InRisk: '#e74c3c', // Red
    OnTrack: '#1abc9c' // Blue
};

export const TASK_CHART_STATUSES = {
    Dropped: 'Completed',
    Delayed: 'Delayed',
    InRisk: 'Urgent',
    OnTrack: 'Outstanding / On Track'
};
