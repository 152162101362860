import { environment } from "environments/environment";
export const KAI_AI_SERVICES_BASE_URL = environment.kaiApiUrl;

export const URL_CONST = {
    BASE_URL: '',
    AUTHENTICATION: {
        LOG_IN: '/api/login',
        FORGOT_PASSWORD: '/api/change-password',
        RESET_PASSWORD: '/api/resetPassword',
        LOGOUT: '/api/logout/$USER_ID',
        REFRESH_TOKEN: '/api/renew-token'
    },
    CONFIG: {
        APP_CONFIG: '/api/config'
    },
    ACCESS: {
        APP_MODULE_ACCESS: '/api/access'
    },
    PROFILE: {
        UPDATE: '/api/users/$ID',
        USER_INFO: '/api/user-info'
    },
    COMMON: {
        GET_HELPER_VALUES: '/api/value-help'
    },
    FILE: {
        UPLOAD: '/api/files/upload',
        UPLOAD_ALL: '/api/files/upload/all',
        DOWNLOAD: '/api/files/download/$ID',
        DELETE: '/api/files/delete/$ID',
        KAI_DOWNLOAD: '/api/files/download/$ID',
        KAI_UPLOAD: '/api/files/upload',
        KAI_UPLOAD_ALL: '/api/files/uploads'
        // KAI_DOWNLOAD: KAI_AI_SERVICES_BASE_URL + '/api/v1/files/download/$ID',
        // KAI_UPLOAD: KAI_AI_SERVICES_BASE_URL + '/api/v1/files/upload',
        // KAI_UPLOAD_ALL: KAI_AI_SERVICES_BASE_URL + '/api/v1/files/uploads'
    },
    USERS: {
        GET_ALL: '/api/users',
        SEARCH_BY_ID: '/api/users/$ID',
        ADD: '/api/users',
        UPDATE: '/api/users/$ID',
        DELETE: '/api/users/delete/all',
        ADD_GUEST: '/api/users/guest',
        GET_PROFILE_BY_ID: '/api/users/$USER_ID/profile',
        UPDATE_PROFILE_BY_ID: '/api/users/$USER_ID/profile/$PROFILE_ID',
        SEARCH_OPTIONS: '/api/users/search/options',
        SEARCH_BY_FIELD: '/api/users/search/$KEY/$VALUE?page=$PAGE&size=$SIZE',
        SEARCH_BY_ROLE: '/api/users/userRole/$USER_ROLE'
    },
    ROLES: {
        GET_ALL: '/api/roles',
        SEARCH_BY_ID: '/api/roles/$ID',
        ADD: '/api/roles',
        UPDATE: '/api/roles/$ID',
        DELETE: '/api/roles/delete/all'
    },
    FIRMS: {
        GET_ALL: '/api/firms',
        SEARCH_BY_ID: '/api/firms/$ID',
        ADD: '/api/firms',
        UPDATE: '/api/firms/$ID',
        DELETE: '/api/firms/delete/all',
        GET_SEARCH_OPTION: '//api/sign-document/search/options',
        TEST_CONNECTIVITY: '/api/firms/sign-document/test-connectivity',
        TEST_EMAIL_CONNECTIVITY: '/api/firms/email-setup/test-connectivity',
        DOCUMENT_SIGNATURE: '/api/firms/sign-document/send-file',
        DOCUMENT_SIGNATURE_TRACK_DETAILS: '/api/sign-document/track-details',
        DOCUMENT_SIGNATURE_SEARCH_BY_FIELDS: '/api/sign-document/search/$KEY/$VALUE?page=$PAGE&size=$SIZE',
        DOCUMENT_SIGNATURE_REMINDER: '/api/sign-document/reminder/$ID',
        DOWNLOAD_SIGN_DOCUMENT: '/api/sign-document/download/$ID',
        DOWNLOAD_SIGN_SEARCH_OPTIONS: '/api/firms/sign-document/search-options',
        GET_LEADS: '/api/leads?page=$PAGE&size=$SIZE',
        DOWNLOAD_LEADS: '/api/leads/download/$ID'
    },
    NOTIFICATIONS: {
        GET_ALL: '/api/notification-template/firm/$FIRM_ID',
        ADD: '/api/notification-template',
        UPDATE: '/api/notification-template/$ID',
        DELETE: '/api/notification-template/delete/all',
        TEMPLATE: '/api/notification-template/variables',
        SEARCH_BY_ID: '/api/notification-template/$ID',
        SEARCH_BY_FIELDS: '/api/notification-template/search/$KEY/$VALUE?page=$PAGE&size=$SIZE',
        GET_SEARCH_OPTION: '/api/notification-template/search/options',
        SEARCH_BY_NAME: '/api/notification-template/autoComplete'
    },
    AUDIT_LOGS: {
        GET: '/api/logs/filterLogs',
        GET_FILTERED: '/api/logs/filterLogs',
        DOWNLOAD_CSV: '/api/logs/download'
    },
    ENQUIRY: {
        GET_ALL: '/api/case-enquiry',
        GET_BY_ID: '/api/case-enquiry/$ID',
        ADD: '/api/case-enquiry',
        UPDATE: '/api/case-enquiry/$ID',
        DELETE: '/api/case-enquiry/delete/all',
        GET_EXTRACTION: '/api/case-enquiry/extraction',
        IMPORT: '/api/case-enquiry/import',
        SEARCH_OPTIONS: '/api/case-enquiry/search/options',
        SEARCH_BY_FIELD: '/api/case-enquiry/search/$KEY/$VALUE?page=$PAGE&size=$SIZE'
    },
    METER: {
        GET_ALL: '/api/meters/list/$FIRM_ID',
        GET_BY_ID: '/api/meters/$ID',
        UPDATE: '/api/meters/$ID',
        EXPORT: '/api/meters/export/$FIRM_ID',
        SEARCH_OPTIONS: '/api/meters/search/options',
        SEARCH_BY_FIELD: '/api/meters/search/$KEY/$VALUE'
    },
    TASK: {
        GET_ALL: '/api/tasks/list/$USER_ID',
        SEARCH_BY_ID: '/api/tasks/$ID',
        GET_TASK_CHART_DATA_BY_USER_ID: '/api/tasks/chart-data/$USER_ID',
        ADD: '/api/tasks',
        UPDATE: '/api/tasks/$ID',
        UPDATE_STATUS: '/api/tasks/update-status/$ID',
        DELETE: '/api/tasks/delete/all',
        ASSIGN_USER_LIST: '/api/tasks/assignList/$USER_ID',
        REASSIGN_TASK: '/api/tasks/reassign/$ID',
        CREATE_CALENDAR_EVENT: '/api/tasks/sync-task/$ID?startTime=$DATE'
    },
    PROJECT: {
        GET_ALL: '/api/projects',
        SEARCH_BY_ID: '/api/projects/$ID',
        ADD: '/api/projects',
        UPDATE: '/api/projects/$ID',
        DELETE: '/api/projects/delete/all'
    },
    SYSTEM_CALENDAR: {
        GET_CALENDAR_EVENT: '/api/calendar-events',
        ADD_CALENDAR_EVENT: '/api/calendar-events',
        UPDATE_CALENDAR_EVENT: '/api/calendar-events/$ID',
        SEARCH_BY_ID: '/api/calendar-events/$ID',
        DELETE: '/api/calendar-events/$ID',
        SYNC_CALENDAR_DATA: '/api/calendar-events/microsoft/sync',
        SYNC_CALENDAR: '/api/calendar-events/microsoft/authorize?token=$TOKEN'
    },
    MATTERS: {
        GET_ALL: '/api/matters',
        SEARCH_BY_ID: '/api/matters/$ID',
        ADD: '/api/matters',
        UPDATE: '/api/matters/$ID',
        DELETE: '/api/matters/delete/all',
        SEARCH_OPTIONS: '/api/matters/search/options',
        SEARCH_BY_FIELD: '/api/matters/search/$KEY/$VALUE?page=$PAGE&size=$SIZE',
        CLIENTS_BY_MATTER_ID: '/api/matters/clients/$ID',
        GET_FILE_COMPONENT_DATA: '/api/matters/$ID/get-file-component-data',
        ADD_FILE_COMPONENT_DATA: '/api/matters/$ID/add-file-component',
        UPDATE_FILE_COMPONENT_DATA: '/api/matters/$ID/update-file-component/$DATA_ID',
        DELETE_FILE_COMPONENT_DATA: '/api/matters/$ID/delete-file-component/$DATA_ID'
    },
    CLIENTS: {
        GET_ALL: '/api/client-profiles',
        SEARCH_BY_ID: '/api/client-profiles/$ID',
        MATTERS_BY_CLIENT_ID: '/api/client-profiles/matters/$ID',
        SEARCH_BY_FIELDS: '/api/client-profiles/search/$KEY/$VALUE?page=$PAGE&size=$SIZE',
        SEARCH_OPTIONS: '/api/client-profiles/search/options',
        UPDATE: '/api/client-profiles/$ID',
        DELETE: '/api/client-profiles/delete/all'
    },
    SPEECH_AI: {
        GET_ALL: KAI_AI_SERVICES_BASE_URL + '/api/s2T',
        SEARCH_BY_ID: KAI_AI_SERVICES_BASE_URL + '/api/s2T/$ID',
        ADD: KAI_AI_SERVICES_BASE_URL + '/api/s2T',
        DELETE: KAI_AI_SERVICES_BASE_URL + '/api/s2T/delete/all',
        SAVE_AS_NOTE: KAI_AI_SERVICES_BASE_URL + '/api/s2T/$ID/add-notes',
        RUN_EXTRACTION: KAI_AI_SERVICES_BASE_URL + '/api/s2T/$ID/extraction'
    },
    VISION_AI: {
        GET_ALL: KAI_AI_SERVICES_BASE_URL + '/api/vision-ai',
        SEARCH_BY_ID: KAI_AI_SERVICES_BASE_URL + '/api/vision-ai/$ID',
        ADD: KAI_AI_SERVICES_BASE_URL + '/api/vision-ai',
        DELETE: KAI_AI_SERVICES_BASE_URL + '/api/vision-ai/delete/all',
        SAVE_AS_NOTE: KAI_AI_SERVICES_BASE_URL + '/api/vision-ai/$ID/add-notes',
        RUN_EXTRACTION: KAI_AI_SERVICES_BASE_URL + '/api/vision-ai/$ID/extraction'
    },
    RESEARCH_AI: {
        GET_ALL: KAI_AI_SERVICES_BASE_URL + '/api/models',
        SEARCH_BY_ID: KAI_AI_SERVICES_BASE_URL + '/api/models/$ID',
        ADD: KAI_AI_SERVICES_BASE_URL + '/api/models',
        UPDATE: KAI_AI_SERVICES_BASE_URL + '/api/models/$ID',
        DELETE: KAI_AI_SERVICES_BASE_URL + '/api/models/delete/all',
        ADD_SOURCES: KAI_AI_SERVICES_BASE_URL + '/api/models/source/add',
        REMOVE_SOURCES: KAI_AI_SERVICES_BASE_URL + '/api/models/source/remove',
        TRAIN: KAI_AI_SERVICES_BASE_URL + '/api/models/train/$ID',
        QUERY: KAI_AI_SERVICES_BASE_URL + '/api/models/query'
    },
    BILLING: {
        GET_ALL: '/api/billings?page=$PAGE&size=$SIZE',
        SEARCH_BY_ID: '/api/billings/$ID',
        ADD: '/api/billings',
        UPDATE: '/api/billings/$ID',
        DELETE: '/api/billings/delete/all',
        DOWNLOAD_BILLING: '/api/billings/download/$ID'
    },
    DATA_CONNECTORS: {
        GET_ALL: '/api/data-connector',
        CLEAN: '/api/data-connector/clean-action',
        UPLOAD: '/api/data-connector/upload-action'
    }
}
