import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';

import { CommonUtil } from 'app/utils/common.util';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { deleteCalendarEventResponse } from 'app/store/operation/calendar/store.module';
import { helperValues } from 'app/store/common/commonStore.module';
import { DeleteCalendarEvent, DeleteCalendarEventResponse, GetCalendarData } from 'app/store/operation/calendar/actions';
import { PresentToastr } from 'app/store/common/actions';
import { CONFIRM_DELETE_MESSAGE, CONFIRM_DELETE_TITLE, TOASTR_TYPE } from 'app/constants/common.constant';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModuleAccessValue } from 'app/interface';
import { GetAllFirms } from 'app/store/setup/firms/actions';
import { GetAllClients } from 'app/store/operation/clients/actions';
import { GetAllUsers } from 'app/store/setup/users/actions';

@Component({
    selector: 'app-calendar-event-view-modal',
    templateUrl: './calendar-event-view-modal.component.html',
    styleUrls: ['./calendar-event-view-modal.component.scss']
})
export class CalendarEventViewModalComponent implements OnInit, OnDestroy {
    @Input() moduleAccess: ModuleAccessValue;
    @Input() IsEventTabEditable: boolean = false;
    @Input() isHomeCalendar: boolean = false;;
    @Input() title: String = ''
    @Input() calendarEventForm: FormGroup;
    @Input() eventModalRef: any;
    @Input() handleEditClick: Function
    @Input() handleCloseClick: Function;

    public modalRef: any;
    formSubmitted: boolean = false;

    public currentUser: any;
    public eventDate: string = '';
    public eventData: any;

    unsubscribe = new Subject();

    constructor(
        private store: Store,
        private router: Router,
        private modalService: NgbModal,
        private commonUtil: CommonUtil,
        private _translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.currentUser = this.commonUtil.getUserDetail();
        this.eventDate = this.commonUtil.formatDateRange(this.ReactiveFormValues?.startTime, this.ReactiveFormValues?.endTime);

        this.eventData = cloneDeep({ ...this.ReactiveFormValues });

        this.store.select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    const taskModule = (resp?.taskModule || []).filter(module => this.eventData?.kaiTaskDetails?.taskModule === module?.id);
                    if (taskModule?.length) {
                        this.eventData.kaiTaskDetails.taskModule = taskModule[0]?.name;
                    }
                }
            })

        this.store.select(deleteCalendarEventResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (resp) => {
                    if (resp) {
                        if (resp.status) {
                            this.store.dispatch(new GetCalendarData());

                            this.store.dispatch(new PresentToastr({
                                type: TOASTR_TYPE.SUCCESS,
                                message: resp.message
                            }))

                            this.modalRef.close();
                            this.eventModalRef.close();
                            if (this.isHomeCalendar) {
                                this.router.navigate(['/operation/home']);
                            } else {
                                this.router.navigate(['/operation/systemCalendar']);
                            }
                        } else {
                            this.store.dispatch(new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: resp.message
                            }))
                        }
                        this.store.dispatch(new DeleteCalendarEventResponse(null))
                    }
                })
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.eventData = {};
    }

    get ReactiveFormControl() {
        return this.calendarEventForm.controls;
    }

    get ReactiveFormValues() {
        return this.calendarEventForm.getRawValue();
    }

    get MeetingDetailsFormControl() {
        return this.calendarEventForm.get("meetingDetails")['controls'];
    }

    get KaiTaskDetailsFormControl() {
        return this.calendarEventForm.get("kaiTaskDetails")['controls'];
    }

    get IsEventDeletable() {
        return (this.ReactiveFormValues?.id && this.moduleAccess?.["canDelete"])
    }

    onEditClick() {
        this.handleEditClick();
    }

    onCancelClick() {
        this.calendarEventForm.reset({ eventColor: "#0A2746" });
        this.handleCloseClick();
    }

    onDeleteClick(deleteId) {
        this.openDeleteConfirmModal(deleteId);
    }

    openDeleteConfirmModal(deleteId) {
        this.modalRef = this.modalService.open(ConfirmModalComponent, {
            windowClass: 'modal',
            centered: true
        });

        this.modalRef.componentInstance.title = this._translateService.instant(CONFIRM_DELETE_TITLE);
        this.modalRef.componentInstance.message = this._translateService.instant(CONFIRM_DELETE_MESSAGE)

        this.modalRef.componentInstance.handleConfirmClick = () => {
            this.store.dispatch(new DeleteCalendarEvent(deleteId));
        }
        this.modalRef.componentInstance.handleCancelClick = () => this.modalRef.close();
    }
}
