import { CoreConfig } from "@core/types";
import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";
import {
    PRESENT_TOASTR, DISABLE_TOASTR, UPLOAD_FILE, UPLOAD_FILE_RESP, DOWNLOAD_FILE, DOWNLOAD_FILE_RESP, GET_USER_ACCESS, SET_USER_ACCESS,
    SET_SEARCH_OPTIONS, GET_SEARCH_OPTIONS, GET_APP_CONFIG, APP_CONFIG, SET_TABLE_SEARCH_PARAM, SET_PERSISTED_SEARCH,
    GET_HELPER_VALUES, SET_HELPER_VALUES,
    GET_UNIPAAS_PAYMENT_LINK, SET_UNIPAAS_PAYMENT_LINK_RESPONSE
} from "./actionTypes";
import { IToastr, SearchParam } from "./state";

export class GetAppConfig implements Action {
    readonly type = GET_APP_CONFIG;
}

export class AppConfig implements Action {
    readonly type = APP_CONFIG;
    readonly payload: CoreConfig = null;
    constructor(data: CoreConfig) {
        this.payload = data;
    }
}
export class PresentToastr implements Action {
    readonly type = PRESENT_TOASTR;
    readonly payload = null;
    constructor(data: IToastr) {
        this.payload = data;
    }
}

export class DisableToastr implements Action {
    readonly type = DISABLE_TOASTR;
}

export class UploadFile implements Action {
    readonly type = UPLOAD_FILE;
    readonly payload = null;
    constructor(data) {
        this.payload = data
    }
}

export class UploadFileResp implements Action {
    readonly type = UPLOAD_FILE_RESP;
    readonly payload = null;
    constructor(data) {
        this.payload = data
    }
}

export class DownloadFile implements Action {
    readonly type = DOWNLOAD_FILE;
    readonly payload = null;
    constructor(data) {
        this.payload = data
    }
}

export class DownloadFileResp implements Action {
    readonly type = DOWNLOAD_FILE_RESP;
    readonly payload = null;
    constructor(data) {
        this.payload = data
    }
}

export class GetUserAccess implements Action {
    readonly type = GET_USER_ACCESS;
}

export class SetUserAccess implements Action {
    readonly type = SET_USER_ACCESS;
    readonly payload = null;
    constructor(accessData) {
        this.payload = accessData;
    }
}

export class GetSearchOptions implements Action {
    readonly type = GET_SEARCH_OPTIONS;
    readonly payload = null;
    constructor(moduleId) {
        this.payload = moduleId;
    }
}

export class SetSearchOptions implements Action {
    readonly type = SET_SEARCH_OPTIONS;
    readonly payload = null;
    constructor(searchOptions) {
        this.payload = searchOptions;
    }
}

export class SetTableSearchParam implements Action {
    readonly type = SET_TABLE_SEARCH_PARAM;
    readonly payload = null;
    constructor(searchField: string = 'select', searchValue: string | number = 'all') {
        this.payload = {
            searchField,
            searchValue
        }
    }
}

export class SetPersistedSearch implements Action {
    readonly type = SET_PERSISTED_SEARCH;
    readonly payload = null;
    constructor(moduleId, searchParam: SearchParam) {
        this.payload = {
            moduleId,
            searchParam
        }
    }
}

export class GetHelperValues implements Action {
    readonly type = GET_HELPER_VALUES;
}

export class SetHelperValues implements Action {
    readonly type = SET_HELPER_VALUES;
    readonly payload = {}
    constructor(dataObject: any) {
        this.payload = dataObject;
    }
}

export class GetUnipaasPaymentLink implements Action {
    readonly type = GET_UNIPAAS_PAYMENT_LINK;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetUnipaasPaymentLinkResponse implements Action {
    readonly type = SET_UNIPAAS_PAYMENT_LINK_RESPONSE;
    readonly payload = null;
    constructor(data: IServerResponse) {
        this.payload = data;
    }
}

export type Actions = PresentToastr | DisableToastr | UploadFile | UploadFileResp | DownloadFile | DownloadFileResp |
    GetUserAccess | SetUserAccess | GetSearchOptions | SetSearchOptions | GetAppConfig | AppConfig |
    SetTableSearchParam | SetPersistedSearch | GetHelperValues | SetHelperValues | 
    GetUnipaasPaymentLink | SetUnipaasPaymentLinkResponse;
