import { Action } from '@ngrx/store';
import { IServerResponse } from 'app/interface';

import {
    GET_ALL_TASKS,
    SET_ALL_TASKS,
    GET_TASK_BY_ID,
    SET_TASK_BY_ID,
    ADD_TASK,
    ADD_TASK_RESPONSE,
    UPDATE_TASK,
    UPDATE_TASK_RESPONSE,
    DELETE_TASK,
    DELETE_TASK_RESPONSE,
    GET_ALL_PROJECTS,
    SET_ALL_PROJECTS,
    GET_PROJECT_BY_ID,
    SET_PROJECT_BY_ID,
    ADD_PROJECT,
    ADD_PROJECT_RESPONSE,
    UPDATE_PROJECT,
    UPDATE_PROJECT_RESPONSE,
    DELETE_PROJECT,
    DELETE_PROJECT_RESPONSE,
    UPDATE_TASK_STATUS,
    UPDATE_TASK_STATUS_RESPONSE,
    GET_TASK_CHART_DATA_BY_USER_ID,
    SET_TASK_CHART_DATA_BY_USER_ID,
    SET_ASSIGN_USER_LIST,
    GET_ASSIGN_USER_LIST,
    REASSIGN_TASK,
    REASSIGN_TASK_RESPONSE,
    CREATE_CALENDAR_EVENT,
    CREATE_CALENDAR_EVENT_RESPONSE
} from './actionTypes';

export class GetAllTasks implements Action {
    readonly type = GET_ALL_TASKS;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetAllTasks implements Action {
    readonly type = SET_ALL_TASKS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetAllProjects implements Action {
    readonly type = GET_ALL_PROJECTS;
}

export class SetAllProjects implements Action {
    readonly type = SET_ALL_PROJECTS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetTaskChartDataByUserId implements Action {
    readonly type = GET_TASK_CHART_DATA_BY_USER_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetTaskChartDataByUserId implements Action {
    readonly type = SET_TASK_CHART_DATA_BY_USER_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetTaskById implements Action {
    readonly type = GET_TASK_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetTaskById implements Action {
    readonly type = SET_TASK_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetProjectById implements Action {
    readonly type = GET_PROJECT_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetProjectById implements Action {
    readonly type = SET_PROJECT_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetAssignUserList implements Action {
    readonly type = GET_ASSIGN_USER_LIST;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetAssignUserList implements Action {
    readonly type = SET_ASSIGN_USER_LIST;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddTask implements Action {
    readonly type = ADD_TASK;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class AddTaskResponse implements Action {
    readonly type = ADD_TASK_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddProject implements Action {
    readonly type = ADD_PROJECT;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class AddProjectResponse implements Action {
    readonly type = ADD_PROJECT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateTask implements Action {
    readonly type = UPDATE_TASK;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateTaskResponse implements Action {
    readonly type = UPDATE_TASK_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateTaskStatus implements Action {
    readonly type = UPDATE_TASK_STATUS;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateTaskStatusResponse implements Action {
    readonly type = UPDATE_TASK_STATUS_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class ReassignTask implements Action {
    readonly type = REASSIGN_TASK;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class ReassignTaskResponse implements Action {
    readonly type = REASSIGN_TASK_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateProject implements Action {
    readonly type = UPDATE_PROJECT;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateProjectResponse implements Action {
    readonly type = UPDATE_PROJECT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteTask implements Action {
    readonly type = DELETE_TASK;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray;
    }
}

export class DeleteTaskResponse implements Action {
    readonly type = DELETE_TASK_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteProject implements Action {
    readonly type = DELETE_PROJECT;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray;
    }
}

export class DeleteProjectResponse implements Action {
    readonly type = DELETE_PROJECT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class CreateCalendarEvent implements Action {
    readonly type = CREATE_CALENDAR_EVENT;
    readonly payload: string = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class CreateCalendarEventResponse implements Action {
    readonly type = CREATE_CALENDAR_EVENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions =
    | GetAllTasks
    | SetAllTasks
    | GetTaskById
    | SetTaskById
    | AddTask
    | AddTaskResponse
    | UpdateTask
    | UpdateTaskResponse
    | DeleteTask
    | DeleteTaskResponse
    | GetAllProjects
    | SetAllProjects
    | GetProjectById
    | SetProjectById
    | AddProject
    | AddProjectResponse
    | UpdateProject
    | UpdateProjectResponse
    | DeleteProject
    | DeleteProjectResponse
    | UpdateTaskStatus
    | UpdateTaskStatusResponse
    | GetTaskChartDataByUserId
    | SetTaskChartDataByUserId
    | GetAssignUserList
    | SetAssignUserList
    | ReassignTask
    | ReassignTaskResponse
    | CreateCalendarEvent 
    | CreateCalendarEventResponse;
