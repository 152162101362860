export interface NotificationsState {
    notificationsList: Array<any> | null;
    notificationsByFieldList: Array<any> | null;
    notificationsById: any,
    notificationsTemplateList: Array<any> | null;
    addNotificationsResponse: any;
    updateNotificationsResponse: any;
    deleteNotificationsResponse: any;

}

export const initialState: NotificationsState = {
    notificationsList: null,
    notificationsByFieldList: null,
    notificationsById: null,
    notificationsTemplateList: null,
    addNotificationsResponse: null,
    updateNotificationsResponse: null,
    deleteNotificationsResponse: null
}
