import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_BILLING_DATA, SET_ALL_BILLING_DATA, GET_BILLING_BY_ID, SET_BILLING_BY_ID,
    ADD_BILLING, ADD_BILLING_RESPONSE, UPDATE_BILLING, UPDATE_BILLING_RESPONSE,
    DELETE_BILLING, DELETE_BILLING_RESPONSE,
    GET_BILLING_BY_FIELD,
    SET_BILLING_BY_FIELD
} from "./actionTypes"

export class GetAllBillingData implements Action {
    readonly type = GET_ALL_BILLING_DATA;
}

export class GetBillingsByField implements Action {
    readonly type = GET_BILLING_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetBillingsByField implements Action {
    readonly type = SET_BILLING_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetAllBillingData implements Action {
    readonly type = SET_ALL_BILLING_DATA;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetBillingById implements Action {
    readonly type = GET_BILLING_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetBillingById implements Action {
    readonly type = SET_BILLING_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddBilling implements Action {
    readonly type = ADD_BILLING;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class AddBillingResponse implements Action {
    readonly type = ADD_BILLING_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateBilling implements Action {
    readonly type = UPDATE_BILLING;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class UpdateBillingResponse implements Action {
    readonly type = UPDATE_BILLING_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteBilling implements Action {
    readonly type = DELETE_BILLING;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray
    }
}

export class DeleteBillingResponse implements Action {
    readonly type = DELETE_BILLING_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions = GetAllBillingData | SetAllBillingData | GetBillingById | SetBillingById |
    AddBilling | AddBillingResponse | UpdateBilling | UpdateBillingResponse |
    DeleteBilling | DeleteBillingResponse | GetBillingsByField | SetBillingsByField;
