import { NgModule } from "@angular/core";

import { SpeechAiStoreModule } from "./speech-ai/store.module";
import { VisionAiStoreModule } from "./vision-ai/store.module";
import { ResearchAiStoreModule } from "./research-ai/store.module";

@NgModule({
    imports: [
        SpeechAiStoreModule,
        VisionAiStoreModule,
        ResearchAiStoreModule
    ]
})
export class UtilitiesStoreModule { }
