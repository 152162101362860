import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as FirmsAction from './actions';
import { FirmsService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class FirmsEffect {
    constructor(
        private action: Actions,
        private firmsService: FirmsService
    ) { }

    GetAllFirms = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_FIRMS),
        switchMap(() => this.firmsService.getAllFirms()),
        map((data: Array<any>) => new FirmsAction.SetAllFirms(data)
        )
    ));

    GetAllLeads = createEffect(() => this.action.pipe(
        ofType(type.GET_LEADS),
        switchMap(() => this.firmsService.getAllLeads()),
        map((data) => new FirmsAction.SetAllLeads(data))
    ));

    GetFirmById = createEffect(() => this.action.pipe(
        ofType(type.GET_FIRM_BY_ID),
        switchMap((data: any) => this.firmsService.getFirmById(data.payload)),
        map((data: any) => new FirmsAction.SetFirmById(data)
        )
    ));

    AddFirm = createEffect(() => this.action.pipe(
        ofType(type.ADD_FIRM),
        switchMap((data: any) => this.firmsService.addFirm(data.payload)),
        map((data: IServerResponse) => new FirmsAction.AddFirmResponse(data)
        )
    ));

    UpdateFirm = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_FIRM),
        switchMap((data: any) => this.firmsService.updateFirm(data.payload)),
        map((data: IServerResponse) => new FirmsAction.UpdateFirmResponse(data)
        )
    ));

    DeleteFirm = createEffect(() => this.action.pipe(
        ofType(type.DELETE_FIRM),
        switchMap((data: any) => this.firmsService.deleteFirm(data.payload)),
        map((data: IServerResponse) => new FirmsAction.DeleteFirmResponse(data)
        )
    ));

    TestDocumentSignatureConnectivity = createEffect(() => this.action.pipe(
        ofType(type.TEST_DOCUMENT_SIGNATURE_CONNECTIVITY),
        switchMap((data: any) => this.firmsService.testDocumentSignatureConnectivity(data.payload)),
        map((data: IServerResponse) => new FirmsAction.TestDocumentSignatureConnectivityResponse(data)
        )
    ));

    TestEmailConnectivity = createEffect(() => this.action.pipe(
        ofType(type.TEST_EMAIL_CONNECTIVITY),
        switchMap((data: any) => this.firmsService.TestEmailConnectivity(data.payload)),
        map((data: any) => new FirmsAction.TestEmailConnectivityResponse(data)
        )
    ));

    GetDoumentSignatureTrackDetails = createEffect(() => this.action.pipe(
        ofType(type.GET_DOCUMENT_SIGNATURE_TRACK_DETAILS),
        switchMap(() => this.firmsService.getDoumentSignatureTrackDetails()),
        map((data: Array<any>) => new FirmsAction.SetDoumentSignatureTrackDetails(data)
        )
    ));

    GetDoumentSignatureByField = createEffect(() => this.action.pipe(
        ofType(type.GET_DOCUMENT_SIGNATURE_BY_FIELD),
        switchMap((data: any) => this.firmsService.getDoumentSignatureSearchByFields(data.payload)),
        map((data) => new FirmsAction.SetDocumentSignatureByField(data))
    ));

    SendDocumentSignatureReminder = createEffect(() => this.action.pipe(
        ofType(type.SEND_DOCUMENT_SIGNATURE_REMINDER),
        switchMap((data: any) => this.firmsService.sendDocumentSignatureReminder(data.payload)),
        map((data: IServerResponse) => new FirmsAction.SendDocumentSignatureReminderResponse(data)
        )
    ));

}
