import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class VisionAiService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store
    ) {
    }

    public getAllVisionAiDocuments() {
        return this.http.get(
            this.urlUtil.getUrl('vision-ai', 'getAll'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllVisionAiDocuments error =>", e);
                    return of([]);
                })
            )
    }

    public getVisionAiDocumentById(id) {
        return this.http.get(
            this.urlUtil.getUrl('vision-ai', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        if (resp.data instanceof Array) {
                            return resp.data[0] || {};
                        }
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getVisionAiDocumentById error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }

    public addVisionAiDocument({ extractionModel, formData }) {
        return this.http.post(
            this.urlUtil.getUrl('vision-ai', 'add', [
                { key: "actions", value: extractionModel }
            ]), formData)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("addVisionAiDocument error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataAdd")
                    });
                })
            )
    }

    public deleteVisionAiDocument(idsArray) {
        return this.http.post(this.urlUtil.getUrl('vision-ai', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("deleteVisionAiDocument error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataDelete")
                    });
                })
            )
    }

    public saveVisionAiDocumentAsNote({ id, moduleName, moduleId }) {
        return this.http.get(
            this.urlUtil.getUrl('vision-ai', 'saveAsNote', [
                { key: 'moduleName', value: moduleName },
                { key: 'moduleId', value: moduleId }
            ]).replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("saveVisionAiDocumentAsNote error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }

    public runVisionAiExtractionFromSummary({ id, extractionModel }) {
        return this.http.get(
            this.urlUtil.getUrl('vision-ai', 'run-extraction', [
                { key: "actions", value: extractionModel }
            ]).replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("runVisionAiExtractionFromSummary error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }
}
