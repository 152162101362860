import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_FIRMS, SET_ALL_FIRMS, GET_FIRM_BY_ID, SET_FIRM_BY_ID,
    ADD_FIRM, ADD_FIRM_RESPONSE, UPDATE_FIRM, UPDATE_FIRM_RESPONSE,
    DELETE_FIRM, DELETE_FIRM_RESPONSE, SEND_DOCUMENT_SIGNATURE_REMINDER,
    TEST_DOCUMENT_SIGNATURE_CONNECTIVITY, TEST_DOCUMENT_SIGNATURE_CONNECTIVITY_RESPONSE,
    TEST_EMAIL_CONNECTIVITY, TEST_EMAIL_CONNECTIVITY_RESPONSE,
    GET_DOCUMENT_SIGNATURE_TRACK_DETAILS, SET_DOCUMENT_SIGNATURE_TRACK_DETAILS, GET_DOCUMENT_SIGNATURE_BY_FIELD, SET_DOCUMENT_SIGNATURE_BY_FIELD, SEND_DOCUMENT_SIGNATURE_REMINDER_RESPONSE,
    GET_LEADS_RESPONSE,
    GET_LEADS
} from "./actionTypes"

export class GetAllFirms implements Action {
    readonly type = GET_ALL_FIRMS;
}

export class SetAllFirms implements Action {
    readonly type = SET_ALL_FIRMS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetAllLeads implements Action {
    readonly type = GET_LEADS;
}

export class SetAllLeads implements Action {
    readonly type = GET_LEADS_RESPONSE;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}


export class GetDocumentSignatureByField implements Action {
    readonly type = GET_DOCUMENT_SIGNATURE_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetDocumentSignatureByField implements Action {
    readonly type = SET_DOCUMENT_SIGNATURE_BY_FIELD;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetFirmById implements Action {
    readonly type = GET_FIRM_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetFirmById implements Action {
    readonly type = SET_FIRM_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddFirm implements Action {
    readonly type = ADD_FIRM;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class AddFirmResponse implements Action {
    readonly type = ADD_FIRM_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateFirm implements Action {
    readonly type = UPDATE_FIRM;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class UpdateFirmResponse implements Action {
    readonly type = UPDATE_FIRM_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteFirm implements Action {
    readonly type = DELETE_FIRM;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray
    }
}

export class DeleteFirmResponse implements Action {
    readonly type = DELETE_FIRM_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class TestDocumentSignatureConnectivity implements Action {
    readonly type = TEST_DOCUMENT_SIGNATURE_CONNECTIVITY;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class SendDocumentSignatureReminder implements Action {
    readonly type = SEND_DOCUMENT_SIGNATURE_REMINDER;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class SendDocumentSignatureReminderResponse implements Action {
    readonly type = SEND_DOCUMENT_SIGNATURE_REMINDER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}


export class TestDocumentSignatureConnectivityResponse implements Action {
    readonly type = TEST_DOCUMENT_SIGNATURE_CONNECTIVITY_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class TestEmailConnectivity implements Action {
    readonly type = TEST_EMAIL_CONNECTIVITY;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class TestEmailConnectivityResponse implements Action {
    readonly type = TEST_EMAIL_CONNECTIVITY_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetDoumentSignatureTrackDetails implements Action {
    readonly type = GET_DOCUMENT_SIGNATURE_TRACK_DETAILS;
}

export class SetDoumentSignatureTrackDetails implements Action {
    readonly type = SET_DOCUMENT_SIGNATURE_TRACK_DETAILS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export type Actions = GetAllFirms | SetAllFirms | GetFirmById | SetFirmById | SendDocumentSignatureReminder | SendDocumentSignatureReminderResponse |
    AddFirm | AddFirmResponse | UpdateFirm | UpdateFirmResponse | GetDocumentSignatureByField | SetDocumentSignatureByField |
    DeleteFirm | DeleteFirmResponse | TestDocumentSignatureConnectivity | TestDocumentSignatureConnectivityResponse |
    TestEmailConnectivity | TestEmailConnectivityResponse | GetDoumentSignatureTrackDetails | SetDoumentSignatureTrackDetails | GetAllLeads | SetAllLeads;
