import { Action } from '@ngrx/store';
import {
    GET_CALENDAR_DATA,
    CALENDAR_DATA,
    SET_CURRENT_EVENT,
    ADD_CALENDAR_EVENT,
    ADD_CALENDAR_EVENT_RESPONSE,
    UPDATE_CALENDAR_EVENT,
    UPDATE_CALENDAR_EVENT_RESPONSE,
    SYNC_CALENDAR_EVENT,
    SYNC_CALENDAR_EVENT_RESPONSE,
    GET_CALENDAR_DATA_BY_ID,
    SET_CALENDAR_DATA_BY_ID,
    DELETE_CALENDAR_EVENT,
    DELETE_CALENDAR_EVENT_RESPONSE,
    SYNC_CALENDAR
} from './actionTypes';
import { IServerResponse } from 'app/interface';

export class GetCalendarData implements Action {
    readonly type = GET_CALENDAR_DATA;
}

export class CalendarData implements Action {
    readonly type = CALENDAR_DATA;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetCalendarDataById implements Action {
    readonly type = GET_CALENDAR_DATA_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetCalendarDataById implements Action {
    readonly type = SET_CALENDAR_DATA_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class SetCurrentEvent implements Action {
    readonly type = SET_CURRENT_EVENT;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class AddCalendarEvent implements Action {
    readonly type = ADD_CALENDAR_EVENT;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class AddCalendarEventResponse implements Action {
    readonly type = ADD_CALENDAR_EVENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateCalendarEvent implements Action {
    readonly type = UPDATE_CALENDAR_EVENT;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateCalendarEventResponse implements Action {
    readonly type = UPDATE_CALENDAR_EVENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class SyncCalendarData implements Action {
    readonly type = SYNC_CALENDAR_EVENT;
    readonly payload = null;
}

export class SyncCalendarDataResponse implements Action {
    readonly type = SYNC_CALENDAR_EVENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class SyncCalendar implements Action {
    readonly type = SYNC_CALENDAR;
    readonly payload = null;
}
export class DeleteCalendarEvent implements Action {
    readonly type = DELETE_CALENDAR_EVENT;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray;
    }
}

export class DeleteCalendarEventResponse implements Action {
    readonly type = DELETE_CALENDAR_EVENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions =
    | GetCalendarData
    | CalendarData
    | SetCurrentEvent
    | AddCalendarEvent
    | AddCalendarEventResponse
    | UpdateCalendarEvent
    | UpdateCalendarEventResponse
    | SyncCalendarData
    | SyncCalendarDataResponse
    | GetCalendarDataById
    | SetCalendarDataById
    | DeleteCalendarEvent
    | DeleteCalendarEventResponse
    | SyncCalendar;
