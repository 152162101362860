import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as DataConnectorsAction from './actions';
import { DataConnectorsService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class DataConnectorsEffect {
    constructor(
        private action: Actions,
        private dataConnectorsService: DataConnectorsService
    ) { }

    GetDataConnectors = createEffect(() => this.action.pipe(
        ofType(type.GET_DATA_CONNECTORS),
        switchMap(() => this.dataConnectorsService.getDataConnectors()),
        map((data: Array<any>) => new DataConnectorsAction.SetDataConnectors(data)
        )
    ));

    CleanDataConnectors = createEffect(() => this.action.pipe(
        ofType(type.CLEAN_DATA_CONNECTORS),
        switchMap((data: any) => this.dataConnectorsService.cleanDataConnectors(data.payload)),
        map((data: any) => new DataConnectorsAction.CleanDataConnectorsResponse(data)
        )
    ));

    UploadDataConnector = createEffect(() => this.action.pipe(
        ofType(type.UPLOAD_DATA_CONNECTOR),
        switchMap((data: any) => this.dataConnectorsService.uploadDataConnector(data.payload)),
        map((data: IServerResponse) => new DataConnectorsAction.UploadDataConnectorResponse(data)
        )
    ));
}
