import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ShepherdService } from 'angular-shepherd';

import { GetUserProfileById, UpdateUserProfileById, UpdateUserProfileByIdResponse } from 'app/store/setup/users/actions';
import { userProfileById, updateUserProfileByIdResponse } from 'app/store/setup/users/store.module';
import { CommonUtil } from 'app/utils/common.util';

// import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { AuthenticationService } from 'app/auth/service';

@Injectable({
    providedIn: 'root'
})
export class TourService implements OnDestroy {
    public backBtnClass = 'btn btn-sm btn-outline-primary';
    public nextBtnClass = 'btn btn-sm btn-primary btn-next';

    unsubscribe = new Subject();

    private userProfileData: any = {};
    private tourStarted: boolean = false;
    private tourCancelled: boolean = false;

    constructor(
        private store: Store,
        private shepherdService: ShepherdService,
        private commonUtil: CommonUtil,
        // private _coreLoadingScreenService: CoreLoadingScreenService,
        private _authenticationService: AuthenticationService
    ) {
        this.store.select(userProfileById)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (resp) => {
                    if (resp) {
                        this.userProfileData = resp;

                        this.resetTourFlags();
                        this.startTour();
                    }
                }
            )

        this.store.select(updateUserProfileByIdResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (resp) => {
                    if (resp) {
                        if (this.shepherdService) {
                            if (this.tourCancelled) {
                                this.store.dispatch(new UpdateUserProfileByIdResponse(null));

                                this.shepherdService.cancel();
                                // this._coreLoadingScreenService.hide();

                                const userId = this.commonUtil.getUserDetail('id');
                                this.store.dispatch(new GetUserProfileById(userId));

                                this.resetTourFlags();
                            }
                        }
                    }
                }
            )
    }

    resetTourFlags() {
        this.tourCancelled = false;
        this.tourStarted = false;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.userProfileData = {};
    }

    nextStep(service: any) {
        service.next();
        this.unbindNextStepToChatWidget();
    }

    nextStepFunction = () => {
        this.nextStep(this.shepherdService)
    }

    bindNextStepToChatWidget() {
        const chatWidgetIcon = document.getElementById("profile_div");
        if (chatWidgetIcon) {
            chatWidgetIcon.addEventListener("click", this.nextStepFunction);
        }
    }

    unbindNextStepToChatWidget() {
        const chatWidgetIcon = document.getElementById("profile_div");
        if (chatWidgetIcon) {
            chatWidgetIcon.removeEventListener("click", this.nextStepFunction);
        }
    }

    closeChatbotIfOpen() {
        const widgetIcon = document.getElementById("profile_div");
        if (widgetIcon && widgetIcon.className.includes("bot-open")) {
            const closeIcon = document.getElementById("close");
            if (closeIcon) {
                closeIcon.click();
            }
        }
    }

    updateNeverShowSiteTourInUserProfile() {
        this.unbindNextStepToChatWidget();

        this.tourCancelled = true;
        // this._coreLoadingScreenService.show();
        this.store.dispatch(new UpdateUserProfileById({
            ...this.userProfileData,
            showSiteTour: false
        }))
    }

    startTour() {
        const {
            isLegalFirmAdmin, isManagingPartner, isPartner, isLawyer, isParaLegal, isPersonalAssistant, isComplianceOfficer,
            isClient, isGuest
        } = this._authenticationService;

        const isEmployee = isLegalFirmAdmin || isManagingPartner || isPartner || isLawyer || isParaLegal || isPersonalAssistant || isComplianceOfficer;
        const isGuestOrClient = isClient || isGuest;
        let firstStepMessage = "";
        let secondStepMessage = "";
        if (isEmployee) {
            firstStepMessage = "You can click here to work with KAi, ask questions or see your recent incoming tasks!";
            secondStepMessage = "This is where you can manage all your tasks";
        } else if (isGuestOrClient) {
            firstStepMessage = "KAi, our digital legal assistant will guide you through questions tailored to your query. Click the icon and KAi will get you set up straight away.";
            secondStepMessage = "This is where you can raise and manage all your enquiries";
        } else {
            firstStepMessage = "Please click this icon to open chat popup to help us with your legal needs!";
            secondStepMessage = "This is where you can raise and manage all your enquiries";
        }


        if (this.userProfileData?.showSiteTour && !this.tourStarted) {
            this.tourStarted = true;

            this.bindNextStepToChatWidget();

            this.shepherdService.defaultStepOptions = {
                // scrollTo: false,
                // cancelIcon: {
                //     enabled: true
                // }
            };
            this.shepherdService.modal = true;

            this.shepherdService.addSteps([
                {
                    title: 'Welcome!',
                    text: firstStepMessage,
                    attachTo: {
                        element: '#profile_div',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: "Don't show this again",
                            classes: this.backBtnClass,
                            action: () => {
                                this.updateNeverShowSiteTourInUserProfile();
                            }
                        },
                        {
                            text: 'Skip',
                            type: 'cancel',
                            classes: this.backBtnClass
                        }
                        // {
                        //     text: 'Next',
                        //     type: 'next',
                        //     classes: this.nextBtnClass
                        // }
                    ],
                    useModalOverlay: true
                },
                {
                    title: 'Legal AI chatbot',
                    text: secondStepMessage,
                    attachTo: {
                        element: '.widget',
                        on: 'top'
                    },
                    buttons: [
                        {
                            text: 'Back',
                            // type: 'back',
                            classes: this.backBtnClass,
                            action: () => {
                                this.closeChatbotIfOpen();
                                this.shepherdService.back();
                            }
                        },
                        {
                            text: 'Finish',
                            // type: 'next',
                            classes: this.nextBtnClass,
                            action: () => {
                                this.updateNeverShowSiteTourInUserProfile();
                            }
                        }
                    ],
                    useModalOverlay: true
                }
            ]);
            this.shepherdService.start();
        }
    }
}
