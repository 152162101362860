import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { AuthenticationService } from 'app/auth/service';
import { ModuleAccessValue } from 'app/interface/common.interface';
import { ACCESS } from 'app/constants';

import { Logout } from 'app/store/core/actions';
import { Store } from '@ngrx/store';
import { LocalstorageService } from '@core/services/localstorage.service';

import { updateUserDetailResponse } from 'app/store/core/store.module';


@Component({
    selector: '[core-menu]',
    templateUrl: './core-menu.component.html',
    styleUrls: ['./core-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit, OnDestroy {
    currentUser: any;

    @Input()
    layout = 'vertical';

    @Input()
    menu: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {CoreMenuService} _coreMenuService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _coreMenuService: CoreMenuService,
        private localStorage: LocalstorageService,
        private store: Store,
        private _authenticationService: AuthenticationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // get the currentUser details from localStorage
        this._authenticationService.currentUser.subscribe((x) => (this.currentUser = x));

        this.store.select(updateUserDetailResponse)
            .pipe(
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((resp) => {
                if (resp) {
                    if (resp.status) {
                        setTimeout(() => {
                            this.currentUser = JSON.parse(this.localStorage.getItem('currentUser'));
                        }, 0)
                    }
                }
            })

    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the menu either from the input or from the service
        this.menu = this.menu || this._coreMenuService.getCurrentMenu();

        // Subscribe to the current menu changes
        this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.currentUser = this._coreMenuService.currentUser;

            // Load menu
            this.menu = this._coreMenuService.getCurrentMenu();

            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    logout() {
        this.store.dispatch(new Logout(true))
    }

    getCurrentModuleAccess(moduleName): ModuleAccessValue | boolean {
        return this._authenticationService.userAccessValue[moduleName] ? this._authenticationService.userAccessValue[moduleName][ACCESS.VISIBILITY] : false;
    }

    getCurrentSectionAccess(moduleName): ModuleAccessValue | boolean {
        return moduleName.some((item) => this._authenticationService.userAccessValue[item][ACCESS.VISIBILITY]);
    }
}
