import { Injectable } from '@angular/core';

/**
 * An object used to get page information from the server
 */
@Injectable()
export class Page {
    // The number of elements in the page
    size: number = 10;
    // The total number of elements
    totalElements: number = 0;
    // The total number of pages
    totalPages: number = 0;
    // The current page number
    pageNumber: number = 0;

    public setPageData(data) {
        this.size = data.size;
        this.totalElements = data.totalElements;
        this.totalPages = data.totalPages;
        this.pageNumber = data.pageNumber
    }

    public getPageAndSize() {
        return {
            currentPage: this.pageNumber.toString(),
            size: this.size.toString()
        }
    }

}
