<div class="modal-header">
  <h4 class="modal-title">{{ "Task" }}</h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'add-task-modal'"
>
  <form class="form" [formGroup]="taskForm">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <h5 class="card-title" *ngIf="title">
              {{ title }}
            </h5>

            <div class="row">
              <div class="col-12" *ngIf="ReactiveFormControl.title">
                <div class="form-group required">
                  <label for="title" class="control-label">{{
                    "Title" | translate
                  }}</label>
                  <input
                    id="title"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.title.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Title' | translate }}"
                    formControlName="title"
                  />
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.title.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.title.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" *ngIf="ReactiveFormControl.description">
                <div class="form-group required">
                  <label for="description" class="control-label">{{
                    "Description" | translate
                  }}</label>
                  <textarea
                    id="description"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.description.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Description' | translate }}"
                    formControlName="description"
                  ></textarea>
                  <div
                    *ngIf="
                      formSubmitted && ReactiveFormControl.description.errors
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="ReactiveFormControl.description.errors.required"
                    >
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12" *ngIf="ReactiveFormControl.priority">
                <div class="form-group required">
                  <label for="priority" class="control-label">{{
                    "Priority" | translate
                  }}</label>
                  <ng-select
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.priority.errors
                    }"
                    id="priority"
                    name="priority"
                    [items]="priorityOptions"
                    bindLabel="name"
                    bindValue="id"
                    formControlName="priority"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.priority.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.priority.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12" *ngIf="ReactiveFormControl.dueDate">
                <div class="form-group required">
                  <label for="dueDate" class="control-label">
                    {{ "Due Date" | translate }}
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    name="dueDate"
                    formControlName="dueDate"
                    (change)="handleDueDateChange($event.target.value)"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted &&
                        !!(
                          ReactiveFormControl.dueDate.errors?.required ||
                          ReactiveFormControl.dueDate.errors?.invalid
                        )
                    }"
                    [min]="TodaysDate"
                  />
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.dueDate.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.dueDate.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                    <div *ngIf="ReactiveFormControl.dueDate.errors.invalid">
                      {{ "ErrorInvalidDate" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-7 col-12" *ngIf="ReactiveFormControl.duration">
                <label for="taskDuration" class="control-label">{{
                  "Task Duration" | translate
                }}</label>
                <div class="row" formGroupName="duration">
                  <div class="col-6">
                    <div class="form-group required">
                      <label for="hour" class="control-label">
                        {{ "Hours" | translate }}
                      </label>
                      <ng-select
                        [ngClass]="{
                          'is-invalid':
                            formSubmitted &&
                            ReactiveFormControl.duration.errors?.invalid
                        }"
                        id="hour"
                        name="hour"
                        [items]="hourOptions"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="hour"
                        (change)="CheckDurationError()"
                        [closeOnSelect]="true"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group required">
                      <label for="hour" class="control-label">
                        {{ "Minutes" | translate }}
                      </label>
                      <ng-select
                        [ngClass]="{
                          'is-invalid':
                            formSubmitted &&
                            ReactiveFormControl.duration.errors?.invalid
                        }"
                        id="minute"
                        name="minute"
                        [items]="minuteOptions"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="minute"
                        (change)="CheckDurationError()"
                        [closeOnSelect]="true"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12 py-0 mt-n1">
                    <div
                      *ngIf="
                        formSubmitted &&
                        ReactiveFormControl.duration.errors?.invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      <div>
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!taskDetails?.id">
              <div class="col-md-6 col-12" *ngIf="ReactiveFormControl.type">
                <div class="form-group">
                  <label for="type" class="control-label">
                    {{ "Is Role Base?" | translate }}
                  </label>
                  <div class="mt-25 d-flex align-items-center">
                    <label class="switch m-0">
                      <input
                        type="checkbox"
                        [disabled]="ReactiveFormValues?.id"
                        [checked]="ReactiveFormValues?.type === 'ROLE_BASED'"
                        (change)="handleTypeChange($event.target.checked)"
                      />
                      <span class="slider round"></span>
                    </label>
                    <span
                      class="ml-1"
                      *ngIf="ReactiveFormValues?.type === 'ROLE_BASED'"
                      >{{ "Yes" | translate }}</span
                    >
                    <span
                      class="ml-1"
                      *ngIf="ReactiveFormValues?.type === 'USER_BASED'"
                      >{{ "No" | translate }}</span
                    >
                  </div>
                </div>
              </div>

              <div
                class="col-md-6 col-12"
                *ngIf="ReactiveFormValues?.type === 'ROLE_BASED'"
              >
                <div class="form-group required">
                  <label for="role" class="control-label">{{
                    "Role" | translate
                  }}</label>
                  <ng-select
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.role.errors
                    }"
                    id="role"
                    name="role"
                    [items]="roleOptions"
                    bindLabel="name"
                    bindValue="id"
                    formControlName="role"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.role.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.role.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="
                ReactiveFormValues?.type === 'USER_BASED' && !taskDetails?.id
              "
            >
              <div class="col-md-6 col-12 align-self-center py-1">
                <label class="d-flex align-items-center">
                  <input
                    class="mr-1"
                    type="checkbox"
                    [checked]="assignSomeOne"
                    (change)="handleAssignSelf($event.target.checked)"
                  />
                  {{ "Assign to someone else?" | translate }}
                </label>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group required mb-1" *ngIf="assignSomeOne">
                  <label for="userId" class="control-label">{{
                    "User" | translate
                  }}</label>
                  <ng-select
                    [items]="userList"
                    formControlName="userId"
                    groupBy="userRole"
                    bindLabel="name"
                    bindValue="id"
                    [closeOnSelect]="true"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span class="text-capitalize">{{ item.name.toLowerCase() }}</span>
                    </ng-template>
                    <ng-template
                      ng-optgroup-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <b>{{ item.userRole }}</b>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                    <span class="text-capitalize" [ngOptionHighlight]="search">{{ item.name.toLowerCase() }}</span>
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.userId.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.userId.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!taskDetails?.id">
              <div class="col-12" *ngIf="ReactiveFormControl.type">
                <div class="form-group">
                  <label for="type" class="control-label">
                    {{ "Is linked Module?" | translate }}
                  </label>
                  <div class="mt-25 d-flex align-items-center">
                    <label class="switch m-0">
                      <input
                        type="checkbox"
                        [disabled]="ReactiveFormValues?.id"
                        [checked]="hasLinkedModule"
                        (change)="handleHasLinkedModule($event.target.checked)"
                      />
                      <span class="slider round"></span>
                    </label>
                    <span class="ml-1" *ngIf="hasLinkedModule">{{
                      "Yes" | translate
                    }}</span>
                    <span class="ml-1" *ngIf="!hasLinkedModule">{{
                      "No" | translate
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12" *ngIf="hasLinkedModule">
                <div class="form-group required">
                  <label for="module" class="control-label">{{
                    "Module Type" | translate
                  }}</label>
                  <ng-select
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.module.errors
                    }"
                    id="module"
                    name="module"
                    [items]="referenceModuleOptions"
                    bindLabel="name"
                    bindValue="id"
                    formControlName="module"
                    (change)="onModuleChange($event)"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.module.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.module.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-md-6 col-12"
                *ngIf="hasLinkedModule && ReactiveFormValues.module !== 'MISC'"
              >
                <div class="form-group required">
                  <label for="moduleId" class="control-label">{{
                    "Module" | translate
                  }}</label>
                  <ng-select
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.moduleId.errors
                    }"
                    id="moduleId"
                    name="moduleId"
                    [items]="moduleOptions"
                    bindLabel="moduleName"
                    bindValue="id"
                    formControlName="moduleId"
                    [closeOnSelect]="true"
                  >
                  <ng-template ng-option-tmp let-item="item">
                    <div
                      class="text-truncate text-capitalize"
                      [ngbTooltip]="item.moduleName"
                      tooltipClass="text-truncate text-capitalize text-wrap"
                      container="body"
                      value="{{ item.id }}"
                    >
                      {{ item.moduleName }}
                    </div>
                  </ng-template>
                  </ng-select>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.moduleId.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.moduleId.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 d-flex justify-content-end">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  (click)="onSubmitClick()"
                >
                  {{
                    ReactiveFormValues?.id ? "Update" : ("Submit" | translate)
                  }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="onCancelClick()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
