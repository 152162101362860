import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomPreloadService implements PreloadingStrategy {

    constructor() { }

    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        const loadRoute = (delay) => delay > 0 ? timer(delay).pipe(map(() => fn())) : fn();
        if (route.data && route.data.preload) {
            const delay = route.data.loadAfterSeconds ? route.data.loadAfterSeconds : 0;
            return loadRoute(delay);
        }
        return of(null);
    }
}
