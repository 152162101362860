import { initialState, MyTaskState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_TASKS, SET_TASK_BY_ID, ADD_TASK_RESPONSE, UPDATE_TASK_RESPONSE,
    DELETE_TASK_RESPONSE, SET_ALL_PROJECTS, SET_PROJECT_BY_ID, ADD_PROJECT_RESPONSE,
    UPDATE_PROJECT_RESPONSE, DELETE_PROJECT_RESPONSE,
    UPDATE_TASK_STATUS_RESPONSE,
    SET_TASK_CHART_DATA_BY_USER_ID,
    SET_ASSIGN_USER_LIST,
    REASSIGN_TASK_RESPONSE,
    CREATE_CALENDAR_EVENT_RESPONSE
} from "./actionTypes";

export function MyTaskStateReducer(state = initialState, action: Actions): MyTaskState {
    const { type } = action;

    switch (type) {
        case SET_ALL_TASKS:
            return Object.assign({}, state, {
                allTasks: action.payload
            });
        case SET_ALL_PROJECTS:
            return Object.assign({}, state, {
                allProjects: action.payload
            });
        case SET_ASSIGN_USER_LIST:
            return Object.assign({}, state, {
                userList: action.payload
            });
        case SET_TASK_BY_ID:
            return Object.assign({}, state, {
                taskById: action.payload
            });
        case SET_TASK_CHART_DATA_BY_USER_ID:
            return Object.assign({}, state, {
                taskChartDataByUserId: action.payload
            });
        case SET_PROJECT_BY_ID:
            return Object.assign({}, state, {
                projectById: action.payload
            });
        case ADD_TASK_RESPONSE:
            return Object.assign({}, state, {
                addTaskResponse: action.payload
            });
        case ADD_PROJECT_RESPONSE:
            return Object.assign({}, state, {
                addProjectResponse: action.payload
            });
        case UPDATE_TASK_RESPONSE:
            return Object.assign({}, state, {
                updateTaskResponse: action.payload
            });
        case UPDATE_TASK_STATUS_RESPONSE:
            return Object.assign({}, state, {
                updateTaskStatusResponse: action.payload
            });
        case REASSIGN_TASK_RESPONSE:
            return Object.assign({}, state, {
                reassignTaskResponse: action.payload
            });
        case CREATE_CALENDAR_EVENT_RESPONSE:
            return Object.assign({}, state, {
                createCalendarEventResponse: action.payload
            });
        case UPDATE_PROJECT_RESPONSE:
            return Object.assign({}, state, {
                updateProjectResponse: action.payload
            });
        case DELETE_TASK_RESPONSE:
            return Object.assign({}, state, {
                deleteTaskResponse: action.payload
            });
        case DELETE_PROJECT_RESPONSE:
            return Object.assign({}, state, {
                deleteProjectResponse: action.payload
            });
        default:
            return state;
    }
}
