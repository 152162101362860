import { IServerResponse } from "app/interface";

export interface VisionAiState {
    allVisionAiDocuments: Array<any>;
    visionAiDocumentById: any;
    addVisionAiDocumentResponse: IServerResponse;
    deleteVisionAiDocumentResponse: IServerResponse;
    saveVisionAiDocumentAsNoteResponse: IServerResponse;
    runVisionAiExtractionFromSummaryResponse: IServerResponse;
}

export const initialState: VisionAiState = {
    allVisionAiDocuments: null,
    visionAiDocumentById: null,
    addVisionAiDocumentResponse: null,
    deleteVisionAiDocumentResponse: null,
    saveVisionAiDocumentAsNoteResponse: null,
    runVisionAiExtractionFromSummaryResponse: null
}
