import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourService } from './services';
import { ShepherdService } from 'angular-shepherd';

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule
    ],
    providers: [
        TourService,
        ShepherdService
    ],
    exports:[
    ]
})
export class FeatureModule { }
