import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';

import { CommonUtil } from 'app/utils/common.util';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-calendar-sync-setting-modal',
    templateUrl: './calendar-sync-setting-modal.component.html',
    styleUrls: ['./calendar-sync-setting-modal.component.scss']
})
export class CalendarSyncSettingModalComponent implements OnInit {
    @Input() IsSyncSettingTabEditable: boolean;
    @Input() calendarSyncSettingForm: FormGroup;
    @Input() handleSubmitClick: Function;
    @Input() handleCloseClick: Function;

    public formSubmitted: boolean = false;
    public syncOptionsList: Array<any>;
    
    unsubscribe = new Subject();

    constructor(private commonUtil: CommonUtil, private store: Store) {}

    ngOnInit(): void {
        this.syncOptionsList = [
            {
                id: 'OUTLOOK',
                name: 'Outlook'
            }
        ];
    }

    get ReactiveFormControl() {
        return this.calendarSyncSettingForm.controls;
    }

    get ReactiveFormValues() {
        return this.calendarSyncSettingForm.getRawValue();
    }

    onSubmitClick() {
        this.formSubmitted = true;

        if (this.calendarSyncSettingForm.invalid) {
            return;
        }
        const syncSettingDetails = cloneDeep({ ...this.ReactiveFormValues });
        this.handleSubmitClick(syncSettingDetails);
    }

    onCancelClick() {
        this.handleCloseClick();
    }
}
