<div class="modal-header bg-primary">
  <h4 class="modal-title text-white">
    {{ "Sync Setting" }}
  </h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'calendar-sync-setting-modal'"
>
  <form
    class="form"
    [formGroup]="calendarSyncSettingForm"
    (ngSubmit)="onSubmitClick()"
  >
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <fieldset
              [disabled]="!IsSyncSettingTabEditable"
              [ngStyle]="{
                'pointer-events': IsSyncSettingTabEditable ? 'unset' : 'none'
              }"
            >
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group required">
                    <label for="host" class="control-label">
                      {{ "Sync with" | translate }}
                    </label>
                    <ng-select
                      id="syncOptions"
                      name="syncOptions"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted && ReactiveFormControl.syncType.errors
                      }"
                      [items]="syncOptionsList"
                      bindLabel="name"
                      bindValue="id"
                      formControlName="syncType"
                      [closeOnSelect]="true"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        formSubmitted && ReactiveFormControl.syncType.errors
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="ReactiveFormControl.syncType.errors.required">
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="row">
              <div class="col-12 text-right">
                <button type="submit" rippleEffect class="btn btn-primary mr-1">
                  {{ "Authorized" | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="onCancelClick()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
