export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const SET_ALL_ROLES = "SET_ALL_ROLES";

export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const SET_ROLE_BY_ID = "SET_ROLE_BY_ID";

export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_RESPONSE = "ADD_ROLE_RESPONSE";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_RESPONSE = "UPDATE_ROLE_RESPONSE";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_RESPONSE = "DELETE_ROLE_RESPONSE";
