export const GET_ALL_VISION_AI_DOCUMENTS = "GET_ALL_VISION_AI_DOCUMENTS";
export const SET_ALL_VISION_AI_DOCUMENTS = "SET_ALL_VISION_AI_DOCUMENTS";

export const GET_VISION_AI_DOCUMENT_BY_ID = "GET_VISION_AI_DOCUMENT_BY_ID";
export const SET_VISION_AI_DOCUMENT_BY_ID = "SET_VISION_AI_DOCUMENT_BY_ID";

export const ADD_VISION_AI_DOCUMENT = "ADD_VISION_AI_DOCUMENT";
export const ADD_VISION_AI_DOCUMENT_RESPONSE = "ADD_VISION_AI_DOCUMENT_RESPONSE";

export const DELETE_VISION_AI_DOCUMENT = "DELETE_VISION_AI_DOCUMENT";
export const DELETE_VISION_AI_DOCUMENT_RESPONSE = "DELETE_VISION_AI_DOCUMENT_RESPONSE";

export const SAVE_VISION_AI_DOCUMENT_AS_NOTE = "SAVE_VISION_AI_DOCUMENT_AS_NOTE";
export const SAVE_VISION_AI_DOCUMENT_AS_NOTE_RESPONSE = "SAVE_VISION_AI_DOCUMENT_AS_NOTE_RESPONSE";

export const RUN_VISION_AI_EXTRACTION_FROM_SUMMARY = "RUN_VISION_AI_EXTRACTION_FROM_SUMMARY";
export const RUN_VISION_AI_EXTRACTION_FROM_SUMMARY_RESPONSE = "RUN_VISION_AI_EXTRACTION_FROM_SUMMARY_RESPONSE";
