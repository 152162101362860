export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const SET_ALL_CLIENTS = "SET_ALL_CLIENTS";

export const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
export const SET_CLIENT_BY_ID = "SET_CLIENT_BY_ID";

export const GET_CLIENTS_BY_FIELD = "GET_CLIENTS_BY_FIELD";
export const SET_CLIENTS_BY_FIELD = "SET_CLIENTS_BY_FIELD";

export const GET_MATTERS_BY_CLIENT_ID = "GET_MATTERS_BY_CLIENT_ID";
export const SET_MATTERS_BY_CLIENT_ID = "SET_MATTERS_BY_CLIENT_ID";

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_RESPONSE = "UPDATE_CLIENT_RESPONSE";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_RESPONSE = "DELETE_CLIENT_RESPONSE";
