import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { helperValues } from 'app/store/common/commonStore.module';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
    allTasksWithPriority,
    reassignTaskResponse,
    userList
} from 'app/store/home/my-tasks/store.module';
import {
    GetAllTasks,
    GetTaskChartDataByUserId,
    ReassignTask,
    ReassignTaskResponse
} from 'app/store/home/my-tasks/actions';
import { PresentToastr } from 'app/store/common/actions';
import {
    ALLOWED_ROLES_IN_ROLE_BASED_TASK,
    TOASTR_TYPE
} from 'app/constants/common.constant';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonUtil } from 'app/utils/common.util';

@Component({
    selector: 'app-reassign-task-modal',
    templateUrl: './reassign-task-modal.component.html',
    styleUrls: ['./reassign-task-modal.component.scss']
})
export class ReAssignTaskStatusModalComponent implements OnInit, OnDestroy {
    @BlockUI('reassign-task-modal') reassignStatusModalBlockUI: NgBlockUI;

    @Input() taskDetails: any;
    @Input() taskList: Array<any> = [];
    @Input() handleCloseClick: Function;
    @Output() handleDestroy: Function;

    public taskReAssignForm: FormGroup;
    formSubmitted: boolean = false;

    public roleOptions: Array<any> = [];
    public typeOptions: Array<any> = [];
    public userList: Array<any> = [];
    public assignSomeOne: boolean = false;
    public userName: string;

    unsubscribe = new Subject();

    constructor(private store: Store, private commonUtil: CommonUtil) { }

    ngOnInit(): void {
        this.taskReAssignForm = new FormGroup({
            type: new FormControl(null, Validators.required), // 'USER_BASED', 'ROLE_BASE'
            userId: new FormControl(null, Validators.required),
            role: new FormControl(null)
        });


        this.userName = this.taskDetails?.userName;

        this.taskReAssignForm.patchValue({
            type: this.taskDetails?.type,
            userId:
                this.taskDetails?.userId || this.commonUtil.getUserDetail('id'),
            role: this.taskDetails?.role
        });

        this.handleTypeChange(this.taskDetails?.type === 'ROLE_BASED');
        this.assignSomeOne =
            this.taskDetails?.userId !== this.commonUtil.getUserDetail('id');

        this.store
            .select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.roleOptions =
                        resp?.userRole?.filter(({ id }) => ALLOWED_ROLES_IN_ROLE_BASED_TASK.includes(id)) || [];
                    this.typeOptions = [
                        { id: 'USER_BASED', name: 'User Based' },
                        { id: 'ROLE_BASED', name: 'Role Based' }
                    ];
                }
            });

        this.store
            .select(userList)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.userList = this.addNameInObjectArray(resp);
                }
            });

        this.store
            .select(reassignTaskResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.store.dispatch(new ReassignTaskResponse(null));

                    if (resp.status) {
                        this.taskDetails = {
                            ...this.taskDetails,
                            role: this.ReactiveFormValues.role,
                            type: this.ReactiveFormValues.type,
                            userId: this.ReactiveFormValues.userId,
                            userName: this.userName
                        }
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.SUCCESS,
                                message: resp.message
                            })
                        );

                        this.onCancelClick();
                        this.reassignStatusModalBlockUI.stop();
                    } else {
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: resp.message
                            })
                        );
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.taskReAssignForm.reset();
        this.handleDestroy(this.taskDetails);
    }

    get ReactiveFormControl() {
        return this.taskReAssignForm.controls;
    }

    get ReactiveFormValues() {
        return this.taskReAssignForm.getRawValue();
    }

    addNameInObjectArray(assignUserList) {
        let tempArr: Array<any> = [];
        let assignUserListTemp: any = {
            MANAGING_PARTNER: [],
            PARTNER: [],
            LAWYER: [],
            PARA_LEGAL: [],
            PERSONAL_ASSISTANT: [],
            COMPLIANCE_OFFICER: [],
            RO_CLIENT: [],
            LEGAL_FIRM_ADMIN: [],
            SYSTEM_ADMIN: []
        };

        Object.keys(assignUserList).forEach((key) => {
            assignUserList[key].forEach((user: any) => {
                const salutation = this.commonUtil.getSalutationToShow(user?.salutation);
                assignUserListTemp[key].push({
                    ...user,
                    name: `${salutation ? salutation + ' ' : ''}` + `${user.firstName ? `${user.firstName} ` : ''}`,
                    userRole:
                        this.roleOptions.find(({ id }) => id === key).name ||
                        key
                });
            });
        });

        Object.keys(assignUserListTemp).forEach((key) => {
            if (assignUserListTemp[key]) {
                tempArr = [...tempArr, ...assignUserListTemp[key]];
            }
        });

        return tempArr;
    }

    handleTypeChange(isRoleBase: boolean) {
        if (isRoleBase) {
            this.taskReAssignForm.get('type').patchValue('ROLE_BASED');
            this.taskReAssignForm
                .get('role')
                .setValidators(Validators.required);
            this.taskReAssignForm.get('role').updateValueAndValidity();
        } else {
            this.taskReAssignForm.get('type').patchValue('USER_BASED');
            this.taskReAssignForm.patchValue({
                role: null
            });
            this.taskReAssignForm.get('role').clearValidators();
            this.taskReAssignForm.get('role').updateValueAndValidity();
        }
    }

    handleAssignSelf(isChecked: boolean) {
        this.assignSomeOne = isChecked;
        this.taskReAssignForm.get('userId').patchValue(null);
        if (isChecked) {
            this.taskReAssignForm
                .get('userId')
                .setValidators(Validators.required);
            this.taskReAssignForm.get('userId').updateValueAndValidity();
        } else {
            this.taskReAssignForm.get('userId').clearValidators();
            this.taskReAssignForm.get('userId').updateValueAndValidity();
        }
    }

    handleUserSelect(data) {
        this.userName = data
    }

    onSubmitClick() {
        this.formSubmitted = true;

        let userId = this.ReactiveFormValues.userId;
        if (!this.assignSomeOne) {
            userId = this.commonUtil.getUserDetail('id');
        }

        let role = this.ReactiveFormValues.role;
        if (this.ReactiveFormValues.type === 'USER_BASE') {
            role = null;
        }
        this.taskReAssignForm.patchValue({
            userId: userId,
            role: role
        });

        if (this.taskReAssignForm.invalid) {
            return;
        }

        const taskStatusDetails = cloneDeep({ ...this.ReactiveFormValues });

        this.reassignStatusModalBlockUI.start();
        this.store.dispatch(
            new ReassignTask({
                ...taskStatusDetails,
                id: this.taskDetails?.id
            })
        );
    }

    onCancelClick() {
        this.taskReAssignForm.reset();
        this.handleCloseClick();
    }
}
