import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User, Role, RoleName } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { UrlUtil } from 'app/utils/url.util';
import { Store } from '@ngrx/store';

import { LocalstorageService } from '@core/services/localstorage.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';

import { Router } from '@angular/router';
import { SetUserDetail, Logout } from 'app/store/core/actions';
import { LOCALSTORAGE_DATA } from 'app/constants/common.constant';
import { SetUserAccess } from 'app/store/common/actions';

import { v4 as uuidv4 } from 'uuid';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    //public
    public currentUser: Observable<User>;
    public currentUserSubject: BehaviorSubject<User>;

    //private
    private userAccessSubject: BehaviorSubject<any>;
    private userLoggedIn = new Subject<boolean>();

    private _logoutToastrDisplayed = false;

    /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
    constructor(
        private _http: HttpClient,
        private _toastrService: ToastrService,
        private urlUtil: UrlUtil,
        private store: Store,
        private localStorage: LocalstorageService,
        private _router: Router,
        private _ngZone: NgZone,
        private _coreLoadingScreenService: CoreLoadingScreenService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.localStorage.getItem(LOCALSTORAGE_DATA.CURRENT_USER) ? this.localStorage.getItem(LOCALSTORAGE_DATA.CURRENT_USER) : null));
        this.userAccessSubject = new BehaviorSubject<any>(JSON.parse(this.localStorage.getItem(LOCALSTORAGE_DATA.USER_ACCESS) ? this.localStorage.getItem(LOCALSTORAGE_DATA.USER_ACCESS) : null));
        this.currentUser = this.currentUserSubject.asObservable();
        this.userLoggedIn.next(false);
    }

    // getter: currentUserValue
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    get isClient() {
        return this.currentUser && this.currentUserSubject.value.role === Role.CLIENT;
    }
    get isLawyer() {
        return this.currentUser && this.currentUserSubject.value.role === Role.LAWYER;
    }
    get isLegalFirmAdmin() {
        return this.currentUser && this.currentUserSubject.value.role === Role.LEGAL_FIRM_ADMIN;
    }
    get isManagingPartner() {
        return this.currentUser && this.currentUserSubject.value.role === Role.MANAGING_PARTNER;
    }
    get isParaLegal() {
        return this.currentUser && this.currentUserSubject.value.role === Role.PARA_LEGAL;
    }
    get isPartner() {
        return this.currentUser && this.currentUserSubject.value.role === Role.PARTNER;
    }
    get isPersonalAssistant() {
        return this.currentUser && this.currentUserSubject.value.role === Role.PERSONAL_ASSISTANT;
    }
    get isComplianceOfficer() {
        return this.currentUser && this.currentUserSubject.value.role === Role.COMPLIANCE_OFFICER;
    }
    get isSystemAdmin() {
        return this.currentUser && this.currentUserSubject.value.role === Role.SYSTEM_ADMIN;
    }
    get isGuest() {
        return this.currentUser && this.currentUserSubject.value.role === Role.RO_CLIENT;
    }

    get logoutToastrDisplayed(): boolean {
        return this._logoutToastrDisplayed;
    }

    set logoutToastrDisplayed(value: boolean) {
        this._logoutToastrDisplayed = value;
    }

    resetLogoutToastrDisplayed() {
        this._logoutToastrDisplayed = false;
    }

    /**
    * User login
    *
    * @param username
    * @param password
    * @returns user
    */
    login(username: string, password: string) {
        return this._http
            .post<any>(this.urlUtil.getUrl('authenticate', 'logIn'), { email: username, password })
            .pipe(
                map((user) => {
                    // login successful if there's a jwt token in the response
                    if (user && user.status && user.jsonData) {
                        const token = user.jsonData || uuidv4();
                        // store jwt token in local storage to keep user logged in between page refreshes
                        this.localStorage.setItem(LOCALSTORAGE_DATA.AUTH_TOKEN, JSON.stringify({ token: token }));
                        this.store.dispatch(new SetUserDetail({ token: token }));

                        this.resetLogoutToastrDisplayed();
                    }

                    return user;
                }),
                catchError((e) => {
                    console.log("login err", e);

                    return of({ status: false, errorMessage: e?.message || "We are facing issue while connecting servers. Please try again in some time." });
                })
            );
    }

    /**
    * User logout
    *
    */
    logout() {
        // remove user from local storage to log user out
        this.localStorage.removeItem(LOCALSTORAGE_DATA.CURRENT_USER);
        this.localStorage.removeItem(LOCALSTORAGE_DATA.AUTH_TOKEN);
        this.localStorage.removeItem(LOCALSTORAGE_DATA.USER_ACCESS);
        this.localStorage.removeItem(LOCALSTORAGE_DATA.LANDING_ROUTE);
        this.store.dispatch(new SetUserDetail(null));

        window["user_id"] = '';
        window["user_email"] = '';
        window["user_role"] = '';
        window["sessionId"] = '';
        window["apiUrl"] = '';
        window["chatbotApiUrl"] = '';
        window["token"] = '';
        window["forceLogoutAction"] = null;
        window["getRefreshToken"] = null;
        window["angularRouteRedirect"] = null;
        document.getElementById("chats").innerHTML = "";

        // notify
        this.currentUserSubject.next(null);

        this.setUserLoggedIn(false);

        // navigate to logi page
        this._router.navigate(['/pages/authentication/login']);
    }

    setUserLoggedIn(userLoggedIn: boolean) {
        // To set user_id and attach enter button press event handler to chatbot widget
        try {
            let token = "";
            if (this.localStorage.getItem('authToken')) {
                const authDataStringified = this.localStorage.getItem('authToken');
                const authDataParsed = JSON.parse(authDataStringified);
                token = authDataParsed?.token;
            }

            const currentUser = JSON.parse(this.localStorage.getItem(LOCALSTORAGE_DATA.CURRENT_USER));
            window["user_id"] = currentUser?.id
            window["user_email"] = currentUser?.email
            window["user_role"] = currentUser?.role
            window["sessionId"] = window["sessionId"] || uuidv4();
            window["updateSessionId"] = () => {
                window["sessionId"] = uuidv4();
            };
            window["apiUrl"] = environment.apiUrl
            window["chatbotApiUrl"] = environment.chatbotApiUrl
            window["token"] = token;
            window["forceLogoutAction"] = (shouldCallLogoutApi = true) => this._ngZone.run(() => this.store.dispatch(new Logout(shouldCallLogoutApi)));
            window["getRefreshToken"] = () => this._ngZone.run(() => this.getRefreshToken().toPromise());
            window["angularRouteRedirect"] = (redirectionRoute) => this._router.navigate([redirectionRoute]);

            const toggleChatbot = window['toggleChatbot']
            if (toggleChatbot) {
                toggleChatbot();
            }
        } catch (e) {
            console.log("catch e", e)
        }

        this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

    getUserInfo() {
        return this._http
            .get<any>(this.urlUtil.getUrl('profile', 'userInfo'))
            .pipe(
                map((user) => {
                    if (user && user.status && user.data) {
                        // store user details in local storage
                        this.localStorage.setItem(LOCALSTORAGE_DATA.CURRENT_USER, JSON.stringify({ profileImage: 'assets/images/portrait/small/avatar-s-11.png', ...user.data, roleName: RoleName[user?.data?.role] }));
                        this.store.dispatch(new SetUserDetail({ profileImage: 'assets/images/portrait/small/avatar-s-11.png', ...user.data, roleName: RoleName[user?.data?.role] }));
                        // // Display welcome toast!
                        // setTimeout(() => {
                        //     this._toastrService.success(
                        //         'You have successfully logged in to KAi - Legal AI.',
                        //         'Welcome, ' + user.data.name + '!',
                        //         { toastClass: 'toast ngx-toastr', closeButton: true }
                        //     );
                        // }, 2500);

                        // notify
                        this.currentUserSubject.next({ profileImage: 'assets/images/portrait/small/avatar-s-11.png', ...user.data, roleName: RoleName[user?.data?.role] });
                    } else {
                        return false;
                    }

                    return user;
                })
            )
    }

    getAccess() {
        return this._http
            .get<any>(this.urlUtil.getUrl('access', 'appAccess'))
            .pipe(
                map((accessData) => {
                    if (accessData.status) {
                        this.localStorage.setItem(LOCALSTORAGE_DATA.USER_ACCESS, JSON.stringify(accessData.data));
                        this.store.dispatch(new SetUserAccess(accessData.data))
                        this.userAccessSubject.next(accessData.data);
                    } else {
                        return false;
                    }
                    return accessData.data;
                })
            )
    }

    getRefreshToken() {
        this._coreLoadingScreenService.show();
        return this._http
            .get<any>(this.urlUtil.getUrl('authenticate', 'refreshToken'))
            .pipe(
                map((refreshTokenResponse) => {
                    const { status, jsonData: newToken } = refreshTokenResponse;
                    if (status && newToken) {
                        this.localStorage.setItem(LOCALSTORAGE_DATA.AUTH_TOKEN, JSON.stringify({ token: newToken }));
                        this.store.dispatch(new SetUserDetail({
                            ...this.currentUserValue,
                            token: newToken
                        }));
                        window["token"] = newToken;

                        this._coreLoadingScreenService.hide();
                        return newToken;
                    }
                    this._coreLoadingScreenService.hide();
                    return false;
                }),
                catchError((e) => {
                    console.log("getRefreshToken err", e);
                    this._coreLoadingScreenService.hide();

                    return of(false);
                })
            );
    }

    public get userAccessValue(): User {
        return this.userAccessSubject.value;
    }

}
