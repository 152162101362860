import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

// ContentHeader component interface
export interface ContentHeader {
    headerTitle: string;
    actionButton: boolean;
    actionButtonOption?: Array<any>;
    breadcrumb?: {
        type?: string;
        links?: Array<{
            name?: string;
            isLink?: boolean;
            link?: string;
        }>;
    };
}

@Component({
    selector: 'app-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent {
    // input variable
    @Input() contentHeader: ContentHeader;
    @Input() linkButton: ContentHeader;
    @Output() actionButtonEvent = new EventEmitter<number>();
    @Output() linkButtonEvent = new EventEmitter<number>();
    @Output() downloadButtonEvent = new EventEmitter<number>();

    constructor() {}

    actionButtonClicked(actionId) {
        this.actionButtonEvent.emit(actionId);
    }

    linkButtonClicked() {
        this.linkButtonEvent.emit();
    }
    downloadButtonClicked() {
        this.downloadButtonEvent.emit();
    }
}
