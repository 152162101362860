<div class="modal-header bg-primary">
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="d-flex align-items-center">
      <h1 class="modal-title text-white mb-0">
        {{ title }}
      </h1>
    </div>
    <div class="d-flex align-items-center">
      <button
        type="button"
        rippleEffect
        class="btn p-0 m-0 mr-1"
        (click)="onEditClick()"
        *ngIf="IsEventTabEditable"
      >
        <i data-feather="edit" class="text-white"></i>
      </button>
      <button
        *ngIf="eventData?.id"
        type="button"
        rippleEffect
        class="btn p-0 m-0 mr-1"
        (click)="onDeleteClick(eventData?.id)"
      >
        <i data-feather="trash-2" class="text-white"></i>
      </button>
      <button
        type="button"
        rippleEffect
        class="btn p-0 m-0"
        (click)="onCancelClick()"
      >
        <i data-feather="x-circle" class="text-white"></i>
      </button>
    </div>
  </div>
</div>

<div class="modal-body p-0" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <div class="card rounded-0 mb-0">
        <div class="card-body">
          <p>
            <i data-feather="calendar" class="text-primary mr-1"></i>
            <strong>{{ ' ' }}{{ eventDate }}</strong>
          </p>

          <div *ngIf="eventData?.eventType === 'MEETING'">
            <p>
              <i data-feather="link" class="text-danger mr-1"></i>
              <a
                [href]="eventData?.meetingDetails?.meetingUrl"
                target="_blank"
                class="text-primary text-decoration-none"
                >{{ eventData?.meetingDetails?.meetingUrl }}</a
              >
            </p>
            <p>
              <i data-feather="users" class="text-primary mr-1"></i>
              {{ eventData?.meetingDetails?.attendees?.length + 1 }}
              {{ 'Guests' }}
            </p>
            <div class="d-flex align-items-center">
              <img
                class="round ml-3 mr-1"
                src="{{
                  this.currentUser.profileImage ||
                    'assets/images/portrait/small/avatar-s-11.png'
                }}"
                alt="avatar"
                height="20"
                width="20"
              />
              <span class="font-weight-bold"
                >{{ eventData?.meetingDetails?.organizer }}
                {{ '(Organizer)' }}</span
              >
            </div>
            <br />
            <div
              *ngFor="
                let attendee of eventData?.meetingDetails?.attendees;
                let i = index
              "
            >
              <div class="d-flex align-items-center">
                <img
                  class="round ml-3 mr-1"
                  src="{{
                    this.currentUser.profileImage ||
                      'assets/images/portrait/small/avatar-s-11.png'
                  }}"
                  alt="avatar"
                  height="20"
                  width="20"
                />{{ attendee }}
              </div>
              <br />
            </div>
          </div>

          <div *ngIf="eventData?.eventType === 'KAI_TASK'">
            <div *ngIf="eventData?.kaiTaskDetails?.taskModule" class="mb-1">
              <!-- <i data-feather="layers" class="text-primary mr-1"></i> -->
              <i data-feather="grid" class="text-primary mr-1"></i>
              {{ eventData?.kaiTaskDetails?.taskModule }}
              <span *ngIf="eventData?.kaiTaskDetails?.moduleReference">
                {{ ' ' }}({{ eventData?.kaiTaskDetails?.moduleReference }})
              </span>
            </div>

            <div *ngIf="eventData?.kaiTaskDetails?.taskType">
              <i data-feather="briefcase" class="text-primary mr-1"></i>
              <span>
                {{ eventData?.kaiTaskDetails?.taskType }}
              </span>
            </div>
          </div>

          <h6 *ngIf="eventData?.description" class="mt-1">
            <!-- <i data-feather="file-text" class="text-primary mr-1"></i> -->
            <i data-feather="menu" class="text-primary mr-1"></i>
            <!-- <i data-feather="align-left" class="text-primary mr-1"></i> -->
            {{ eventData?.description }}
          </h6>

          <!-- <div class="row mt-1">
            <div class="col-4" *ngIf="IsEventDeletable">
              <button
                *ngIf="eventData?.id"
                type="button"
                rippleEffect
                class="btn btn-danger"
                (click)="onDeleteClick(eventData?.id)"
              >
                {{ 'Delete' | translate }}
              </button>
            </div>
            <div
              class="text-right"
              [ngClass]="eventData?.id ? 'col-8' : 'col-12'"
            >
              <button
                type="button"
                rippleEffect
                class="btn btn-primary mr-1"
                (click)="onEditClick()"
                *ngIf="IsEventTabEditable"
              >
                {{ 'Edit' | translate }}
              </button>
              <button
                type="button"
                rippleEffect
                class="btn btn-outline-secondary"
                (click)="onCancelClick()"
              >
                {{ 'Cancel' | translate }}
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
