import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';

import { ALL_BILLING_DATA_RESPONSE } from "./stub";
import { v4 as uuidv4 } from 'uuid';
import { Page } from 'app/utils/page.util';

@Injectable()
export class BillingService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store,
        private page: Page
    ) {
    }

    public getAllBillingData() {
        return this.http.get(
            this.urlUtil.getUrl('billing', 'getAll'))
            .pipe(
                map((resp: any) => {
                    if (!resp?.data?.length) {
                        return ALL_BILLING_DATA_RESPONSE.data;
                    }

                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllBillingData error =>", e);
                    // return of([]);
                    return of(ALL_BILLING_DATA_RESPONSE.data);
                })
            )
    }

    public searchByField(name = null) {
        const {currentPage, size} = this.page.getPageAndSize();
        const { key, value } = name;
        return this.http.get(
            this.urlUtil.getUrl('billing', 'getAll')
                // .replace('$KEY', key)
                // .replace('$VALUE', value)
                .replace('$PAGE', currentPage)
                .replace('$SIZE', size))
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        const respData = resp.data
                        this.page.setPageData({
                            size: respData.size,
                            totalElements: respData.totalElements,
                            totalPages: respData.totalPages,
                            pageNumber: respData.number
                        })
                        return respData.content;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of(null);
                })
            )
    }

    public getBillingById(id) {
        return this.http.get(
            this.urlUtil.getUrl('billing', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getBillingById error =>", e);
                    // this.store.dispatch(new PresentToastr({
                    //     type: TOASTR_TYPE.ERROR,
                    //     message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    // }))
                    // return of({});

                    const billingById = ALL_BILLING_DATA_RESPONSE.data.find(({ id: billingId }) => id === billingId) || {}
                    return of(billingById);
                })
            )
    }

    public addBilling(dataObject) {
        return this.http.post(
            this.urlUtil.getUrl('billing', 'add'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("addBilling error =>", e);
                    // return of({
                    //     status: false,
                    //     message: e?.errorMessage || e?.message || this._translateService.instant("FailDataAdd")
                    // });

                    const newData = { ...dataObject, id: uuidv4() };
                    ALL_BILLING_DATA_RESPONSE.data.push(newData);
                    return of({
                        status: true,
                        message: this._translateService.instant("DataAddSuccess")
                    });
                })
            )
    }

    public updateBilling(dataObject) {
        return this.http.put(
            this.urlUtil.getUrl('billing', 'update').replace('$ID', dataObject.id), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("updateBilling error =>", e);
                    // return of({
                    //     status: false,
                    //     message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    // });

                    const dataObjectId = dataObject.id;
                    ALL_BILLING_DATA_RESPONSE.data = ALL_BILLING_DATA_RESPONSE.data.map((item) => item.id === dataObjectId ? { ...item, ...dataObject } : item);
                    return of({
                        status: true,
                        message: this._translateService.instant("DataUpdateSuccess")
                    });
                })
            )
    }

    public deleteBilling(idsArray) {
        return this.http.post(this.urlUtil.getUrl('billing', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("deleteBilling error =>", e);
                    // return of({
                    //     status: false,
                    //     message: e?.errorMessage || e?.message || this._translateService.instant("FailDataDelete")
                    // });

                    ALL_BILLING_DATA_RESPONSE.data = ALL_BILLING_DATA_RESPONSE.data.filter(({ id }) => !idsArray.includes(id));
                    return of({
                        status: true,
                        message: this._translateService.instant("DataDeleteSuccess")
                    });
                })
            )
    }
}
