import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_DATA_CONNECTORS, SET_DATA_CONNECTORS, CLEAN_DATA_CONNECTORS,
    CLEAN_DATA_CONNECTORS_RESPONSE, UPLOAD_DATA_CONNECTOR, UPLOAD_DATA_CONNECTOR_RESPONSE
} from "./actionTypes"

export class GetDataConnectors implements Action {
    readonly type = GET_DATA_CONNECTORS;
}

export class SetDataConnectors implements Action {
    readonly type = SET_DATA_CONNECTORS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class CleanDataConnectors implements Action {
    readonly type = CLEAN_DATA_CONNECTORS;
    readonly payload: any = null;
    constructor(data: any) {
        this.payload = data
    }
}

export class CleanDataConnectorsResponse implements Action {
    readonly type = CLEAN_DATA_CONNECTORS_RESPONSE;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UploadDataConnector implements Action {
    readonly type = UPLOAD_DATA_CONNECTOR;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data
    }
}

export class UploadDataConnectorResponse implements Action {
    readonly type = UPLOAD_DATA_CONNECTOR_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions = GetDataConnectors | SetDataConnectors | CleanDataConnectors |
    CleanDataConnectorsResponse | UploadDataConnector | UploadDataConnectorResponse;
