import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthenticationService } from "app/auth/service";
import { userDetail } from "app/store/core/store.module";
import { AUDIO_FILE_REGEX, IMAGE_FILE_REGEX, PDF_FILE_REGEX, DOC_FILE_REGEX, TEXT_FILE_REGEX } from "./regexPatterns.util";

import * as JsEncryptModule from 'jsencrypt';
import { environment } from 'environments/environment';
import { helperValues } from "app/store/common/commonStore.module";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CommonUtil {

    userDetail = null;

    private encryptMod: any;
    private publicKey: string = environment.publicKey;

    private allowedFileTypesForEnquiry: { [key: string]: string[] } = {
        'audio': [
            'audio/wav',
            'audio/mpeg',
            'audio/opus',
            'audio/ogg',
            'audio/flac',
            'audio/x-ms-wma',
            'audio/aac',
            'audio/x-alaw-basic',
            'audio/x-mulaw-basic',
            'audio/amr',
            'audio/webm',
            'audio/x-m4a',
            'audio/speex'
        ],
        'eml': ['message/rfc822']
    };

    private allowedFileTypesForDataConnectors = ['text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    private salutationOptions: Array<any> = [];
    private matterStateOptions: Array<any> = [];

    constructor(
        private store: Store,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService
    ) {
        this.store.select(userDetail)
            .subscribe((resp) => {
                if (resp) {
                    this.userDetail = resp;
                }
            })
        this.store.select(helperValues)
            .subscribe((resp) => {
                if (resp) {
                    const { salutation = [], matterState = [] } = resp;
                    this.salutationOptions = salutation;
                    this.matterStateOptions = matterState;
                }
            })

        this.encryptMod = new JsEncryptModule.JSEncrypt({
            default_key_size: environment.defaultKeySize
        });
    }

    public isValidFileType(file: File, fileType: string): boolean {
        // console.log('isValidFileType module', module)
        // let validMimeTypes = [];
        // switch (module) {
        //     case MODULE_ID.ENQUIRY:
        //         validMimeTypes = this.allowedFileTypesForEnquiry[fileType.toLowerCase()];
        //         break;
        //     case MODULE_ID.DATA_CONNECTORS:
        //         validMimeTypes = this.allowedFileTypesForDataConnectors[fileType.toLowerCase()];
        //         break;
        // }
        const validMimeTypes = this.allowedFileTypesForEnquiry[fileType.toLowerCase()];
        return validMimeTypes ? validMimeTypes.includes(file.type) : false;
    }

    public isValidFileTypeForDataConnector(file: File): boolean {
        const validMimeTypes = this.allowedFileTypesForDataConnectors;
        return validMimeTypes ? validMimeTypes.includes(file?.type) : false;
    }

    public encrypt(plainText: string) {
        this.encryptMod.setPublicKey(this.publicKey);
        return this.encryptMod.encrypt(plainText);
    }

    public convertObjectToArray(obj) {
        return Object.keys(obj).map((item) => {
            return {
                key: item,
                value: obj[item]
            }
        })
    }

    public getUserDetail(key = null) {
        if (this.userDetail === null) {
            return null;
        }
        if (key) {
            return this.userDetail[key];
        } else {
            return this.userDetail;
        }
    }

    public convertBrowserDateToTimestamp(dateString = '') {
        // expected date format yyyy-MM-dd
        if (dateString) {
            // returning timestamp in milliseconds
            return (new Date(dateString)).getTime()
        }
        return dateString;
    }

    public convertBrowserTimeToTimestamp(timeString = '') {
        // expected date format HH:mm:ss or HH:mm
        let timestampInSeconds = 0;

        if (timeString) {
            timeString.split(":").forEach((splitItem, index) => {
                switch (index) {
                    case 0:
                        timestampInSeconds += Number(splitItem) * 60 * 60;
                        break;
                    case 1:
                        timestampInSeconds += Number(splitItem) * 60;
                        break;
                    case 2:
                        timestampInSeconds += Number(splitItem);
                        break;
                    default:
                        break;
                }
            })
        }

        return timestampInSeconds * 1000;
    }

    public convertTimestampToBrowserTime(timestamp, format) {
        if (timestamp) {
            const timestampInSeconds = timestamp / 1000
            const totalMinutes = Math.floor(timestampInSeconds / 60);
            const seconds = timestampInSeconds % 60;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            switch (format) {
                case "HH:mm:ss":
                    return ('0' + hours).slice(-2) + ":" + ('0' + minutes).slice(-2) + ":" + ('0' + seconds).slice(-2)
                case "HH:mm":
                    return ('0' + hours).slice(-2) + ":" + ('0' + minutes).slice(-2)
                default:
                    return ('0' + hours).slice(-2) + ":" + ('0' + minutes).slice(-2) + ":" + ('0' + seconds).slice(-2)
            }
        }

        return "00:00:00";
    }

    public convertTimestampToBrowserDate(timestampInMilliseconds, format) {
        if (timestampInMilliseconds) {
            const date = new Date(timestampInMilliseconds)

            let day: any = date.getDate()
            let month: any = date.getMonth() + 1
            const year = date.getFullYear()

            if (day < 10) {
                day = '0' + day;
            }
            if (month < 10) {
                month = '0' + month;
            }

            switch (String(format).toLowerCase()) {
                case "dd-mm-yyyy":
                    return `${day}-${month}-${year}`
                case "yyyy-mm-dd":
                    return `${year}-${month}-${day}`
                default:
                    return `${day}-${month}-${year}`
            }
        }

        return "";
    }

    public checkIfCurrent(startDate, endDate) {
        const startTimestamp = typeof startDate === 'number'
            ?
            new Date(startDate).getTime()
            :
            typeof startDate === 'string'
                ?
                this.convertBrowserDateToTimestamp(startDate)
                : 0

        const endTimestamp = typeof endDate === 'number'
            ?
            new Date(endDate).getTime()
            :
            typeof endDate === 'string'
                ?
                this.convertBrowserDateToTimestamp(endDate)
                : 0

        const currentTimestamp = new Date().getTime()

        if (currentTimestamp >= startTimestamp && currentTimestamp <= endTimestamp) {
            return true;
        }
        return false;
    }

    public convertDateTimeToMillis(dateTime: string) {
        // dateTime format expected: '2024-07-17T14:00'
        if (dateTime) {
            // returning dateTime in milliseconds
            return (new Date(dateTime)).getTime();
        }

        return dateTime;
    }

    public convertMillisToDateTime(millis: number) {
        const date = new Date(millis);

        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    public convertMillisToMonthDate(millis: number) {
        const date = new Date(millis);
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const month = monthNames[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');

        return `${month} ${day}`;
    }

    formatDateRange(startMilliseconds: number, endMilliseconds: number): string {
        const startDate = new Date(startMilliseconds);
        const endDate = new Date(endMilliseconds);

        const dateOptions: Intl.DateTimeFormatOptions = {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        };

        const timeOptions: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const startDateFormatted = startDate.toLocaleDateString('en-US', dateOptions);
        const startTimeFormatted = startDate.toLocaleTimeString('en-US', timeOptions);

        const endDateFormatted = endDate.toLocaleDateString('en-US', dateOptions);
        const endTimeFormatted = endDate.toLocaleTimeString('en-US', timeOptions);

        const startDateTime = `${startDateFormatted} ${startTimeFormatted}`;
        const endDateTime = `${endDateFormatted} ${endTimeFormatted}`;

        return `${startDateTime} - ${endDateTime}`;
    }

    public checkIfAudioFile(file) {
        return AUDIO_FILE_REGEX.test(file?.name || "")
    }

    public checkIfImageFile(file) {
        return IMAGE_FILE_REGEX.test(file?.name || "")
    }

    public checkIfPdfFile(file) {
        return PDF_FILE_REGEX.test(file?.name || "")
    }

    public checkIfDocFile(file) {
        return DOC_FILE_REGEX.test(file?.name || "")
    }

    public checkIfTextFile(file) {
        return TEXT_FILE_REGEX.test(file?.name || "")
    }

    public generateAndDownloadTextFile(filename, content) {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
        downloadLink.setAttribute('download', filename);

        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);
    }

    public generateTimeOptions(): string[] {
        const times: string[] = [];
        for (let hour = 1; hour <= 12; hour++) {
            for (const minute of [0, 15, 30, 45]) {
                for (const ampm of ['AM', 'PM']) {
                    times.push(`${('0' + hour).slice(-2)}:${minute.toString().padStart(2, '0')} ${ampm}`);
                }
            }
        }
        return this.sortTimes(times);
    }

    public sortTimes(times: string[]): string[] {
        return times.sort((a, b) => {
            const [hourA, minuteA, ampmA] = this.parseTime(a);
            const [hourB, minuteB, ampmB] = this.parseTime(b);

            if (ampmA !== ampmB) {
                return ampmA === 'AM' ? -1 : 1;
            }
            return hourA !== hourB ? hourA - hourB : minuteA - minuteB;
        });
    }

    public parseTime(time: string): [number, number, string] {
        const [timePart, ampm] = time.split(' ');
        const [hour, minute] = timePart.split(':').map(Number);
        return [hour, minute, ampm];
    }

    public formatEnquiryOptions(enquiryOptions) {
        return (enquiryOptions || []).map((item) => {
            const userSalutationId = item?.enquiryProfile?.userInfo?.salutation;
            const userSalutationValue = this.getSalutationToShow(userSalutationId);
            const userName = item?.enquiryProfile?.userInfo?.firstName || '';
            const enquiryReference = item?.enquiryReference ? `(${item?.enquiryReference})` : '';

            return {
                ...item,
                fullName: `${userSalutationValue ? userSalutationValue + " " : ""}${userName} ${enquiryReference}`
            }
        });
    }

    public formatUserOptions(userOptions) {
        return (userOptions || []).map((item) => {
            const salutationValue = this.getSalutationToShow(item?.salutation);

            return {
                ...item,
                fullName: `${salutationValue ? `${salutationValue} ` : ''}${item.firstName ? `${item.firstName}` : ''}`
            }
        });
    }

    public getSalutationToShow(salutationId) {
        if (!salutationId || salutationId === "NONE" || salutationId === "OTHER") {
            return "";
        }
        if (!this.salutationOptions?.length) {
            return salutationId;
        }

        return this.salutationOptions.find(({ id }) => id === salutationId)?.name || salutationId || "";
    }

    public getFormattedMatterStateName(matterStateId, isGuestOrClient) {
        if (isGuestOrClient) {
            switch (matterStateId) {
                case "RISK_ASSESSMENT_INITIATED":
                    return this._translateService.instant("We need more information from you, click Kai for help");
                case "ONBOARDING_COMPLETE":
                    return this._translateService.instant("In review, We will come back to you soon");
                case "MATTER_DENIED":
                    return this._translateService.instant("Enquiry closing");
                default:
                    return this.matterStateOptions.find(({ id }) => id === matterStateId)?.name || matterStateId || "-";
            }
        }

        return this.matterStateOptions.find(({ id }) => id === matterStateId)?.name || matterStateId || "-";
    }
}
