import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { FirmsEffect } from "./effect";
import { FirmsStateReducer } from "./reducer";

@NgModule({
    imports: [
        EffectsModule.forFeature([FirmsEffect]),
        StoreModule.forFeature('firms', FirmsStateReducer)
    ]
})

export class FirmsStoreModule { }

export const allFirms = (store: any) => store && store.firms && store.firms.allFirms;
export const firmById = (store: any) => store && store.firms && store.firms.firmById;
export const allLeads = (store: any) => store && store.firms && store.firms.allLeads;
export const addFirmResponse = (store: any) => store && store.firms && store.firms.addFirmResponse;
export const updateFirmResponse = (store: any) => store && store.firms && store.firms.updateFirmResponse;
export const deleteFirmResponse = (store: any) => store && store.firms && store.firms.deleteFirmResponse;
export const testDocumentSignatureConnectivityResponse = (store: any) => store && store.firms && store.firms.testDocumentSignatureConnectivityResponse;
export const testEmailConnectivityResponse = (store: any) => store && store.firms && store.firms.testEmailConnectivityResponse;
export const documentsToBeSigned = (store: any) => store && store.firms && store.firms.documentsToBeSigned;
export const documentSignatureByFields = (store: any) => store && store.firms && store.firms.documentSignatureByFields;
export const sendDocumentSignatureReminderResponse = (store: any) => store && store.firms && store.firms.sendDocumentSignatureReminderResponse;
