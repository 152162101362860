import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import packageInfo from "../../package.json";

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    private versionUrl = '/assets/version.json';

    constructor(private http: HttpClient) {
        // this.checkForVersionUpdate();
    }

    public async checkForVersionUpdate() {
        const origin: string = window.location.origin;
        const versionUrlWithOrigin = origin + this.versionUrl;
        await this.http.get<{ version: string }>(versionUrlWithOrigin, {
            headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' }
        })
            .pipe(map((data) => data.version))
            .subscribe((latestVersion) => {
                console.log(packageInfo.version, "Inside http subscribe", latestVersion)
                if (packageInfo.version !== latestVersion) {
                    window.location.reload();
                }
            });
    }
}
