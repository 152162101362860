import { initialState, CoreState } from "./state";
import { Actions } from './actions';
import {
    SET_USER_DETAIL, FORGOT_PASSWORD_RESP, RESET_PASSWORD_RESP, UPDATE_USER_DETAIL_RESP
} from "./actionTypes";

export function CoreReducer(state = initialState, action: Actions): CoreState {
    switch (action.type) {
        case SET_USER_DETAIL:
            return Object.assign({}, state, {
                userDetail: action.payload
            });
        case FORGOT_PASSWORD_RESP:
            return Object.assign({}, state, {
                forgotPasswordResp: action.payload
            });
        case RESET_PASSWORD_RESP:
            return Object.assign({}, state, {
                resetPasswordResp: action.payload
            });
        case UPDATE_USER_DETAIL_RESP:
            return Object.assign({}, state, {
                updateUserDetailResponse: action.payload
            });
        default:
            return state;
    }
}
