import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { MattersEffect } from "./effect";
import { MattersStateReducer } from "./reducer";

import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([MattersEffect]),
        StoreModule.forFeature('matters', MattersStateReducer)
    ]
})

export class MatterStoreModule { }

export const allMatters = (store: any) => store && store.matters && store.matters.allMatters;
export const mattersByFields = (store: any) => store && store.matters && store.matters.mattersByFields;
export const matterById = (store: any) => store && store.matters && store.matters.matterById;
export const clientsByMatterId = (store: any) => store && store.matters && store.matters.clients;
export const addMatterResponse = (store: any) => store && store.matters && store.matters.addMatterResponse;
export const updateMatterResponse = (store: any) => store && store.matters && store.matters.updateMatterResponse;
export const deleteMatterResponse = (store: any) => store && store.matters && store.matters.deleteMatterResponse;
