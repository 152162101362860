import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_ENCRYPTION_KEY } from 'app/constants/common.constant';
import * as SecureLS from 'secure-ls';

@Injectable({
    providedIn: 'root'
})
export class LocalstorageService {

    dataStream = new SecureLS({ encodingType: 'des', encryptionSecret: LOCAL_STORAGE_ENCRYPTION_KEY });

    constructor() { }

    setItem(key, value) {
        this.dataStream.set(key, value);
    }

    getItem(key: string) {
        if (this.dataStream.getAllKeys().includes(key)) {
            return this.dataStream.get(key);
        } else {
            return null;
        }

    }

    removeItem(key) {
        this.dataStream.remove(key);
    }
}
