import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CALENDAR_COLOR_CODE,
    FONT_COLOR_BASED_ON_BG_COLOR
} from 'app/constants/colors.const';
import { UrlUtil } from 'app/utils/url.util';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocalstorageService } from '@core/services/localstorage.service';
import { environment } from 'environments/environment';
import { getTextColorBasedOnBgColor } from 'app/utils/commonFunctions.util';

@Injectable()
export class CalendarService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private localStorage: LocalstorageService
    ) { }

    public syncCalendarData() {
        return this.http
            .get(this.urlUtil.getUrl('systemCalendar', 'sync'))
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant('DataAddSuccess')
                        };
                    } else {
                        return {
                            status: false,
                            message: errorMessage || jsonData
                        };
                    }
                }),
                catchError((e) => {
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message
                    });
                })
            );
    }

    // public getFilterList() {
    //     return this.http.get(
    //         this.urlUtil.getUrl('systemCalendar', 'calendarOptions')
    //     )
    //         .pipe(
    //             map((resp: any) => {
    //                 if (resp.status) {
    //                     let res = [];
    //                     Object.keys(resp.data).forEach((item) => {
    //                         res.push({
    //                             key: item,
    //                             value: resp.data[item],
    //                             checked: true,
    //                             color: CALENDAR_COLOR_CODE[item],
    //                             textColor: FONT_COLOR_BASED_ON_BG_COLOR[CALENDAR_COLOR_CODE[item]]
    //                         })
    //                     })
    //                     return res;
    //                 } else {
    //                     return [];
    //                 }
    //             }),
    //             catchError((e) => {
    //                 console.log(e);
    //                 // return of(null);
    //                 return of(HELPER_VALUES.calendarOptions)
    //             })
    //         )
    // }

    public getCalendarEvent() {
        return this.http
            .get(this.urlUtil.getUrl('systemCalendar', 'calendarEvent'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        // return resp.data;
                        return this.formatData(resp.data);
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(
                        'getCalendarEvent: failed to fetch calendar events:',
                        e
                    );
                    return [];
                    // return of(this.formatData([]));
                })
            );
    }

    public getCalendarDataById(id) {
        return this.http
            .get(
                this.urlUtil
                    .getUrl('systemCalendar', 'searchById')
                    .replace('$ID', id)
            )
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log('getCalendarDataById error =>', e);
                    return of({});
                })
            );
    }

    public syncCalendar() {
        try {
            let token = '';
            if (this.localStorage.getItem('authToken')) {
                const authDataStringified =
                    this.localStorage.getItem('authToken');
                const authDataParsed = JSON.parse(authDataStringified);
                token = authDataParsed?.token;
            }
            const url =
                environment.apiUrl +
                this.urlUtil
                    .getUrl('systemCalendar', 'syncCalendar')
                    .replace('$TOKEN', token);
            window.location.href = url;
            return [];
        } catch (error) {
            return [];
        }
    }

    public addCalendarEvent(payload) {
        return this.http
            .post(this.urlUtil.getUrl('systemCalendar', 'addEvent'), payload)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant('DataAddSuccess')
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataAdd')
                        };
                    }
                }),
                catchError((e) => {
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataAdd')
                    });
                })
            );
    }

    public updateCalendarEvent(payload) {
        return this.http
            .put(
                this.urlUtil
                    .getUrl('systemCalendar', 'updateEvent')
                    .replace('$ID', payload.id),
                payload
            )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant(
                                    'DataUpdateSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataUpdate')
                        };
                    }
                }),
                catchError((e) => {
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataUpdate')
                    });
                })
            );
    }

    public deleteCalendarEvent(id) {
        return this.http
            .delete(
                this.urlUtil
                    .getUrl('systemCalendar', 'deleteEvent')
                    .replace('$ID', id)
            )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                (errorMessage || '') + (jsonData || '') ||
                                this._translateService.instant(
                                    'DataDeleteSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                (errorMessage || '') + (jsonData || '') ||
                                this._translateService.instant('FailDataDelete')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('deleteCalendarEvent error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataDelete')
                    });
                })
            );
    }

    private formatData(data): Array<any> {
        const colorOptions = [
            '#00FFFF',
            '#4A90E2',
            '#50E3C2',
            '#F5A623',
            '#7ED321',
            '#D0021B'
        ];

        let formattedData = [];
        data.forEach((item) => {
            const { eventColor, title, eventType, description, startTime, endTime, kaiTaskDetails } = item || {};
            let isFullDay = item?.isFullDay;
            if (!isFullDay) {
                isFullDay = this.checkIfMoreThanEqualTo24Hours(
                    startTime,
                    endTime
                );
            }
            const color = eventColor || this.getRandomColor(colorOptions);
            const textColor = getTextColorBasedOnBgColor(color);

            formattedData.push({
                eventType: eventType,
                title: title,
                description: description || '',
                // allDay: isFullDay,
                allDay: isFullDay,
                moduleId: kaiTaskDetails?.moduleId,
                entity: kaiTaskDetails?.taskType,
                id: item.id,
                start: this.formatDate(item.startTime, isFullDay, false),
                end: this.formatDate(item.endTime, isFullDay, true),
                color: color,
                textColor: textColor,
                extendedProps: {
                    filterType: 'event_start',
                    color: color,
                    textColor: textColor
                }
            });
        });
        return formattedData;
    }

    private getRandomColor(colorOptions: string[]): string {
        const randomIndex = Math.floor(Math.random() * colorOptions.length);
        return colorOptions[randomIndex];
    }

    private formatDate(millisecondDate, allDay: boolean, isEndDate: boolean) {
        const date = new Date(millisecondDate);

        if (allDay && isEndDate) {
            date.setDate(date.getDate() + 1);
        }

        const localDateTime =
            date.getFullYear() +
            '-' +
            String(date.getMonth() + 1).padStart(2, '0') +
            '-' +
            String(date.getDate()).padStart(2, '0') +
            'T' +
            String(date.getHours()).padStart(2, '0') +
            ':' +
            String(date.getMinutes()).padStart(2, '0');

        return allDay ? localDateTime?.split('T')?.[0] : localDateTime;
    }

    private checkIfMoreThanEqualTo24Hours(startMillis, endMillis) {
        const diffInMilli = endMillis - startMillis;
        if (diffInMilli) {
            const diffInHours = diffInMilli / 3600000;
            return diffInHours >= 24;
        } else {
            return false;
        }
    }
}
