import { MODULE_ID } from "app/constants/module.constant";
import { IServerResponse } from "app/interface";

export interface ClientsState {
    allClients: Array<any>;
    clientById: any;
    clientsByField: Array<any>;
    mattersByClientId: Array<any>;
    updateClientResponse: IServerResponse;
    deleteClientResponse: IServerResponse;
}

export const initialState: ClientsState = {
    allClients: null,
    clientById: null,
    clientsByField: null,
    mattersByClientId: null,
    updateClientResponse: null,
    deleteClientResponse: null
}
