import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MODULE_ID } from "app/constants/module.constant";
import { CommonUtil } from "app/utils/common.util";
import { UrlUtil } from "app/utils/url.util";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { SetSearchOptions } from "./actions";
import { APP_CONFIG } from "./stub";

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CommonService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private commonUtil: CommonUtil,
        private store: Store,
        private _translateService: TranslateService
    ) {

    }

    public getUserAccess() {
        return this.http.get(
            this.urlUtil.getUrl('access', 'appAccess'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return null;
                    }
                }),
                catchError((e) => {
                    console.log("getUserAccess catch", e);
                    return of(null);
                })
            )
    }

    public getAppConfig() {
        return this.http.get(
            this.urlUtil.getUrl('config', 'appConfig'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return null;
                    }
                }),
                catchError((e) => {
                    console.log("getAppConfig catch", e);
                    return of(APP_CONFIG);
                })
            )
    }

    public uploadFile(data) {
        let fileData = new FormData();
        fileData.append('file', data);
        return this.http.post(
            this.urlUtil.getUrl('file', 'upload'), fileData)
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        return resp.data;
                    } else {
                        return null;
                    }
                }),
                catchError((e) => {
                    console.log("upload file catch", e);
                    return of(null);
                })
            )
    }

    public downloadFile(id) {
        return this.http.get(
            this.urlUtil.getUrl('file', 'download'))
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        return resp.data;
                    } else {
                        return null;
                    }
                }),
                catchError((e) => {
                    console.log("download file catch", e);
                    return of(null);
                })
            )
    }

    public getSearchOption(moduleId) {
        let url = "";
        if (moduleId === MODULE_ID.CLIENTS) {
            url = this.urlUtil.getUrl('clients', 'searchOptions');
        }
        if (moduleId === MODULE_ID.MATTERS) {
            url = this.urlUtil.getUrl('matters', 'searchOptions');
        }
        if (moduleId === MODULE_ID.ENQUIRY) {
            url = this.urlUtil.getUrl('enquiry', 'searchOptions');
        }
        if (moduleId === MODULE_ID.METERS) {
            url = this.urlUtil.getUrl('meters', 'searchOptions');
        }
        if (moduleId === MODULE_ID.USERS) {
            url = this.urlUtil.getUrl('users', 'searchOptions');
        }
        if (moduleId === MODULE_ID.DOCUMENT_SIGNATURE_TRACKING) {
            url = this.urlUtil.getUrl('firms', 'searchOptions');
        }
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return this.commonUtil.convertObjectToArray(resp.data);
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    this.store.dispatch(new SetSearchOptions([]))
                    return of(null);
                    // return of([
                    //     { key: 'select', value: "Select" },
                    //     { key: 'module', value: 'Module' },
                    //     { key: 'documentType', value: 'Document Type' },
                    //     { key: 'moduleReferenceNumber', value: 'Module Reference Number' }
                    // ]);
                })
            )
    }

    public getHelperValues() {
        return this.http.get(
            this.urlUtil.getUrl('common', 'helperValues'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getHelperValues catch", e);
                    return of({});
                })
            )
    }

    public getUnipaasPaymentLink(data) {
        return this.http.post(this.urlUtil.getUrl('unipaas', 'getPaymentLink'), data)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: jsonData
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("getUnipaasPaymentLink catch", e);
                    return of({
                        status: false,
                        message: e.message || this._translateService.instant("ErrorGeneral")
                    });
                })
            )
    }
}

