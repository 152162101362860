import { Action } from '@ngrx/store';
import { IServerResponse } from 'app/interface';

import {
    SET_ALL_MATTERS,
    GET_MATTER_BY_ID,
    SET_MATTER_BY_ID,
    ADD_MATTER,
    ADD_MATTER_RESPONSE,
    UPDATE_MATTER,
    UPDATE_MATTER_RESPONSE,
    DELETE_MATTER,
    DELETE_MATTER_RESPONSE,
    GET_MATTER_BY_FIELD,
    GET_MATTER_CLIENTS_BY_ID,
    SET_MATTER_CLIENTS_BY_ID,
    SET_MATTER_BY_FIELD,
    GET_ALL_MATTERS
} from './actionTypes';

export class GetAllMatters implements Action {
    readonly type = GET_ALL_MATTERS;
    readonly payload = null;
}

export class SetAllMatters implements Action {
    readonly type = SET_ALL_MATTERS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetMattersByField implements Action {
    readonly type = GET_MATTER_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetMattersByField implements Action {
    readonly type = SET_MATTER_BY_FIELD;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetMatterById implements Action {
    readonly type = GET_MATTER_BY_ID;
    readonly payload: string = null;
    constructor(matterId) {
        this.payload = matterId;
    }
}

export class SetMatterById implements Action {
    readonly type = SET_MATTER_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}
export class GetClientsByMatterId implements Action {
    readonly type = GET_MATTER_CLIENTS_BY_ID;
    readonly payload: string = null;
    constructor(matterId) {
        this.payload = matterId;
    }
}

export class SetClientsByMatterId implements Action {
    readonly type = SET_MATTER_CLIENTS_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddMatter implements Action {
    readonly type = ADD_MATTER;
    readonly payload = null;
    constructor(matterObject) {
        this.payload = matterObject;
    }
}

export class AddMatterResponse implements Action {
    readonly type = ADD_MATTER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateMatter implements Action {
    readonly type = UPDATE_MATTER;
    readonly payload = null;
    constructor(matterObject) {
        this.payload = matterObject;
    }
}

export class UpdateMatterResponse implements Action {
    readonly type = UPDATE_MATTER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteMatter implements Action {
    readonly type = DELETE_MATTER;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray;
    }
}

export class DeleteMatterResponse implements Action {
    readonly type = DELETE_MATTER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions =
    | SetAllMatters
    | GetMatterById
    | SetMatterById
    | AddMatter
    | AddMatterResponse
    | UpdateMatter
    | UpdateMatterResponse
    | DeleteMatter
    | DeleteMatterResponse
    | GetMattersByField
    | GetClientsByMatterId
    | SetClientsByMatterId
    | GetAllMatters
    | SetMattersByField;
