<div class="modal-header">
  <h4 class="modal-title">{{ 'Create Calendar Event' }}</h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'reassign-task-modal'"
>
  <form class="form" [formGroup]="createCalendarEventForm">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            
            <div class="row">
              <div class="col-12" *ngIf="ReactiveFormControl.startTime">
                <div class="form-group required">
                  <label for="startTime" class="control-label">
                    {{ "Start Date" | translate }}
                  </label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    name="startTime"
                    formControlName="startTime"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted &&
                        !!(
                          ReactiveFormControl.startTime.errors?.required ||
                          ReactiveFormControl.startTime.errors?.invalid
                        )
                    }"
                    [min]="TodaysDate"
                    [max]="MaxDate"
                  />
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.startTime.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.startTime.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                    <div *ngIf="ReactiveFormControl.startTime.errors.invalid">
                      {{ "ErrorInvalidDate" | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="!taskDetails?.taskDuration">
                <label for="taskDuration" class="control-label">{{
                  "Task Duration" | translate
                }}</label>
                <div class="row" formGroupName="duration">
                  <div class="col-6">
                    <div class="form-group required">
                      <label for="hour" class="control-label">
                        {{ "Hours" | translate }}
                      </label>
                      <ng-select
                        [ngClass]="{
                          'is-invalid':
                            formSubmitted &&
                            ReactiveFormControl.duration.errors?.invalid
                        }"
                        id="hour"
                        name="hour"
                        [items]="hourOptions"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="hour"
                        (change)="CheckDurationError()"
                        [closeOnSelect]="true"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group required">
                      <label for="hour" class="control-label">
                        {{ "Minutes" | translate }}
                      </label>
                      <ng-select
                        [ngClass]="{
                          'is-invalid':
                            formSubmitted &&
                            ReactiveFormControl.duration.errors?.invalid
                        }"
                        id="minute"
                        name="minute"
                        [items]="minuteOptions"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="minute"
                        (change)="CheckDurationError()"
                        [closeOnSelect]="true"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12 py-0 mt-n1">
                    <div
                      *ngIf="
                        formSubmitted &&
                        ReactiveFormControl.duration.errors?.invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      <div>
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-8">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  (click)="onSubmitClick()"
                >
                  {{ 'Submit' | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="onCancelClick()"
                >
                  {{ 'Cancel' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
