import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    Output,
    EventEmitter
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-matter-update-reason-modal',
    templateUrl: './matter-update-reason-modal.component.html',
    styleUrls: ['./matter-update-reason-modal.component.scss']
})
export class MatterUpdateReasonModalComponent implements OnInit, OnDestroy {
    @BlockUI('update-status-modal') updateStatusModalBlockUI: NgBlockUI;

    @Input() handleCloseClick: Function;
    @Output() handleDestroy: Function;
    @Output() handleSubmit: Function;

    public matterUpdateReasonForm: FormGroup;
    formSubmitted: boolean = false;

    unsubscribe = new Subject();

    constructor() {}

    ngOnInit(): void {
        this.matterUpdateReasonForm = new FormGroup({
            comment: new FormControl('', Validators.required)
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.matterUpdateReasonForm.reset();
        this.handleDestroy();
    }

    get ReactiveFormControl() {
        return this.matterUpdateReasonForm.controls;
    }

    get ReactiveFormValues() {
        return this.matterUpdateReasonForm.getRawValue();
    }

    onSubmitClick() {
        this.formSubmitted = true;

        if (this.matterUpdateReasonForm.invalid) {
            return;
        }

        const mattersDetails = cloneDeep({ ...this.ReactiveFormValues });

        this.updateStatusModalBlockUI.start();

        console.log('mattersDetails ::> ', mattersDetails);
        // Todo - once we have `comment` in schema we need to save this
        this.handleSubmit();
    }

    onCancelClick() {
        this.matterUpdateReasonForm.reset();

        this.handleCloseClick();
    }
}
