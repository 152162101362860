import { Action } from "@ngrx/store";
import {
    GET_AUDIT_LOGS, SET_AUDIT_LOGS, DOWNLOAD_AUDIT_LOG_CSV, DOWNLOAD_AUDIT_LOG_CSV_RESPONSE,
    GET_FILTERED_AUDIT_LOGS
} from "./actionTypes";

export class GetAuditLogs implements Action {
    readonly type = GET_AUDIT_LOGS;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class GetFilteredAuditLogs implements Action {
    readonly type = GET_FILTERED_AUDIT_LOGS;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetAuditLogs implements Action {
    readonly type = SET_AUDIT_LOGS;
    readonly payload = []
    constructor(auditLogs: Array<any>) {
        this.payload = auditLogs;
    }
}

export class DownloadAuditLogCsv implements Action {
    readonly type = DOWNLOAD_AUDIT_LOG_CSV;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class DownloadAuditLogCsvResponse implements Action {
    readonly type = DOWNLOAD_AUDIT_LOG_CSV_RESPONSE;
    readonly payload = []
    constructor(report: any) {
        this.payload = report;
    }
}

export type Actions = GetAuditLogs | GetFilteredAuditLogs | SetAuditLogs | DownloadAuditLogCsv | DownloadAuditLogCsvResponse;
