<div class="modal-header">
  <h4 class="modal-title">{{ "Reassign Task" }}</h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'reassign-task-modal'"
>
  <form class="form" [formGroup]="taskReAssignForm">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-12" *ngIf="ReactiveFormControl.type">
                <div class="form-group">
                  <label for="type" class="control-label">
                    {{ "Is Role Base?" | translate }}
                  </label>
                  <div class="mt-25 d-flex align-items-center">
                    <label class="switch m-0">
                      <input
                        type="checkbox"
                        [checked]="ReactiveFormValues?.type === 'ROLE_BASED'"
                        (change)="handleTypeChange($event.target.checked)"
                      />
                      <span class="slider round"></span>
                    </label>
                    <span
                      class="ml-1"
                      *ngIf="ReactiveFormValues?.type === 'ROLE_BASED'"
                      >{{ "Yes" | translate }}</span
                    >
                    <span
                      class="ml-1"
                      *ngIf="ReactiveFormValues?.type === 'USER_BASED'"
                      >{{ "No" | translate }}</span
                    >
                  </div>
                </div>
              </div>

              <div
                class="col-md-6 col-12"
                *ngIf="ReactiveFormValues?.type === 'ROLE_BASED'"
              >
                <div class="form-group required">
                  <label for="role" class="control-label">{{
                    "Role" | translate
                  }}</label>
                  <ng-select
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.role.errors
                    }"
                    id="role"
                    name="role"
                    [items]="roleOptions"
                    bindLabel="name"
                    bindValue="id"
                    formControlName="role"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.role.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.role.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="ReactiveFormValues?.type === 'USER_BASED'">
              <div class="col-md-6 col-12 align-self-center py-1">
                <label class="d-flex align-items-center">
                  <input
                    class="mr-1"
                    type="checkbox"
                    [checked]="assignSomeOne"
                    (change)="handleAssignSelf($event.target.checked)"
                  />
                  {{ "Assign to someone else?" | translate }}
                </label>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group required mb-1" *ngIf="assignSomeOne">
                  <label for="userId" class="control-label">{{
                    "User" | translate
                  }}</label>
                  <ng-select
                    [items]="userList"
                    formControlName="userId"
                    groupBy="userRole"
                    bindLabel="name"
                    bindValue="id"
                    (change)="handleUserSelect($event.name)"
                    [closeOnSelect]="true"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span class="text-capitalize">{{ item.name.toLowerCase() }}</span>
                    </ng-template>
                    <ng-template
                      ng-optgroup-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <b>{{ item.userRole }}</b>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                    <span class="text-capitalize" [ngOptionHighlight]="search">{{ item.name.toLowerCase() }}</span>
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.userId.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.userId.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-8">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  (click)="onSubmitClick()"
                >
                  {{ "Submit" | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="onCancelClick()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
