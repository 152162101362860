import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";

import { CoreStoreModule } from "./core/store.module";
import { CommonStoreModule } from "./common/commonStore.module";
import { HomeStoreModule } from "./home/homeStore.module";
import { OperationStoreModule } from "./operation/operationStore.module";
import { TrackingStoreModule } from "./tracking/trackingStore.module";
import { SetupStoreModule } from "./setup/setupStore.module";
import { UtilitiesStoreModule } from './utilities/utilitiesStore.module';

import { LOGOUT } from "./core/actionTypes";

function logout(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        return reducer(action.type === LOGOUT ? undefined : state, action);
    };
}

const metaReducers: MetaReducer<any>[] = [logout];

@NgModule({
    imports: [
        CoreStoreModule,
        CommonStoreModule,
        HomeStoreModule,
        OperationStoreModule,
        TrackingStoreModule,
        SetupStoreModule,
        UtilitiesStoreModule,
        StoreModule.forRoot([], { metaReducers }),
        EffectsModule.forRoot([])
    ]
})

export class NgrxStoreModule { }
