import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { TOASTR_TYPE } from 'app/constants/common.constant';
import { PresentToastr } from 'app/store/common/actions';

import { TranslateService } from '@ngx-translate/core';

import { checkFilesSize } from 'app/utils/commonFunctions.util';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-test-document-signature-modal',
    templateUrl: './test-document-signature-modal.component.html',
    styleUrls: ['./test-document-signature-modal.component.scss']
})
export class TestDocumentSignatureModalComponent implements OnInit, OnDestroy {
    @Input() handleCloseClick: Function
    @Input() handleSubmitClick: Function

    public documentsToBeSigned: Array<any> = [];
    public clientId: String = "";
    public documentSignModule: String = "";
    public moduleId: String = "";

    constructor(
        private store: Store,
        private _translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.documentsToBeSigned = [];
        this.clientId = "";
        this.documentSignModule = "";
        this.moduleId = "";
    }

    ngOnDestroy(): void {
        this.documentsToBeSigned = [];
    }

    onFilesAdd({ newFiles, controlName }) {
        if (newFiles) {
            const filesArray = Array.from(newFiles)
            const { sizeValidity } = checkFilesSize({ filesObjArr: filesArray, upperLimitInMB: 20, _translateService: this._translateService })

            if (sizeValidity) {
                const userAttachments = this.documentsToBeSigned || [];

                this.documentsToBeSigned = userAttachments.concat(filesArray.map((fileItem: any) => ({
                    id: `new-${uuidv4()}`,
                    name: fileItem.name,
                    url: fileItem
                })))
            } else {
                this.store.dispatch(new PresentToastr({
                    type: TOASTR_TYPE.WARNING,
                    message: this._translateService.instant("Files should not be larger than 20MB")
                }))
            }
        }
        (<HTMLInputElement>document.getElementById(controlName)).value = null;
    }

    onFileRemove({ fileId, controlName }) {
        const userAttachments = this.documentsToBeSigned || []

        this.documentsToBeSigned = userAttachments.filter(({ id }) => id !== fileId)
    }

    onSubmitClick() {
        this.handleSubmitClick(
            {
                files: this.documentsToBeSigned.map(({ url }) => url),
                clientId: this.clientId,
                documentSignModule: this.documentSignModule,
                moduleId: this.moduleId
            }
        );
    }
}
