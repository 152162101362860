import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { CommonUtil } from 'app/utils/common.util';

@Component({
    selector: '[core-menu-vertical-item]',
    templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
    @Input() item: CoreMenuItem;

    constructor(private commonUtil: CommonUtil) {
    }

    openExternalUrl(url, openInNewTab) {
        window.open(url.replace('$TOKEN', this.commonUtil.getUserDetail('token')), openInNewTab ? '_blank' : '_target');
    }
}
