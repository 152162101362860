<div class="modal-header bg-primary">
  <h4 class="modal-title text-white">{{ title }}</h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'settings-modal'"
>
  <form class="form" [formGroup]="settingsForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <fieldset>
              <div class="row">
                <div class="col-md-4 col-12">
                  <div class="form-group required">
                    <label for="apiName" class="control-label">
                      {{ "Api Name" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="apiName"
                      name="apiName"
                      formControlName="apiName"
                      placeholder="Api Name"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.apiName.errors?.required
                      }"
                    />
                    <div
                      *ngIf="
                        formSubmitted && SettingsFormControl.apiName.errors
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="SettingsFormControl.apiName.errors?.required">
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group required">
                    <label for="apiUrl" class="control-label">
                      {{ "Api Url" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="apiUrl"
                      name="apiUrl"
                      formControlName="apiUrl"
                      placeholder="Api Url"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.apiUrl.errors?.required
                      }"
                    />
                    <div
                      *ngIf="formSubmitted && SettingsFormControl.apiUrl.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="SettingsFormControl.apiUrl.errors?.required">
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group required">
                    <label for="authType" class="control-label">
                      {{ "Authentication Type" | translate }}
                    </label>
                    <ng-select
                      id="authType"
                      name="authType"
                      [formControl]="settingsForm.get('authType')"
                      placeholder="Select an authentication type"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted && settingsForm.get('authType').invalid
                      }"
                      [items]="authTypeValues"
                      bindLabel="name"
                      bindValue="id"
                      (change)="onAuthTypeChange($event)"
                      [closeOnSelect]="true"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        formSubmitted && settingsForm.get('authType').invalid
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="settingsForm.get('authType').errors?.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Dynamic Authentication type fields -->

              <div class="row">
                <div class="col-md-4 col-12" *ngIf="apiKeyAuth">
                  <div class="form-group required">
                    <label for="apiKey" class="control-label">
                      {{ "Api Key" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="apiKey"
                      name="apiKey"
                      formControlName="apiKey"
                      placeholder="Api Key"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.apiKey.errors?.required
                      }"
                    />
                    <div
                      *ngIf="formSubmitted && SettingsFormControl.apiKey.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="SettingsFormControl.apiKey.errors?.required">
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12" *ngIf="basicAuth">
                  <div class="form-group required">
                    <label for="userName" class="control-label">
                      {{ "Username" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="userName"
                      name="userName"
                      formControlName="userName"
                      placeholder="Username"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.userName.errors?.required
                      }"
                    />
                    <div
                      *ngIf="
                        formSubmitted && SettingsFormControl.userName.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="SettingsFormControl.userName.errors?.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12" *ngIf="basicAuth">
                  <div class="form-group required">
                    <label for="password" class="control-label">
                      {{ "Password" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="password"
                      name="password"
                      formControlName="password"
                      placeholder="Password"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.password.errors?.required
                      }"
                    />
                    <div
                      *ngIf="
                        formSubmitted && SettingsFormControl.password.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="SettingsFormControl.password.errors?.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-12" *ngIf="oAuthACG">
                  <div class="form-group required">
                    <label for="authorizationCode" class="control-label">
                      {{ "Authorization Code" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="authorizationCode"
                      name="authorizationCode"
                      formControlName="authorizationCode"
                      placeholder="Authorization Code"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.authorizationCode.errors?.required
                      }"
                    />
                    <div
                      *ngIf="
                        formSubmitted &&
                        SettingsFormControl.authorizationCode.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          SettingsFormControl.authorizationCode.errors?.required
                        "
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-12" *ngIf="oAuthACG">
                  <div class="form-group required">
                    <label for="clientId" class="control-label">
                      {{ "Client Id" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="clientId"
                      name="clientId"
                      formControlName="clientId"
                      placeholder="Client Id"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.clientId.errors?.required
                      }"
                    />
                    <div
                      *ngIf="
                        formSubmitted && SettingsFormControl.clientId.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="SettingsFormControl.clientId.errors?.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-12" *ngIf="oAuthACG">
                  <div class="form-group required">
                    <label for="clientSecret" class="control-label">
                      {{ "Client Secret" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="clientSecret"
                      name="clientSecret"
                      formControlName="clientSecret"
                      placeholder="Client Secret"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.clientSecret.errors?.required
                      }"
                    />
                    <div
                      *ngIf="
                        formSubmitted && SettingsFormControl.clientSecret.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          SettingsFormControl.clientSecret.errors?.required
                        "
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-12" *ngIf="oAuthACG">
                  <div class="form-group required">
                    <label for="redirectUri" class="control-label">
                      {{ "Redirect URI" | translate }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="redirectUri"
                      name="redirectUri"
                      formControlName="redirectUri"
                      placeholder="Redirect URI"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          SettingsFormControl.redirectUri.errors?.required
                      }"
                    />
                    <div
                      *ngIf="
                        formSubmitted && SettingsFormControl.redirectUri.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="SettingsFormControl.redirectUri.errors?.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="row">
              <div class="col-12">
                <button
                  *ngIf="!pingSuccessful"
                  type="submit"
                  (click)="onTestConnectivityClick()"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  [disabled]="loading"
                >
                  <span
                    *ngIf="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span *ngIf="!loading">{{ "Test" | translate }}</span>
                </button>
                <button type="submit" rippleEffect class="btn btn-primary mr-1">
                  {{ "Save" | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="cancel()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
