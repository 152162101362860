import { NgModule } from "@angular/core";
import { CoreService } from "./service";

@NgModule({
    providers: [
        CoreService
    ]
})

export class CoreServiceModule { }
