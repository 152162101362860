import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_METERS, SET_ALL_METERS, GET_METER_BY_ID, SET_METER_BY_ID, UPDATE_METER, UPDATE_METER_RESPONSE, GET_METER_BY_FIELD, METER_EXPORT, EXPORT_METER_RESPONSE, SET_METER_BY_FIELD
} from "./actionTypes"

export class GetAllMeters implements Action {
    readonly type = GET_ALL_METERS;
}

export class SetAllMeters implements Action {
    readonly type = SET_ALL_METERS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetMeterById implements Action {
    readonly type = GET_METER_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetMeterById implements Action {
    readonly type = SET_METER_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateMeter implements Action {
    readonly type = UPDATE_METER;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class UpdateMeterResponse implements Action {
    readonly type = UPDATE_METER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetMetersByField implements Action {
    readonly type = GET_METER_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetMetersByField implements Action {
    readonly type = SET_METER_BY_FIELD;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class Export implements Action {
    readonly type = METER_EXPORT;
}

export class SetExportResponse implements Action {
    readonly type = EXPORT_METER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data) {
        this.payload = data;
    }
}


export type Actions = GetAllMeters | SetAllMeters | GetMeterById | SetMeterById | UpdateMeter | UpdateMeterResponse
    | GetMetersByField | SetMetersByField | Export | SetExportResponse;
