import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { RolesEffect } from "./effect";
import { RolesStateReducer } from "./reducer";

@NgModule({
    imports: [
        EffectsModule.forFeature([RolesEffect]),
        StoreModule.forFeature('roles', RolesStateReducer)
    ]
})

export class RolesStoreModule { }

export const allRoles = (store: any) => store && store.roles && store.roles.allRoles;
export const roleById = (store: any) => store && store.roles && store.roles.roleById;
export const addRoleResponse = (store: any) => store && store.roles && store.roles.addRoleResponse;
export const updateRoleResponse = (store: any) => store && store.roles && store.roles.updateRoleResponse;
export const deleteRoleResponse = (store: any) => store && store.roles && store.roles.deleteRoleResponse;
