import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';
import { Page } from "app/utils/page.util";

@Injectable()
export class FirmsService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store,
        private page: Page
    ) {
    }

    public getAllFirms() {
        return this.http.get(
            this.urlUtil.getUrl('firms', 'getAll'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllFirms error =>", e);
                    return of([]);
                })
            )
    }

    public getFirmById(id) {
        return this.http.get(
            this.urlUtil.getUrl('firms', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getFirmById error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }

    public addFirm(dataObject) {
        return this.http.post(
            this.urlUtil.getUrl('firms', 'add'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage, data } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess"),
                            data
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("addFirm error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataAdd")
                    });
                })
            )
    }

    public updateFirm(dataObject) {
        return this.http.put(
            this.urlUtil.getUrl('firms', 'update').replace('$ID', dataObject.id), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("updateFirm error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }

    public deleteFirm(idsArray) {
        return this.http.post(this.urlUtil.getUrl('firms', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("deleteFirm error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataDelete")
                    });
                })
            )
    }

    public testDocumentSignatureConnectivity(dataObject) {
        return this.http.post(
            this.urlUtil.getUrl('firms', 'testConnectivity'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("Connection testing successful!")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("testDocumentSignatureConnectivity error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    });
                })
            )
    }

    public testDocumentSignature({
        clientId, documentSignModule, moduleId,
        formData, firmId
    }) {
        return this.http.post(this.urlUtil.getUrl('firms', 'documentSignature', [
            { key: 'clientId', value: clientId },
            { key: 'documentSignModule', value: documentSignModule },
            { key: 'moduleId', value: moduleId }
        ]), formData, { headers: { firmId: firmId } })
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("Documents sent for signature!")
                        };
                    } else {
                        return ({
                            status: false,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("ErrorGeneral")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("deleteFirm error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    });
                })
            )
    }

    public TestEmailConnectivity(dataObject) {
        return this.http.post(
            this.urlUtil.getUrl('firms', 'test-email-connectivity'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, data, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: true,
                            message: this._translateService.instant(data || jsonData || errorMessage || "Message has been sent successfully!")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant(data || jsonData || errorMessage || "Message has not been sent!")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("test-email-connectivity error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("Message has not been sent!")
                    });
                })
            )
    }

    public sendDocumentSignatureReminder(dataObject) {
        return this.http.get(
            this.urlUtil.getUrl('firms', 'send-document-signature-reminder').replace('$ID', dataObject.id), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, data, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: true,
                            message: this._translateService.instant(data || jsonData || errorMessage || "Reminder has been sent successfully!")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant(data || jsonData || errorMessage || "Reminder has not been sent!")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("send-document-signature-reminder error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("Reminder has not been sent!")
                    });
                })
            )
    }

    public getDoumentSignatureTrackDetails() {
        return this.http.get(
            this.urlUtil.getUrl('firms', 'sign-document-track-details')) 
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getDoumentSignatureTrackDetails error =>", e);
                    return of([]);
                })
            )
    }

    public getDoumentSignatureSearchByFields(data:any) {
        const {currentPage, size} = this.page.getPageAndSize();       
        return this.http.get(
            this.urlUtil.getUrl('firms', 'searchByField')
                .replace('$KEY', data.key)
                .replace('$VALUE', data.value)
                .replace('$PAGE', currentPage)
                .replace('$SIZE', size))
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        const respData = resp.data
                        this.page.setPageData({
                            size: respData.size,
                            totalElements: respData.totalElements,
                            totalPages: respData.totalPages,
                            pageNumber: respData.number
                        })
                        return respData.content;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getDoumentSignatureTrackDetails error =>", e);
                    return of([]);
                })
            )
    }

    public getAllLeads() {
        const {currentPage, size} = this.page.getPageAndSize();
        return this.http.get(
            this.urlUtil.getUrl('firms', 'leads')
                .replace('$PAGE', currentPage)
                .replace('$SIZE', size))
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        const respData = resp.data
                        this.page.setPageData({
                            size: respData.size,
                            totalElements: respData.totalElements,
                            totalPages: respData.totalPages,
                            pageNumber: respData.number
                        })
                        return respData.content;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllLeads error =>", e);
                    return of([]);
                })
            )
    }
}
