<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCloseClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <div class="card rounded-0 mb-0">
        <div class="card-body">
          <div class="row">
            <div class="col-12" *ngIf="showFilesSelection">
              <app-file-drop-upload
                inputId="filesToBeUploaded"
                [selectedFiles]="filesToBeUploaded"
                (updateSelectedFiles)="onFilesAdd($event)"
                (removeFile)="onFileRemove($event)"
                [multiple]="multipleFileSelection"
              ></app-file-drop-upload>
            </div>

            <div class="col-12 mb-2">
              <div class="form-group required">
                <label for="extractionModelSelected" class="control-label">
                  {{ "Extraction Model" | translate }}
                </label>
                <ng-select
                  id="extractionModelSelected"
                  name="extractionModelSelected"
                  [items]="extractionModelOptions"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="extractionModelSelected"
                  [clearable]="false"
                  [closeOnSelect]="true"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-right">
              <button
                type="button"
                rippleEffect
                class="btn btn-primary mr-1"
                (click)="onSubmitClick()"
                [disabled]="showFilesSelection && !filesToBeUploaded?.length"
              >
                {{ "Submit" | translate }}
              </button>
              <button
                type="button"
                rippleEffect
                class="btn btn-outline-secondary"
                (click)="handleCloseClick()"
              >
                {{ "Cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
