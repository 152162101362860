<div class="modal-header">
  <h4 class="modal-title">{{ "Create New KaiCase" | translate }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCloseClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <div class="card rounded-0 mb-0">
        <div class="card-body">
          <form class="form" [formGroup]="mattersForm" (ngSubmit)="submit()">
            <div class="row" [formGroup]="mattersForm.get('clientInfo')">
              <div class="col-md-6 col-12">
                <div class="form-group required">
                  <label for="name" class="control-label">
                    {{ "Name" | translate }}
                  </label>
                  <input
                    id="name"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ClientProfileFormControl.name.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Name' | translate }}"
                    formControlName="name"
                  />
                  <div
                    *ngIf="
                      formSubmitted && ClientProfileFormControl.name.errors
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="ClientProfileFormControl.name.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group required">
                  <label for="address" class="control-label">
                    {{ "Address" | translate }}
                  </label>
                  <textarea
                    class="form-control"
                    placeholder="{{ 'AllowNotApplicable' | translate }}"
                    id="address"
                    formControlName="address"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ClientProfileFormControl.address.errors
                    }"
                  ></textarea>
                  <div
                    *ngIf="
                      formSubmitted && ClientProfileFormControl.address.errors
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="ClientProfileFormControl.address.errors.required"
                    >
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="contactNumber" class="control-label">
                    {{ "Contact Number" | translate }}
                  </label>
                  <input
                    id="contactNumber"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted &&
                        ClientProfileFormControl.contactNumber.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Contact Number' | translate }}"
                    formControlName="contactNumber"
                  />
                  <div
                    *ngIf="
                      formSubmitted &&
                      ClientProfileFormControl.contactNumber.errors
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        ClientProfileFormControl.contactNumber.errors.required
                      "
                    >
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group required">
                  <label for="email" class="control-label">
                    {{ "Email" | translate }}
                  </label>
                  <input
                    id="email"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ClientProfileFormControl.email.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Email' | translate }}"
                    formControlName="email"
                  />
                  <div
                    *ngIf="
                      formSubmitted && ClientProfileFormControl.email.errors
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="ClientProfileFormControl.email.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                    <div *ngIf="ClientProfileFormControl.email.errors.pattern">
                      {{ "ErrorInvalidEmail" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-md-6 col-12"
                [formGroup]="mattersForm.get('mattersDetails')"
              >
                <div class="form-group required">
                  <label for="briefSummary" class="control-label">
                    {{ "Brief Summary Notes" | translate }}
                  </label>
                  <textarea
                    class="form-control"
                    placeholder="{{ 'Brief Summary Notes' | translate }}"
                    id="briefSummary"
                    formControlName="briefSummary"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted &&
                        MattersDetailsFormControl.briefSummary.errors
                    }"
                    rows="8"
                  ></textarea>
                  <div
                    *ngIf="
                      formSubmitted &&
                      MattersDetailsFormControl.briefSummary.errors
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        MattersDetailsFormControl.briefSummary.errors.required
                      "
                    >
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <button type="submit" rippleEffect class="btn btn-primary mr-1">
                  {{ "Submit" | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="handleCloseClick()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
