import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomPreloadService } from "./custom-preload.service";

const appRoutes: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule)
    },
    {
        path: 'operation',
        data: {
            preload: true,
            loadDelay: 7000
        },
        loadChildren: () => import('./main/operation/operation.module').then((m) => m.OperationModule)
    },
    {
        path: 'tracking',
        data: {
            preload: true,
            loadDelay: 7000
        },
        loadChildren: () => import('./main/tracking/tracking.module').then((m) => m.TrackingModule)
    },
    {
        path: 'setup',
        data: {
            preload: true,
            loadDelay: 7000
        },
        loadChildren: () => import('./main/setup/setup.module').then((m) => m.SetupModule)
    },
    {
        path: 'utilities',
        data: {
            preload: true,
            loadDelay: 7000
        },
        loadChildren: () => import('./main/utilities/utilities.module').then((m) => m.UtilitiesModule)
    },
    {
        path: '',
        redirectTo: 'operation/home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // Add options right here
            relativeLinkResolution: 'legacy',
            preloadingStrategy: CustomPreloadService,
            onSameUrlNavigation: 'reload'
        })
    ]
})

export class RoutingModule { }
