import { NgModule } from "@angular/core";
import { MattersService } from "./matters/service";
import { ClientsService } from "./clients/service";
import { CalendarService } from "./calendar/service";

@NgModule({
    providers: [
        MattersService,
        ClientsService,
        CalendarService
    ]
})

export class OperationServiceModule { }
