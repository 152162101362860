<div class="modal-header bg-primary">
  <h4 class="modal-title text-white">{{ ModalTitle }}</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCancelClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div
  class="modal-body"
  tabindex="0"
  ngbAutofocus
  *blockUI="'manual-upload-modal'"
>
  <form class="form" (ngSubmit)="handleSubmit()">
    <div class="row" *ngIf="!showReason">
      <div class="col-12">
        <label [for]="manualCheckType" class="control-label">
          {{ "Document" | translate }}
        </label>
        <app-file-drop-upload
          [inputId]="manualCheckType"
          [selectedFiles]="fileToBeUploaded ? [fileToBeUploaded] : []"
          (updateSelectedFiles)="onDocumentChange($event)"
          [multiple]="false"
          (removeFile)="onDocumentRemove()"
          hidePreviewButton="true"
        >
        </app-file-drop-upload>
      </div>
    </div>

    <div class="row" *ngIf="showReason">
      <div class="col-12 mb-1" *ngIf="matchData?.length">
        <div class="row">
          <div class="col-3">
            <p class="font-weight-bold">Entity</p>
          </div>
          <div class="col-3">
            <p class="font-weight-bold">Original Value</p>
          </div>
          <div class="col-3">
            <p class="font-weight-bold">New Extracted Value</p>
          </div>
          <div class="col-3">
            <p class="font-weight-bold">Matching Ratio</p>
          </div>
        </div>

        <div class="row" *ngFor="let item of matchData">
          <div class="col-3">
            <p>{{ item.name }}</p>
          </div>
          <div class="col-3">
            <p>{{ item.inputText }}</p>
          </div>
          <div class="col-3">
            <p>{{ item.extractedText }}</p>
          </div>
          <div class="col-3">
            <p>{{ item.matchingRatio }}</p>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label for="reason">{{ "Override Reason" | translate }}</label>
          <textarea
            type="text"
            class="form-control"
            [(ngModel)]="reason"
            id="reason"
            name="reason"
            placeholder="{{ 'Reason' | translate }}"
          >
          </textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <button
          *ngIf="!showReason"
          type="submit"
          rippleEffect
          class="btn btn-primary mr-1"
          [disabled]="!fileToBeUploaded?.url"
        >
          {{ "Upload and Check" | translate }}
        </button>

        <button
          *ngIf="showReason"
          type="button"
          rippleEffect
          class="btn btn-primary mr-1"
          [disabled]="!reason"
          (click)="handleOverrideSubmit()"
        >
          {{ "Override" | translate }}
        </button>

        <button
          type="button"
          rippleEffect
          class="btn btn-outline-secondary"
          (click)="handleCancelClick()"
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
