import { Action } from '@ngrx/store';
import { IServerResponse } from 'app/interface';

import {
    GET_ALL_USERS,
    SET_ALL_USERS,
    GET_USER_BY_ID,
    SET_USER_BY_ID,
    ADD_USER,
    ADD_USER_RESPONSE,
    UPDATE_USER,
    UPDATE_USER_RESPONSE,
    DELETE_USER,
    DELETE_USER_RESPONSE,
    ADD_GUEST_USER,
    ADD_GUEST_USER_RESPONSE,
    GET_USER_PROFILE_BY_ID,
    SET_USER_PROFILE_BY_ID,
    UPDATE_USER_PROFILE_BY_ID,
    UPDATE_USER_PROFILE_BY_ID_RESPONSE,
    GET_USER_BY_FIELD,
    SET_USER_BY_FIELD
} from './actionTypes';

export class GetAllUsers implements Action {
    readonly type = GET_ALL_USERS;
}

export class SetAllUsers implements Action {
    readonly type = SET_ALL_USERS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetUsersByField implements Action {
    readonly type = GET_USER_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetUsersByField implements Action {
    readonly type = SET_USER_BY_FIELD;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetUserById implements Action {
    readonly type = GET_USER_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetUserById implements Action {
    readonly type = SET_USER_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddUser implements Action {
    readonly type = ADD_USER;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class AddUserResponse implements Action {
    readonly type = ADD_USER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddGuestUser implements Action {
    readonly type = ADD_GUEST_USER;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class AddGuestUserResponse implements Action {
    readonly type = ADD_GUEST_USER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateUser implements Action {
    readonly type = UPDATE_USER;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateUserResponse implements Action {
    readonly type = UPDATE_USER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteUser implements Action {
    readonly type = DELETE_USER;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray;
    }
}

export class DeleteUserResponse implements Action {
    readonly type = DELETE_USER_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetUserProfileById implements Action {
    readonly type = GET_USER_PROFILE_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetUserProfileById implements Action {
    readonly type = SET_USER_PROFILE_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateUserProfileById implements Action {
    readonly type = UPDATE_USER_PROFILE_BY_ID;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateUserProfileByIdResponse implements Action {
    readonly type = UPDATE_USER_PROFILE_BY_ID_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions =
    | GetAllUsers
    | SetAllUsers
    | GetUserById
    | SetUserById
    | AddUser
    | AddUserResponse
    | UpdateUser
    | UpdateUserResponse
    | DeleteUser
    | DeleteUserResponse
    | AddGuestUser
    | AddGuestUserResponse
    | GetUserProfileById
    | SetUserProfileById
    | UpdateUserProfileById
    | UpdateUserProfileByIdResponse
    | GetUsersByField
    | SetUsersByField;
