import { IServerResponse } from "app/interface";

export interface MattersState {
    allMatters: Array<any>;
    mattersByFields: Array<any>;
    matterById: any;
    clients: Array<any>;
    addMatterResponse: IServerResponse;
    updateMatterResponse: IServerResponse;
    deleteMatterResponse: IServerResponse;
}

export const initialState: MattersState = {
    allMatters: null,
    mattersByFields: null,
    matterById: null,
    clients: null,
    addMatterResponse: null,
    updateMatterResponse: null,
    deleteMatterResponse: null
}
