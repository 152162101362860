import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { CurrencyFormatterPipe } from '@core/pipes/currencyFormatter.pipe';

@NgModule({
    declarations: [
        CurrencyFormatterPipe,
        InitialsPipe,
        FilterPipe,
        StripHtmlPipe,
        SafePipe
    ],
    imports: [],
    exports: [
        CurrencyFormatterPipe,
        InitialsPipe,
        FilterPipe,
        StripHtmlPipe,
        SafePipe
    ]
})
export class CorePipesModule { }
