import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';

import { EMAIL_REGEX } from 'app/utils/regexPatterns.util';

@Component({
    selector: 'app-register-modal',
    templateUrl: './register-modal.component.html',
    styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {
    @Input() title: String = ''
    @Input() handleSubmitClick: Function
    @Input() handleCloseClick: Function;

    public salutationOptions: Array<any> = [
        { id: 'DR', name: 'Dr' },
        { id: 'MISS', name: 'Miss' },
        { id: 'MR', name: 'Mr' },
        { id: 'MRS', name: 'Mrs' },
        { id: 'MS', name: 'Ms' },
        { id: 'MX', name: 'Mx' },
        { id: 'NONE', name: 'None' },
        { id: 'OTHER', name: 'Other' }
    ];

    public registrationForm: FormGroup;
    formSubmitted: boolean = false;

    unsubscribe = new Subject();

    constructor() { }

    ngOnInit(): void {
        this.registrationForm = new FormGroup({
            salutation: new FormControl(null, Validators.required),
            firstName: new FormControl("", Validators.required),
            lastName: new FormControl(""),
            email: new FormControl("", [Validators.pattern(EMAIL_REGEX), Validators.required])
        })
    }

    get ReactiveFormControl() {
        return this.registrationForm.controls;
    }

    get ReactiveFormValues() {
        return this.registrationForm.getRawValue();
    }

    onSubmitClick() {
        this.formSubmitted = true;

        if (this.registrationForm.invalid) {
            return;
        }

        const registrationDetails = cloneDeep({ ...this.ReactiveFormValues });
        this.handleSubmitClick(registrationDetails);
    }
}
