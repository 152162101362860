import { Component, OnInit, Input } from '@angular/core';
import { FileService } from '@core/services/file.service';

@Component({
    selector: 'app-email-modal',
    templateUrl: './email-modal.component.html',
    styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
    @Input() emailData: any = {};

    @Input() handleCloseClick: Function

    constructor(
        private _fileService: FileService
    ) { }

    ngOnInit(): void {
    }

    get emailSubject() {
        return this.emailData.subject;
    }

    get emailDate() {
        return this.emailData.dateTime;
    }

    get mailFrom() {
        return this.emailData.mailFrom;
    }

    get mailTo() {
        return this.emailData.mailTo;
    }

    get emailText() {
        return this.emailData.text;
    }

    get emailAttachments() {
        return this.emailData.attachments;
    }

    downloadAttachment(e, file) {
        e.preventDefault();

        this._fileService.downloadFile(file);
    }

}
