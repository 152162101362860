<div class="modal-header">
  <h4 class="modal-title">{{ "Reason for update" }}</h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'update-status-modal'"
>
  <form class="form" [formGroup]="matterUpdateReasonForm">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <!-- <label for="comment" class="control-label">{{
                    "Reason for update" | translate
                  }}</label> -->
                  <textarea
                    id="comment"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.comment.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Comment' | translate }}"
                    formControlName="comment"
                  ></textarea>
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.comment.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.comment.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-8">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  (click)="onSubmitClick()"
                >
                  {{ "Submit" | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="onCancelClick()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
