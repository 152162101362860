import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as BillingAction from './actions';
import { BillingService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class BillingEffect {
    constructor(
        private action: Actions,
        private medicalEntitiesService: BillingService
    ) { }

    GetAllBillingData = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_BILLING_DATA),
        switchMap(() => this.medicalEntitiesService.getAllBillingData()),
        map((data: Array<any>) => new BillingAction.SetAllBillingData(data)
        )
    ));

    GetClientsByField = createEffect(() => this.action.pipe(
        ofType(type.GET_BILLING_BY_FIELD),
        switchMap((data: any) => this.medicalEntitiesService.searchByField(data.payload)),
        map((data) => new BillingAction.SetBillingsByField(data))
    ));

    GetBillingById = createEffect(() => this.action.pipe(
        ofType(type.GET_BILLING_BY_ID),
        switchMap((data: any) => this.medicalEntitiesService.getBillingById(data.payload)),
        map((data: any) => new BillingAction.SetBillingById(data)
        )
    ));

    AddBilling = createEffect(() => this.action.pipe(
        ofType(type.ADD_BILLING),
        switchMap((data: any) => this.medicalEntitiesService.addBilling(data.payload)),
        map((data: IServerResponse) => new BillingAction.AddBillingResponse(data)
        )
    ));

    UpdateBilling = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_BILLING),
        switchMap((data: any) => this.medicalEntitiesService.updateBilling(data.payload)),
        map((data: IServerResponse) => new BillingAction.UpdateBillingResponse(data)
        )
    ));

    DeleteBilling = createEffect(() => this.action.pipe(
        ofType(type.DELETE_BILLING),
        switchMap((data: any) => this.medicalEntitiesService.deleteBilling(data.payload)),
        map((data: IServerResponse) => new BillingAction.DeleteBillingResponse(data)
        )
    ));
}
