import { Component, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MODULE_ID } from 'app/constants/module.constant';
import { SearchColumnType } from 'app/model';
import { SetPersistedSearch, SetSearchOptions } from 'app/store/common/actions';
import { helperValues, tableSearchWithOptions } from 'app/store/common/commonStore.module';
import { ClientsService } from 'app/store/operation/clients/service';
import { allClientsWithPreferredContactTypeAndTime } from 'app/store/operation/clients/store.module';
import { allMatters } from 'app/store/operation/matters/store.module';

import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { CommonUtil } from 'app/utils/common.util';

@Component({
    selector: 'app-table-search',
    templateUrl: './table-search.component.html',
    styleUrls: ['./table-search.component.scss']
})

export class TableSearchComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() moduleId: string = null;
    @Output() triggerSearch: EventEmitter<any> = new EventEmitter();
    @Output() clearSearch: EventEmitter<any> = new EventEmitter();

    private unsubscribe: Subject<any>;
    filterColumnOptions: Array<{
        key: any;
        value: any,
        type?: SearchColumnType
    }> = [];
    searchType: string = SearchColumnType.Text;
    searchValueList = null;
    filterForm: FormGroup;
    tableSearchParam = null;

    public allClients: Array<any> = [];
    public allMatters: Array<any> = [];
    public enquiryStageOptions: Array<any> = [];
    public matterState: Array<any> = [];
    public signStatus: Array<any> = [];
    public documentSignModule: Array<any> = [];
    public userRoleOptions: Array<any> = [];
    private isGuestOrClient: boolean = false;
    private loggedInUserId: string = "";

    constructor(
        private store: Store,
        private clientsService: ClientsService,
        private _authenticationService: AuthenticationService,
        private commonUtil: CommonUtil
    ) {
        this.filterForm = new FormGroup({
            columnName: new FormControl('select', Validators.required),
            columnValue: new FormControl({ value: null, disabled: true }, Validators.required)
        })
        this.unsubscribe = new Subject();

        const { isGuest, isClient } = this._authenticationService;
        this.isGuestOrClient = isGuest || isClient;
        this.loggedInUserId = this.commonUtil.getUserDetail('id');
    }

    ngOnInit(): void {
        this.store
            .select(allClientsWithPreferredContactTypeAndTime)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    if (this.isGuestOrClient) {
                        this.allClients = resp.filter(({ id, createdBy }) => [id, createdBy].includes(this.loggedInUserId));
                    } else {
                        this.allClients = resp;
                    }
                }
            });
        this.store
            .select(allMatters)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.allMatters = resp.map(({ id, matterDetails }) => {
                        return {
                            id,
                            name: matterDetails.matterReference
                        }
                    });
                }
            });

        this.store.select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.enquiryStageOptions = resp.enquiryStage;
                    this.matterState = resp.matterState;
                    this.signStatus = resp.signStatus;
                    this.documentSignModule = resp.documentSignModule;
                    this.userRoleOptions = resp.userRole;
                }
            })
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngAfterViewInit(): void {
        this.store.select(tableSearchWithOptions)
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe((resp) => {
                if (resp) {
                    this.filterColumnOptions = resp.searchOption;
                    this.tableSearchParam = resp.persistedSearch[this.moduleId];
                    this.filterForm.get('columnValue').enable();
                    this.filterForm.setValue({
                        columnName: resp.persistedSearch[this.moduleId].searchField,
                        columnValue: resp.persistedSearch[this.moduleId].searchValue || null
                    })
                    this.setSearchTypeAndValue(resp.persistedSearch[this.moduleId].searchField);
                    this.search();
                    this.store.dispatch(new SetSearchOptions([]))
                }
            })
    }

    onFilterColumnUpdate(filterColumnValue) {
        this.filterForm.get('columnName').patchValue(filterColumnValue);
        if (filterColumnValue !== null) {
            this.filterForm.get('columnValue').enable();
        } else {
            this.filterForm.get('columnValue').disable();
        }
        this.filterForm.patchValue({ columnValue: null })
        this.setSearchTypeAndValue(this.filterForm.value.columnName);
    }

    onClear(event: Event) {
        this.filterForm.patchValue({ columnValue: null, columnName: 'select' })
        this.setSearchTypeAndValue('select');
        this.clearSearch.emit(event);
        this.updatePersistedSearchInRxState();
    }


    setSearchTypeAndValue(columnId) {
        switch (columnId) {
            case 'select':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = [{
                    id: 'all',
                    name: 'All'
                }]
                this.filterForm.patchValue({ columnValue: 'all' })
                break;
            case 'module':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = [
                    { id: "MATTER", name: "Matters" }
                    // { id: "PRE_ACTION_PROTOCOL", name: "Pre Action Protocols" },
                    // { id: "MEDICAL_EVIDENCE", name: "Medical Evidences" }
                ];
                break;
            case 'documentType':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = [
                    { id: "4", name: "Insurance Claim" },
                    { id: "5", name: "Consent" },
                    { id: "6", name: "CLA" }
                ];
                break;
            case 'clientId':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = (this.allClients || []).map(({ name, clientId }) => ({ name, id: clientId }));
                break;
            case 'moduleId':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = this.documentSignModule;
                break;
            case 'role':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = this.userRoleOptions;
                break;
            case 'signStatus':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = this.signStatus;
                break;
            case 'caseMatterId':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = this.allMatters;
                break;
            case 'stage':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = this.enquiryStageOptions;
                break;
            case 'matterDetails.matterState':
                this.searchType = SearchColumnType.Select;
                this.searchValueList = this.matterState;
                break;
            default:
                this.searchType = SearchColumnType.Text;
                this.searchValueList = null;
                break;
        }
    }

    updatePersistedSearchInRxState() {
        if (this.searchType === SearchColumnType.Date) {
            this.store.dispatch(new SetPersistedSearch(this.moduleId, {
                searchField: this.filterForm.value.columnName,
                searchValue: new Date(this.filterForm.value.columnValue).getTime()
            }));
        } else {
            this.store.dispatch(new SetPersistedSearch(this.moduleId, {
                searchField: this.filterForm.value.columnName,
                searchValue: this.filterForm.value.columnValue
            }));
        }
    }

    changePersistedSearch() {
        this.updatePersistedSearchInRxState();
        this.search();
    }

    search() {
        if (this.searchType === SearchColumnType.Date) {
            this.triggerSearch.emit({ ...this.filterForm.value, columnValue: new Date(this.filterForm.value.columnValue).getTime() });
        } else {
            this.triggerSearch.emit(this.filterForm.value);
        }
    }

    searchName = (name$: Observable<string>) => name$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((name) => {
            if (name) {
                switch (this.moduleId) {
                    case MODULE_ID.CLIENTS:
                        return this.clientsService.searchByName(name);
                    default:
                        return of(null);
                }
            }
            return of(null);
        }),
        catchError((e) => {
            console.log(e);
            return of(null);
        })
    );

    resultFormatAutoCompleteListValue(value: any) {
        return value;
    }

    inputFormatAutoCompleteListValue(value: any) {
        return value;
    }
}
