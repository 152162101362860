import { IServerResponse } from "app/interface";

export interface MeterState {
    allMeters: Array<any>;
    meterById: any;
    updateMeterResponse: IServerResponse;
    exportMeterResponse: IServerResponse;
    meterByFields: IServerResponse;
}

export const initialState: MeterState = {
    allMeters: null,
    meterById: null,
    updateMeterResponse: null,
    exportMeterResponse: null,
    meterByFields: null
}
