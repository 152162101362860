import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CoreEffect } from "./effect";
import { CoreReducer } from "./reducer";

@NgModule({
    imports: [
        EffectsModule.forFeature([CoreEffect]),
        StoreModule.forFeature('core', CoreReducer)
    ]
})
export class CoreStoreModule { }

export const userDetail = (store: any) => store && store.core && store.core.userDetail;
export const forgotPasswordResp = (store: any) => store && store.core && store.core.forgotPasswordResp;
export const resetPasswordResp = (store: any) => store && store.core && store.core.resetPasswordResp;
export const updateUserDetailResponse = (store: any) => store && store.core && store.core.updateUserDetailResponse;
