import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions, Effect } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import * as type from './actionTypes';
import * as CommonAction from './actions';
import { CoreService } from "./service";
import { IServerResponse } from "app/interface";
import { AuthenticationService } from "app/auth/service";

import { Store } from '@ngrx/store';
import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';

@Injectable()
export class CoreEffect {

    constructor(
        private action: Actions,
        private coreService: CoreService,
        private _authenticationService: AuthenticationService,
        private store: Store,
        private _coreLoadingScreenService: CoreLoadingScreenService
    ) { }

    UpdateUserDetail = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_USER_DETAIL),
        switchMap((data: any) => this.coreService.updateUserDetail(data.payload)),
        map((data: IServerResponse) => new CommonAction.UpdateUserDetailResp(data))
    ));
    ForgotPassword = createEffect(() => this.action.pipe(
        ofType(type.FORGOT_PASSWORD),
        switchMap((data: any) => this.coreService.forgotPassword(data.payload)),
        map((data: IServerResponse) => new CommonAction.ForgotPasswordResp(data))
    ));

    ResetPassword = createEffect(() => this.action.pipe(
        ofType(type.RESET_PASSWORD),
        switchMap((data: any) => this.coreService.resetPassword(data.payload)),
        map((data: IServerResponse) => new CommonAction.ResetPasswordResp(data))
    ));

    Logout = createEffect(() => this.action.pipe(
        ofType(type.LOGOUT),
        switchMap((data: any) => {
            const callLogoutAPI = data.payload;
            if (callLogoutAPI) {
                this._coreLoadingScreenService.show();
                return this.coreService.logout();
            }

            setTimeout(() => {
                if (!this._authenticationService.logoutToastrDisplayed) {
                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.INFO,
                        message: 'Please Sign In Again'
                    }))

                    this._authenticationService.logoutToastrDisplayed = true;
                }
            })
            return of({ status: true });
        }),
        map((data: IServerResponse) => {
            const { status, message } = data || {};
            if (status) {
                // this.store.dispatch(new PresentToastr({
                //     type: TOASTR_TYPE.SUCCESS,
                //     message: message
                // }));

                this._authenticationService.logout();
            } else {
                this.store.dispatch(new PresentToastr({
                    type: TOASTR_TYPE.ERROR,
                    message: message
                }));
            }
            this._coreLoadingScreenService.hide();
        })
        // tap(() => {
        //     this._authenticationService.logout()
        // })
    ), { dispatch: false });
}
