import { URL_CONST } from "./url.const"

// Old
export const MODULE_ID = {
    HOME: 'HOME',
    OPERATION: 'OPERATION',
    MATTERS: 'Matter',
    CLIENTS: 'CLIENTS',
    CALENDAR: 'CALENDAR',
    TRACKING: 'TRACKING',
    ENQUIRY: 'ENQUIRY',
    BILLING: 'BILLING',
    DOCUMENT_SIGNATURE_TRACKING: 'DOCUMENT_SIGNATURE_TRACKING',
    METERS: 'METERS',
    AUDIT_LOGS: 'AUDIT_LOGS',
    SETUP: 'SETUP',
    FIRMS: 'FIRM',
    USERS: 'USER',
    DATA_CONNECTORS: 'DATA_CONNECTORS',
    NOTIFICATIONS: 'NOTIFICATIONS',
    ASKELIE: 'ASKELIE',
    USER_ROLE: 'USER_ROLE',
    NOTIFICATION_TEMPLATE: 'NOTIFICATION_TEMPLATE',
    SPEECH_AI: 'STT',
    VISION_AI: 'VISION_AI',
    RESEARCH_AI: 'MODEL',
    LEADS: 'LEADS'
}

export const MODULE_KEY = {
    HOME: 'Home',
    MATTERS: 'Matter',
    CLIENTS: 'Clients',
    CALENDAR: 'Calender',
    ENQUIRY: 'Enquiry',
    BILLING: 'Billing',
    DOCUMENT_SIGNATURE_TRACKING: 'DOCUMENT_SIGNATURE_TRACKING',
    METERS: 'Meter',
    AUDIT_LOGS: 'AuditLog',
    FIRMS: 'LegalFirm',
    USERS: 'UM',
    DATA_CONNECTORS: 'DataConnectors',
    NOTIFICATIONS: 'Notification',
    ASKELIE: 'Bot',
    SPEECH_AI: 'STT',
    VISION_AI: 'VISION_AI',
    RESEARCH_AI: 'MODEL',
    LEADS: 'LEAD'
}

export const MODULE_TITLE = {
    HOME: 'Home',
    OPERATION: 'Operation',
    MATTERS: 'Matters',
    CLIENTS: 'Clients',
    CALENDAR: 'Calendar',
    TRACKING: 'Tracking',
    ENQUIRY: 'Enquiry',
    BILLING: 'Billing',
    DOCUMENT_SIGNATURE_TRACKING: 'Document Signature Tracking',
    METERS: 'Meters',
    AUDIT_LOGS: 'Audit Logs',
    SETUP: 'Setup',
    FIRMS: 'Firms',
    USERS: 'Users',
    DATA_CONNECTORS: 'Data Connectors',
    NOTIFICATIONS: 'Notifications',
    ASKELIE: 'Askelie',
    SPEECH_AI: 'Speech AI',
    VISION_AI: 'Doc Vision',
    RESEARCH_AI: 'Research AI',
    LEADS: 'Leads'
}

export const IMPORT_MODULE_CONFIGS = {
    ENQUIRY: {
        importUrl: URL_CONST.ENQUIRY.IMPORT,
        title: "Enquiry",
        fields: {
            fileType: true,
            files: true
        }
    },
    DATA_CONNECTORS: {
        importUrl: URL_CONST.DATA_CONNECTORS.UPLOAD,
        title: "Data Connectors",
        fields: {
            files: true
        }
    }
}
