import { Action } from '@ngrx/store';
import { IServerResponse } from 'app/interface';

import {
    GET_ALL_ENQUIRIES,
    SET_ALL_ENQUIRIES,
    GET_ENQUIRY_BY_ID,
    SET_ENQUIRY_BY_ID,
    ADD_ENQUIRY,
    ADD_ENQUIRY_RESPONSE,
    UPDATE_ENQUIRY,
    UPDATE_ENQUIRY_RESPONSE,
    DELETE_ENQUIRY,
    DELETE_ENQUIRY_RESPONSE,
    GET_ENQUIRY_EXTRACTIONS,
    SET_ENQUIRY_EXTRACTIONS,
    GET_ENQUIRY_BY_FIELD,
    SET_ENQUIRY_BY_FIELD
} from './actionTypes';

export class GetAllEnquiries implements Action {
    readonly type = GET_ALL_ENQUIRIES;
}

export class SetAllEnquiries implements Action {
    readonly type = SET_ALL_ENQUIRIES;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetEnquiryById implements Action {
    readonly type = GET_ENQUIRY_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetEnquiryById implements Action {
    readonly type = SET_ENQUIRY_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddEnquiry implements Action {
    readonly type = ADD_ENQUIRY;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class AddEnquiryResponse implements Action {
    readonly type = ADD_ENQUIRY_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateEnquiry implements Action {
    readonly type = UPDATE_ENQUIRY;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateEnquiryResponse implements Action {
    readonly type = UPDATE_ENQUIRY_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteEnquiry implements Action {
    readonly type = DELETE_ENQUIRY;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray;
    }
}

export class DeleteEnquiryResponse implements Action {
    readonly type = DELETE_ENQUIRY_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetEnquiryExtractions implements Action {
    readonly type = GET_ENQUIRY_EXTRACTIONS;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class SetEnquiryExtractions implements Action {
    readonly type = SET_ENQUIRY_EXTRACTIONS;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class GetEnquiriesByField implements Action {
    readonly type = GET_ENQUIRY_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class SetEnquiriesByField implements Action {
    readonly type = SET_ENQUIRY_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export type Actions =
    | GetAllEnquiries
    | SetAllEnquiries
    | GetEnquiryById
    | SetEnquiryById
    | AddEnquiry
    | AddEnquiryResponse
    | UpdateEnquiry
    | UpdateEnquiryResponse
    | DeleteEnquiry
    | DeleteEnquiryResponse
    | GetEnquiryExtractions
    | SetEnquiryExtractions
    | GetEnquiriesByField
    | SetEnquiriesByField;
