import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";

import { BlockUI, NgBlockUI } from 'ng-block-ui';



@Component({
    selector: 'app-doc-viewer-modal',
    templateUrl: './doc-viewer-modal.component.html',
    styleUrls: ['./doc-viewer-modal.component.scss']
})
export class DocumentViewerModalComponent implements OnInit, OnDestroy {
    @BlockUI('doc-viewer-modal') reassignStatusModalBlockUI: NgBlockUI;

    @Input() title: string;
    @Input() docType: any;
    @Input() docUrl: any;
    @Input() handleCloseClick: Function;
    @Output() handleDestroy: Function;

    unsubscribe = new Subject();
    urlDoc: string = '';
    officeUrlDoc: string = `https://view.officeapps.live.com/op/embed.aspx?src=`;
    urlSafe: SafeResourceUrl;


    constructor(public sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        if (['doc', 'docx'].includes(this.docType)) {
            this.urlDoc = this.officeUrlDoc + this.docUrl
            window.open(this.docUrl, '_blank');
            this.handleCloseClick()
        } else {
            this.urlDoc = this.docUrl;
        }
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlDoc);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.handleDestroy();
    }

    onCancelClick() {
        this.handleCloseClick();
    }
}
