<div class="modal-header bg-success">
  <h4 class="modal-title text-white">{{ title || "" }}</h4>
</div>

<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <p>{{ message }}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-right">
      <button
        type="button"
        rippleEffect
        class="btn btn-outline-secondary"
        (click)="handleCloseClick()"
      >
        {{ "Close" | translate }}
      </button>
    </div>
  </div>
</div>
