import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CalendarEffect } from "./effect";
import { CalendarReducer } from "./reducer";

@NgModule({
    imports: [
        EffectsModule.forFeature([CalendarEffect]),
        StoreModule.forFeature('systemCalendar', CalendarReducer)
    ]
})

export class CalendarStoreModule { }

export const syncCalendarDataResponse = (store: any) => store && store.systemCalendar && store.systemCalendar.syncCalendarDataResponse;
export const calendarEvent = (store: any) => store && store.systemCalendar && store.systemCalendar.calendarEvent;
export const calendarDataById = (store: any) => store && store.systemCalendar && store.systemCalendar.calendarDataById;
export const currentEvent = (store: any) => store && store.systemCalendar && store.systemCalendar.currentEvent;
export const addCalendarEventResponse = (store: any) => store && store.systemCalendar && store.systemCalendar.addCalendarEventResponse;
export const updateCalendarEventResponse = (store: any) => store && store.systemCalendar && store.systemCalendar.updateCalendarEventResponse;
export const deleteCalendarEventResponse = (store: any) => store && store.systemCalendar && store.systemCalendar.deleteCalendarEventResponse;
