export const GET_ALL_FIRMS = "GET_ALL_FIRMS";
export const SET_ALL_FIRMS = "SET_ALL_FIRMS";

export const GET_FIRM_BY_ID = "GET_FIRM_BY_ID";
export const SET_FIRM_BY_ID = "SET_FIRM_BY_ID";

export const ADD_FIRM = "ADD_FIRM";
export const ADD_FIRM_RESPONSE = "ADD_FIRM_RESPONSE";

export const UPDATE_FIRM = "UPDATE_FIRM";
export const UPDATE_FIRM_RESPONSE = "UPDATE_FIRM_RESPONSE";

export const DELETE_FIRM = "DELETE_FIRM";
export const DELETE_FIRM_RESPONSE = "DELETE_FIRM_RESPONSE";

export const TEST_DOCUMENT_SIGNATURE_CONNECTIVITY = "TEST_DOCUMENT_SIGNATURE_CONNECTIVITY";
export const TEST_DOCUMENT_SIGNATURE_CONNECTIVITY_RESPONSE = "TEST_DOCUMENT_SIGNATURE_CONNECTIVITY_RESPONSE";

export const TEST_EMAIL_CONNECTIVITY = "TEST_EMAIL_CONNECTIVITY";
export const TEST_EMAIL_CONNECTIVITY_RESPONSE = "TEST_EMAIL_CONNECTIVITY_RESPONSE";

export const GET_DOCUMENT_SIGNATURE_TRACK_DETAILS = "GET_DOCUMENT_SIGNATURE_TRACK_DETAILS";
export const SET_DOCUMENT_SIGNATURE_TRACK_DETAILS = "SET_DOCUMENT_SIGNATURE_TRACK_DETAILS";

export const SET_DOCUMENT_SIGNATURE_BY_FIELD = "SET_DOCUMENT_SIGNATURE_BY_FIELD";
export const GET_DOCUMENT_SIGNATURE_BY_FIELD = "GET_DOCUMENT_SIGNATURE_BY_FIELD";

export const SEND_DOCUMENT_SIGNATURE_REMINDER = "SEND_DOCUMENT_SIGNATURE_REMINDER";
export const SEND_DOCUMENT_SIGNATURE_REMINDER_RESPONSE = "SEND_DOCUMENT_SIGNATURE_REMINDER_RESPONSE";

export const GET_LEADS = "GET_LEADS";
export const GET_LEADS_RESPONSE = "GET_LEADS_RESPONSE";

