import { IServerResponse } from "app/interface";

export interface ResearchAiState {
    allResearchAiModels: Array<any>;
    researchAiModelById: any;
    addResearchAiModelResponse: IServerResponse;
    updateResearchAiModelResponse: IServerResponse;
    deleteResearchAiModelResponse: IServerResponse;
    addResearchAiSourcesResponse: IServerResponse;
    removeResearchAiSourcesResponse: IServerResponse;
    trainResearchAiModelResponse: IServerResponse;
    queryResearchAiModelResponse: IServerResponse;
}

export const initialState: ResearchAiState = {
    allResearchAiModels: null,
    researchAiModelById: null,
    addResearchAiModelResponse: null,
    updateResearchAiModelResponse: null,
    deleteResearchAiModelResponse: null,
    addResearchAiSourcesResponse: null,
    removeResearchAiSourcesResponse: null,
    trainResearchAiModelResponse: null,
    queryResearchAiModelResponse: null
}
