import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { createSelector, StoreModule } from "@ngrx/store";
import { CommonEffect } from "./effect";
import { CommonReducer } from "./reducer";

@NgModule({
    imports: [
        EffectsModule.forFeature([CommonEffect]),
        StoreModule.forFeature('common', CommonReducer)
    ]
})
export class CommonStoreModule { }

export const appConfig = (store: any) => store && store.common && store.common.appConfig;
export const getToastrData = (store: any) => store && store.common && store.common.toasterData;
export const userAccess = (store: any) => store && store.common && store.common.userAccess;
export const helperValues = (store: any) => store && store.common && store.common.helperValues;
export const unipaasPaymentLinkResponse = (store: any) => store && store.common && store.common.unipaasPaymentLinkResponse;

// file upload/download
export const uploadFileResp = (store: any) => store && store.common && store.common.uploadFileResp;

// searchOptions
export const getSearchOptions = (store: any) => store && store.common && store.common.searchOptions;
export const tableSearchParam = (store: any) => store && store.common && store.common.tableSearchParam;
export const persistedSearch = (store: any) => store && store.common && store.common.persistedSearch;

export const tableSearchWithOptions = createSelector(
    getSearchOptions,
    persistedSearch,
    (getSearchOptions, persistedSearch) => {
        if (getSearchOptions && getSearchOptions.length && persistedSearch) {
            return {
                searchOption: getSearchOptions,
                persistedSearch
            }
        }
    }
)
