import { Validators } from '@angular/forms';

export const getSettingsFormFields = (authType: number) => {
    return {
        "id": [''],
        "apiName": ['', Validators.required],
        "apiUrl": ['', Validators.required],
        "authType": [null, Validators.required],
        "apiKey": authType === 1 ? ['', Validators.required] : [''],
        "userName": authType === 2 ? ['', Validators.required] : [''],
        "password": authType === 2 ? ['', Validators.required] : [''],
        "clientId": authType === 3 ? ['', Validators.required] : [''],
        "clientSecret": authType === 3 ? ['', Validators.required] : [''],
        "authorizationCode": authType === 3 ? ['', Validators.required] : [''],
        "redirectUri": authType === 3 ? ['', Validators.required] : [''],
    };
};