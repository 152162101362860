<div class="modal-header">
  <h4 class="modal-title">{{ "Document" | translate }}:&nbsp; {{ title }}</h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'doc-viewer-modal'"
>
  <div class="wrapper">
    <iframe [src]="urlSafe" frameborder="0"></iframe>
  </div>
</div>
