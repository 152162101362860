import { NgModule } from "@angular/core";
import { MatterStoreModule } from "./matters/store.module";
import { ClientsStoreModule } from "./clients/store.module";
import { CalendarStoreModule } from "./calendar/store.module";

@NgModule({
    imports: [
        MatterStoreModule,
        ClientsStoreModule,
        CalendarStoreModule
    ]
})
export class OperationStoreModule { }
