import { NgModule } from "@angular/core";

import { CommonServiceModule } from "./common/commonService.Module";
import { CoreServiceModule } from "./core/service.Module";
import { HomeServiceModule } from "./home/homeService.module";
import { OperationServiceModule } from "./operation/operationService.module";
import { TrackingServiceModule } from "./tracking/trackingService.module";
import { SetupServiceModule } from "./setup/setupService.module";
import { UtilitiesServiceModule } from "./utilities/utilitiesService.module";

@NgModule({
    imports: [
        CommonServiceModule,
        CoreServiceModule,
        HomeServiceModule,
        OperationServiceModule,
        TrackingServiceModule,
        SetupServiceModule,
        UtilitiesServiceModule
    ]
})

export class NgrxServiceModule { }
