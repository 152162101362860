import { Component, OnInit, Input, OnDestroy, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { CommonUtil } from 'app/utils/common.util';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { helperValues } from 'app/store/common/commonStore.module';
import { PresentToastr } from 'app/store/common/actions';
import { ALLOWED_ROLES_IN_ROLE_BASED_TASK, CONFIRM_DELETE_MESSAGE, CONFIRM_DELETE_TITLE, TOASTR_TYPE } from 'app/constants/common.constant';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModuleAccessValue } from 'app/interface';
import { DeleteTask, GetAllTasks, SetTaskById } from 'app/store/home/my-tasks/actions';
import { deleteTaskResponse } from 'app/store/home/my-tasks/store.module';
import { UpdateTaskStatusModalComponent } from '../update-task-status-modal/update-task-status-modal.component';
import { ReAssignTaskStatusModalComponent } from '../reassign-task-modal/reassign-task-modal.component';
import { CreateCalendarEventModalComponent } from '../create-calendar-event-modal/create-calendar-event-modal.component';
import { GetCalendarData } from 'app/store/operation/calendar/actions';
import { PAGE_ROUTES } from 'app/constants/routes.constants';

@Component({
    selector: 'app-my-task-view-modal',
    templateUrl: './my-task-view-modal.component.html',
    styleUrls: ['./my-task-view-modal.component.scss']
})
export class MyTaskViewModalComponent implements OnInit, OnDestroy {
    @Input() moduleAccess: ModuleAccessValue;

    @Input() taskDetails: any = {};
    @Input() handleEditClick: Function;
    @Input() handleCloseClick: Function;
    @Input() taskForm: FormGroup;
    @Input() taskList: Array<any> = [];

    @Output() destroyViewTaskModal: EventEmitter<void> = new EventEmitter();


    public modalRef: any;
    formSubmitted: boolean = false;
    unsubscribe = new Subject();

    public dueDate: string;
    public duration: any;

    public referenceModuleOptions: Array<any> = [];
    public roleOptions: Array<any> = [];
    public module: string;
    public role: string;

    public showMoreOptions: boolean = false;

    constructor(
        private store: Store,
        private router: Router,
        private modalService: NgbModal,
        private commonUtil: CommonUtil,
        private _translateService: TranslateService
    ) { }

    ngOnInit(): void {
        if (this.taskDetails?.id) {
            this.dueDate = this.commonUtil.convertMillisToMonthDate(this.taskDetails?.dueDate);
            // this.dueDate = this.commonUtil.convertTimestampToBrowserDate(this.taskDetails?.dueDate, 'dd-mm-yyyy');

            const hour = `${this.taskDetails?.duration?.hour || 0}`
            const minute = `${this.taskDetails?.duration?.minute || 0}`
            const duration = {
                hour: hour,
                minute: minute
            }
            this.duration = duration;
        }

        this.store.select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.referenceModuleOptions = resp?.taskModule;


                    const moduleObj = this.referenceModuleOptions.find(({ id }) => id === this.taskDetails.module)
                    this.module = moduleObj?.name || this.taskDetails.module

                    this.roleOptions = resp?.userRole?.filter(({ id }) => ALLOWED_ROLES_IN_ROLE_BASED_TASK.includes(id)) || [];
                    const roleObj = this.roleOptions.find(({ id }) => id === this.taskDetails.role)
                    this.role = roleObj?.name || this.taskDetails.role
                }
            })

        this.store.select(deleteTaskResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (resp) => {
                    if (resp) {
                        if (resp.status) {
                            const userId = this.commonUtil.getUserDetail('id');
                            this.store.dispatch(new GetAllTasks(userId));

                            this.store.dispatch(new PresentToastr({
                                type: TOASTR_TYPE.SUCCESS,
                                message: resp.message
                            }))

                            this.onCancelClick()
                        } else {
                            this.store.dispatch(new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: resp.message
                            }))
                        }
                    }
                })
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.taskDetails = {};
        this.store.dispatch(new SetTaskById({}));
        this.taskForm.reset();
        this.destroyViewTaskModal.emit();
    }

    get ReactiveFormControl() {
        return this.taskForm.controls;
    }

    get ReactiveFormValues() {
        return this.taskForm.getRawValue();
    }

    get IsMyTaskDeletable() {
        return (this.ReactiveFormValues?.id && this.moduleAccess?.["canDelete"])
    }

    get IsMyTaskEditable() {
        return (this.ReactiveFormValues?.id && this.moduleAccess?.["canUpdate"])
    }

    onEditClick() {
        this.handleEditClick(this.taskDetails);
    }

    onCancelClick() {
        this.taskForm.reset();
        this.handleCloseClick();
    }

    onDeleteClick(deleteId) {
        this.openDeleteConfirmModal(deleteId);
    }

    openDeleteConfirmModal(deleteId) {
        this.modalRef = this.modalService.open(ConfirmModalComponent, {
            windowClass: 'modal',
            centered: true
        });

        this.modalRef.componentInstance.title = this._translateService.instant(CONFIRM_DELETE_TITLE);
        this.modalRef.componentInstance.message = this._translateService.instant(CONFIRM_DELETE_MESSAGE)

        this.modalRef.componentInstance.handleConfirmClick = () => {
            this.store.dispatch(new DeleteTask(deleteId));
        }
        this.modalRef.componentInstance.handleCancelClick = () => this.modalRef.close();
    }

    onUpdateStatusClick() {
        this.modalRef = this.modalService.open(UpdateTaskStatusModalComponent, {
            windowClass: 'modal',
            centered: true
        });

        this.modalRef.componentInstance.taskDetails = this.taskList.find(({ id }) => id === this.taskDetails.id);
        this.modalRef.componentInstance.taskList = this.taskList;

        this.modalRef.componentInstance.handleCloseClick = (statusUpdatedSuccessfully?) => {
            this.modalRef.close();
            if (statusUpdatedSuccessfully) {
                this.onCancelClick();
            }
        };

        this.modalRef.componentInstance.handleDestroy = (data) => {
            this.taskDetails = data;
        };
    }

    onReAssignClick() {
        this.modalRef = this.modalService.open(ReAssignTaskStatusModalComponent, {
            windowClass: 'modal',
            centered: true
        });

        this.modalRef.componentInstance.taskDetails = this.taskList.find(({ id }) => id === this.taskDetails.id);
        this.modalRef.componentInstance.taskList = this.taskList;

        this.modalRef.componentInstance.handleCloseClick = () => {
            this.modalRef.close();
        };

        this.modalRef.componentInstance.handleDestroy = (data: any) => {
            this.taskDetails = data;

            const roleObj = this.roleOptions.find(({ id }) => id === this.taskDetails.role)
            this.role = roleObj?.name || this.taskDetails.role
        };
    }

    onCreateCalenderEventClick() {
        this.modalRef = this.modalService.open(CreateCalendarEventModalComponent, {
            windowClass: 'modal',
            centered: true
        });

        this.modalRef.componentInstance.taskDetails = this.taskList.find(({ id }) => id === this.taskDetails.id);

        this.modalRef.componentInstance.handleCloseClick = () => {
            this.modalRef.close();
        };

        this.modalRef.componentInstance.handleDestroy = () => {
            this.store.dispatch(new GetCalendarData());
        };
    }
    navigateToEditModule() {
        if (this.taskDetails?.moduleKey) {
            let module = this.taskDetails.module;
            if (module === 'CASE_MATTER') {
                module = 'MATTERS';
            }
            this.router.navigate([PAGE_ROUTES[module], 'edit', this.taskDetails.moduleId]);
        } else {
            this.router.navigate([PAGE_ROUTES[this.taskDetails.module], 'list'])
        }
    }
}
