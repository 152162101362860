export const GET_ALL_SPEECH_AI_DOCUMENTS = "GET_ALL_SPEECH_AI_DOCUMENTS";
export const SET_ALL_SPEECH_AI_DOCUMENTS = "SET_ALL_SPEECH_AI_DOCUMENTS";

export const GET_SPEECH_AI_DOCUMENT_BY_ID = "GET_SPEECH_AI_DOCUMENT_BY_ID";
export const SET_SPEECH_AI_DOCUMENT_BY_ID = "SET_SPEECH_AI_DOCUMENT_BY_ID";

export const ADD_SPEECH_AI_DOCUMENT = "ADD_SPEECH_AI_DOCUMENT";
export const ADD_SPEECH_AI_DOCUMENT_RESPONSE = "ADD_SPEECH_AI_DOCUMENT_RESPONSE";

export const DELETE_SPEECH_AI_DOCUMENT = "DELETE_SPEECH_AI_DOCUMENT";
export const DELETE_SPEECH_AI_DOCUMENT_RESPONSE = "DELETE_SPEECH_AI_DOCUMENT_RESPONSE";

export const SAVE_SPEECH_AI_DOCUMENT_AS_NOTE = "SAVE_SPEECH_AI_DOCUMENT_AS_NOTE";
export const SAVE_SPEECH_AI_DOCUMENT_AS_NOTE_RESPONSE = "SAVE_SPEECH_AI_DOCUMENT_AS_NOTE_RESPONSE";

export const RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY = "RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY";
export const RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY_RESPONSE = "RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY_RESPONSE";
