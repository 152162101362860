<ng-container *ngIf="!item.hidden">
  <!-- item with router url -->
  <a
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl"
    [routerLink]="[item.url]"
    [target]="item.openInNewTab ? '_blank' : '_self'"
    [ngbTooltip]="item.title"
    placement="right"
    container="body"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item with external url -->
  <a
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl"
    href="javascript:void(0)"
    (click)="openExternalUrl(item.url, item.openInNewTab)"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <span *ngIf="!item.icon && item.iconText" class="h3 ml-25">
      {{ item.iconText }}
    </span>
    <!-- <span class="menu-title text-truncate">{{ item.title | translate }}</span>
    <span
      class="badge ml-auto mr-1"
      *ngIf="item.badge"
      [ngClass]="item.badge.classes"
    >
      {{ item.badge.title | translate }}
    </span> -->
  </ng-template>
</ng-container>
