import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { EnquiryEffect } from "./effect";
import { EnquiryStateReducer } from "./reducer";
import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([EnquiryEffect]),
        StoreModule.forFeature('enquiry', EnquiryStateReducer)
    ]
})

export class EnquiriesStoreModule { }

export const allEnquiries = (store: any) => store && store.enquiry && store.enquiry.allEnquiries;
export const enquiriesByField = (store: any) => store && store.enquiry && store.enquiry.enquiriesByField;
export const enquiryById = (store: any) => store && store.enquiry && store.enquiry.enquiryById;
export const addEnquiryResponse = (store: any) => store && store.enquiry && store.enquiry.addEnquiryResponse;
export const updateEnquiryResponse = (store: any) => store && store.enquiry && store.enquiry.updateEnquiryResponse;
export const deleteEnquiryResponse = (store: any) => store && store.enquiry && store.enquiry.deleteEnquiryResponse;
export const enquiryExtractions = (store: any) => store && store.enquiry && store.enquiry.enquiryExtractions;

export const allEnquiriesWithFullNameAndStage = createSelector(
    enquiriesByField, helperValues, (list, values) => {
        const enquiryStage = values?.enquiryStage || [];
        const salutation = values?.salutation || [];
        return (list || []).map((item) => {
            const enquiryStageId = item?.stage || '';
            const salutationId = item?.enquiryProfile?.userInfo?.salutation || '';
            const salutationValue = (salutationId && salutationId !== "NONE" && salutationId !== "OTHER") ? salutation.find(({ id }) => id === salutationId)?.name || "" : "";
            const firstName = item?.enquiryProfile?.userInfo?.firstName || '';
            // const lastName = item?.enquiryProfile?.userInfo?.lastName || '';
            // const fullName = `${salutationValue ? `${salutationValue} ` : ''}${firstName ? `${firstName} ` : ''}${lastName ? `${lastName}` : ''}`
            const fullName = `${salutationValue ? `${salutationValue} ` : ''}${firstName ? `${firstName}` : ''}`
            return {
                ...item,
                fullName,
                enquiryStageValue: enquiryStage.find(({ id }) => id === enquiryStageId)?.name || enquiryStageId || '',
            }
        })
    }
)
