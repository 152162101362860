import { DataConnectorsState, initialState } from "./state";
import { Actions } from './actions';
import {
    SET_DATA_CONNECTORS, CLEAN_DATA_CONNECTORS_RESPONSE, UPLOAD_DATA_CONNECTOR_RESPONSE
} from "./actionTypes";

export function DataConnectorsStateReducer(state = initialState, action: Actions): DataConnectorsState {
    const { type } = action;

    switch (type) {
        case SET_DATA_CONNECTORS:
            return Object.assign({}, state, {
                allDataConnectors: action.payload
            });
        case CLEAN_DATA_CONNECTORS_RESPONSE:
            return Object.assign({}, state, {
                cleanDataConnectorsResponse: action.payload
            });
        case UPLOAD_DATA_CONNECTOR_RESPONSE:
            return Object.assign({}, state, {
                uploadDataConnectorsResponse: action.payload
            });
        default:
            return state;
    }
}
