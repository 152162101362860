import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";
import {
    SET_USER_DETAIL, UPDATE_USER_DETAIL, UPDATE_USER_DETAIL_RESP, FORGOT_PASSWORD, FORGOT_PASSWORD_RESP,
    RESET_PASSWORD, RESET_PASSWORD_RESP, LOGOUT
} from "./actionTypes";

export class SetUserDetail implements Action {
    readonly type = SET_USER_DETAIL;
    readonly payload = null;
    constructor(userDetail) {
        this.payload = userDetail
    }
}

export class UpdateUserDetail implements Action {
    readonly type = UPDATE_USER_DETAIL;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class UpdateUserDetailResp implements Action {
    readonly type = UPDATE_USER_DETAIL_RESP;
    readonly payload: IServerResponse = null;
    constructor(resp) {
        this.payload = resp;
    }
}

export class ForgotPassword implements Action {
    readonly type = FORGOT_PASSWORD;
    readonly payload: string = null;
    constructor(emailid) {
        this.payload = emailid;
    }
}

export class ForgotPasswordResp implements Action {
    readonly type = FORGOT_PASSWORD_RESP;
    readonly payload: any;
    constructor(resp) {
        this.payload = resp;
    }
}

export class ResetPassword implements Action {
    readonly type = RESET_PASSWORD;
    readonly payload: string = null;
    constructor(emailid) {
        this.payload = emailid;
    }
}

export class ResetPasswordResp implements Action {
    readonly type = RESET_PASSWORD_RESP;
    readonly payload: any;
    constructor(resp) {
        this.payload = resp;
    }
}

export class Logout implements Action {
    readonly type = LOGOUT;
    readonly payload = null;
    constructor(callLogoutAPI) {
        this.payload = callLogoutAPI
    }
}

export type Actions = SetUserDetail | UpdateUserDetail | UpdateUserDetailResp | ForgotPassword | ForgotPasswordResp
    | ResetPassword | ResetPasswordResp | Logout;
