import { NgModule } from "@angular/core";
import { CommonService } from "./service";

@NgModule({
    providers: [
        CommonService
    ]
})

export class CommonServiceModule { }
