import { NgModule } from "@angular/core";

import { AuditLogStoreModule } from "./audit-logs/store.module";
import { EnquiriesStoreModule } from "./enquiry/store.module";
import { BillingStoreModule } from "./billing/store.module";
import { MetersStoreModule } from "./meter/store.module";

@NgModule({
    imports: [
        AuditLogStoreModule,
        EnquiriesStoreModule,
        MetersStoreModule,
        BillingStoreModule
    ]
})
export class TrackingStoreModule { }
