import { Role } from './role';

export class User {
    id: string;
    name: string;
    email: string;
    contactNumber: string;
    role: Role;
    address: string;
    profileImage: string;
    firmId: string;
    firmAdminId?: string;
    caseManagerId?: string;
    roleName?: string;
    token?: string;
    clientId: string;
    medicalExpert?: any;
}
