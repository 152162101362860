import { IServerResponse } from "app/interface";

export interface MyTaskState {
    allTasks: Array<any>;
    taskById: any;
    taskChartDataByUserId: any;
    addTaskResponse: IServerResponse;
    updateTaskResponse: IServerResponse;
    updateTaskStatusResponse: IServerResponse;
    reassignTaskResponse: IServerResponse;
    createCalendarEventResponse: IServerResponse;
    deleteTaskResponse: IServerResponse;
    userList: Array<any>;
    allProjects: Array<any>;
    projectById: any;
    addProjectResponse: IServerResponse;
    updateProjectResponse: IServerResponse;
    deleteProjectResponse: IServerResponse;
}

export const initialState: MyTaskState = {
    allTasks: null,
    taskById: null,
    taskChartDataByUserId: null,
    addTaskResponse: null,
    updateTaskResponse: null,
    updateTaskStatusResponse: null,
    reassignTaskResponse: null,
    createCalendarEventResponse: null,
    deleteTaskResponse: null,
    userList: null,
    allProjects: null,
    projectById: null,
    addProjectResponse: null,
    updateProjectResponse: null,
    deleteProjectResponse: null
}
