import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation, OnChanges } from '@angular/core';
import * as d3 from 'd3';

export interface BarChartDataInterface {
    xLabel: string,
    yLabel: string,
    data: Array<any>,
    colors?: Array<string> | any
}

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class BarChartComponent implements AfterViewInit, OnDestroy, OnChanges {

    @ViewChild('chart') chartContainer: ElementRef;

    @Input() margin = { top: 20, bottom: 20, left: 50, right: 20 }
    @Input() chartData: BarChartDataInterface = {
        xLabel: 'xLabel',
        yLabel: 'yLabel',
        data: [],
        colors: ["#0a2746"]
    }

    private chart: any;
    private height: number;
    private width: number;
    private xScale: any;
    private yScale: any;
    private xAxis: any;
    private yAxis: any;
    private xLabel: any;
    private yLabel: any;
    private d3Element: any;
    private d3Svg: any;
    private labelSpace: number = 40

    constructor() { }

    onResize(event) {
        event.target.innerWidth;
        this.createChart()
    }

    ngAfterViewInit(): void {
        this.d3Element = this.chartContainer.nativeElement;
        this.d3Svg = d3.select(this.d3Element).append('svg')
        this.chart = this.d3Svg.append('g')
        this.xAxis = this.d3Svg.append('g')
        this.yAxis = this.d3Svg.append('g')
        this.xLabel = this.d3Svg.append('g')
        this.yLabel = this.d3Svg.append('g')
        this.createChart();
        if (this.chartData.data) {
            this.updateChart();
        }
    }

    ngOnChanges() {
        if (this.chart) {
            this.updateChart();
        }
    }

    ngOnDestroy(): void {
        this.chartContainer.nativeElement = null;
    }

    createChart() {
        var element = this.d3Element

        this.width = element.offsetWidth - this.margin.left - this.margin.right - this.labelSpace;
        this.height = element.offsetHeight - this.margin.top - this.margin.bottom - this.labelSpace;

        this.d3Svg
            .attr('width', element.offsetWidth)
            .attr('height', element.offsetHeight);

        // chart plot area
        this.chart
            .attr('class', 'bars')
            .attr('transform', `translate(${this.margin.left + this.labelSpace}, ${this.margin.top})`);

        // create scales
        this.xScale = d3.scaleLinear().domain([0, Math.max(...this.chartData.data.map((item) => item.value))]).range([0, this.width])
        this.yScale = d3.scaleBand().domain(this.chartData.data.map((d) => d.name)).range([this.height, 0]).padding(.25)

        // bar colors
        // this.chartData.colors = d3.scaleLinear().domain([0, this.chartData.data.length]).range(<any[]>['red', 'blue']);

        // x & y axis
        this.xAxis
            .attr('class', 'axis axis-x')
            .attr('transform', `translate(${this.margin.left + this.labelSpace}, ${this.margin.top + this.height})`)
            .call(d3.axisBottom(this.xScale));
        this.yAxis
            .attr('class', 'axis axis-y')
            .attr('transform', `translate(${this.margin.left + this.labelSpace}, ${this.margin.top})`)
            .call(d3.axisLeft(this.yScale));

        this.xLabel.append("text")
            .attr('class', 'axis-label axis-label-x')
            .attr("text-anchor", "end")
            .attr("x", this.width / 2 + this.margin.left + this.labelSpace)
            .attr("y", this.height + this.margin.top + this.labelSpace)
            .text(this.chartData.xLabel || "");

        this.yLabel.append("text")
            .attr('class', 'axis-label axis-label-y')
            .attr("text-anchor", "end")
            .attr("transform", "rotate(-90)")
            .attr("y", this.labelSpace - 20)
            .attr("x", -this.margin.top)
            .text(this.chartData.yLabel || "")

        this.updateChart()
    }

    updateChart() {
        this.xScale.domain([0, Math.max(...this.chartData.data.map((item) => item.value))]);
        this.yScale.domain(this.chartData.data.map((d) => d.name));
        // this.chartData.colors.domain([0, this.chartData.data.length]);
        this.xAxis.transition().call(d3.axisBottom(this.xScale));
        this.yAxis.transition().call(d3.axisLeft(this.yScale));

        // remove exiting bars
        const update = this.chart.selectAll('.bar')
            .data(this.chartData.data);
        update.exit().remove();

        // remove existing labels
        this.xLabel.select('.axis-label-x').remove();
        this.xLabel.append("text")
            .attr('class', 'axis-label axis-label-x')
            .attr("text-anchor", "end")
            .attr("x", this.width / 2 + this.margin.left + this.labelSpace)
            .attr("y", this.height + this.margin.top + this.labelSpace)
            .text(this.chartData.xLabel || "");

        this.yLabel.select('.axis-label-y').remove();
        this.yLabel.append("text")
            .attr('class', 'axis-label axis-label-y')
            .attr("text-anchor", "end")
            .attr("transform", "rotate(-90)")
            .attr("y", this.labelSpace - 20)
            .attr("x", -this.margin.top)
            .text(this.chartData.yLabel || "")


        // update existing bars
        this.chart.selectAll('.bar').transition()
            .attr('x', (d) => this.xScale(0))
            .attr('y', (d) => this.yScale(d.name))
            .attr('width', (d) => this.xScale(d.value))
            .attr("height", this.yScale.bandwidth())
            .style('fill', (d, i) => this.chartData.colors && this.chartData.colors[i] || "#0a2746");

        // add new bars
        update
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', (d) => this.xScale(d[0]))
            .attr('y', (d) => this.yScale(0))
            .attr('width', (d) => d.value || 0)
            .attr('height', 10)
            .style('fill', (d, i) => this.chartData.colors && this.chartData.colors[i] || "#0a2746")
            .transition()
            .delay((d, i) => i * 10)
            .attr('y', (d) => this.yScale(d[1]))
        // .attr('width', this.yScale.bandwidth());
    }
}
