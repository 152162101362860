export const ALL_BILLING_PAYMENT_STATUS_RESPONSE = {
    "status": true,
    "data": [
        { "id": "due", "name": "Due" },
        // { "id": "partialPaid", "name": "Partial Paid" },
        { "id": "paid", "name": "Paid" }
    ]
}

export const ALL_BILLING_COST_TASK_RESPONSE = {
    "status": true,
    "data": [
        { "id": "caseMatter_onboardingNewClient", "name": "Case Matter: Onboarding New Client" },
        { "id": "caseMatter_uploadDocuments", "name": "Case Matter: Upload Documents" },
        { "id": "caseMatter_downloadDocuments", "name": "Case Matter: Download Documents" },
        { "id": "caseMatter_notifyClient", "name": "Case Matter: Notify Client" },
        { "id": "caseMatter_requestSignedDocuments", "name": "Case Matter: Request Signed Documents" },
        { "id": "caseMatter_insuranceApproval", "name": "Case Matter: Insurance Approval" },
        { "id": "caseMatter_processAudioNotes", "name": "Case Matter: Process Audio Notes" },
        { "id": "caseMatter_processImagePdfNotes", "name": "Case Matter: Process Image/PDF Notes" },
        { "id": "preActionProtocol_createNewCase", "name": "Pre Action Protocol: Create New Case" },
        { "id": "preActionProtocol_uploadDocuments", "name": "Pre Action Protocol: Upload Documents" },
        { "id": "preActionProtocol_downloadDocuments", "name": "Pre Action Protocol: Download Documents" },
        { "id": "preActionProtocol_letterOfClaimGeneration", "name": "Pre Action Protocol: Letter of Claim Generation" },
        { "id": "preActionProtocol_sendLetterOfClaim", "name": "Pre Action Protocol: send Letter of Claim" },
        { "id": "preActionProtocol_reminder", "name": "Pre Action Protocol: Reminder" },
        { "id": "medicalEvidence_createNewCase", "name": "Medical Evidence: Create New Case" },
        { "id": "medicalEvidence_uploadDocuments", "name": "Medical Evidence: Upload Documents" },
        { "id": "medicalEvidence_downloadDocuments", "name": "Medical Evidence: Download Documents" },
        { "id": "medicalEvidence_generateCoveringLetter", "name": "Medical Evidence: Generate Covering Letter" },
        { "id": "medicalEvidence_processingMedicalRecords", "name": "Medical Evidence: Processing Medical Records" },
        { "id": "billing_generateInvoice", "name": "Billing: Generate Invoice" },
        { "id": "billing_paymentServices", "name": "Billing: Payment Services" },
        { "id": "billing_reminder", "name": "Billing: Reminder" }
    ]
}

export const ALL_BILLING_DATA_RESPONSE = {
    "status": true,
    "data": [
        {
            "id": "1234-5678-9012-3456",
            "client": "dushyant.singh@askelie.com",
            "matterReference": "IB/1/CRI010",
            "cost": {
                "baseCost": 100,
                "perTaskCost": [
                    {
                        "taskId": "caseMatter_onboardingNewClient",
                        "taskName": "Case Matter: Onboarding New Client",
                        "taskCost": 50
                    },
                    {
                        "taskId": "caseMatter_uploadDocuments",
                        "taskName": "Case Matter: Upload Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "caseMatter_downloadDocuments",
                        "taskName": "Case Matter: Download Documents",
                        "taskCost": 10
                    },
                    {
                        "taskId": "caseMatter_notifyClient",
                        "taskName": "Case Matter: Notify Client",
                        "taskCost": 10
                    },
                    {
                        "taskId": "caseMatter_requestSignedDocuments",
                        "taskName": "Case Matter: Request Signed Documents",
                        "taskCost": 10
                    },
                    {
                        "taskId": "medicalEvidence_createNewCase",
                        "taskName": "Medical Evidence: Create New Case",
                        "taskCost": 10
                    },
                    {
                        "taskId": "medicalEvidence_uploadDocuments",
                        "taskName": "Medical Evidence: Upload Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "medicalEvidence_downloadDocuments",
                        "taskName": "Medical Evidence: Download Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "medicalEvidence_generateCoveringLetter",
                        "taskName": "Medical Evidence: Generate Covering Letter",
                        "taskCost": 20
                    },
                    {
                        "taskId": "medicalEvidence_processingMedicalRecords",
                        "taskName": "Medical Evidence: Processing Medical Records",
                        "taskCost": 25
                    }
                ],
                "total": 1200
            },
            "status": "due"
        },
        {
            "id": "2345-5678-9012-3456",
            "client": "dushyant.singh@askelie.com",
            "matterReference": "CM/13/10/23/01",
            "cost": {
                "baseCost": 50,
                "perTaskCost": [
                    {
                        "taskId": "caseMatter_onboardingNewClient",
                        "taskName": "Case Matter: Onboarding New Client",
                        "taskCost": 50
                    },
                    {
                        "taskId": "caseMatter_uploadDocuments",
                        "taskName": "Case Matter: Upload Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "caseMatter_downloadDocuments",
                        "taskName": "Case Matter: Download Documents",
                        "taskCost": 10
                    },
                    {
                        "taskId": "preActionProtocol_createNewCase",
                        "taskName": "Pre Action Protocol: Create New Case",
                        "taskCost": 25
                    },
                    {
                        "taskId": "preActionProtocol_uploadDocuments",
                        "taskName": "Pre Action Protocol: Upload Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "preActionProtocol_downloadDocuments",
                        "taskName": "Pre Action Protocol: Download Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "preActionProtocol_letterOfClaimGeneration",
                        "taskName": "Pre Action Protocol: Letter of Claim Generation",
                        "taskCost": 10
                    },
                    {
                        "taskId": "medicalEvidence_uploadDocuments",
                        "taskName": "Medical Evidence: Upload Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "medicalEvidence_downloadDocuments",
                        "taskName": "Medical Evidence: Download Documents",
                        "taskCost": 50
                    },
                    {
                        "taskId": "medicalEvidence_generateCoveringLetter",
                        "taskName": "Medical Evidence: Generate Covering Letter",
                        "taskCost": 20
                    },
                    {
                        "taskId": "medicalEvidence_processingMedicalRecords",
                        "taskName": "Medical Evidence: Processing Medical Records",
                        "taskCost": 25
                    }
                ],
                "total": 1000
            },
            "status": "paid"
        }
    ]
}
