import { initialState, CommonState } from "./state";
import { Actions } from './actions';
import {
    PRESENT_TOASTR, DISABLE_TOASTR, UPLOAD_FILE_RESP, SET_USER_ACCESS, SET_SEARCH_OPTIONS, APP_CONFIG,
    SET_TABLE_SEARCH_PARAM, SET_PERSISTED_SEARCH, SET_HELPER_VALUES,
    SET_UNIPAAS_PAYMENT_LINK_RESPONSE
} from "./actionTypes";

export function CommonReducer(state = initialState, action: Actions): CommonState {
    switch (action.type) {
        case APP_CONFIG:
            return Object.assign({}, state, {
                appConfig: action.payload
            });
        case PRESENT_TOASTR:
            return Object.assign({}, state, {
                toasterData: action.payload
            });
        case DISABLE_TOASTR:
            return Object.assign({}, state, {
                toasterData: null
            });
        case UPLOAD_FILE_RESP:
            return Object.assign({}, state, {
                uploadFileResp: action.payload
            });
        case SET_USER_ACCESS:
            return Object.assign({}, state, {
                userAccess: action.payload
            });
        case SET_SEARCH_OPTIONS:
            return Object.assign({}, state, {
                searchOptions: action.payload
            });
        case SET_TABLE_SEARCH_PARAM:
            return Object.assign({}, state, {
                tableSearchParam: action.payload
            });
        case SET_PERSISTED_SEARCH:
            return Object.assign({}, state, {
                persistedSearch: { ...state.persistedSearch, [action.payload.moduleId]: action.payload.searchParam }
            });
        case SET_HELPER_VALUES: {
            const dataObject = action.payload;
            const formattedHelperValues = {};

            Object.keys(dataObject).forEach((dataKey) => {
                const valuesDataObject = dataObject[dataKey];

                formattedHelperValues[dataKey] = [];

                Object.keys(valuesDataObject).forEach((valueKey) => {
                    formattedHelperValues[dataKey].push({ id: valueKey, name: valuesDataObject[valueKey] })
                })
            })

            return Object.assign({}, state, {
                helperValues: {
                    ...state.helperValues,
                    ...formattedHelperValues
                }
            })
        }
        case SET_UNIPAAS_PAYMENT_LINK_RESPONSE:
            return Object.assign({}, state, {
                unipaasPaymentLinkResponse: action.payload
            });

        default:
            return state;
    }
}
