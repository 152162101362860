import { NgModule } from "@angular/core";

import { UsersService } from "./users/service";
import { FirmsService } from "./firms/service";
import { NotificationsService } from "./notifications/service";
import { RolesService } from "./roles/service";
import { DataConnectorsService } from "./data-connectors/service";

@NgModule({
    providers: [
        UsersService, FirmsService, RolesService, NotificationsService, DataConnectorsService
    ]
})

export class SetupServiceModule { }
