import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { ResearchAiEffect } from "./effect";
import { ResearchAiStateReducer } from "./reducer";

import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([ResearchAiEffect]),
        StoreModule.forFeature('researchAi', ResearchAiStateReducer)
    ]
})

export class ResearchAiStoreModule { }

export const allResearchAiModels = (store: any) => store && store.researchAi && store.researchAi.allResearchAiModels;
export const researchAiModelById = (store: any) => store && store.researchAi && store.researchAi.researchAiModelById;
export const addResearchAiModelResponse = (store: any) => store && store.researchAi && store.researchAi.addResearchAiModelResponse;
export const updateResearchAiModelResponse = (store: any) => store && store.researchAi && store.researchAi.updateResearchAiModelResponse;
export const deleteResearchAiModelResponse = (store: any) => store && store.researchAi && store.researchAi.deleteResearchAiModelResponse;
export const addResearchAiSourcesResponse = (store: any) => store && store.researchAi && store.researchAi.addResearchAiSourcesResponse;
export const removeResearchAiSourcesResponse = (store: any) => store && store.researchAi && store.researchAi.removeResearchAiSourcesResponse;
export const trainResearchAiModelResponse = (store: any) => store && store.researchAi && store.researchAi.trainResearchAiModelResponse;
export const queryResearchAiModelResponse = (store: any) => store && store.researchAi && store.researchAi.queryResearchAiModelResponse;

export const allResearchAiModelsWithStateName = createSelector(
    allResearchAiModels,
    helperValues,
    (documents, helperValues) => {
        const allItems = (documents || []).map((item) => ({
            ...item,
            modelStateName: (helperValues?.modelState || []).find(({ id }) => id === item.modelState)?.name || item.modelState || "-"
        }))
        return allItems;
    }
)
