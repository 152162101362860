import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlUtil } from "app/utils/url.util";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PROTOCOL_TYPES_LIST, DATABASE_TYPES_LIST, RISK_RATING_LIST } from "./stub";
import { CommonUtil } from "app/utils/common.util";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CoreService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private commonUtil: CommonUtil,
        private _translateService: TranslateService
    ) {

    }

    public updateUserDetail(data) {
        return this.http.put(
            this.urlUtil.getUrl('profile', 'update').replace('$ID', data.id), data)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: 'Data updated successfully.'
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || 'Data was not updated.'
                        });
                    }
                }),
                catchError((e) => {
                    console.log("update user detail catch", e);
                    return of({
                        status: false,
                        message: 'Data was not updated.'
                    });
                })
            )
    }

    public forgotPassword(emailId) {
        return this.http.post(
            this.urlUtil.getUrl('authenticate', 'forgotPassword'), {}, { headers: { email: emailId } })
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: 'We have sent reset email link to your email id.'
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || 'We are facing some issue while reseting your password.'
                        });
                    }
                }),
                catchError((e) => {
                    console.log("forget password catch", e);
                    return of({
                        status: false,
                        message: 'We are facing some issue while reseting your password.'
                    });
                })
            )
    }

    public resetPassword(emailId) {
        return this.http.post(
            this.urlUtil.getUrl('authenticate', 'forgotPassword'), {}, { headers: { email: emailId } })
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: 'We have sent reset email link to your email id.'
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || 'We are facing some issue while reseting your password.'
                        });
                    }
                }),
                catchError((e) => {
                    console.log("reset password catch", e);
                    return of({
                        status: false,
                        message: 'We are facing some issue while reseting your password.'
                    });
                })
            )
    }

    public logout() {
        return this.http.get(
            this.urlUtil.getUrl('authenticate', 'logout').replace("$USER_ID", this.commonUtil.getUserDetail('id'))
        )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage, data } = resp
                    if (status) {
                        return ({
                            status: true,
                            message: errorMessage || jsonData || 'You have been logged out successfully!'
                        });
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("logout catch", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    });
                })
            )
    }
}
