import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DataConnectorsService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store
    ) {
    }

    public getDataConnectors() {
        return this.http.get(
            this.urlUtil.getUrl('dataConnectors', 'getAll'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getDataConnectors error =>", e);
                    return of([]);
                })
            )
    }

    public cleanDataConnectors(data) {
        return this.http.get(
            this.urlUtil.getUrl('dataConnectors', 'clean', [{ key: 'type', value: data }]))
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataConnectorCleaned")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataConnectorClean")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("cleanDataConnectors error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataConnectorClean")
                    });
                })
            )
    }

    public uploadDataConnector({ type, file }) {
        const formData = new FormData()
        formData.append("file", file);
        return this.http.post(
            this.urlUtil.getUrl('dataConnectors', 'upload', [{ key: 'type', value: type }]), formData)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("uploadDataConnector error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataAdd")
                    });
                })
            )
    }
}
