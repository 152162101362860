export const PRESENT_TOASTR = "PRESENT_TOASTR";
export const DISABLE_TOASTR = "DISABLE_TOASTR";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_RESP = "UPLOAD_FILE_RESP";

export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DOWNLOAD_FILE_RESP = "DOWNLOAD_FILE_RESP";

export const GET_USER_ACCESS = "GET_USER_ACCESS";
export const SET_USER_ACCESS = "SET_USER_ACCESS";

export const GET_SEARCH_OPTIONS = 'GET_SEARCH_OPTIONS';
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS';

export const GET_APP_CONFIG = "GET_APP_CONFIG";
export const APP_CONFIG = "APP_CONFIG";

export const SET_TABLE_SEARCH_PARAM = "SET_TABLE_SEARCH_PARAM";
export const SET_PERSISTED_SEARCH = "SET_PERSISTED_SEARCH";

export const GET_HELPER_VALUES = "GET_HELPER_VALUES";
export const SET_HELPER_VALUES = "SET_HELPER_VALUES";

export const GET_UNIPAAS_PAYMENT_LINK = "GET_UNIPAAS_PAYMENT_LINK";
export const SET_UNIPAAS_PAYMENT_LINK_RESPONSE = "SET_UNIPAAS_PAYMENT_LINK_RESPONSE";
