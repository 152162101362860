import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'app-add-project-modal',
    templateUrl: './add-project-modal.component.html',
    styleUrls: ['./add-project-modal.component.scss']
})
export class AddProjectModalComponent implements OnInit {
    @Input() title: String = ''
    @Input() handleSubmitClick: Function
    @Input() handleCloseClick: Function;

    public projectForm: FormGroup;
    formSubmitted: boolean = false;

    unsubscribe = new Subject();

    constructor() { }

    ngOnInit(): void {
        this.projectForm = new FormGroup({
            name: new FormControl('', Validators.required),
        })
    }

    get ReactiveFormControl() {
        return this.projectForm.controls;
    }

    get ReactiveFormValues() {
        return this.projectForm.getRawValue();
    }

    onSubmitClick() {
        this.formSubmitted = true;

        if (this.projectForm.invalid) {
            return;
        }

        const projectDetails = cloneDeep({ ...this.ReactiveFormValues });
        this.handleSubmitClick(projectDetails);
    }
}
