import { IServerResponse } from "app/interface";

export interface BillingState {
    allBillingData: Array<any>;
    billingById: any;
    addBillingResponse: IServerResponse;
    updateBillingResponse: IServerResponse;
    deleteBillingResponse: IServerResponse;
}

export const initialState: BillingState = {
    allBillingData: null,
    billingById: null,
    addBillingResponse: null,
    updateBillingResponse: null,
    deleteBillingResponse: null
}
