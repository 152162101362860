import { initialState, UsersState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_USERS, SET_USER_BY_ID, ADD_USER_RESPONSE, UPDATE_USER_RESPONSE, DELETE_USER_RESPONSE,
    ADD_GUEST_USER_RESPONSE, SET_USER_PROFILE_BY_ID, UPDATE_USER_PROFILE_BY_ID_RESPONSE,
    SET_USER_BY_FIELD
} from "./actionTypes";

export function UsersStateReducer(state = initialState, action: Actions): UsersState {
    const { type } = action;

    switch (type) {
        case SET_ALL_USERS:
            return Object.assign({}, state, {
                allUsers: action.payload
            });
        case SET_USER_BY_FIELD:
            return Object.assign({}, state, {
                allUsersBySearch: action.payload
            });
        case SET_USER_BY_ID:
            return Object.assign({}, state, {
                userById: action.payload
            });
        case ADD_USER_RESPONSE:
            return Object.assign({}, state, {
                addUserResponse: action.payload
            });
        case ADD_GUEST_USER_RESPONSE:
            return Object.assign({}, state, {
                addGuestUserResponse: action.payload
            });
        case UPDATE_USER_RESPONSE:
            return Object.assign({}, state, {
                updateUserResponse: action.payload
            });
        case DELETE_USER_RESPONSE:
            return Object.assign({}, state, {
                deleteUserResponse: action.payload
            });
        case SET_USER_PROFILE_BY_ID:
            return Object.assign({}, state, {
                userProfileById: action.payload
            });
        case UPDATE_USER_PROFILE_BY_ID_RESPONSE:
            return Object.assign({}, state, {
                updateUserProfileByIdResponse: action.payload
            })

        default:
            return state;
    }
}
