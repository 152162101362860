import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Store } from '@ngrx/store';

import { TOASTR_TYPE } from 'app/constants/common.constant';
import { PresentToastr } from 'app/store/common/actions';

import { TranslateService } from '@ngx-translate/core';

import { checkFilesSize } from 'app/utils/commonFunctions.util';
import { v4 as uuidv4 } from 'uuid';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { IMPORT_MODULE_CONFIGS } from 'app/constants/module.constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '@core/services/file.service';
import { helperValues } from 'app/store/common/commonStore.module';
import { takeUntil } from 'rxjs/operators';
import { CommonUtil } from 'app/utils/common.util';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnDestroy {
    @BlockUI('file-upload-modal') blockUIModal: NgBlockUI;

    @Input() module: string;
    @Input() title: String = ''
    @Input() multipleFileSelection: boolean = true;
    @Input() connectorType: string;
    @Input() handleCloseClick: Function;
    @Input() handleSubmitClick: Function;

    public showFileTypeOptions: boolean = false;

    public filesToBeUploaded = [];

    public fileTypeOptions: Array<any> = [
        {
            id: 'AUDIO',
            name: 'Audio'
        },
        {
            id: 'EML',
            name: 'EML'
        }
    ]

    public importForm: FormGroup;
    public moduleConfig: any;

    formSubmitted: boolean = false;
    unsubscribe = new Subject();

    public extractionModelOptions = [];
    public extractionModelSelected = null;

    constructor(
        private store: Store,
        private activeModal: NgbActiveModal,
        private commonUtil: CommonUtil,
        private _translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.moduleConfig = cloneDeep(IMPORT_MODULE_CONFIGS[this.module])
        const formFields = this.moduleConfig.fields;

        if (formFields?.fileType) {
            this.showFileTypeOptions = true;
            this.importForm = new FormGroup({
                fileType: new FormControl(null, Validators.required)
            })
        } else {
            this.importForm = new FormGroup({})
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()

        this.filesToBeUploaded = [];

        this.stopUploadFileModalBlockUI();
    }

    get ReactiveFormControl() {
        return this.importForm.controls;
    }

    get ReactiveFormValues() {
        return this.importForm.getRawValue();
    }

    get filesNames() {
        return this.ReactiveFormValues.files?.name || ""
    }

    startUploadFileModalBlockUI() {
        this.blockUIModal.start();
    }

    stopUploadFileModalBlockUI() {
        this.blockUIModal.stop();
    }

    onFilesAdd({ newFiles, controlName }) {
        if (newFiles) {
            const filesArray = Array.from(newFiles)
            const { sizeValidity } = checkFilesSize({ filesObjArr: filesArray, upperLimitInMB: 20, _translateService: this._translateService })

            if (sizeValidity) {
                if (this.multipleFileSelection) {
                    const userAttachments = this.filesToBeUploaded || [];

                    this.filesToBeUploaded = userAttachments.concat(filesArray.map((fileItem: any) => ({
                        id: `new-${uuidv4()}`,
                        name: fileItem.name,
                        url: fileItem
                    })))
                } else {
                    this.filesToBeUploaded = filesArray.map((fileItem: any) => ({
                        id: `new-${uuidv4()}`,
                        name: fileItem.name,
                        url: fileItem
                    }));
                }
            } else {
                this.store.dispatch(new PresentToastr({
                    type: TOASTR_TYPE.WARNING,
                    message: this._translateService.instant("Files should not be larger than 20MB")
                }))
            }
        }
        (<HTMLInputElement>document.getElementById(controlName)).value = null;
    }

    onFileRemove({ fileId, controlName }) {
        const userAttachments = this.filesToBeUploaded || []

        this.filesToBeUploaded = userAttachments.filter(({ id }) => id !== fileId)
    }

    onSubmitClick() {
        this.formSubmitted = true;

        if (this.importForm.invalid) {
            return;
        }

        const fileType = this.importForm.controls?.fileType?.value;
        const files = this.filesToBeUploaded.map(({ url }) => url);

        if (fileType) {
            if (this.commonUtil.isValidFileType(files?.[0], fileType)) {
                this.handleSubmitClick({ fileType, files });
            } else {
                this.store.dispatch(new PresentToastr({
                    type: TOASTR_TYPE.WARNING,
                    message: 'Mismatch between selected File Type and File!'
                }))
            }
        } else if (this.connectorType) {
            if (this.commonUtil.isValidFileTypeForDataConnector(files?.[0])) {
                this.handleSubmitClick({ files });
            } else {
                this.store.dispatch(new PresentToastr({
                    type: TOASTR_TYPE.WARNING,
                    message: 'Unsupported file format. Only Excel (.xlsx) and CSV (.csv) are allowed.'
                }))
            }
        }
    }
}
