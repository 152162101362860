import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { URL_CONST } from 'app/constants';
import { HeaderService } from '@core/services/header.service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
    constructor(
        private headerService: HeaderService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url.indexOf('http') !== -1 || request.url.indexOf('https') !== -1) {
            request = request.clone({
                setHeaders: this.headerService.generateCommonHeaders(request.headers),
                url: request.url
            });
        } else {
            if ((request.url.indexOf(URL_CONST.AUTHENTICATION.LOG_IN) !== -1) || (request.url.indexOf(URL_CONST.AUTHENTICATION.FORGOT_PASSWORD.replace('$EMAIL', '')) !== -1)) {
                request = request.clone({
                    url: environment.apiUrl + request.url
                });
            } else {
                request = request.clone({
                    setHeaders: this.headerService.generateCommonHeaders(request.headers),
                    url: environment.apiUrl + request.url
                });
            }
        }

        return next.handle(request);
    }
}
