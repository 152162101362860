<div class="modal-header bg-primary">
  <h4 class="modal-title text-white">{{ "Version History" | translate }}</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCloseClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div class="modal-body" tabindex="0" ngbAutofocus *blockUI="'enquiry-modal'">
  <div class="row" *blockUI="'version-history-modal'">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="action-col">{{ "Version #" | translate }}</th>
              <th class="action-col">{{ "Modified On" | translate }}</th>
              <th class="action-col">{{ "Modified By" | translate }}</th>
              <th class="action-col"></th>
            </tr>
          </thead>

          <tbody *ngIf="!versionRows?.length">
            <tr>
              <td colspan="4">{{ "No Data To Show" }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="versionRows?.length">
            <tr *ngFor="let versionItem of versionRows; let rowIndex = index">
              <td>{{ versionItem.versionNumber }}</td>
              <td>
                {{ versionItem.modifiedOn | date : "dd/MM/yyyy, h:mm:ss a" }}
              </td>
              <td>
                {{ versionItem.modifiedByName || "-" }}
              </td>
              <td>
                <a (click)="openFileInNewTab($event, versionItem)" href="#">
                  <span [data-feather]="'link'"></span>
                  {{ versionItem.name || " Link" | translate }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
