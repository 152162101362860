import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { CONFIRM_DEFAULT_TITLE, CONFIRM_DEFAULT_MESSAGE } from 'app/constants/common.constant';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
    @BlockUI('confirm-modal') confirmModalBlockUI: NgBlockUI;

    readonly defaultTitle = this._translateService.instant(CONFIRM_DEFAULT_TITLE)
    readonly defaultMessage = this._translateService.instant(CONFIRM_DEFAULT_MESSAGE)

    @Input() title: string;
    @Input() message: string;
    @Input() isDelete: boolean = true

    @Input() handleConfirmClick: Function
    @Input() handleCancelClick: Function

    constructor(
        private _translateService: TranslateService
    ) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.stopConfirmModalBlockUI();
    }

    startConfirmModalBlockUI() {
        this.confirmModalBlockUI.start();
    }

    stopConfirmModalBlockUI() {
        this.confirmModalBlockUI.stop();
    }
}
