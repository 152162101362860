import { NgModule } from "@angular/core";

import { SpeechAiService } from "./speech-ai/service";
import { VisionAiService } from "./vision-ai/service";
import { ResearchAiService } from "./research-ai/service";

@NgModule({
    providers: [
        SpeechAiService,
        VisionAiService,
        ResearchAiService
    ]
})

export class UtilitiesServiceModule { }
