<div class="row align-items-center">
  <div class="col-12 col-md-5">
    <div class="border selector-list">
      <ul>
        <li class="header">
          <div class="row">
            <div class="col" *ngFor="let column of columnsData">
              {{ column.label }}
            </div>
          </div>
        </li>
        <div class="data-container">
          <li *ngFor="let item of availableList" (click)="onUpdateItemsToBeAdded(item)" class="selector-item list-item"
            [class.selected]="this.itemsToBeAdded.includes(item)">
            <div class="row">
              <div class="col" *ngFor="let column of columnsData">
                <small>
                  {{ item[column.key] }}
                </small>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>

  <div class="col-12 col-md-2 text-center">
    <div class="my-1">
      <button type="button" rippleEffect class="btn btn-primary w-100" (click)="onAddItemsSubmit()"
        [disabled]="!itemsToBeAdded || itemsToBeAdded.length === 0 || disable">
        <span [data-feather]="'chevrons-right'"></span>
      </button>
    </div>

    <div class="my-1">
      <button type="button" rippleEffect class="btn btn-outline-secondary w-100" (click)="onRemoveItemsSubmit()"
        [disabled]="!itemsToBeRemoved || itemsToBeRemoved.length === 0 || disable">
        <span [data-feather]="'chevrons-left'"></span>
      </button>
    </div>
  </div>

  <div class="col-12 col-md-5">
    <div class="border selector-list">
      <ul>
        <li class="header">
          <div class="row">
            <div class="col" *ngFor="let column of columnsData">
              {{ column.label }}
            </div>
          </div>
        </li>
        <div class="data-container">
          <li *ngFor="let item of selectedList" (click)="onUpdateItemsToBeRemoved(item)" class="selector-item list-item"
            [class.selected]="this.itemsToBeRemoved.includes(item)">
            <div class="row">
              <div class="col" *ngFor="let column of columnsData">
                <small>
                  {{ item[column.key] }}
                </small>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>