<div class="row">
  <div class="col-12" *ngIf="filterColumnOptions.length">
    <form
      class="form"
      [formGroup]="filterForm"
      (ngSubmit)="changePersistedSearch()"
    >
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 mb-1">
          <select
            class="form-control"
            (change)="onFilterColumnUpdate($event.target.value)"
            name="columnName"
            id="columnName"
            formControlName="columnName"
            ngDefaultControl
          >
            <option
              *ngFor="let column of filterColumnOptions"
              [value]="column.key"
            >
              {{ column.value }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 mb-1">
          <ng-template [ngIf]="searchType === 'text'">
            <input
              type="search"
              placeholder="Search"
              class="form-control"
              name="columnValue"
              id="columnValue"
              formControlName="columnValue"
            />
          </ng-template>
          <ng-template [ngIf]="searchType === 'date'">
            <input
              type="date"
              placeholder="Search"
              class="form-control ml-25"
              name="columnValue"
              id="columnValue"
              formControlName="columnValue"
            />
          </ng-template>
          <ng-template [ngIf]="searchType === 'select'">
            <ng-select
              id="columnValue"
              name="columnValue"
              [items]="searchValueList"
              bindLabel="name"
              bindValue="id"
              formControlName="columnValue"
              [closeOnSelect]="true"
            >
            </ng-select>
          </ng-template>
        </div>

        <div
          class="col-12 col-sm-6 offset-sm-6 col-lg-4 offset-lg-0 mb-1 text-right"
        >
          <div class="btn-group" role="group" aria-label="filters btn group">
            <button
              type="submit"
              [disabled]="this.filterForm.invalid"
              rippleEffect
              class="btn btn-primary"
              [ngbTooltip]="'Search' | translate"
            >
              <span [data-feather]="'search'"></span>
            </button>
            <button
              type="button"
              (click)="onClear($event)"
              rippleEffect
              class="btn btn-outline-primary"
              [ngbTooltip]="'Clear Filters' | translate"
            >
              <span [data-feather]="'x'"></span>
            </button>
            <button
              type="submit"
              rippleEffect
              class="btn btn-outline-primary"
              [ngbTooltip]="'Refresh' | translate"
            >
              <span [data-feather]="'refresh-cw'"></span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
