import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { DataConnectorsEffect } from "./effect";
import { DataConnectorsStateReducer } from "./reducer";

@NgModule({
    imports: [
        EffectsModule.forFeature([DataConnectorsEffect]),
        StoreModule.forFeature('dataConnectors', DataConnectorsStateReducer)
    ]
})

export class DataConnectorsStoreModule { }

export const allDataConnectors = (store: any) => store && store.dataConnectors && store.dataConnectors.allDataConnectors;
export const cleanDataConnectorsResponse = (store: any) => store && store.dataConnectors && store.dataConnectors.cleanDataConnectorsResponse;
export const uploadDataConnectorsResponse = (store: any) => store && store.dataConnectors && store.dataConnectors.uploadDataConnectorsResponse;
