import { Injectable } from '@angular/core';
import { LocalstorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    constructor(
        private localStorage: LocalstorageService
    ) { }

    public generateCommonHeaders(customHeaders = null) {
        const headers = {}

        try {
            if (this.localStorage.getItem('authToken')) {
                const authDataStringified = this.localStorage.getItem('authToken')
                const authDataParsed = JSON.parse(authDataStringified)
                const {
                    token
                } = authDataParsed

                headers['Authorization'] = token ? `Bearer ${token}` : null;
            }

            if (customHeaders) {
                customHeaders.forEach((key, value) => {
                    headers[key] = value?.[0]
                })
            }
        } catch (err) {
            console.log("generateCommonHeaders catch err", err)
        } finally {
            return headers
        }
    }
}
