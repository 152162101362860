export const GET_ALL_METERS = "GET_ALL_METERS";
export const SET_ALL_METERS = "SET_ALL_METERS";

export const GET_METER_BY_ID = "GET_METER_BY_ID";
export const SET_METER_BY_ID = "SET_METER_BY_ID";

export const ADD_METER = "ADD_METER";
export const ADD_METER_RESPONSE = "ADD_METER_RESPONSE";

export const UPDATE_METER = "UPDATE_METER";
export const UPDATE_METER_RESPONSE = "UPDATE_METER_RESPONSE";

export const DELETE_METER = "DELETE_METER";
export const DELETE_METER_RESPONSE = "DELETE_METER_RESPONSE";

export const GET_METER_EXTRACTIONS = "GET_METER_EXTRACTIONS";
export const SET_METER_EXTRACTIONS = "SET_METER_EXTRACTIONS";

export const GET_METER_BY_FIELD = "GET_METER_BY_FIELD";
export const SET_METER_BY_FIELD = "SET_METER_BY_FIELD";


export const METER_EXPORT = "METER_EXPORT";
export const EXPORT_METER_RESPONSE = "EXPORT_METER_RESPONSE";
export const EXPORT_METER_FAIL = "EXPORT_METER_FAIL";