import { initialState, NotificationsState } from "./state";
import { Actions } from './actions';
import {
    NOTIFICATION_LIST, NOTIFICATION_BY_ID, NOTIFICATION_TEMPLATE_LIST, NOTIFICATION_BY_FIELD_LIST,
    ADD_NOTIFICATION_RESPONSE, UPDATE_NOTIFICATION_RESPONSE, DELETE_NOTIFICATION_RESPONSE
} from "./actionTypes";
import { SortListAscending } from "app/utils/sort.util";

export function NotificationsReducer(state = initialState, action: Actions): NotificationsState {
    switch (action.type) {
        case NOTIFICATION_LIST:
            return Object.assign({}, state, {
                notificationsList: SortListAscending(action.payload, "name")
            });
        case NOTIFICATION_BY_FIELD_LIST:
            return Object.assign({}, state, {
                notificationsByFieldList: SortListAscending(action.payload, "name")
            });
        case NOTIFICATION_BY_ID:
            return Object.assign({}, state, {
                notificationsById: action.payload
            });
        case NOTIFICATION_TEMPLATE_LIST:
            const dataObject = action.payload;
            const formattedTemplateList = [];

            Object.keys(dataObject).forEach((valueKey) => {
                formattedTemplateList.push({ id: valueKey, name: dataObject[valueKey] })
            })           

            return Object.assign({}, state, {
                notificationsTemplateList: formattedTemplateList
            })

        case ADD_NOTIFICATION_RESPONSE:
            return Object.assign({}, state, {
                addNotificationsResponse: action.payload
            });
        case UPDATE_NOTIFICATION_RESPONSE:
            return Object.assign({}, state, {
                updateNotificationsResponse: action.payload
            });
        case DELETE_NOTIFICATION_RESPONSE:
            return Object.assign({}, state, {
                deleteNotificationsResponse: action.payload
            });
        default:
            return state;
    }
}
