import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-file-preview-modal',
    templateUrl: './file-preview-modal.component.html',
    styleUrls: ['./file-preview-modal.component.scss']
})
export class FilePreviewModalComponent {

    constructor() { }

}
