import { IServerResponse } from "app/interface";

export interface SpeechAiState {
    allSpeechAiDocuments: Array<any>;
    speechAiDocumentById: any;
    addSpeechAiDocumentResponse: IServerResponse;
    deleteSpeechAiDocumentResponse: IServerResponse;
    saveSpeechAiDocumentAsNoteResponse: IServerResponse;
    runSpeechAiExtractionFromSummaryResponse: IServerResponse;
}

export const initialState: SpeechAiState = {
    allSpeechAiDocuments: null,
    speechAiDocumentById: null,
    addSpeechAiDocumentResponse: null,
    deleteSpeechAiDocumentResponse: null,
    saveSpeechAiDocumentAsNoteResponse: null,
    runSpeechAiExtractionFromSummaryResponse: null
}
