import { NgModule } from "@angular/core";
import { VisualisationUtil } from "./visualisation.util";
import { UrlUtil } from "./url.util";
import { CommonUtil } from "./common.util";
import { UserAccessUtil } from "./userAccess.util";
import { CommunicationService } from "./communicationService.util";
import { Page } from "./page.util"

@NgModule({
    providers: [
        CommonUtil,
        UrlUtil,
        UserAccessUtil,
        VisualisationUtil,
        CommunicationService,
        Page
    ]
})

export class UtilModule { }
