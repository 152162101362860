import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { BillingEffect } from "./effect";
import { BillingStateReducer } from "./reducer";

import { helperValues } from "app/store/common/commonStore.module";
import { ALL_BILLING_PAYMENT_STATUS_RESPONSE } from 'app/store/tracking/billing/stub';

@NgModule({
    imports: [
        EffectsModule.forFeature([BillingEffect]),
        StoreModule.forFeature('billing', BillingStateReducer)
    ]
})

export class BillingStoreModule { }

export const allBillingData = (store: any) => store && store.billing && store.billing.allBillingData;
export const billingById = (store: any) => store && store.billing && store.billing.billingById;
export const addBillingResponse = (store: any) => store && store.billing && store.billing.addBillingResponse;
export const updateBillingResponse = (store: any) => store && store.billing && store.billing.updateBillingResponse;
export const deleteBillingResponse = (store: any) => store && store.billing && store.billing.deleteBillingResponse;

export const allBillingDataStatusName = createSelector(
    allBillingData,
    helperValues,
    (allBillings, values) => {
        const statuses = values.paymentStatus?.length ? values.paymentStatus : ALL_BILLING_PAYMENT_STATUS_RESPONSE.data;
        if (allBillings?.length > 0 && statuses !== null) {
            return allBillings.map((item) => {
                const status = item.status
                return {
                    ...item,
                    statusName: statuses.find(({ id }) => id === status)?.name || "-"
                }
            })
        }
    }
)
