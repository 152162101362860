import { NgModule } from "@angular/core";

import { UsersStoreModule } from "./users/store.module";
import { FirmsStoreModule } from "./firms/store.module";
import { NotificationsStoreModule } from "./notifications/store.module";
import { RolesStoreModule } from "./roles/store.module";
import { DataConnectorsStoreModule } from "./data-connectors/store.module";

@NgModule({
    imports: [
        UsersStoreModule, FirmsStoreModule, RolesStoreModule, NotificationsStoreModule,
        DataConnectorsStoreModule
    ]
})
export class SetupStoreModule { }
