import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_RESEARCH_AI_MODELS, SET_ALL_RESEARCH_AI_MODELS, GET_RESEARCH_AI_MODEL_BY_ID, SET_RESEARCH_AI_MODEL_BY_ID,
    ADD_RESEARCH_AI_MODEL, ADD_RESEARCH_AI_MODEL_RESPONSE, UPDATE_RESEARCH_AI_MODEL, UPDATE_RESEARCH_AI_MODEL_RESPONSE,
    DELETE_RESEARCH_AI_MODEL, DELETE_RESEARCH_AI_MODEL_RESPONSE,
    ADD_RESEARCH_AI_SOURCES, ADD_RESEARCH_AI_SOURCES_RESPONSE, REMOVE_RESEARCH_AI_SOURCES, REMOVE_RESEARCH_AI_SOURCES_RESPONSE,
    TRAIN_RESEARCH_AI_MODEL, TRAIN_RESEARCH_AI_MODEL_RESPONSE, QUERY_RESEARCH_AI_MODEL, QUERY_RESEARCH_AI_MODEL_RESPONSE
} from "./actionTypes"

export class GetAllResearchAiModels implements Action {
    readonly type = GET_ALL_RESEARCH_AI_MODELS;
}

export class SetAllResearchAiModels implements Action {
    readonly type = SET_ALL_RESEARCH_AI_MODELS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetResearchAiModelById implements Action {
    readonly type = GET_RESEARCH_AI_MODEL_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id;
    }
}

export class SetResearchAiModelById implements Action {
    readonly type = SET_RESEARCH_AI_MODEL_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddResearchAiModel implements Action {
    readonly type = ADD_RESEARCH_AI_MODEL;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class AddResearchAiModelResponse implements Action {
    readonly type = ADD_RESEARCH_AI_MODEL_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateResearchAiModel implements Action {
    readonly type = UPDATE_RESEARCH_AI_MODEL;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class UpdateResearchAiModelResponse implements Action {
    readonly type = UPDATE_RESEARCH_AI_MODEL_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteResearchAiModel implements Action {
    readonly type = DELETE_RESEARCH_AI_MODEL;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray;
    }
}

export class DeleteResearchAiModelResponse implements Action {
    readonly type = DELETE_RESEARCH_AI_MODEL_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddResearchAiSources implements Action {
    readonly type = ADD_RESEARCH_AI_SOURCES;
    readonly payload = null;
    constructor(dataArray) {
        this.payload = dataArray;
    }
}

export class AddResearchAiSourcesResponse implements Action {
    readonly type = ADD_RESEARCH_AI_SOURCES_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class RemoveResearchAiSources implements Action {
    readonly type = REMOVE_RESEARCH_AI_SOURCES;
    readonly payload = null;
    constructor(dataArray) {
        this.payload = dataArray;
    }
}

export class RemoveResearchAiSourcesResponse implements Action {
    readonly type = REMOVE_RESEARCH_AI_SOURCES_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class TrainResearchAiModel implements Action {
    readonly type = TRAIN_RESEARCH_AI_MODEL;
    readonly payload = null;
    constructor(id) {
        this.payload = id;
    }
}

export class TrainResearchAiModelResponse implements Action {
    readonly type = TRAIN_RESEARCH_AI_MODEL_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class QueryResearchAiModel implements Action {
    readonly type = QUERY_RESEARCH_AI_MODEL;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject;
    }
}

export class QueryResearchAiModelResponse implements Action {
    readonly type = QUERY_RESEARCH_AI_MODEL_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions = GetAllResearchAiModels | SetAllResearchAiModels | GetResearchAiModelById | SetResearchAiModelById |
    AddResearchAiModel | AddResearchAiModelResponse | UpdateResearchAiModel | UpdateResearchAiModelResponse |
    DeleteResearchAiModel | DeleteResearchAiModelResponse |
    AddResearchAiSources | AddResearchAiSourcesResponse | RemoveResearchAiSources | RemoveResearchAiSourcesResponse |
    TrainResearchAiModel | TrainResearchAiModelResponse | QueryResearchAiModel | QueryResearchAiModelResponse;
