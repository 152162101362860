export const GET_CALENDAR_DATA = "GET_CALENDAR_DATA";
export const CALENDAR_DATA = "CALENDAR_DATA";

export const GET_CALENDAR_DATA_BY_ID = "GET_CALENDAR_DATA_BY_ID";
export const SET_CALENDAR_DATA_BY_ID = "SET_CALENDAR_DATA_BY_ID";

export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";

export const ADD_CALENDAR_EVENT = "ADD_CALENDAR_EVENT";
export const ADD_CALENDAR_EVENT_RESPONSE = "ADD_CALENDAR_EVENT_RESPONSE";

export const UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT"
export const UPDATE_CALENDAR_EVENT_RESPONSE = "UPDATE_CALENDAR_EVENT_RESPONSE"

export const SYNC_CALENDAR_EVENT = "SYNC_CALENDAR_EVENT"
export const SYNC_CALENDAR_EVENT_RESPONSE = "SYNC_CALENDAR_EVENT_RESPONSE"

export const DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT"
export const DELETE_CALENDAR_EVENT_RESPONSE = "DELETE_CALENDAR_EVENT_RESPONSE"

export const SYNC_CALENDAR = "SYNC_CALENDAR";
