import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-success-modal',
    templateUrl: './success-modal.component.html',
    styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
    @Input() title: string;
    @Input() message: string;

    @Input() handleCloseClick: Function

    constructor() { }

    ngOnInit(): void {
    }
}
