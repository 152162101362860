import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-selector-lists',
    templateUrl: './selector-lists.component.html',
    styleUrls: ['./selector-lists.component.scss']
})
export class SelectorListsComponent implements OnInit, OnChanges {

    @Input() availableList: Array<any> = []
    @Input() selectedList: Array<any> = []
    @Input() columnsData: Array<any> = [];
    @Input() disable: boolean = false;

    @Output() updateAvailableList = new EventEmitter<any>();
    @Output() updateSelectedList = new EventEmitter<any>();

    public itemsToBeAdded: Array<any>
    public itemsToBeRemoved: Array<any>

    constructor() { }

    ngOnInit(): void {
        this.itemsToBeAdded = []
        this.itemsToBeRemoved = []
    }

    ngOnChanges(changes: SimpleChanges): void {
        
    }

    onAddItemsSubmit() {
        const newAvailableList = this.availableList.filter((item) => !this.itemsToBeAdded.includes(item))
        const newSelectedList = this.selectedList.concat(this.itemsToBeAdded)
        this.itemsToBeAdded = []

        this.updateAvailableList.emit(newAvailableList)
        this.updateSelectedList.emit(newSelectedList)
    }

    onRemoveItemsSubmit() {
        const newAvailableList = this.availableList.concat(this.itemsToBeRemoved)
        const newSelectedList = this.selectedList.filter((item) => !this.itemsToBeRemoved.includes(item))
        this.itemsToBeRemoved = []

        this.updateAvailableList.emit(newAvailableList)
        this.updateSelectedList.emit(newSelectedList)
    }

    onUpdateItemsToBeAdded(item) {
        if (this.itemsToBeAdded.some((data) => data.id === item.id)) {
            this.itemsToBeAdded = this.itemsToBeAdded.filter((data) => data.id !== item.id)
        } else {
            this.itemsToBeAdded = this.itemsToBeAdded.concat(item)
        }
    }

    onUpdateItemsToBeRemoved(item) {
        if (this.itemsToBeRemoved.some((data) => data.id === item.id)) {
            this.itemsToBeRemoved = this.itemsToBeRemoved.filter((data) => data.id !== item.id)
        } else {
            this.itemsToBeRemoved = this.itemsToBeRemoved.concat(item)
        }
    }

}
