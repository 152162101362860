<div class="modal-header">
  <h4 class="modal-title">{{ title || defaultTitle }}</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCancelClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div class="modal-body" tabindex="0" ngbAutofocus *blockUI="'confirm-modal'">
  <form class="form" (ngSubmit)="handleConfirmClick()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <p>{{ message || defaultMessage }}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          type="submit"
          rippleEffect
          class="btn {{ isDelete ? 'btn-danger' : 'btn-primary' }} mr-1"
        >
          {{ 'Confirm' | translate }}
        </button>
        <button
          type="button"
          rippleEffect
          class="btn btn-outline-secondary"
          (click)="handleCancelClick()"
        >
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
