import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as AuditLogAction from './actions';
import { AuditLogService } from "./service";

@Injectable()
export class AuditLogEffect {

    constructor(
        private action: Actions,
        private auditLogService: AuditLogService
    ) { }

    GetAuditLogs = createEffect(() => this.action.pipe(
        ofType(type.GET_AUDIT_LOGS),
        switchMap((data: any) => this.auditLogService.getAuditLogs(data.payload)),
        map((data: Array<any>) => new AuditLogAction.SetAuditLogs(data)
        )
    ));

    GetFilteredAuditLogs = createEffect(() => this.action.pipe(
        ofType(type.GET_FILTERED_AUDIT_LOGS),
        switchMap((data: any) => this.auditLogService.getFilteredAuditLogs(data.payload)),
        map((data: Array<any>) => new AuditLogAction.SetAuditLogs(data)
        )
    ));

    DownloadSavedReportByIdAsCsv = createEffect(() => this.action.pipe(
        ofType(type.DOWNLOAD_AUDIT_LOG_CSV),
        switchMap((data: any) => this.auditLogService.downloadAuditLogCsv(data.payload)),
        map((data: any) => new AuditLogAction.DownloadAuditLogCsvResponse(data))
    ));
}
