import { NgModule } from "@angular/core";

import { AuditLogService } from "./audit-logs/service";
import { EnquiryService } from "./enquiry/service";
import { BillingService } from "./billing/service";
import { MeterService } from "./meter/service";

@NgModule({
    providers: [
        AuditLogService,
        EnquiryService,
        BillingService,
        MeterService
    ]
})

export class TrackingServiceModule { }
