import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as VisionAiDocumentsAction from './actions';
import { VisionAiService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class VisionAiEffect {
    constructor(
        private action: Actions,
        private visionAiService: VisionAiService
    ) { }

    GetAllVisionAiDocuments = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_VISION_AI_DOCUMENTS),
        switchMap(() => this.visionAiService.getAllVisionAiDocuments()),
        map((data: Array<any>) => new VisionAiDocumentsAction.SetAllVisionAiDocuments(data)
        )
    ));

    GetVisionAiDocumentById = createEffect(() => this.action.pipe(
        ofType(type.GET_VISION_AI_DOCUMENT_BY_ID),
        switchMap((data: any) => this.visionAiService.getVisionAiDocumentById(data.payload)),
        map((data: any) => new VisionAiDocumentsAction.SetVisionAiDocumentById(data)
        )
    ));

    AddVisionAiDocument = createEffect(() => this.action.pipe(
        ofType(type.ADD_VISION_AI_DOCUMENT),
        switchMap((data: any) => this.visionAiService.addVisionAiDocument(data.payload)),
        map((data: IServerResponse) => new VisionAiDocumentsAction.AddVisionAiDocumentResponse(data)
        )
    ));

    DeleteVisionAiDocument = createEffect(() => this.action.pipe(
        ofType(type.DELETE_VISION_AI_DOCUMENT),
        switchMap((data: any) => this.visionAiService.deleteVisionAiDocument(data.payload)),
        map((data: IServerResponse) => new VisionAiDocumentsAction.DeleteVisionAiDocumentResponse(data)
        )
    ));

    SaveVisionAiDocumentAsNote = createEffect(() => this.action.pipe(
        ofType(type.SAVE_VISION_AI_DOCUMENT_AS_NOTE),
        switchMap((data: any) => this.visionAiService.saveVisionAiDocumentAsNote(data.payload)),
        map((data: IServerResponse) => new VisionAiDocumentsAction.SaveVisionAiDocumentAsNoteResponse(data)
        )
    ));

    RunVisionAiExtractionFromSummary = createEffect(() => this.action.pipe(
        ofType(type.RUN_VISION_AI_EXTRACTION_FROM_SUMMARY),
        switchMap((data: any) => this.visionAiService.runVisionAiExtractionFromSummary(data.payload)),
        map((data: IServerResponse) => new VisionAiDocumentsAction.RunVisionAiExtractionFromSummaryResponse(data)
        )
    ));
}
