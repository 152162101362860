import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { createCalendarEventResponse } from 'app/store/home/my-tasks/store.module';
import {
    CreateCalendarEvent,
    CreateCalendarEventResponse
} from 'app/store/home/my-tasks/actions';
import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonUtil } from 'app/utils/common.util';
import { formatDate } from '@angular/common';
import { helperValues } from 'app/store/common/commonStore.module';

@Component({
    selector: 'app-create-calendar-event-modal',
    templateUrl: './create-calendar-event-modal.component.html',
    styleUrls: ['./create-calendar-event-modal.component.scss']
})
export class CreateCalendarEventModalComponent implements OnInit, OnDestroy {
    @BlockUI('create-calendar-event-modal') reassignStatusModalBlockUI: NgBlockUI;

    @Input() taskDetails: any;
    @Input() handleCloseClick: Function;
    @Output() handleDestroy: Function;

    public createCalendarEventForm: FormGroup;
    public hourOptions: any;
    public minuteOptions: any;

    formSubmitted: boolean = false;
    unsubscribe = new Subject();

    constructor(private store: Store, private commonUtil: CommonUtil) {}

    ngOnInit(): void {
        this.createCalendarEventForm = new FormGroup({
            startTime: new FormControl(null, Validators.required),
            duration: new FormGroup({
                hour: new FormControl(null),
                minute: new FormControl(null)
            })
        });

        this.store
            .select(createCalendarEventResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.store.dispatch(new CreateCalendarEventResponse(null));
                    if (resp.status) {
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.SUCCESS,
                                message: resp.message
                            })
                        );

                        this.onCancelClick();
                        this.reassignStatusModalBlockUI.stop();
                    } else {
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: resp.message
                            })
                        );
                    }
                }
            });

        this.store
            .select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.hourOptions = resp?.taskHour;
                    this.minuteOptions = resp?.taskMinute;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.createCalendarEventForm.reset();
        this.handleDestroy();
    }

    get ReactiveFormControl() {
        return this.createCalendarEventForm.controls;
    }
    
    get ReactiveFormValues() {
        return this.createCalendarEventForm.getRawValue();
    }

    get TodaysDate() {
        return formatDate(new Date().getTime(), 'yyyy-MM-dd', 'en') + 'T00:00';
    }

    get MaxDate() {
        return (
            formatDate(this.taskDetails.dueDate, 'yyyy-MM-dd', 'en') + 'T00:00'
        );
    }

    CheckDurationError() {
        const hour = Boolean(JSON.parse(this.ReactiveFormValues.duration.hour));
        const minute = Boolean(
            JSON.parse(this.ReactiveFormValues.duration.minute)
        );
        if (!hour && !minute) {
            this.ReactiveFormControl.duration.setErrors({ invalid: true });
            return true;
        } else {
            this.ReactiveFormControl.duration.setErrors(null);
            return false;
        }
    }

    onSubmitClick() {
        this.formSubmitted = true;

        const taskStatusDetails = cloneDeep({ ...this.ReactiveFormValues });
        taskStatusDetails.startTime = this.commonUtil.convertDateTimeToMillis(
            taskStatusDetails.startTime
        );

        if (!this.taskDetails?.taskDuration) {
            this.CheckDurationError();
            taskStatusDetails.duration = {
                hour: Number(taskStatusDetails?.duration?.hour) || 0,
                minute: Number(taskStatusDetails?.duration?.minute) || 0
            }
        } else {
            taskStatusDetails.duration = {
                hour: Number(this.taskDetails?.duration?.hour) || 0,
                minute: Number(this.taskDetails?.duration?.minute) || 0
            }
        }

        if (this.createCalendarEventForm.invalid) {
            return;
        }
        this.reassignStatusModalBlockUI.start();
        this.store.dispatch(
            new CreateCalendarEvent({
                ...taskStatusDetails,
                id: this.taskDetails?.id
            })
        );
    }

    onCancelClick() {
        this.createCalendarEventForm.reset();
        this.handleCloseClick();
    }
}
