// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    // Old Dev
    // apiUrl: ' http://20.0.0.193:9802',
    // kaiApiUrl: 'https://aiservertest.legalai.ltd',
    // chatbotApiUrl: 'http://20.0.0.193:7000/webhooks/rest/webhook',

    // New Dev
    // apiUrl: 'http://40.81.136.65:9801',
    // kaiApiUrl: 'http://40.81.136.65:9801',
    // chatbotApiUrl: 'http://40.81.136.65:7000/webhooks/rest/webhook',
    apiUrl: 'https://dev.server.kaicase.co.uk',
    kaiApiUrl: 'https://dev.server.kaicase.co.uk',
    chatbotApiUrl: 'https://dev.bot.kaicase.co.uk/webhooks/rest/webhook',
    publicKey: `-----BEGIN PUBLIC KEY-----
                MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1Tt47rdVu+u0gbZqdkN7TpdyZ/OrUHAW9AW8GUtx7m3ZaJMXE73VLartKTZ8KCafSWBXWOBp7bV2Mfcg7bPMxL2ZwXI6OAjgZr+OrO2Q85rbucHUU+L1Uv6fBBFBO28JURTJHA6gDI+Uo33dSnzdYitZZMwLtTEoAtfOaPmCx6PnaCLWFVFCtV1k959XDYbeFQ0df/kznVkNrNEyhgT5PPjaha6vTKWOv7xqE0NBzMqWrk1/FUYVsy2AYiswakNFxEB2BZhdX32s0b/jErU8rJEGj6v199/42dIH0Iikv2cqJOLVOqaG19IEA6fFChGYgRNHo3YLoLQ3ZSpqxS9fLwIDAQAB
                -----END PUBLIC KEY-----`,
    defaultKeySize: '2048'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
