import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as RolesAction from './actions';
import { RolesService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class RolesEffect {
    constructor(
        private action: Actions,
        private rolesService: RolesService
    ) { }

    GetAllRoles = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_ROLES),
        switchMap(() => this.rolesService.getAllRoles()),
        map((data: Array<any>) => new RolesAction.SetAllRoles(data)
        )
    ));

    GetRoleById = createEffect(() => this.action.pipe(
        ofType(type.GET_ROLE_BY_ID),
        switchMap((data: any) => this.rolesService.getRoleById(data.payload)),
        map((data: any) => new RolesAction.SetRoleById(data)
        )
    ));

    AddRole = createEffect(() => this.action.pipe(
        ofType(type.ADD_ROLE),
        switchMap((data: any) => this.rolesService.addRole(data.payload)),
        map((data: IServerResponse) => new RolesAction.AddRoleResponse(data)
        )
    ));

    UpdateRole = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_ROLE),
        switchMap((data: any) => this.rolesService.updateRole(data.payload)),
        map((data: IServerResponse) => new RolesAction.UpdateRoleResponse(data)
        )
    ));

    DeleteRole = createEffect(() => this.action.pipe(
        ofType(type.DELETE_ROLE),
        switchMap((data: any) => this.rolesService.deleteRole(data.payload)),
        map((data: IServerResponse) => new RolesAction.DeleteRoleResponse(data)
        )
    ));
}
