<div class="modal-header">
  <h4 class="modal-title">{{ 'Add Project' }}</h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'add-project-modal'"
>
  <form class="form" [formGroup]="projectForm">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <h5 class="card-title" *ngIf="title">
              {{ title }}
            </h5>

            <div class="row">
              <div class="col-12" *ngIf="ReactiveFormControl.name">
                <div class="form-group required">
                  <label for="name" class="control-label">{{
                    'Project Name' | translate
                  }}</label>
                  <input
                    id="name"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.name.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Project Name' | translate }}"
                    formControlName="name"
                  />
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.name.errors.required">
                      {{ 'ErrorRequiredField' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  (click)="onSubmitClick()"
                >
                  {{ 'Submit' | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="handleCloseClick()"
                >
                  {{ 'Cancel' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
