import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AskElieComponent } from './ask-elie/ask-elie.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        AskElieComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        CoreCommonModule,
        TranslateModule.forChild()
    ],
    exports: [
        AskElieComponent
    ]
})
export class ChatbotModule { }
