import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TOASTR_TYPE } from 'app/constants/common.constant';
import { PresentToastr } from 'app/store/common/actions';

import { TranslateService } from '@ngx-translate/core';

import { checkFilesSize } from 'app/utils/commonFunctions.util';
import { v4 as uuidv4 } from 'uuid';
import { helperValues } from 'app/store/common/commonStore.module';

@Component({
    selector: 'app-upload-files-for-extraction',
    templateUrl: './upload-files-for-extraction.component.html',
    styleUrls: ['./upload-files-for-extraction.component.scss']
})
export class UploadFilesForExtractionComponent implements OnInit, OnDestroy {
    @Input() title: String = ''
    @Input() showFilesSelection: boolean = true;
    @Input() multipleFileSelection: boolean = true;
    @Input() handleCloseClick: Function;
    @Input() handleSubmitClick: Function;

    public filesToBeUploaded = [];

    public extractionModelOptions = [];
    public extractionModelSelected = null;

    private unsubscribe = new Subject();

    constructor(
        private store: Store,
        private _translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.store.select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (resp) => {
                    if (resp) {
                        const { speechToTextAction = [] } = resp;

                        if (this.showFilesSelection) {
                            this.extractionModelOptions = speechToTextAction;
                            const summaryOptionId = speechToTextAction.find(({ id, name }) => String(id).toLowerCase() === "summary" || String(name).toLowerCase() === "summary")?.id
                            this.extractionModelSelected = summaryOptionId || this.extractionModelOptions?.[0]?.id || null;
                        } else {
                            this.extractionModelOptions = speechToTextAction.filter(({ id, name }) => String(id).toLowerCase() !== "summary" || String(name).toLowerCase() !== "summary");
                            const summaryOptionId = speechToTextAction.find(({ id, name }) => String(id).toLowerCase() === "generic" || String(name).toLowerCase() === "generic")?.id
                            this.extractionModelSelected = summaryOptionId || this.extractionModelOptions?.[0]?.id || null;
                        }
                    }
                }
            )
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.filesToBeUploaded = [];
    }

    onFilesAdd({ newFiles, controlName }) {
        if (newFiles) {
            const filesArray = Array.from(newFiles)
            const { sizeValidity } = checkFilesSize({ filesObjArr: filesArray, upperLimitInMB: 20, _translateService: this._translateService })

            if (sizeValidity) {
                if (this.multipleFileSelection) {
                    const userAttachments = this.filesToBeUploaded || [];

                    this.filesToBeUploaded = userAttachments.concat(filesArray.map((fileItem: any) => ({
                        id: `new-${uuidv4()}`,
                        name: fileItem.name,
                        url: fileItem
                    })))
                } else {
                    this.filesToBeUploaded = filesArray.map((fileItem: any) => ({
                        id: `new-${uuidv4()}`,
                        name: fileItem.name,
                        url: fileItem
                    }));
                }
            } else {
                this.store.dispatch(new PresentToastr({
                    type: TOASTR_TYPE.WARNING,
                    message: this._translateService.instant("Files should not be larger than 20MB")
                }))
            }
        }
        (<HTMLInputElement>document.getElementById(controlName)).value = null;
    }

    onFileRemove({ fileId, controlName }) {
        const userAttachments = this.filesToBeUploaded || []

        this.filesToBeUploaded = userAttachments.filter(({ id }) => id !== fileId)
    }

    onSubmitClick() {
        this.handleSubmitClick(this.filesToBeUploaded.map(({ url }) => url), this.extractionModelSelected);
    }
}
