import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as ClientsAction from './actions';
import { ClientsService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class ClientsEffect {
    constructor(
        private action: Actions,
        private clientsService: ClientsService
    ) { }

    GetAllClients = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_CLIENTS),
        switchMap(() => this.clientsService.getAllClients()),
        map((data: Array<any>) => new ClientsAction.SetAllClients(data))
    ));

    GetClientById = createEffect(() => this.action.pipe(
        ofType(type.GET_CLIENT_BY_ID),
        switchMap((data: any) => this.clientsService.getClientById(data.payload)),
        map((data: any) => new ClientsAction.SetClientById(data))
    ));

    GetMattersByClientId = createEffect(() => this.action.pipe(
        ofType(type.GET_MATTERS_BY_CLIENT_ID),
        switchMap((data: any) => this.clientsService.getMattersByClientId(data.payload)),
        map((data: any) => new ClientsAction.SetMattersByClientId(data))
    ));

    GetClientsByField = createEffect(() => this.action.pipe(
        ofType(type.GET_CLIENTS_BY_FIELD),
        switchMap((data: any) => this.clientsService.searchByName(data.payload)),
        map((data) => new ClientsAction.SetClientsByField(data))
    ));

    UpdateClient = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_CLIENT),
        switchMap((data: any) => this.clientsService.updateClient(data.payload)),
        map((data: IServerResponse) => new ClientsAction.UpdateClientResponse(data))
    ));

    DeleteClient = createEffect(() => this.action.pipe(
        ofType(type.DELETE_CLIENT),
        switchMap((data: any) => this.clientsService.deleteClient(data.payload)),
        map((data: IServerResponse) => new ClientsAction.DeleteClientResponse(data))
    ));
}
