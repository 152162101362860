import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    AfterViewInit,
    AfterViewChecked
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';

import { CommonUtil } from 'app/utils/common.util';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { deleteCalendarEventResponse } from 'app/store/operation/calendar/store.module';
// import { helperValues } from 'app/store/common/commonStore.module';
import {
    DeleteCalendarEvent,
    DeleteCalendarEventResponse,
    GetCalendarData
} from 'app/store/operation/calendar/actions';
import { PresentToastr } from 'app/store/common/actions';
import {
    CONFIRM_DELETE_MESSAGE,
    CONFIRM_DELETE_TITLE,
    TOASTR_TYPE,
    CALENDAR_EVENT_TYPES_OPTIONS, CALENDAR_EVENT_TASK_MODULES_OPTIONS
} from 'app/constants/common.constant';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModuleAccessValue } from 'app/interface';
import { EMAIL_REGEX } from 'app/utils/regexPatterns.util';
import { GetAllUsers } from 'app/store/setup/users/actions';
import { GetAllEnquiries } from 'app/store/tracking/enquiry/actions';
import { GetAllMatters } from 'app/store/operation/matters/actions';
import { allUsers } from 'app/store/setup/users/store.module';
import { allMatters } from 'app/store/operation/matters/store.module';
import { allEnquiries } from 'app/store/tracking/enquiry/store.module';
import { allFirms } from 'app/store/setup/firms/store.module';
import { allClientsWithPreferredContactTypeAndTime } from 'app/store/operation/clients/store.module';
import { GetAllFirms } from 'app/store/setup/firms/actions';
import { GetAllClients } from 'app/store/operation/clients/actions';

@Component({
    selector: 'app-calendar-event-modal',
    templateUrl: './calendar-event-modal.component.html',
    styleUrls: ['./calendar-event-modal.component.scss']
})
export class CalendarEventModalComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    @Input() moduleAccess: ModuleAccessValue;
    @Input() IsEventTabEditable: boolean = false;
    @Input() isHomeCalendar: boolean = false;
    @Input() title: String = '';
    @Input() eventModalRef: any;
    @Input() calendarEventForm: FormGroup;
    @Input() handleSubmitClick: Function;
    @Input() handleCloseClick: Function;

    public modalRef: any;
    formSubmitted: boolean = false;

    public currentDateTime: string;

    public moduleOptions: Array<any> = [];
    public taskModuleOptions: Array<any> = [];
    public eventTypeOptions: Array<any> = [];
    public attendees: Array<any> = [];

    public userList: Array<any> = [];
    public matterList: Array<any> = [];
    public enquiryList: Array<any> = [];
    public firmList: Array<any> = [];
    public clientList: Array<any> = [];

    public attendee: string = '';

    unsubscribe = new Subject();

    constructor(
        private store: Store,
        private router: Router,
        private modalService: NgbModal,
        private commonUtil: CommonUtil,
        private _translateService: TranslateService,
        private cdRef: ChangeDetectorRef
    ) {
        this.eventTypeOptions = CALENDAR_EVENT_TYPES_OPTIONS.slice();
        this.taskModuleOptions = CALENDAR_EVENT_TASK_MODULES_OPTIONS.slice();
    }

    ngOnInit(): void {
        this.store.dispatch(new GetAllFirms());
        this.store.dispatch(new GetAllClients());
        this.store.dispatch(new GetAllUsers());
        this.store.dispatch(new GetAllEnquiries());
        this.store.dispatch(new GetAllMatters());

        this.currentDateTime = new Date().toISOString().slice(0, 16);

        if (this.ReactiveFormValues?.id) {
            this.attendees = this.ReactiveFormValues?.meetingDetails?.attendees;
        } else {
            const userDetails = this.commonUtil.getUserDetail();

            this.calendarEventForm.patchValue({ firmId: userDetails?.firmId });
        }

        this.calendarEventForm
            .get('startTime')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.validateEndTime();
            });

        this.calendarEventForm
            .get('endTime')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.validateEndTime();
            });

        // this.store
        //     .select(helperValues)
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe((resp) => {
        //         if (resp) {
        //             this.taskModuleOptions = (resp.auditModules || []).filter(module => this.moduleIds.includes(module.id));
        //         }
        //     });

        this.store
            .select(allUsers)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.userList = this.addNameInObjectArray(resp);
                }
            });

        this.store
            .select(allMatters)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.matterList = resp;
                    if (this.ReactiveFormValues?.id && this.ReactiveFormValues?.kaiTaskDetails?.taskModule === 'MATTER') {
                        this.assignModuleOptions(
                            this.ReactiveFormValues?.kaiTaskDetails?.taskModule
                        );
                    }
                }
            });

        this.store
            .select(allEnquiries)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.enquiryList = resp;
                    if (this.ReactiveFormValues?.id && this.ReactiveFormValues?.kaiTaskDetails?.taskModule === 'ENQUIRY') {
                        this.assignModuleOptions(
                            this.ReactiveFormValues?.kaiTaskDetails?.taskModule
                        );
                    }
                }
            });

        this.store
            .select(allFirms)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.firmList = resp;

                    if (this.ReactiveFormValues?.id && this.ReactiveFormValues?.kaiTaskDetails?.taskModule === 'FIRM') {
                        this.assignModuleOptions(
                            this.ReactiveFormValues?.kaiTaskDetails?.taskModule
                        );
                    }
                }
            });

        this.store
            .select(allClientsWithPreferredContactTypeAndTime)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.clientList = resp;
                    if (this.ReactiveFormValues?.id && this.ReactiveFormValues?.kaiTaskDetails?.taskModule === 'CLIENT') {
                        this.assignModuleOptions(
                            this.ReactiveFormValues?.kaiTaskDetails?.taskModule
                        );
                    }
                }
            });

        this.store
            .select(deleteCalendarEventResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    if (resp.status) {
                        this.store.dispatch(new GetCalendarData());

                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.SUCCESS,
                                message: resp.message
                            })
                        );

                        this.modalRef.close();
                        this.eventModalRef.close();
                        if (this.isHomeCalendar) {
                            this.router.navigate(['/operation/home']);
                        } else {
                            this.router.navigate(['/operation/systemCalendar']);
                        }
                    } else {
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: resp.message
                            })
                        );
                    }
                    this.store.dispatch(new DeleteCalendarEventResponse(null));
                }
            });

        // update meeting details if eventType is MEETING
        this.ReactiveFormControl.eventType.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((eventTypeUpdated: any) => {
                if (eventTypeUpdated === "MEETING") {
                    const userDetails = this.commonUtil.getUserDetail();
                    this.calendarEventForm.get("meetingDetails")?.patchValue({
                        syncType: "OUTLOOK",
                        organizer: userDetails?.email
                    });

                    this.KaiTaskDetailsFormControl.taskModule.patchValue(null);
                    this.KaiTaskDetailsFormControl.taskModule.clearValidators();
                } else if (eventTypeUpdated === "KAI_TASK") {
                    this.KaiTaskDetailsFormControl.taskModule.setValidators(Validators.required);

                    this.calendarEventForm.get("meetingDetails")?.patchValue({
                        syncType: null,
                        organizer: null
                    });
                }
                this.KaiTaskDetailsFormControl.taskModule.updateValueAndValidity();
            });

        // moduleId not required if taskModule is MISC
        this.KaiTaskDetailsFormControl.taskModule.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((taskModuleUpdated: any) => {
                if (!taskModuleUpdated || taskModuleUpdated === 'MISC') {
                    this.KaiTaskDetailsFormControl.moduleId.patchValue(null);
                    this.KaiTaskDetailsFormControl.moduleId.clearValidators();
                } else {
                    this.KaiTaskDetailsFormControl.moduleId.setValidators(
                        Validators.required
                    );
                }
                this.KaiTaskDetailsFormControl.moduleId.updateValueAndValidity();
            });
    }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.calendarEventForm.reset({ eventColor: "#0A2746" });
    }

    get ReactiveFormControl() {
        return this.calendarEventForm.controls;
    }

    get ReactiveFormValues() {
        return this.calendarEventForm.getRawValue();
    }

    get MeetingDetailsFormControl() {
        return this.calendarEventForm.get('meetingDetails')['controls'];
    }

    get KaiTaskDetailsFormControl() {
        return this.calendarEventForm.get('kaiTaskDetails')['controls'];
    }
    get KaiTaskDetailsFormValues() {
        return this.ReactiveFormValues.kaiTaskDetails || {};
    }

    get IsEventDeletable() {
        return this.ReactiveFormValues?.id && this.moduleAccess?.['canDelete'];
    }

    get showModuleIdSelection() {
        const taskModule = this.KaiTaskDetailsFormValues.taskModule;
        return this.ReactiveFormValues.eventType === 'KAI_TASK' && taskModule && !['MISC', 'FIRM'].includes(taskModule);
    }

    addNameInObjectArray(arr) {
        if (arr?.length) {
            return arr.map((item: any) => ({
                ...item,
                name: `${item.firstName ? `${item.firstName} ` : ''}`
            }));
        }

        return arr;
    }

    validateEndTime() {
        const startTime = this.calendarEventForm.get('startTime')?.value;
        const endTime = this.calendarEventForm.get('endTime')?.value;

        if (startTime && endTime && new Date(endTime) < new Date(startTime)) {
            this.calendarEventForm
                .get('endTime')
                ?.setErrors({ endBeforeStart: true });
        } else {
            this.calendarEventForm.get('endTime')?.setErrors(null);
        }
    }

    onEventTypeChange(e) {
        // this.calendarEventForm.get('kaiTaskDetails').reset();
        // this.calendarEventForm.get('meetingDetails').reset();

        this.attendee = '';
        this.attendees = [];
    }

    onTaskModuleChange(e) {
        this.assignModuleOptions(e?.id, true);
    }

    assignModuleOptions(moduleType, isModuleTypeChanged?) {
        if (isModuleTypeChanged === true) {
            this.calendarEventForm
                .get('kaiTaskDetails.moduleId')
                ?.patchValue(null);
        }

        const currentModuleType =
            moduleType || this.ReactiveFormValues.moduleType;
        switch (currentModuleType) {
            case 'ENQUIRY':
                this.moduleOptions = this.addModuleNameInModules(
                    this.enquiryList,
                    'ENQUIRY'
                );
                this.assignDefaultModuleOption(this.moduleOptions, 'Enquiry');
                break;
            case 'MATTER':
                this.moduleOptions = this.addModuleNameInModules(
                    this.matterList,
                    'MATTER'
                );
                this.assignDefaultModuleOption(this.moduleOptions, 'Matter');
                break;
            case 'CLIENT':
                this.moduleOptions = this.addModuleNameInModules(
                    this.clientList,
                    'CLIENT'
                );
                this.assignDefaultModuleOption(this.moduleOptions, 'Client');
                break;
            case 'FIRM':
                this.calendarEventForm.get('kaiTaskDetails').patchValue({
                    moduleId: this.commonUtil.getUserDetail('firmId')
                });
                this.moduleOptions = [];
                break;
            case 'MISC':
                this.moduleOptions = [];
                break;
            default:
                this.moduleOptions = [];
        }
    }

    assignDefaultModuleOption(moduleOption, defaultModuleName) {
        if (!moduleOption?.length) {
            this.moduleOptions = [
                {
                    id: defaultModuleName,
                    moduleName: defaultModuleName
                }
            ];
        }
    }

    addModuleNameInModules(arr: any, moduleType) {
        if (arr?.length) {
            return arr.map((item) => {
                let moduleName = '';

                switch (moduleType) {
                    case 'MATTER':
                        moduleName =
                            item?.matterDetails?.matterReference ||
                            item?.enquiryReference ||
                            item?.id;
                        break;
                    case 'ENQUIRY':
                        moduleName = item?.enquiryReference || item?.id;
                        break;
                    case 'CLIENT':
                        moduleName = item?.name || item?.id;
                        break;
                    default:
                        return item;
                }

                return {
                    ...item,
                    moduleName
                };
            });
        }

        return arr;
    }

    addAttendee() {
        if (this.attendee.trim()) {
            if (EMAIL_REGEX.test(this.attendee)) {
                if (this.attendees.includes(this.attendee)) {
                    this.store.dispatch(
                        new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: `Duplicate email address: ${this.attendee}`
                        })
                    );
                } else {
                    // this.attendees.push(this.attendee);
                    this.attendees = [...this.attendees, this.attendee];
                    this.attendee = '';

                    this.MeetingDetailsFormControl.attendees.patchValue(
                        this.attendees
                    );
                }
            } else {
                this.store.dispatch(
                    new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: `Invalid email address: ${this.attendee}`
                    })
                );
            }
        } else {
            this.store.dispatch(
                new PresentToastr({
                    type: TOASTR_TYPE.ERROR,
                    message: `Please enter email address`
                })
            );
        }
    }

    removeAttendee(e, targetIndex) {
        e.preventDefault();

        this.attendees = this.attendees.filter(
            (attendee, index) => index !== targetIndex
        );
    }

    validateAttendees() {
        const attendees = this.MeetingDetailsFormControl?.attendees?.value;

        const meetingDetailsFormGroup = this.calendarEventForm.get(
            'meetingDetails'
        ) as FormGroup;
        const attendeesControl = meetingDetailsFormGroup.get(
            'attendees'
        ) as FormControl;

        if (this.isValidAttendees(attendees)) {
            attendeesControl?.setErrors(null);
        } else {
            attendeesControl?.setErrors({ required: true });
        }
        attendeesControl?.markAsTouched();
    }

    isValidAttendees(attendees) {
        return (
            attendees !== null &&
            attendees?.length &&
            this.attendees !== null &&
            this.attendees?.length
        );
    }

    onSubmitClick() {
        this.formSubmitted = true;

        if (this.calendarEventForm.invalid) {
            return;
        }

        const eventDetails = cloneDeep(this.ReactiveFormValues);
        if (eventDetails.eventType === 'MEETING') {
            eventDetails.meetingDetails.attendees = this.attendees;
            eventDetails.kaiTaskDetails = null;
        } else if (eventDetails.eventType === 'KAI_TASK') {
            eventDetails.meetingDetails = null;
        }

        this.handleSubmitClick(eventDetails);
    }

    onCancelClick() {
        this.calendarEventForm.reset({ eventColor: "#0A2746" });
        this.handleCloseClick();
    }

    onDeleteClick(deleteId) {
        this.openDeleteConfirmModal(deleteId);
    }

    openDeleteConfirmModal(deleteId) {
        this.modalRef = this.modalService.open(ConfirmModalComponent, {
            windowClass: 'modal',
            centered: true
        });

        this.modalRef.componentInstance.title =
            this._translateService.instant(CONFIRM_DELETE_TITLE);
        this.modalRef.componentInstance.message =
            this._translateService.instant(CONFIRM_DELETE_MESSAGE);

        this.modalRef.componentInstance.handleConfirmClick = () => {
            this.store.dispatch(new DeleteCalendarEvent(deleteId));
        };
        this.modalRef.componentInstance.handleCancelClick = () => this.modalRef.close();
    }
}
