import { initialState, EnquiryState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_ENQUIRIES, SET_ENQUIRY_BY_ID, ADD_ENQUIRY_RESPONSE, UPDATE_ENQUIRY_RESPONSE,
    DELETE_ENQUIRY_RESPONSE, SET_ENQUIRY_EXTRACTIONS,
    SET_ENQUIRY_BY_FIELD
} from "./actionTypes";

export function EnquiryStateReducer(state = initialState, action: Actions): EnquiryState {
    const { type } = action;

    switch (type) {
        case SET_ALL_ENQUIRIES:
            return Object.assign({}, state, {
                allEnquiries: action.payload
            });
        case SET_ENQUIRY_BY_FIELD:
            return Object.assign({}, state, {
                enquiriesByField: action.payload
            });
        case SET_ENQUIRY_BY_ID:
            return Object.assign({}, state, {
                enquiryById: action.payload
            });
        case ADD_ENQUIRY_RESPONSE:
            return Object.assign({}, state, {
                addEnquiryResponse: action.payload
            });
        case UPDATE_ENQUIRY_RESPONSE:
            return Object.assign({}, state, {
                updateEnquiryResponse: action.payload
            });
        case DELETE_ENQUIRY_RESPONSE:
            return Object.assign({}, state, {
                deleteEnquiryResponse: action.payload
            });
        case SET_ENQUIRY_EXTRACTIONS:
            return Object.assign({}, state, {
                enquiryExtractions: action.payload
            });

        default:
            return state;
    }
}
