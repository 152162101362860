import { initialState, VisionAiState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_VISION_AI_DOCUMENTS, SET_VISION_AI_DOCUMENT_BY_ID, ADD_VISION_AI_DOCUMENT_RESPONSE,
    DELETE_VISION_AI_DOCUMENT_RESPONSE, SAVE_VISION_AI_DOCUMENT_AS_NOTE_RESPONSE,
    RUN_VISION_AI_EXTRACTION_FROM_SUMMARY_RESPONSE
} from "./actionTypes";

export function VisionAiStateReducer(state = initialState, action: Actions): VisionAiState {
    const { type } = action;

    switch (type) {
        case SET_ALL_VISION_AI_DOCUMENTS:
            return Object.assign({}, state, {
                allVisionAiDocuments: action.payload
            });
        case SET_VISION_AI_DOCUMENT_BY_ID:
            return Object.assign({}, state, {
                visionAiDocumentById: action.payload
            });
        case ADD_VISION_AI_DOCUMENT_RESPONSE:
            return Object.assign({}, state, {
                addVisionAiDocumentResponse: action.payload
            });
        case DELETE_VISION_AI_DOCUMENT_RESPONSE:
            return Object.assign({}, state, {
                deleteVisionAiDocumentResponse: action.payload
            });
        case SAVE_VISION_AI_DOCUMENT_AS_NOTE_RESPONSE:
            return Object.assign({}, state, {
                saveVisionAiDocumentAsNoteResponse: action.payload
            });
        case RUN_VISION_AI_EXTRACTION_FROM_SUMMARY_RESPONSE:
            return Object.assign({}, state, {
                runVisionAiExtractionFromSummaryResponse: action.payload
            });

        default:
            return state;
    }
}
