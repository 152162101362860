import { initialState, CalendarState } from './state';
import { Actions } from './actions';
import {
    ADD_CALENDAR_EVENT_RESPONSE,
    CALENDAR_DATA,
    SET_CURRENT_EVENT,
    SYNC_CALENDAR_EVENT_RESPONSE,
    UPDATE_CALENDAR_EVENT_RESPONSE,
    SET_CALENDAR_DATA_BY_ID,
    DELETE_CALENDAR_EVENT_RESPONSE
} from './actionTypes';

export function CalendarReducer(
    state = initialState,
    action: Actions
): CalendarState {
    switch (action.type) {
        case CALENDAR_DATA:
            return Object.assign({}, state, {
                calendarEvent: action.payload
            });
        case SET_CALENDAR_DATA_BY_ID:
            return Object.assign({}, state, {
                calendarDataById: action.payload
            });
        case SET_CURRENT_EVENT:
            return Object.assign({}, state, {
                currentEvent: action.payload
            });
        case ADD_CALENDAR_EVENT_RESPONSE:
            return Object.assign({}, state, {
                addCalendarEventResponse: action.payload
            });
        case UPDATE_CALENDAR_EVENT_RESPONSE:
            return Object.assign({}, state, {
                updateCalendarEventResponse: action.payload
            });
        case SYNC_CALENDAR_EVENT_RESPONSE:
            return Object.assign({}, state, {
                syncCalendarDataResponse: action.payload
            });
        case DELETE_CALENDAR_EVENT_RESPONSE:
            return Object.assign({}, state, {
                deleteCalendarEventResponse: action.payload
            });
        default:
            return state;
    }
}
