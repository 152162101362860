import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { createSelector, StoreModule } from "@ngrx/store";
import { AuditLogEffect } from "./effect";
import { AuditLogReducer } from "./reducer";
import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([AuditLogEffect]),
        StoreModule.forFeature('auditLogs', AuditLogReducer)
    ]
})

export class AuditLogStoreModule { }

export const auditLogs = (store: any) => store && store.auditLogs && store.auditLogs.auditLogs;
export const downloadAuditLogCsvResponse = (store: any) => store && store.auditLogs && store.auditLogs.downloadAuditLogCsvResponse;

export const auditLogsWithModuleActionAndExecuter = createSelector(
    auditLogs, helperValues, (list, values) => {
        const module = values?.auditModules || [];
        const action = values?.auditAction || [];
        const executer = values?.auditExecuter || [];
        return (list || []).map(item => {
            const moduleId = item.modules;
            const actionId = item.action;
            const executerId = item?.executer || '';
            const executerNameValue = item?.executerName || '';
            const executerEmailValue = item?.executerEmail || '';
            const executerNameEmail = executerNameValue || executerEmailValue
                ? `${executerId ? `${executerId}: ` : ''}${executerNameValue}${executerEmailValue ? ` (${executerEmailValue})` : ''}`
                : executerId
                    ? `${executerId}`
                    : '';
            return {
                ...item,
                executerNameEmail,
                moduleValue: module.find(({ id }) => id === moduleId)?.name || moduleId || '',
                actionValue: action.find(({ id }) => id === actionId)?.name || actionId || ''
            }
        })
    }
)
