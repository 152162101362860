export const GET_ALL_USERS = "GET_ALL_USERS";
export const SET_ALL_USERS = "SET_ALL_USERS";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const SET_USER_BY_ID = "SET_USER_BY_ID";

export const ADD_USER = "ADD_USER";
export const ADD_USER_RESPONSE = "ADD_USER_RESPONSE";

export const ADD_GUEST_USER = "ADD_GUEST_USER";
export const ADD_GUEST_USER_RESPONSE = "ADD_GUEST_USER_RESPONSE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_RESPONSE = "UPDATE_USER_RESPONSE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_RESPONSE = "DELETE_USER_RESPONSE";

export const GET_USER_PROFILE_BY_ID = "GET_USER_PROFILE_BY_ID";
export const SET_USER_PROFILE_BY_ID = "SET_USER_PROFILE_BY_ID";

export const UPDATE_USER_PROFILE_BY_ID = "UPDATE_USER_PROFILE_BY_ID";
export const UPDATE_USER_PROFILE_BY_ID_RESPONSE = "UPDATE_USER_PROFILE_BY_ID_RESPONSE";

export const GET_USER_BY_FIELD = "GET_USER_BY_FIELD";
export const SET_USER_BY_FIELD = "SET_USER_BY_FIELD";
