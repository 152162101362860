<div class="mb-2">
  <div *ngIf="!canOverrideDocument">
    <div
      class="file-drop-upload-zone d-none d-md-inline-block"
      *ngIf="selectedFiles.length === 0 && !multiple"
      [ngClass]="{
        disabled: disabled
      }"
    >
      <span [data-feather]="'arrow-up'" class="file-drop-upload-icon"></span>
      <br />
      <span>{{ "Drop file(s) to Upload" | translate }}</span>
      <br />
      <span class="my-1">{{ "OR" | translate }}</span>
      <br />
      <button
        type="button"
        rippleEffect
        class="btn btn-sm btn-primary"
        (click)="triggerFileSelection()"
        [disabled]="disabled"
      >
        {{
          multiple ? ("Choose Files" | translate) : ("Choose File" | translate)
        }}
      </button>
    </div>

    <div class="d-md-none">
      <div>
        <label for="files">{{ "File" | translate }}</label>
      </div>
      <button
        type="button"
        rippleEffect
        class="btn btn-sm btn-primary"
        (click)="triggerFileSelection()"
        [disabled]="disabled"
      >
        {{
          multiple ? ("Choose Files" | translate) : ("Choose File" | translate)
        }}
      </button>
    </div>

    <input
      [multiple]="multiple"
      [id]="inputId"
      type="file"
      class="d-none"
      (change)="onFilesChange($event.target.files)"
    />
  </div>

  <div *ngIf="canOverrideDocument && selectedFiles.length === 0 && !multiple">
    <button
      type="button"
      rippleEffect
      class="btn btn-sm btn-primary"
      (click)="onManualCheckClick()"
      [disabled]="disabled"
    >
      {{
        multiple ? ("Choose Files" | translate) : ("Choose File" | translate)
      }}
    </button>
  </div>

  <div>
    <div
      *ngFor="let file of selectedFiles; let i = index"
      class="d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          role="button"
          class="btn btn-link btn-sm text-primary download-enabled"
          (click)="openDoc($event, file)"
          *ngIf="!file.id?.includes('new') && !hidePreviewButton"
          [ngbTooltip]="'View' | translate"
        >
          <span data-feather="eye"></span>
        </div>
        <!-- <div
          role="button"
          class="btn btn-link btn-sm text-primary download-enabled"
          (click)="downloadAttachment($event, file)"
          *ngIf="!file.id?.includes('new')"
          [ngbTooltip]="'Download' | translate"
        >
          <span data-feather="download"></span>
      </div> -->

        {{ file.name }}
      </div>

      <div>
        <button
          *ngIf="canOverrideDocument"
          type="button"
          class="btn btn-link btn-sm text-primary"
          (click)="onManualCheckClick()"
          [ngbTooltip]="'Manual Check' | translate"
        >
          <span data-feather="arrow-up-circle"></span>
        </button>

        <button
          type="button"
          class="btn btn-link btn-sm text-danger"
          (click)="onFileRemove(file.id)"
          [ngbTooltip]="'Delete' | translate"
          *ngIf="!disabled"
        >
          <span data-feather="x-circle"></span>
        </button>
      </div>
    </div>
  </div>
</div>
