import { cloneDeep } from 'lodash';

// (Array, String) => Array
const SortListAscending = (listArray = [], keyName, dataType = "string") => {
    const sortedList = cloneDeep(listArray);
    if (sortedList && sortedList.length) {
        sortedList.sort((first, second) => {
            switch (dataType) {
                case "number": {
                    return keyName ? first[keyName] - second[keyName] : first - second;
                }
                case "string":
                default: {
                    let firstValue = (keyName ? first[keyName] : first)?.toLowerCase();
                    let secondValue = (keyName ? second[keyName] : second)?.toLowerCase();

                    if (firstValue < secondValue) {
                        return -1;
                    }
                    if (firstValue > secondValue) {
                        return 1;
                    }
                    return 0;
                }
            }
        })
    }
    return sortedList || [];
}

// (Array, String) => Array
const sortListDescending = (listArray = [], keyName, dataType = "string") => {
    const sortedList = cloneDeep(listArray);
    sortedList.sort((first, second) => {
        switch (dataType) {
            case "number": {
                return keyName ? second[keyName] - first[keyName] : second - first;
            }
            case "string":
            default: {
                let firstValue = (keyName ? first[keyName] : first)?.toLowerCase();
                let secondValue = (keyName ? second[keyName] : second)?.toLowerCase();

                if (firstValue < secondValue) {
                    return 1;
                }
                if (firstValue > secondValue) {
                    return -1;
                }
                return 0;
            }
        }
    })

    return sortedList
}

export {
    SortListAscending, sortListDescending
}
