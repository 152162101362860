<div class="modal-header bg-primary">
  <h4 class="modal-title text-white">{{ "Register As Guest" }}</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCloseClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'register-modal'"
>
  <form class="form" [formGroup]="registrationForm">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <h5 class="card-title" *ngIf="title">
              {{ title }}
            </h5>

            <div class="row">
              <div class="col-12" *ngIf="ReactiveFormControl.salutation">
                <div class="form-group required">
                  <label for="salutation" class="control-label">{{
                    "Title" | translate
                  }}</label>
                  <ng-select
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.salutation.errors
                    }"
                    id="salutation"
                    name="salutation"
                    [items]="salutationOptions"
                    bindLabel="name"
                    bindValue="id"
                    formControlName="salutation"
                    [closeOnSelect]="true"
                  >
                  </ng-select>
                  <div
                    *ngIf="
                      formSubmitted && ReactiveFormControl.salutation.errors
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.salutation.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" *ngIf="ReactiveFormControl.firstName">
                <div class="form-group required">
                  <label for="firstName" class="control-label">{{
                    "Full Name" | translate
                  }}</label>
                  <input
                    id="firstName"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.firstName.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Full Name' | translate }}"
                    formControlName="firstName"
                  />
                  <div
                    *ngIf="
                      formSubmitted && ReactiveFormControl.firstName.errors
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.firstName.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-12" *ngIf="ReactiveFormControl.firstName">
                <div class="form-group required">
                  <label for="firstName" class="control-label">{{
                    'First Name' | translate
                  }}</label>
                  <input
                    id="firstName"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.firstName.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'First Name' | translate }}"
                    formControlName="firstName"
                  />
                  <div
                    *ngIf="
                      formSubmitted && ReactiveFormControl.firstName.errors
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.firstName.errors.required">
                      {{ 'ErrorRequiredField' | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" *ngIf="ReactiveFormControl.lastName">
                <div class="form-group">
                  <label for="lastName" class="control-label">{{
                    'Last Name' | translate
                  }}</label>
                  <input
                    id="lastName"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.lastName.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Last Name' | translate }}"
                    formControlName="lastName"
                  />
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.lastName.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.lastName.errors.required">
                      {{ 'ErrorRequiredField' | translate }}
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="col-12" *ngIf="ReactiveFormControl.email">
                <div class="form-group required">
                  <label for="email" class="control-label">{{
                    "Email Address" | translate
                  }}</label>
                  <input
                    id="email"
                    type="text"
                    [ngClass]="{
                      'is-invalid':
                        formSubmitted && ReactiveFormControl.email.errors
                    }"
                    class="form-control"
                    placeholder="{{ 'Email Address' | translate }}"
                    formControlName="email"
                  />
                  <div
                    *ngIf="formSubmitted && ReactiveFormControl.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="ReactiveFormControl.email.errors.required">
                      {{ "ErrorRequiredField" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  (click)="onSubmitClick()"
                >
                  {{ "Submit" | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="handleCloseClick()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
