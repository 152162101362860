<div class="modal-header">
  <h4 class="modal-title">{{ "Document Signature Test" | translate }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCloseClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <div class="card rounded-0 mb-0">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <app-file-drop-upload
                inputId="documentsToBeSigned"
                [selectedFiles]="documentsToBeSigned"
                (updateSelectedFiles)="onFilesAdd($event)"
                (removeFile)="onFileRemove($event)"
                [multiple]="false"
              ></app-file-drop-upload>
            </div>

            <div class="col-12 mb-2">
              <label for="clientId" class="control-label">
                {{ "Client Id" | translate }}
              </label>
              <input
                id="clientId"
                type="text"
                class="form-control"
                placeholder="{{ 'Client Id' | translate }}"
                [(ngModel)]="clientId"
              />
            </div>

            <div class="col-12 mb-2">
              <label for="documentSignModule" class="control-label">
                {{ "Document Sign Module" | translate }}
              </label>
              <input
                id="documentSignModule"
                type="text"
                class="form-control"
                placeholder="{{ 'Document Sign Module' | translate }}"
                [(ngModel)]="documentSignModule"
              />
            </div>

            <div class="col-12 mb-2">
              <label for="moduleId" class="control-label">
                {{ "Module Id" | translate }}
              </label>
              <input
                id="moduleId"
                type="text"
                class="form-control"
                placeholder="{{ 'Module Id' | translate }}"
                [(ngModel)]="moduleId"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-right">
              <button
                type="button"
                rippleEffect
                class="btn btn-primary mr-1"
                (click)="onSubmitClick()"
                [disabled]="!documentsToBeSigned?.length"
              >
                {{ "Submit" | translate }}
              </button>
              <button
                type="button"
                rippleEffect
                class="btn btn-outline-secondary"
                (click)="handleCloseClick()"
              >
                {{ "Cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
