import { initialState, SpeechAiState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_SPEECH_AI_DOCUMENTS, SET_SPEECH_AI_DOCUMENT_BY_ID, ADD_SPEECH_AI_DOCUMENT_RESPONSE,
    DELETE_SPEECH_AI_DOCUMENT_RESPONSE, SAVE_SPEECH_AI_DOCUMENT_AS_NOTE_RESPONSE,
    RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY_RESPONSE
} from "./actionTypes";

export function SpeechAiStateReducer(state = initialState, action: Actions): SpeechAiState {
    const { type } = action;

    switch (type) {
        case SET_ALL_SPEECH_AI_DOCUMENTS:
            return Object.assign({}, state, {
                allSpeechAiDocuments: action.payload
            });
        case SET_SPEECH_AI_DOCUMENT_BY_ID:
            return Object.assign({}, state, {
                speechAiDocumentById: action.payload
            });
        case ADD_SPEECH_AI_DOCUMENT_RESPONSE:
            return Object.assign({}, state, {
                addSpeechAiDocumentResponse: action.payload
            });
        case DELETE_SPEECH_AI_DOCUMENT_RESPONSE:
            return Object.assign({}, state, {
                deleteSpeechAiDocumentResponse: action.payload
            });
        case SAVE_SPEECH_AI_DOCUMENT_AS_NOTE_RESPONSE:
            return Object.assign({}, state, {
                saveSpeechAiDocumentAsNoteResponse: action.payload
            });
        case RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY_RESPONSE:
            return Object.assign({}, state, {
                runSpeechAiExtractionFromSummaryResponse: action.payload
            });

        default:
            return state;
    }
}
