import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MODULE_KEY } from 'app/constants/module.constant';
import { ModuleAccessValue } from 'app/interface';
import { Location } from '@angular/common';
import { UserAccessUtil } from 'app/utils/userAccess.util';
import { getSettingsFormFields } from '../data-connector-modal/data-connector-forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-data-connector-modal',
  templateUrl: './data-connector-modal.component.html',
  styleUrls: ['./data-connector-modal.component.scss']
})
export class DataConnectorModalComponent implements OnInit {
  @BlockUI('settings-modal') confirmModalBlockUI: NgBlockUI;
  public moduleAccess: ModuleAccessValue;
  public settingsForm: FormGroup;
  public authTypeValues: Array<any> = [
    { id: 1, name: 'API Key Authentication' },
    { id: 2, name: 'Basic Authentication' },
    { id: 3, name: 'OAuth 2.0 (Authorization Code Grant)' },
  ];
  selectedAuthType = this.authTypeValues[0]?.id;
  public apiKeyAuth = false;
  public basicAuth = false;
  public oAuthACG = false;
  public pingSuccessful = false;
  public loading = false;
  public formSubmitted = false;

  @Input() title: string;
  @Input() handleCloseClick: Function;
  @Output() testConnectivity = new EventEmitter<void>();

  constructor(
    private userAccessUtil: UserAccessUtil,
    private location: Location,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.moduleAccess = this.userAccessUtil.getUserAccessForModule(MODULE_KEY.MATTERS);
    this.settingsForm = this.formBuilder.group(getSettingsFormFields(this.selectedAuthType));
  }

  updateValidators(authType: number): void {
    const newFields = getSettingsFormFields(authType);
    
    Object.keys(newFields).forEach(fieldName => {
        const control = this.settingsForm.get(fieldName);
        if (control) {
            control.setValidators(newFields[fieldName][1]);
        } else {
            this.settingsForm.addControl(fieldName, this.formBuilder.control('', newFields[fieldName][1]));
        }
    });

    Object.keys(this.settingsForm.controls).forEach(controlName => {
        if (!newFields[controlName]) {
            this.settingsForm.removeControl(controlName);
        }
    });

    this.settingsForm.updateValueAndValidity();
}

  async submit() {
    this.formSubmitted = true;
    if (this.settingsForm.invalid) {
      return;
    }
    this.settingsForm.markAllAsTouched();
  }

  get SettingsFormControl() {
    return this.settingsForm.controls;
  }

  onAuthTypeChange(selectedValue: { id: number }): void {
    this.selectedAuthType = selectedValue.id;
    this.updateValidators(selectedValue.id);

    this.apiKeyAuth = this.selectedAuthType === 1;
    this.basicAuth = this.selectedAuthType === 2;
    this.oAuthACG = this.selectedAuthType === 3;

    this.settingsForm.get('authType').updateValueAndValidity();
    this.settingsForm.markAllAsTouched();
}

  cancel() {
    this.handleCloseClick();
  }

  onTestConnectivityClick() {
    this.testConnectivity.emit();
  }
}
