import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AuthenticationService } from 'app/auth/service';

import { Logout } from 'app/store/core/actions';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isFetchingNewToken = false;
    private newTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private store: Store,
        private _authenticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (request?.url?.includes("renew-token")) {
                    this.store.dispatch(new Logout(false));
                    // throwError
                    const error = err.error || err.statusText;
                    return throwError(error);
                } else {
                    switch (String(err.status)) {
                        case "401": {
                            if (!this.isFetchingNewToken) {
                                this.isFetchingNewToken = true;
                                this.newTokenSubject.next(null);

                                return this._authenticationService.getRefreshToken().pipe(
                                    switchMap((token: string) => {
                                        this.isFetchingNewToken = false;
                                        if (token) {
                                            this.newTokenSubject.next(token);
                                            return next.handle(this.addTokenToRequest(request, token));
                                        } else {
                                            this.isFetchingNewToken = false;
                                            this.store.dispatch(new Logout(false));
                                            return throwError(new Error("Error in getRefreshToken"));
                                        }
                                    }),
                                    catchError((err) => {
                                        this.isFetchingNewToken = false;
                                        this.store.dispatch(new Logout(false));
                                        return throwError(err);
                                    })
                                );
                            } else {
                                return this.newTokenSubject.pipe(
                                    filter((token) => token !== null),
                                    take(1),
                                    switchMap((token) => {
                                        return next.handle(this.addTokenToRequest(request, token!));
                                    })
                                );
                            }
                        }
                        case "403": {
                            this.store.dispatch(new Logout(false));

                            // throwError
                            const error = err.error || err.statusText;
                            return throwError(error);
                        }
                        default:
                            // throwError
                            const error = err.error || err.statusText;
                            return throwError(error);
                    }
                }
            })
        );
    }

    private addTokenToRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
