import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { FileService } from '@core/services/file.service';

import { TOASTR_TYPE } from 'app/constants/common.constant';
import { PresentToastr } from 'app/store/common/actions';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentViewerModalComponent } from '../modal';

@Component({
    selector: 'app-file-drop-upload',
    templateUrl: './file-drop-upload.component.html',
    styleUrls: ['./file-drop-upload.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FileDropUploadComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() groupName: string = "";
    @Input() inputId: string = "file-drop-upload";
    @Input() selectedFiles: Array<any> = [];
    @Input() multiple: boolean = true;
    @Input() disabled: boolean = false;

    @Output() updateSelectedFiles = new EventEmitter<any>();
    @Output() removeFile = new EventEmitter<any>();
    @Output() openDocViewerModal = new EventEmitter<any>();

    public modalRef: any;

    constructor(
        private _fileService: FileService,
        private store: Store,
        private _translateService: TranslateService,
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        // console.log("FileDropUploadComponent ngOnInit");
    }

    ngAfterViewInit(): void {
        const parentNode = (<HTMLElement>document.getElementById(this.inputId).parentNode)
        const dropZone = (<HTMLCollection>parentNode?.getElementsByClassName("file-drop-upload-zone"))

        if (dropZone[0]) {
            dropZone[0].addEventListener("dragenter", (e: any) => {
                e.preventDefault();
                e.stopPropagation();

                dropZone[0].classList.add("active")
            });
            dropZone[0].addEventListener("dragover", (e: any) => {
                e.preventDefault();
                e.stopPropagation();

                dropZone[0].classList.add("active")
            });

            dropZone[0].addEventListener("dragleave", (e: any) => {
                e.preventDefault();
                e.stopPropagation();

                dropZone[0].classList.remove("active")
            });

            dropZone[0].addEventListener("drop", (e: any) => {
                e.preventDefault();
                e.stopPropagation();

                dropZone[0].classList.remove("active")
                this.onFilesChange(e?.dataTransfer?.files || [])
            });
        }
    }

    ngOnDestroy(): void {
        const parentNode = (<HTMLElement>document.getElementById(this.inputId)?.parentNode)
        const dropZone = (<HTMLCollection>parentNode?.getElementsByClassName("file-drop-upload-zone"))

        if (dropZone?.[0]) {
            dropZone[0].removeAllListeners();
        }
    }

    triggerFileSelection() {
        document.getElementById(this.inputId).click();
    }

    onFilesChange(files) {
        const newFiles = files || []
        if (!this.multiple && newFiles.length > 1) {
            this.store.dispatch(new PresentToastr({
                type: TOASTR_TYPE.WARNING,
                message: this._translateService.instant("Only 1 file allowed!")
            }))

            return;
        }

        if (newFiles.length) {
            this.updateSelectedFiles.emit({ newFiles: newFiles, groupName: this.groupName, controlName: this.inputId });
        }
    }

    onFileRemove(fileId) {
        this.removeFile.emit({ fileId: fileId, groupName: this.groupName, controlName: this.inputId });
    }

    async openDoc(e, doc: any) {
        e.preventDefault();
        this.openDocViewerModal.emit({ event: e, doc: doc });
    }

    downloadAttachment(e, file) {
        e.preventDefault();

        this._fileService.downloadFile(file);
    }
}
