import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlUtil } from "app/utils/url.util";
import { CommonUtil } from "app/utils/common.util";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationsService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private commonUtil: CommonUtil,
        private _translateService: TranslateService
    ) {

    }

    public getNotificationsList() {
        return this.http.get(
            this.urlUtil.getUrl('notifications', 'getAll').replace('$FIRM_ID', this.commonUtil.getUserDetail('firmId')))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getNotificationsList error =>", e);
                    return of([]);
                })
            )
    }

    public getNotificationsTemplateList() {
        return this.http.get(
            this.urlUtil.getUrl('notifications', 'template'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of([]);
                })
            )
    }

    public getNotificationsById(id: string = null) {
        return this.http.get(
            this.urlUtil.getUrl('notifications', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        const respData = resp.data
                        if (respData.length) {
                            return respData[0]
                        }
                        return respData;
                    } else {
                        return null;
                    }
                }),
                catchError((e) => {
                    console.log("getNotificationsById error =>", e);
                    return of(null);
                })
            )
    }

    public searchNotificationsByFields(data) {
        return this.http.get(
            this.urlUtil.getUrl('notifications', 'searchByField').replace('$KEY', data.key).replace('$VALUE', data.value)
        )
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of(null);
                })
            )
    }

    public addNotifications(payload) {
        return this.http.post(
            this.urlUtil.getUrl('notifications', 'add'), payload)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of({
                        status: false,
                        message: this._translateService.instant("FailDataAdd")
                    });
                })
            )
    }

    public updateNotifications(data) {
        return this.http.put(
            this.urlUtil.getUrl('notifications', 'update').replace('$ID', data.id), data)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of({
                        status: false,
                        message: this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }

    public deleteNotifications(ids) {
        return this.http.post(
            this.urlUtil.getUrl('notifications', 'delete'), ids)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of({
                        status: false,
                        message: this._translateService.instant("FailDataDelete")
                    });
                })
            )
    }

    public searchByName(name = null) {
        return this.http.get(
            this.urlUtil.getUrl('notifications', 'searchByName', [{ key: 'name', value: name }]))
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of(null);
                })
            )
    }
}
