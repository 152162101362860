export const GET_MATTER_BY_FIELD = "GET_MATTER_BY_FIELD";
export const SET_MATTER_BY_FIELD = "SET_MATTER_BY_FIELD";

export const GET_ALL_MATTERS = "GET_ALL_MATTERS";
export const SET_ALL_MATTERS = "SET_ALL_MATTERS";

export const GET_MATTER_BY_ID = "GET_MATTER_BY_ID";
export const SET_MATTER_BY_ID = "SET_MATTER_BY_ID";

export const GET_MATTER_CLIENTS_BY_ID = "GET_MATTER_CLIENTS_BY_ID";
export const SET_MATTER_CLIENTS_BY_ID = "SET_MATTER_CLIENTS_BY_ID";

export const ADD_MATTER = "ADD_MATTER";
export const ADD_MATTER_RESPONSE = "ADD_MATTER_RESPONSE";

export const UPDATE_MATTER = "UPDATE_MATTER";
export const UPDATE_MATTER_RESPONSE = "UPDATE_MATTER_RESPONSE";

export const DELETE_MATTER = "DELETE_MATTER";
export const DELETE_MATTER_RESPONSE = "DELETE_MATTER_RESPONSE";

export const MANUAL_UPLOAD = "MANUAL_UPLOAD";
export const MANUAL_UPLOAD_RESPONSE = "MANUAL_UPLOAD_RESPONSE";

export const MANUAL_UPLOAD_OVERRIDE = "MANUAL_UPLOAD_OVERRIDE";
export const MANUAL_UPLOAD_OVERRIDE_RESPONSE = "MANUAL_UPLOAD_OVERRIDE_RESPONSE";
