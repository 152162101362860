import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions, Effect } from "@ngrx/effects";
import { of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import * as type from './actionTypes';
import * as calendarAction from './actions';
import { CalendarService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class CalendarEffect {

    constructor(
        private action: Actions,
        private calendarService: CalendarService
    ) { }

    GetCalendarEvent = createEffect(() => this.action.pipe(
        ofType(type.GET_CALENDAR_DATA),
        switchMap(() => this.calendarService.getCalendarEvent()),
        map((data: Array<any>) => new calendarAction.CalendarData(data)
        )
    ));

    GetCalendarDataById = createEffect(() => this.action.pipe(
        ofType(type.GET_CALENDAR_DATA_BY_ID),
        switchMap((data: any) => this.calendarService.getCalendarDataById(data.payload)),
        map((data: any) => new calendarAction.SetCalendarDataById(data)
        )
    ));

    SyncCalendarData = createEffect(() => this.action.pipe(
        ofType(type.SYNC_CALENDAR_EVENT),
        switchMap(() => this.calendarService.syncCalendarData()),
        map((data: IServerResponse) => new calendarAction.SyncCalendarDataResponse(data)
        )
    ));

    AddCalendarEvent = createEffect(() => this.action.pipe(
        ofType(type.ADD_CALENDAR_EVENT),
        switchMap((data: any) => this.calendarService.addCalendarEvent(data.payload)),
        map((data: IServerResponse) => new calendarAction.AddCalendarEventResponse(data)
        )
    ));

    UpdateCalendarEvent = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_CALENDAR_EVENT),
        switchMap((data: any) => this.calendarService.updateCalendarEvent(data.payload)),
        map((data: IServerResponse) => new calendarAction.UpdateCalendarEventResponse(data)
        )
    ));

    SyncCalendar = createEffect(() => this.action.pipe(
        ofType(type.SYNC_CALENDAR),
        switchMap(() => this.calendarService.syncCalendar())
    ));

    DeleteCalendarEvent = createEffect(() => this.action.pipe(
        ofType(type.DELETE_CALENDAR_EVENT),
        switchMap((data: any) => this.calendarService.deleteCalendarEvent(data.payload)),
        map((data: IServerResponse) => new calendarAction.DeleteCalendarEventResponse(data)
        )
    ));

}
