import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BlockUIModule } from 'ng-block-ui';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { TableSearchComponent } from './data-table/table-search/table-search.component';
import { NetworkVisTreeChartComponent } from './network-vis-tree-chart/network-vis-tree-chart.component';
import { SelectorListsComponent } from './selector-lists/selector-lists.component';
import { ToastrComponent } from './toastr/toastr.component';
import { DataTableComponent } from './data-table/data-table.component';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FilePreviewModalComponent, ConfirmModalComponent, RegisterModalComponent, SuccessModalComponent, AddProjectModalComponent, AddTaskModalComponent, CalendarEventModalComponent, CalendarSyncSettingModalComponent, UpdateTaskStatusModalComponent, CreateNewMattersModalComponent, SaveDocumentAsNoteModalComponent, CalendarEventViewModalComponent, MatterUpdateReasonModalComponent, DocumentViewerModalComponent } from './modal';
import { BarChartComponent } from './d3/bar-chart/bar-chart.component';
import { FileDropUploadComponent } from './file-drop-upload/file-drop-upload.component';
import { EmailModalComponent } from './modal/email-modal/email-modal.component';
import { UploadFileComponent } from './modal/upload-file/upload-file.component';
import { UploadFilesForExtractionComponent } from './modal/upload-files-for-extraction/upload-files-for-extraction.component';
import { TestDocumentSignatureModalComponent } from './modal/test-document-signature-modal/test-document-signature-modal.component';

import { NgxExplorerModule, DataService, NgeExplorerConfig, ConfigProvider } from 'dex-ng-file-explorer';
import { MattersFileExplorerService } from '@core/services/matters-file-explorer.service';
import { CmFileExplorerComponent } from './cm-file-explorer/cm-file-explorer.component';
import { MyTaskViewModalComponent } from './modal/my-task-modals/my-task-view-modal/my-task-view-modal.component';
import { ReAssignTaskStatusModalComponent } from './modal/my-task-modals/reassign-task-modal/reassign-task-modal.component';
import { CreateCalendarEventModalComponent } from './modal/my-task-modals/create-calendar-event-modal/create-calendar-event-modal.component';
import { DataConnectorModalComponent } from './modal/data-connector-modal/data-connector-modal.component';
import { VersionHistoryModalComponent } from './modal/version-history-modal/version-history-modal.component';
import { NgxColorsModule } from 'ngx-colors';
import { ManualIdAddressCheckModalComponent } from './modal/manual-id-address-check-modal/manual-id-address-check-modal.component';


const modalComponent = [
    FilePreviewModalComponent, ConfirmModalComponent,
    EmailModalComponent,
    UploadFileComponent,
    UploadFilesForExtractionComponent,
    TestDocumentSignatureModalComponent,
    SuccessModalComponent,
    AddProjectModalComponent,
    CalendarEventModalComponent,
    CalendarSyncSettingModalComponent,
    CreateNewMattersModalComponent,
    CalendarEventViewModalComponent,
    AddTaskModalComponent,
    UpdateTaskStatusModalComponent,
    MyTaskViewModalComponent,
    ReAssignTaskStatusModalComponent,
    CreateCalendarEventModalComponent,
    VersionHistoryModalComponent,
    MatterUpdateReasonModalComponent,
    DocumentViewerModalComponent,
    ManualIdAddressCheckModalComponent
]
@NgModule({
    declarations: [
        DataTableComponent,
        TableSearchComponent,
        NetworkVisTreeChartComponent,
        SelectorListsComponent,
        ToastrComponent,
        ...modalComponent,
        BarChartComponent,
        FileDropUploadComponent,
        RegisterModalComponent,
        SuccessModalComponent,
        AddProjectModalComponent,
        CalendarEventModalComponent,
        CalendarSyncSettingModalComponent,
        SaveDocumentAsNoteModalComponent,
        CalendarEventViewModalComponent,
        CmFileExplorerComponent,
        DataConnectorModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ContentHeaderModule,
        CardSnippetModule,
        NgxDatatableModule,
        BlockUIModule.forRoot(),
        CoreCommonModule,
        NgSelectModule,
        TranslateModule.forChild(),
        NgxExplorerModule,
        NgxColorsModule
    ],
    exports: [
        DataTableComponent,
        TableSearchComponent,
        NetworkVisTreeChartComponent,
        SelectorListsComponent,
        ToastrComponent,
        BarChartComponent,
        FileDropUploadComponent,
        CmFileExplorerComponent
    ],
    providers: [
        { provide: DataService, useClass: MattersFileExplorerService },
        {
            provide: ConfigProvider, useValue: new ConfigProvider({
                homeNodeName: 'Home',
                showFilesInSideTree: true,
                showVersionHistory: true
            } as NgeExplorerConfig)
        }
    ]
})
export class SharedModule { }
