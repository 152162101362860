import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/menu/menu';

import { locale as enLocale } from "assets/i18n/en"

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { AuthenticationService } from './auth/service';
import { Store } from '@ngrx/store';

import {
    GetUserAccess, GetHelperValues
} from './store/common/actions';
import { GetAllRoles } from './store/setup/roles/actions';

import { appConfig } from './store/common/commonStore.module';
import { CommonUtil } from './utils/common.util';
import { UserAccessUtil } from './utils/userAccess.util';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Logout } from './store/core/actions';
import { VersionService } from './version.service';
import { NavigationEnd, Router } from '@angular/router';
import { TourService } from '@feature/services/tour.service';
import { MODULE_KEY } from './constants/module.constant';
import { GetUserProfileById } from './store/setup/users/actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    coreConfig: any;
    menu: any;
    defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
    appLanguage: 'en'; // Set application default language i.e fr

    idleTimeInSeconds = 30 * 60; // how long can they be inactive before considered idle, in seconds
    idleTimeoutInSeconds = 5; // how long can they be idle before considered timed out, in seconds
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    title = 'angular-idle-timeout';

    // Private
    private helperApisCalled: boolean = false;
    private _unsubscribeAll: Subject<any>;

    /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _title: Title,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        public _coreConfigService: CoreConfigService,
        private _coreLoadingScreenService: CoreLoadingScreenService,
        private _coreMenuService: CoreMenuService,
        private _coreTranslationService: CoreTranslationService,
        private _translateService: TranslateService,
        private idle: Idle,
        private keepalive: Keepalive,
        private _authenticationService: AuthenticationService,
        private store: Store,
        private commonUtil: CommonUtil,
        private ngSelectConfig: NgSelectConfig,
        private versionService: VersionService,
        private router: Router,
        private tourService: TourService,
        private userAccessUtil: UserAccessUtil
    ) {
        // Get the application main menu
        this.menu = menu;

        // Register the menu to the menu service
        this._coreMenuService.register('main', this.menu);

        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');

        // Add languages to the translation service
        this._translateService.addLangs(['en', 'es']);
        // This language will be used as a fallback when a translation isn't found in the current language
        this._translateService.setDefaultLang('en');
        // Set the translations for the menu
        this._coreTranslationService.translate(enLocale);

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // IDLE TIMEOUT [START]
        // sets an idle timeout. after this much seconds of inactivity, the user will be considered idle.
        idle.setIdle(this.idleTimeInSeconds);
        // sets a timeout period. after this much seconds being idle, the user will be considered timed out.
        idle.setTimeout(this.idleTimeoutInSeconds);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.'
            console.log("idle.onIdleEnd", this.idleState);
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            console.log("idle.onTimeout", this.idleState);
            this.store.dispatch(new Logout(true));
        });

        idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!'
            console.log(this.idleState);
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!';
            console.log("idle.onTimeoutWarning", this.idleState);
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());

        this._authenticationService.getUserLoggedIn().subscribe((userLoggedIn) => {
            if (userLoggedIn) {
                this.reset();
                if (!this.helperApisCalled) {
                    this.store.dispatch(new GetUserAccess());
                    this.store.dispatch(new GetHelperValues());
                    this.store.dispatch(new GetAllRoles());
                    const userId = this.commonUtil.getUserDetail('id');
                    this.store.dispatch(new GetUserProfileById(userId));

                    this.helperApisCalled = true;

                    setTimeout(() => {
                        this.tourService.startTour();
                    }, 200);
                }
            } else {
                idle.stop();

                this.helperApisCalled = false;
            }
        })

        // IDLE TIMEOUT [END]

        this.store.select(appConfig)
            .subscribe((resp) => {
                if (resp) {
                    this._coreConfigService.config = resp;
                }
            })

        this.helperApisCalled = false;
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
   * On init
   */
    ngOnInit(): void {
        // Init wave effect (Ripple effect)
        Waves.init();

        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.coreConfig = config;

            // Set application language
            const userLanguage = this.commonUtil.getUserDetail('language')
            this.setLanguage(userLanguage)

            // Layout
            //--------

            // Remove default classes first
            this._elementRef.nativeElement.classList.remove(
                'vertical-layout',
                'vertical-menu-modern',
                'horizontal-layout',
                'horizontal-menu'
            );
            // Add class based on config options
            if (this.coreConfig.layout.type === 'vertical') {
                this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
            } else if (this.coreConfig.layout.type === 'horizontal') {
                this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
            }

            // Navbar
            //--------

            // Remove default classes first
            this._elementRef.nativeElement.classList.remove(
                'navbar-floating',
                'navbar-static',
                'navbar-sticky',
                'navbar-hidden'
            );

            // Add class based on config options
            if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
                this._elementRef.nativeElement.classList.add('navbar-static');
            } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
                this._elementRef.nativeElement.classList.add('navbar-sticky');
            } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
                this._elementRef.nativeElement.classList.add('navbar-floating');
            } else {
                this._elementRef.nativeElement.classList.add('navbar-hidden');
            }

            // Footer
            //--------

            // Remove default classes first
            this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

            // Add class based on config options
            if (this.coreConfig.layout.footer.type === 'footer-sticky') {
                this._elementRef.nativeElement.classList.add('footer-fixed');
            } else if (this.coreConfig.layout.footer.type === 'footer-static') {
                this._elementRef.nativeElement.classList.add('footer-static');
            } else {
                this._elementRef.nativeElement.classList.add('footer-hidden');
            }

            // Blank layout
            if (
                this.coreConfig.layout.menu.hidden &&
                this.coreConfig.layout.navbar.hidden &&
                this.coreConfig.layout.footer.hidden
            ) {
                this._elementRef.nativeElement.classList.add('blank-page');
                // ! Fix: Transition issue while coming from blank page
                this._renderer.setAttribute(
                    this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                    'style',
                    'transition:none'
                );
            } else {
                this._elementRef.nativeElement.classList.remove('blank-page');
                // ! Fix: Transition issue while coming from blank page
                setTimeout(() => {
                    this._renderer.setAttribute(
                        this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                        'style',
                        'transition:300ms ease all'
                    );
                }, 0);
                // If navbar hidden
                if (this.coreConfig.layout.navbar.hidden) {
                    this._elementRef.nativeElement.classList.add('navbar-hidden');
                }
                // Menu (Vertical menu hidden)
                if (this.coreConfig.layout.menu.hidden) {
                    this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
                } else {
                    this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
                }
                // Footer
                if (this.coreConfig.layout.footer.hidden) {
                    this._elementRef.nativeElement.classList.add('footer-hidden');
                }
            }

            // Skin Class (Adding to body as it requires highest priority)
            if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
                this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
                this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
            }
        });

        // Set the application page title
        this._title.setTitle(this.coreConfig.app.appTitle);

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd),
            takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.versionService.checkForVersionUpdate();
            });
    }

    /**
   * On destroy
   */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    reset() {
        // console.log("Idle watcher started at", new Date());
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    setLanguage(newLanguage?) {
        const nextLanguage = newLanguage || this.coreConfig.app.appLanguage || 'en';
        this._translateService.use(nextLanguage)

        // ng-select translations
        this.ngSelectConfig.notFoundText = this._translateService.instant("No items found");
        this.ngSelectConfig.placeholder = this._translateService.instant("Select");

        // change language in index.html
        document.getElementsByTagName('html')[0].lang = newLanguage;
    }
}
