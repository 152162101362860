import { User } from "app/auth/models";
import { IServerResponse } from "app/interface";

export interface CoreState {
    userDetail: User;
    forgotPasswordResp: IServerResponse,
    resetPasswordResp: IServerResponse,
    updateUserDetailResponse: any
}

export const initialState: CoreState = {
    userDetail: null,
    forgotPasswordResp: null,
    resetPasswordResp: null,
    updateUserDetailResponse: null
}
