import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_SPEECH_AI_DOCUMENTS, SET_ALL_SPEECH_AI_DOCUMENTS, GET_SPEECH_AI_DOCUMENT_BY_ID, SET_SPEECH_AI_DOCUMENT_BY_ID,
    ADD_SPEECH_AI_DOCUMENT, ADD_SPEECH_AI_DOCUMENT_RESPONSE, DELETE_SPEECH_AI_DOCUMENT, DELETE_SPEECH_AI_DOCUMENT_RESPONSE,
    SAVE_SPEECH_AI_DOCUMENT_AS_NOTE, SAVE_SPEECH_AI_DOCUMENT_AS_NOTE_RESPONSE,
    RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY, RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY_RESPONSE
} from "./actionTypes"

export class GetAllSpeechAiDocuments implements Action {
    readonly type = GET_ALL_SPEECH_AI_DOCUMENTS;
}

export class SetAllSpeechAiDocuments implements Action {
    readonly type = SET_ALL_SPEECH_AI_DOCUMENTS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetSpeechAiDocumentById implements Action {
    readonly type = GET_SPEECH_AI_DOCUMENT_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetSpeechAiDocumentById implements Action {
    readonly type = SET_SPEECH_AI_DOCUMENT_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddSpeechAiDocument implements Action {
    readonly type = ADD_SPEECH_AI_DOCUMENT;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class AddSpeechAiDocumentResponse implements Action {
    readonly type = ADD_SPEECH_AI_DOCUMENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteSpeechAiDocument implements Action {
    readonly type = DELETE_SPEECH_AI_DOCUMENT;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray
    }
}

export class DeleteSpeechAiDocumentResponse implements Action {
    readonly type = DELETE_SPEECH_AI_DOCUMENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class SaveSpeechAiDocumentAsNote implements Action {
    readonly type = SAVE_SPEECH_AI_DOCUMENT_AS_NOTE;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class SaveSpeechAiDocumentAsNoteResponse implements Action {
    readonly type = SAVE_SPEECH_AI_DOCUMENT_AS_NOTE_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class RunSpeechAiExtractionFromSummary implements Action {
    readonly type = RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class RunSpeechAiExtractionFromSummaryResponse implements Action {
    readonly type = RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions = GetAllSpeechAiDocuments | SetAllSpeechAiDocuments | GetSpeechAiDocumentById | SetSpeechAiDocumentById |
    AddSpeechAiDocument | AddSpeechAiDocumentResponse | DeleteSpeechAiDocument | DeleteSpeechAiDocumentResponse |
    SaveSpeechAiDocumentAsNote | SaveSpeechAiDocumentAsNoteResponse | RunSpeechAiExtractionFromSummary | RunSpeechAiExtractionFromSummaryResponse;
