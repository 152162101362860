export const locale = {
    "lang": "en",
    "code": "en",
    "data": {
        "Dashboard": "Dashboard",
        "Matter": "Matter",
        "Matters": "Matters",
        "State Count": "State Count",
        "Risk State": "Risk State",
        "Client Signed Documents And IDs Requested": "Client Signed Documents And IDs Requested",
        "Insurance Verifications Awaiting": "Insurance Verifications Awaiting",
        "Legal Documents Uploaded": "Legal Documents Uploaded",
        "Matters Created": "Matters Created",
        "Onboarding Completed": "Onboarding Completed",
        "Total": "Total",
        "On Track": "On Track",
        "Delayed": "Delayed",
        "Dropped": "Dropped",
        "In Risk": "In Risk",
        "DataAddSuccess": "Data added successfully.",
        "FailDataAdd": "Data was not added.",
        "DataUpdateSuccess": "Data updated successfully.",
        "FailDataUpdate": "Data was not updated.",
        "DataDeleteSuccess": "Data deleted successfully.",
        "FailDataDelete": "Data was not deleted.",
        "FilesUploadSuccess": "File(s) uploaded successfully.",
        "FilesUploadFailure": "File(s) was not uploaded.",
        "ExecuteActionSuccess": "Action executed successfully.",
        "ExecuteActionFailure": "Action was not executed.",
        "Add": "Add",
        "Edit": "Edit",
        "Name": "Name",
        "CaseDescription": "Case Description",
        "Email": "Email",
        "Address": "Address",
        "MatterReference": "Matter Reference",
        "EnquiryReference": "Enquiry Reference",
        "ClientName": "Client Name",
        "ClientReference": "Client Reference",
        "ErrorRequiredField": "This field is required",
        "ErrorRequiredName": "Name is required",
        "ErrorRequiredEmail": "Email is required",
        "ErrorRequiredMatterReference": "Matter Reference is required",
        "ErrorRequiredClientReference": "Client Reference is required",
        "ErrorRequiredDescription": "Description is required",
        "ErrorInvalidEmail": "Invalid email address",
        "ErrorInvalidDate": "Invalid Date",
        "ErrorGeneral": "Something went wrong, please try again later!",
        "AllowNotApplicable": "NA if not available",
        "Drop file(s) to Upload": "Drop file(s) to Upload",
        "OR": "OR",
        "Choose Files": "Choose Files",
        "Files should not be larger than 20MB": "Files should not be larger than 20MB",
        "Files should not be larger than 100MB": "Files should not be larger than 100MB",
        "Size is less than": "Size is less than {{limit}}MB",
        "Size is more than": "Size is less than {{limit}}MB",
        "ClientCareLetter": "Client Care Letter",
        "ConsentForm": "Consent Form",
        "TermsAndConditions": "Terms And Conditions",
        "cfaDocument": "CFA Document",
        "ClientIdDocument": "Client Id Document",
        "InsuranceDocument": "Insurance Document",
        "ComplianceDocument": "Compliance Document",
        "OtherDocuments": "Other Documents",
        "MatterState": "Matter State",
        "CreatedDate": "Created Date",
        "UpdatedDate": "Updated Date",
        "Client Info": "Client Info",
        "Matter Info": "Matter Info",
        "Legal Info": "Legal Info",
        "RefreshList": "Refresh List",
        "Confirm": "Confirm",
        "Cancel": "Cancel",
        "Confirmation": "Confirmation",
        "Are you sure?": "Are you sure?",
        "Delete Confirmation": "Delete Confirmation",
        "Are you sure you want to delete selected record(s)?": "Are you sure you want to delete selected record(s)?",
        "Some record(s) were not deleted, please verify!": "Some record(s) were not deleted, please verify!",
        "MatterDocumentsUploadError": "Please reupload {{documentTypes}} documents in Matter Details!",
        "Pre Action Protocol": "Pre Action Protocol",
        "Conversation Notes": "Conversation Notes",
        "Pre Action Info": "Pre Action Info",
        "Documents": "Documents",
        "Communication Emails": "Communication Emails",
        "PapType": "Pre Action Protocol (PAP) Type",
        "PreActionState": "Pre Action State",
        "DefendantLocation": "Defendant Location",
        "DefendantEmail": "Defendant Email",
        "LocDocument": "Letter of Claim Document",
        "ExpertDocuments": "Expert Documents",
        "DefendantReplyDocuments": "Defendant Reply Documents",
        "EmailConnector": "Email Connector",
        "Subject": "Subject",
        "Text": "Text",
        "Attachments": "Attachments",
        "EmailDetails": "Email Details",
        "None": "None",
        "Logs": "Logs",
        "Date": "Date",
        "FromStatus": "From Status",
        "ToStatus": "To Status",
        "Remark": "Remark",
        "User": "User",
        "Process": "Process",
        "ConvertSpeechToText": "Convert Speech to Text",
        "Matter Details": "Matter Details",
        "PhoneNumber": "Phone Number",
        "AlternateContactDetails": "Alternate Contact Details",
        "Contact Details": "Contact Details",
        "Add Event": "Add Event",
        "Event": "Event",
        "Title": "Title",
        "Description": "Description",
        "Module Type": "Module Type",
        "Module ID": "Module ID",
        "Start Date": "Start Date",
        "End Date": "End Date",
        "End Date must be the same or after the Start Date": "End Date must be the same or after the Start Date",
        "Full Day Event": "Full Day Event",
        "Delete": "Delete",
        "Filter": "Filter",
        "View All": "View All",
        "Clear All": "Clear All",
        "Sync Setting": "Sync Setting",
        "Host": "Host",
        "Port": "Port",
        "Username": "Username",
        "Password": "Password",
        "Ping": "Ping",
        "Ping Successful": "Ping Successful",
        "Ping Failed": "Ping Failed",
        "DataConnectorCleaned": "Data Connectors cleaned successfully",
        "FailDataConnectorClean": "Failed to clean data connectors",
    }
};
