<div class="modal-header">
  <h4 class="modal-title">{{ 'EmailDetails' | translate }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCloseClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <div class="card rounded-0 mb-0">
        <div class="card-body">
          <h5 class="card-title">
            {{ 'Subject' | translate }}: {{ emailSubject }}
          </h5>

          <div class="row justify-content-between">
            <div class="col-6">
              <h6 class="card-subtitle mb-2">
                <span> {{ 'From' | translate }}: </span>
                <span>{{ mailFrom }}</span>
              </h6>
            </div>

            <div class="col-6">
              <h6 class="card-subtitle mb-2 text-right">
                {{ emailDate | date : 'dd/MM/yyyy, h:mm:ss a' }}
              </h6>
            </div>
          </div>

          <h6 class="card-subtitle mb-2 font-weight-normal">
            <span> {{ 'To' | translate }}: </span>
            <span>{{ mailTo }}</span>
          </h6>

          <p class="card-text text-justify">
            {{ emailText }}
          </p>

          <p class="mb-0">{{ 'Attachments' | translate }}:</p>
          <p *ngIf="emailAttachments?.length === 0">
            {{ 'None' | translate }}
          </p>

          <div *ngIf="emailAttachments?.length > 0">
            <div *ngFor="let attachment of emailAttachments" class="mb-0">
              <div
                role="button"
                class="btn btn-link btn-sm text-primary download-enabled"
                (click)="downloadAttachment($event, attachment)"
                [ngbTooltip]="'Download' | translate"
              >
                <span data-feather="download"></span>
              </div>
              <span>{{ attachment.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
