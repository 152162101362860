import { IServerResponse } from "app/interface";

export interface DataConnectorsState {
    allDataConnectors: Array<any>;
    cleanDataConnectorsResponse: IServerResponse;
    uploadDataConnectorsResponse: IServerResponse;
}

export const initialState: DataConnectorsState = {
    allDataConnectors: null,
    cleanDataConnectorsResponse: null,
    uploadDataConnectorsResponse: null
}
