import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlUtil } from "app/utils/url.util";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuditLogService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService
    ) { }

    public getAuditLogs(data) {
        return this.http.post(
            this.urlUtil.getUrl('auditLogs', 'get'), data)
            .pipe(
                map((resp: any) => {
                    if (resp && resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAuditLogs error =>", e);
                    return of([]);
                })
            )
    }

    public getFilteredAuditLogs(data) {
        return this.http.post(
            this.urlUtil.getUrl('auditLogs', 'filter'), data)
            .pipe(
                map((resp: any) => {
                    if (resp && resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getFilteredAuditLogs error =>", e);
                    return of([]);
                })
            )
    }

    public downloadAuditLogCsv(data) {
        return this.http.post(
            this.urlUtil.getUrl('auditLogs', 'downloadCsv'), data, { responseType: 'blob' })
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        return ({
                            status: true,
                            data: resp
                        });
                    }
                    return ({
                        status: false,
                        message: this._translateService.instant("FailReportDownload")
                    });
                }),
                catchError((e) => {
                    console.log(e);
                    return of({
                        status: false,
                        message: this._translateService.instant("FailReportDownload")
                    });
                })
            )
    }
}
