import { initialState, FirmsState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_FIRMS, SET_FIRM_BY_ID, ADD_FIRM_RESPONSE, UPDATE_FIRM_RESPONSE,
    DELETE_FIRM_RESPONSE, TEST_DOCUMENT_SIGNATURE_CONNECTIVITY_RESPONSE, SET_DOCUMENT_SIGNATURE_BY_FIELD,
    TEST_EMAIL_CONNECTIVITY_RESPONSE, SET_DOCUMENT_SIGNATURE_TRACK_DETAILS, SEND_DOCUMENT_SIGNATURE_REMINDER_RESPONSE,
    GET_LEADS_RESPONSE
} from "./actionTypes";

export function FirmsStateReducer(state = initialState, action: Actions): FirmsState {
    const { type } = action;

    switch (type) {
        case SET_ALL_FIRMS:
            return Object.assign({}, state, {
                allFirms: action.payload
            });
        case SET_FIRM_BY_ID:
            return Object.assign({}, state, {
                firmById: action.payload
            });
        case ADD_FIRM_RESPONSE:
            return Object.assign({}, state, {
                addFirmResponse: action.payload
            });
        case UPDATE_FIRM_RESPONSE:
            return Object.assign({}, state, {
                updateFirmResponse: action.payload
            });
        case GET_LEADS_RESPONSE:
            return Object.assign({}, state, {
                allLeads: action.payload
            });
        case DELETE_FIRM_RESPONSE:
            return Object.assign({}, state, {
                deleteFirmResponse: action.payload
            });
        case TEST_DOCUMENT_SIGNATURE_CONNECTIVITY_RESPONSE:
            return Object.assign({}, state, {
                testDocumentSignatureConnectivityResponse: action.payload
            });
        case TEST_EMAIL_CONNECTIVITY_RESPONSE:
            return Object.assign({}, state, {
                testEmailConnectivityResponse: action.payload
            });
        case SET_DOCUMENT_SIGNATURE_TRACK_DETAILS:
            return Object.assign({}, state, {
                documentsToBeSigned: action.payload
            });
        case SET_DOCUMENT_SIGNATURE_BY_FIELD:
            return Object.assign({}, state, {
                documentSignatureByFields: action.payload
            });
        case SEND_DOCUMENT_SIGNATURE_REMINDER_RESPONSE:
            return Object.assign({}, state, {
                sendDocumentSignatureReminderResponse: action.payload
            });
        default:
            return state;
    }
}
