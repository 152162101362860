import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of, Subscriber, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { takeUntil, catchError, map, switchMap } from "rxjs/operators";
import { IDataService, NodeContent } from 'dex-ng-file-explorer';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';
import { FileService } from "./file.service";

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { VersionHistoryModalComponent } from "app/main/shared/modal";

import { matterById } from "app/store/operation/matters/store.module";

let MOCK_FOLDERS = [
    { id: 1, name: 'Music', path: 'music' },
    { id: 2, name: 'Movies', path: 'movies' },
    { id: 3, name: 'Books', path: 'books' },
    { id: 4, name: 'Games', path: 'games' },
    { id: 5, name: 'Rock', path: 'music/rock' },
    { id: 6, name: 'Jazz', path: 'music/jazz' },
    { id: 7, name: 'Classical', path: 'music/classical' },
    { id: 15, name: 'Aerosmith', path: 'music/rock/aerosmith' },
    { id: 16, name: 'AC/DC', path: 'music/rock/acdc' },
    { id: 17, name: 'Led Zeppelin', path: 'music/rock/ledzeppelin' },
    { id: 18, name: 'The Beatles', path: 'music/rock/thebeatles' },
];

let MOCK_FILES = [
    { id: 428, name: 'notes.txt', path: '', content: 'hi, this is an example' },
    { id: 4281, name: '2.txt', path: '', content: 'hi, this is an example' },
    { id: 28, name: 'Thriller.txt', path: 'music/rock/thebeatles/thriller', content: 'hi, this is an example' },
    { id: 29, name: 'Back in the U.S.S.R.txt', path: 'music/rock/thebeatles', content: 'hi, this is an example' },
    { id: 30, name: 'All You Need Is Love.txt', path: 'music/rock/thebeatles', content: 'hi, this is an example' },
    { id: 31, name: 'Hey Jude.txt', path: 'music/rock/ledzeppelin/heyjude', content: 'hi, this is an example' },
    { id: 32, name: 'Rock And Roll All Nite.txt', path: 'music/rock/ledzeppelin/rockandrollallnight', content: 'hi, this is an example' },
];

interface ExampleNode {
    name: string;
    path: string;
    content?: string;
    id: number | string;
    url: string;
    type: string;
}

@Injectable()
export class MattersFileExplorerService implements IDataService<ExampleNode> {
    @BlockUI('matters') matterBlockUI: NgBlockUI;

    private unsubscribe = new Subject();

    private foldersSubject: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private filesSubject: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private matterIdSubject = new BehaviorSubject<any>(null);

    private modalRef: any;

    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store,
        private _fileService: FileService,
        private modalService: NgbModal,
    ) {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.store.select(matterById)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (resp) => {
                    this.updateMatterId(resp?.id || null);
                }
            );
    }


    get folders() {
        return this.foldersSubject.value;
    }
    get files() {
        return this.foldersSubject.value;
    }

    get currentMatterIdValue(): any {
        return this.matterIdSubject.getValue();
    }

    updateMatterId(newMatterId) {
        this.matterIdSubject.next(newMatterId);
    }

    download(node: ExampleNode): void {
        this.matterBlockUI.start();
        this._fileService.downloadFile({ name: node.name, id: node.url?.split("/").slice(-1)[0] });
        this.matterBlockUI.stop();
    }

    uploadFiles(node: ExampleNode, files: File[]): Observable<any> {
        const filesArray = Array.from(files);

        const results = forkJoin(filesArray.map((file: any) => {
            this.matterBlockUI.start();
            const formData = new FormData()
            formData.append("file", file)

            return this.http.post(this.urlUtil.getUrl('file', 'upload'), formData)
                .pipe(
                    switchMap((uploadResponse: any) => {
                        const { status, data, jsonData, errorMessage } = uploadResponse;
                        if (status) {
                            const { name, id, url } = data;
                            const newFileComponent = { id, parentId: node?.id || null, type: "DOCUMENT", name, url, child: [] };

                            return this.http.post(this.urlUtil.getUrl('matters', 'addFileComponentData').replace('$ID', this.currentMatterIdValue), newFileComponent)
                                .pipe(
                                    map((addResponse: any) => {
                                        this.matterBlockUI.stop();
                                        const { status, jsonData, errorMessage } = addResponse;
                                        if (status) {
                                            return true;
                                        } else {
                                            this.store.dispatch(new PresentToastr({
                                                type: TOASTR_TYPE.ERROR,
                                                message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                                            }));

                                            return false;
                                        }
                                    }),
                                    catchError((e) => {
                                        this.matterBlockUI.stop();
                                        console.log("addFileComponentData catchError", e);

                                        this.store.dispatch(new PresentToastr({
                                            type: TOASTR_TYPE.ERROR,
                                            message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                                        }))

                                        return of(false);
                                    })
                                );
                        } else {
                            this.store.dispatch(new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                            }));

                            return of(false);
                        }
                    }),
                    catchError((e) => {
                        this.matterBlockUI.stop();
                        console.log("uploadFile catchError", e);

                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                        }))

                        return of(false);
                    })
                );
        }));
        return results;
    }

    deleteNodes(nodes: ExampleNode[]): Observable<any> {
        this.matterBlockUI.start();
        const results = forkJoin(nodes.map((node: any) => this.http.delete(this.urlUtil.getUrl('matters', 'deleteFileComponentData').replace('$ID', this.currentMatterIdValue).replace('$DATA_ID', node?.id))
            .pipe(
                map((resp: any) => {
                    const { status, data, jsonData, errorMessage } = resp;
                    if (status) {
                        const path = node.path + '/';

                        const filteredFolders = this.folders.filter((f) => !f.path.startsWith(path) && f.id !== node.id);
                        this.foldersSubject.next(filteredFolders);

                        const filteredFiles = this.files.filter((f) => !f.path.startsWith(path));
                        this.filesSubject.next(filteredFiles);

                        return true;
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }))

                        return false;
                    }
                }),
                catchError((e) => {
                    console.log("deleteFileComponentData error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of(false);
                })
            )
        ));
        this.matterBlockUI.stop();
        return results;
    }

    deleteLeafs(nodes: ExampleNode[]): Observable<any> {
        this.matterBlockUI.start();
        const results = forkJoin(nodes.map((node: any) => this.http.delete(this.urlUtil.getUrl('matters', 'deleteFileComponentData').replace('$ID', this.currentMatterIdValue).replace('$DATA_ID', node?.id))
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        const filteredFiles = this.files.filter((f) => f.id !== node.id);
                        this.filesSubject.next(filteredFiles);

                        return true;
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }))

                        return false;
                    }
                }),
                catchError((e) => {
                    console.log("deleteFileComponentData error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of(false);
                })
            )
        ));
        this.matterBlockUI.stop();
        return results;
    }

    createNode(node: ExampleNode, name: string): Observable<any> {
        this.matterBlockUI.start();
        const newFolderComponent = { parentId: node?.id || null, type: "FOLDER", name, child: [] };

        return this.http.post(this.urlUtil.getUrl('matters', 'addFileComponentData').replace('$ID', this.currentMatterIdValue), newFolderComponent)
            .pipe(
                map((resp: any) => {
                    this.matterBlockUI.stop();
                    const { status, data, jsonData, errorMessage } = resp;
                    if (status) {
                        return true;
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }))

                        return (false);
                    }
                }),
                catchError((e) => {
                    this.matterBlockUI.stop();
                    console.log("addFileComponentData error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of(false);
                })
            )
    }

    getNodeChildren(node: ExampleNode): Observable<NodeContent<ExampleNode>> {
        this.matterBlockUI.start();
        return this.http.get(
            this.urlUtil.getUrl('matters', 'getFileComponentData').replace('$ID', this.currentMatterIdValue))
            .pipe(
                map((resp: any) => {
                    this.matterBlockUI.stop();
                    const { status, data, jsonData, errorMessage } = resp;
                    if (status) {
                        const formattedData = this.formatServerDataToUiData(data, "");
                        const { nodes: formattedNodes, leafs: formattedLeafs } = formattedData;

                        this.foldersSubject.next(formattedNodes);
                        this.filesSubject.next(formattedLeafs);

                        const folderPath = node?.path || '';

                        const nodes = formattedNodes.filter((f) => {
                            const paths = f.path.split('/');
                            paths.pop();
                            const filteredPath = paths.join('/');
                            return filteredPath === folderPath;
                        });

                        const leafs = formattedLeafs.filter((f) => {
                            const paths = f.path.split('/');
                            paths.pop();
                            const filteredPath = paths.join('/');
                            return filteredPath === folderPath;
                        });

                        return { leafs, nodes };
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }))

                        return ({ leafs: [], nodes: [] });
                    }
                }),
                catchError((e) => {
                    this.matterBlockUI.stop();
                    console.log("getFileComponentData error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({ leafs: [], nodes: [] });
                })
            );
    }

    renameNode(nodeInfo: ExampleNode, newName: string): Observable<any> {
        this.matterBlockUI.start();
        const newNodeInfo: any = { ...nodeInfo, name: newName };
        const { id, parentId, type, name, lastModifiedDate, url, versionHistory, child } = newNodeInfo;
        const apiRequestData: any = { id, parentId, type, name, lastModifiedDate, url, versionHistory, child }

        return this.http.put(this.urlUtil.getUrl('matters', 'updateFileComponentData').replace('$ID', this.currentMatterIdValue).replace('$DATA_ID', newNodeInfo.id), apiRequestData)
            .pipe(
                map((resp: any) => {
                    this.matterBlockUI.stop();
                    const { status, data, jsonData, errorMessage } = resp;
                    if (status) {
                        const updatedFolders = this.folders.map((f) => f.id === nodeInfo.id ? { ...f, name: newName } : f);
                        this.foldersSubject.next(updatedFolders);

                        return true;
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }))

                        return false;
                    }
                }),
                catchError((e) => {
                    this.matterBlockUI.stop();
                    console.log("updateFileComponentData error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of(false);
                })
            )
    }

    renameLeaf(leafInfo: ExampleNode, newName: string): Observable<any> {
        this.matterBlockUI.start();
        const newLeafInfo: any = { ...leafInfo, name: newName };
        const { id, parentId, type, name, lastModifiedDate, url, versionHistory, child } = newLeafInfo;
        const apiRequestData: any = { id, parentId, type, name, lastModifiedDate, url, versionHistory, child }

        return this.http.put(this.urlUtil.getUrl('matters', 'updateFileComponentData').replace('$ID', this.currentMatterIdValue).replace('$DATA_ID', newLeafInfo.id), apiRequestData)
            .pipe(
                map((resp: any) => {
                    this.matterBlockUI.stop();
                    const { status, data, jsonData, errorMessage } = resp;
                    if (status) {
                        const updatedFiles = this.folders.map((f) => f.id === leafInfo.id ? { ...f, name: newName } : f);
                        this.filesSubject.next(updatedFiles);

                        return true;
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }))

                        return false;
                    }
                }),
                catchError((e) => {
                    this.matterBlockUI.stop();
                    console.log("updateFileComponentData error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of(false);
                })
            )
    }

    formatServerDataToUiData(dataArray, parentPath) {
        const nodes = [], leafs = [];

        dataArray.forEach((item) => {
            const { type, child, name } = item || {};
            const formattedItem = {
                ...item,
                path: parentPath ? `${parentPath}/${name}` : name
            }

            switch (type) {
                case "FOLDER": {
                    nodes.push(formattedItem);
                    if (child?.length) {
                        const { nodes: nestedNodes, leafs: nestedLeafs } = this.formatServerDataToUiData(child, formattedItem.path);
                        nodes.push(...nestedNodes);
                        leafs.push(...nestedLeafs);
                    }
                    break;
                }
                case "DOCUMENT": {
                    leafs.push(formattedItem);
                    break;
                }
                default:
                    break;
            }
        })

        return { nodes, leafs };
    }

    openVersionHistory(node: ExampleNode) {
        this.modalRef = this.modalService.open(VersionHistoryModalComponent, {
            windowClass: 'modal',
            centered: true,
            size: 'lg'
        });

        this.modalRef.componentInstance.title = this._translateService.instant('Clean Data Connectors Confirmation');
        this.modalRef.componentInstance.data = node;
    }

    uploadNewDocVersion(node: ExampleNode, file: File): Observable<any> {
        this.matterBlockUI.start();

        const formData = new FormData()
        formData.append("file", file)

        return this.http.post(this.urlUtil.getUrl('file', 'upload'), formData)
            .pipe(
                switchMap((uploadResponse: any) => {
                    const { status, data, jsonData, errorMessage } = uploadResponse;
                    if (status) {
                        const { name, url } = data;
                        const updatedFileComponent = { ...node, name, url };

                        return this.http.put(this.urlUtil.getUrl('matters', 'updateFileComponentData').replace('$ID', this.currentMatterIdValue).replace('$DATA_ID', String(node.id)), updatedFileComponent)
                            .pipe(
                                map((addResponse: any) => {
                                    this.matterBlockUI.stop();
                                    const { status, jsonData, errorMessage } = addResponse;
                                    if (status) {
                                        return true;
                                    } else {
                                        this.store.dispatch(new PresentToastr({
                                            type: TOASTR_TYPE.ERROR,
                                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                                        }));

                                        return false;
                                    }
                                }),
                                catchError((e) => {
                                    this.matterBlockUI.stop();
                                    console.log("addFileComponentData catchError", e);

                                    this.store.dispatch(new PresentToastr({
                                        type: TOASTR_TYPE.ERROR,
                                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                                    }))

                                    return of(false);
                                })
                            );
                    } else {
                        this.matterBlockUI.stop();
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }));

                        return of(false);
                    }
                }),
                catchError((e) => {
                    this.matterBlockUI.stop();
                    console.log("uploadFile catchError", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of(false);
                })
            );
    }
}
