import { initialState, MeterState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_METERS, SET_METER_BY_ID, UPDATE_METER_RESPONSE, EXPORT_METER_RESPONSE, SET_METER_BY_FIELD
} from "./actionTypes";

export function MeterStateReducer(state = initialState, action: Actions): MeterState {
    const { type } = action;

    switch (type) {
        case SET_ALL_METERS:
            return Object.assign({}, state, {
                allMeters: action.payload
            });
        case SET_METER_BY_ID:
            return Object.assign({}, state, {
                meterById: action.payload
            });
        case UPDATE_METER_RESPONSE:
            return Object.assign({}, state, {
                updateMeterResponse: action.payload
            });
        case EXPORT_METER_RESPONSE:
            return Object.assign({}, state, {
                exportMeterResponse: action.payload
            });
        case SET_METER_BY_FIELD:
            return Object.assign({}, state, {
                meterByFields: action.payload
            });
        default:
            return state;
    }
}
