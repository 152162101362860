import { ClientsState, initialState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_CLIENTS, SET_CLIENT_BY_ID, UPDATE_CLIENT_RESPONSE, DELETE_CLIENT_RESPONSE,
    SET_CLIENTS_BY_FIELD,
    SET_MATTERS_BY_CLIENT_ID
} from "./actionTypes";
import { SortListAscending } from "app/utils/sort.util";

export function ClientsStateReducer(state = initialState, action: Actions): ClientsState {
    const { type } = action;

    switch (type) {
        case SET_ALL_CLIENTS:
            return Object.assign({}, state, {
                allClients: action.payload
            });
        case SET_CLIENT_BY_ID:
            return Object.assign({}, state, {
                clientById: action.payload
            });
        case SET_MATTERS_BY_CLIENT_ID:
            return Object.assign({}, state, {
                mattersByClientId: action.payload
            });
        case SET_CLIENTS_BY_FIELD:
            return Object.assign({}, state, {
                // clientsByField: SortListAscending(action.payload, "name")                clientsByField: SortListAscending(action.payload, "name")
                clientsByField: action.payload
            });
        case UPDATE_CLIENT_RESPONSE:
            return Object.assign({}, state, {
                updateClientResponse: action.payload
            });
        case DELETE_CLIENT_RESPONSE:
            return Object.assign({}, state, {
                deleteClientResponse: action.payload
            });

        default:
            return state;
    }
}
