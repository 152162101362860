<!-- hidden used instead of ngIf because it was creating issues in DOM event binding to chat text input  -->
<!-- <div class="container" *ngIf="showAskelieBot"> -->
<div class="container {{ showAskelieBot ? 'visible' : 'hidden' }}">
  <div class="widget" [class.open]="isBotOpen">
    <div id="chat-overlay-spinner">
      <div class="spinner-container">
        <img src="./assets/images/logo/logo.png" alt="Loader" />

        <!-- button to trigger ongoing ajaxReuqest -->
        <div id="cancel-btn-div" class="mt-1">
          <button class="primary-btn" id="cancel-request-btn">
            {{ "Cancel" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="chat_header">
      <!--Add the name of the bot here -->
      <button class="profile-image">
        <img class="bot-avatar" src="./assets/images/chatbot/botAvatar.jpg" />
        <span class="avatar-status-xl avatar-status-online"></span>
      </button>
      <span class="chat_header_title"><b>KAi</b></span>
      <div class="option-container">
        <div ngbDropdown container="body">
          <a
            ngbDropdownToggle
            href="javascript:void(0);"
            class="hide-arrow"
            id="dropdownBrowserState"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i data-feather="more-vertical" class="cursor-pointer mr-50"></i>
          </a>
          <div
            ngbDropdownMenu
            class="dropdown-menu-right"
            aria-labelledby="dropdownBrowserState"
          >
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="d-flex align-items-center"
              id="clear"
            >
              {{ "Clear" | translate }}
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="d-flex align-items-center"
              id="restart"
            >
              {{ "Restart" | translate }}
            </a>
            <a
              href="javascript:void(0)"
              ngbDropdownItem
              class="d-flex align-items-center"
              (click)="toggleBot()"
              id="close"
            >
              {{ "Close" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--Chatbot contents goes here -->
    <div class="chats" id="chats">
      <div class="clearfix"></div>
    </div>
    <!--user typing indicator -->
    <div class="keypad">
      <input
        type="text"
        class="usrInput browser-default"
        id="usrInput"
        [placeholder]="('Type a message' | translate) + '...'"
        autocomplete="off"
      />
    </div>
  </div>
  <!--bot widget -->
  <div
    class="profile_div"
    id="profile_div"
    (click)="toggleBot()"
    [class.bot-open]="isBotOpen"
  >
    <img class="imgProfile" src="assets/images/chatbot/botAvatar.jpg" />
  </div>
</div>
