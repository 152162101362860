import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthenticationService } from "app/auth/service";
import { ACCESS } from "app/constants";
import { ModuleAccessValue } from "app/interface";
import { userAccess } from "app/store/common/commonStore.module";


@Injectable()
export class UserAccessUtil {

    userAccess = null;
    constructor(
        private store: Store,
        private authService: AuthenticationService
    ) {
        this.store.select(userAccess)
            .subscribe((resp) => {
                if (resp) {
                    this.userAccess = resp;
                }
            })
    }

    public getUserAccessForModule(accessKeyName): ModuleAccessValue {
        return {
            [ACCESS.VISIBILITY]: false,
            [ACCESS.READ]: false,
            [ACCESS.UPDATE]: false,
            [ACCESS.DELETE]: false,
            [ACCESS.EDIT_ORGANISATION]: false,
            [ACCESS.CREATE]: false,
            ...this.authService.userAccessValue?.[accessKeyName]
        };
    }
}
