import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { helperValues } from 'app/store/common/commonStore.module';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { updateTaskStatusResponse } from 'app/store/home/my-tasks/store.module';
import { GetAllTasks, SetTaskById, UpdateTaskStatus, UpdateTaskStatusResponse } from 'app/store/home/my-tasks/actions';
import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonUtil } from 'app/utils/common.util';

@Component({
    selector: 'app-update-task-status-modal',
    templateUrl: './update-task-status-modal.component.html',
    styleUrls: ['./update-task-status-modal.component.scss']
})
export class UpdateTaskStatusModalComponent implements OnInit, OnDestroy {
    @BlockUI('update-status-modal') updateStatusModalBlockUI: NgBlockUI;

    @Input() taskDetails: any;
    @Input() taskList: Array<any> = [];
    @Input() handleCloseClick: Function;
    @Output() handleDestroy: Function;

    public taskStatusForm: FormGroup;
    formSubmitted: boolean = false;

    public statusOptions: Array<any> = [];

    unsubscribe = new Subject();

    constructor(
        private store: Store,
        private commonUtil: CommonUtil
    ) { }

    ngOnInit(): void {
        this.taskStatusForm = new FormGroup({
            status: new FormControl(null, Validators.required),
            comment: new FormControl('')
        })

        this.store.select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.statusOptions = resp?.taskStatus;
                }
            })

        this.taskStatusForm.patchValue({ status: this.taskDetails?.status, comment: this.taskDetails?.comment });

        this.store.select(updateTaskStatusResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.store.dispatch(new UpdateTaskStatusResponse(null));
                    this.updateStatusModalBlockUI.stop();

                    if (resp.status) {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.SUCCESS,
                            message: resp.message
                        }))

                        this.taskDetails = { ...this.taskDetails, status: this.ReactiveFormValues?.status, comment: this.ReactiveFormValues?.comment };
                        this.store.dispatch(new SetTaskById({ ...this.taskDetails }));

                        const index = this.taskList.findIndex((task: any) => task?.id === this.taskDetails?.id);
                        if (index !== -1) {
                            this.taskList[index] = { ...this.taskDetails };
                        }

                        const userId = this.commonUtil.getUserDetail('id');
                        this.store.dispatch(new GetAllTasks(userId));

                        this.onCancelClick(true);
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: resp.message
                        }))
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.taskStatusForm.reset();
        this.handleDestroy(this.taskDetails)
    }

    get ReactiveFormControl() {
        return this.taskStatusForm.controls;
    }

    get ReactiveFormValues() {
        return this.taskStatusForm.getRawValue();
    }

    onSubmitClick() {
        this.formSubmitted = true;

        if (this.taskStatusForm.invalid) {
            return;
        }

        const taskStatusDetails = cloneDeep({ ...this.ReactiveFormValues });

        this.updateStatusModalBlockUI.start();
        this.store.dispatch(new UpdateTaskStatus({ ...taskStatusDetails, id: this.taskDetails?.id }));
    }

    onCancelClick(isSubmitSuccess?) {
        this.taskStatusForm.reset();

        this.handleCloseClick(isSubmitSuccess);
    }
}
