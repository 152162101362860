import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CommonUtil } from 'app/utils/common.util';
import { AuthenticationService } from 'app/auth/service';

import { getMatterFormFields } from 'app/main/operation/matters/matters-details/matters-form';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-create-new-case-matter-modal',
    templateUrl: './create-new-case-matter-modal.component.html',
    styleUrls: ['./create-new-case-matter-modal.component.scss']
})
export class CreateNewMattersModalComponent implements OnInit, OnDestroy {
    @Input() data: any = {};
    @Input() handleCloseClick: Function;
    @Input() handleSubmitClick: Function;

    public mattersForm: FormGroup;
    public formSubmitted: boolean = false;

    public isClient: boolean = false;
    public isDeveloperAdmin: boolean = false;
    public isFirmAdmin: boolean = false;
    public isCaseManager: boolean = false;
    public isParalegal: boolean = false;

    constructor(
        private commonUtil: CommonUtil,
        private formBuilder: FormBuilder,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService
    ) {
        this.isClient = this._authenticationService.isClient;
        this.isFirmAdmin = this._authenticationService.isLegalFirmAdmin;
        this.isParalegal = this._authenticationService.isParaLegal;
        this.isDeveloperAdmin = this._authenticationService.isSystemAdmin;
    }

    ngOnInit(): void {
        this.mattersForm = this.formBuilder.group(
            getMatterFormFields(this.formBuilder, {
                clientRole: this.commonUtil.getUserDetail('role')
            })
        );

        this.mattersForm.get("firmId").patchValue(this.commonUtil.getUserDetail("firmId"));

        const { id, firmAdminId, caseManagerId } = this.commonUtil.getUserDetail();
        if (this.isDeveloperAdmin) {
            // 
        } else if (this.isFirmAdmin) {
            this.mattersForm.get("firmAdminId").patchValue(id)
        } else if (this.isCaseManager) {
            this.mattersForm.get("firmAdminId").patchValue(firmAdminId)
            this.mattersForm.get("caseManagerId").patchValue(id)
        } else if (this.isParalegal) {
            this.mattersForm.get("firmAdminId").patchValue(firmAdminId)
            this.mattersForm.get("caseManagerId").patchValue(caseManagerId)
            this.mattersForm.get("paraLegalId").patchValue(id)
        }

        this.mattersForm.patchValue(this.data || {});
    }

    ngOnDestroy(): void {
        this.formSubmitted = false;
    }

    get MattersFormValue() {
        return this.mattersForm.getRawValue();
    }

    get MattersFormControl() {
        return this.mattersForm.controls;
    }

    get ClientProfileFormControl() {
        return this.mattersForm.get("clientInfo")?.["controls"] || {};
    }

    get MattersDetailsFormControl() {
        return this.mattersForm.get("mattersDetails")?.["controls"] || {};
    }

    submit() {
        this.formSubmitted = true;

        if (this.mattersForm.invalid) {
            return;
        }

        this.handleSubmitClick(this.MattersFormValue);
    }
}
