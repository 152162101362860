import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SAVE_AS_NOTE_MODULE_OPTIONS } from 'app/constants/common.constant';

import { allMatters } from 'app/store/operation/matters/store.module';
// import { allPreActionProtocols } from 'app/store/operation/pre-actions/store.module';
// import { allMedicalEvidences } from 'app/store/operation/medical-evidences/store.module';

@Component({
    selector: 'app-save-document-as-note-modal',
    templateUrl: './save-document-as-note-modal.component.html',
    styleUrls: ['./save-document-as-note-modal.component.scss']
})
export class SaveDocumentAsNoteModalComponent implements OnInit, OnDestroy {
    @Input() handleCloseClick: Function;
    @Input() handleSubmitClick: Function;

    public noteForm: FormGroup;
    public formSubmitted: boolean = false;

    public moduleNameOptions: Array<any> = SAVE_AS_NOTE_MODULE_OPTIONS.slice();
    public caseMatterOptions: Array<any> = [];
    public preActionProtocolOptions: Array<any> = [];
    public medicalEvidenceOptions: Array<any> = [];

    private unsubscribe = new Subject();

    constructor(
        private store: Store,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.noteForm = this.formBuilder.group(
            {
                moduleName: new FormControl(null, Validators.required),
                moduleId: new FormControl(null, Validators.required)
            }
        );

        this.store.select(allMatters)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (resp) => {
                    if (resp) {
                        this.caseMatterOptions = resp;
                    }
                }
            )
        // this.store.select(allPreActionProtocols)
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe(
        //         (resp) => {
        //             if (resp) {
        //                 this.preActionProtocolOptions = resp;
        //             }
        //         }
        //     )
        // this.store.select(allMedicalEvidences)
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe(
        //         (resp) => {
        //             if (resp) {
        //                 this.medicalEvidenceOptions = resp;
        //             }
        //         }
        //     )
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.formSubmitted = false;
    }

    get NoteFormValue() {
        return this.noteForm.getRawValue();
    }

    get NoteFormControl() {
        return this.noteForm.controls;
    }

    get ModuleIdOptions() {
        switch (this.NoteFormValue.moduleName) {
            case "CASE_MATTER":
                return this.caseMatterOptions;
            case "PRE_ACTION_PROTOCOL":
                return this.preActionProtocolOptions;
            case "MEDICAL_EVIDENCE":
                return this.medicalEvidenceOptions;
            default:
                return [];
        }
    }

    onModuleNameChange() {
        this.NoteFormControl.moduleId.patchValue(null);
    }

    submit() {
        this.formSubmitted = true;

        if (this.noteForm.invalid) {
            return;
        }
        this.handleSubmitClick(this.NoteFormValue);
    }
}
