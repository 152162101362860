import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_VISION_AI_DOCUMENTS, SET_ALL_VISION_AI_DOCUMENTS, GET_VISION_AI_DOCUMENT_BY_ID, SET_VISION_AI_DOCUMENT_BY_ID,
    ADD_VISION_AI_DOCUMENT, ADD_VISION_AI_DOCUMENT_RESPONSE, DELETE_VISION_AI_DOCUMENT, DELETE_VISION_AI_DOCUMENT_RESPONSE,
    SAVE_VISION_AI_DOCUMENT_AS_NOTE, SAVE_VISION_AI_DOCUMENT_AS_NOTE_RESPONSE,
    RUN_VISION_AI_EXTRACTION_FROM_SUMMARY, RUN_VISION_AI_EXTRACTION_FROM_SUMMARY_RESPONSE
} from "./actionTypes"

export class GetAllVisionAiDocuments implements Action {
    readonly type = GET_ALL_VISION_AI_DOCUMENTS;
}

export class SetAllVisionAiDocuments implements Action {
    readonly type = SET_ALL_VISION_AI_DOCUMENTS;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetVisionAiDocumentById implements Action {
    readonly type = GET_VISION_AI_DOCUMENT_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetVisionAiDocumentById implements Action {
    readonly type = SET_VISION_AI_DOCUMENT_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddVisionAiDocument implements Action {
    readonly type = ADD_VISION_AI_DOCUMENT;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class AddVisionAiDocumentResponse implements Action {
    readonly type = ADD_VISION_AI_DOCUMENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteVisionAiDocument implements Action {
    readonly type = DELETE_VISION_AI_DOCUMENT;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray
    }
}

export class DeleteVisionAiDocumentResponse implements Action {
    readonly type = DELETE_VISION_AI_DOCUMENT_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class SaveVisionAiDocumentAsNote implements Action {
    readonly type = SAVE_VISION_AI_DOCUMENT_AS_NOTE;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class SaveVisionAiDocumentAsNoteResponse implements Action {
    readonly type = SAVE_VISION_AI_DOCUMENT_AS_NOTE_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class RunVisionAiExtractionFromSummary implements Action {
    readonly type = RUN_VISION_AI_EXTRACTION_FROM_SUMMARY;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class RunVisionAiExtractionFromSummaryResponse implements Action {
    readonly type = RUN_VISION_AI_EXTRACTION_FROM_SUMMARY_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions = GetAllVisionAiDocuments | SetAllVisionAiDocuments | GetVisionAiDocumentById | SetVisionAiDocumentById |
    AddVisionAiDocument | AddVisionAiDocumentResponse | DeleteVisionAiDocument | DeleteVisionAiDocumentResponse |
    SaveVisionAiDocumentAsNote | SaveVisionAiDocumentAsNoteResponse | RunVisionAiExtractionFromSummary | RunVisionAiExtractionFromSummaryResponse;
