import { IServerResponse } from "app/interface";

export interface EnquiryState {
    allEnquiries: Array<any>;
    enquiriesByField: Array<any>;
    enquiryById: any;
    addEnquiryResponse: IServerResponse;
    updatedEnquiryResponse: IServerResponse;
    deletedEnquiryResponse: IServerResponse;
    enquiryExtractions: any;
}

export const initialState: EnquiryState = {
    allEnquiries: null,
    enquiriesByField: null,
    enquiryById: null,
    addEnquiryResponse: null,
    updatedEnquiryResponse: null,
    deletedEnquiryResponse: null,
    enquiryExtractions: null
}
