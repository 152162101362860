import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { SetUserDetail } from 'app/store/core/actions';
import { userDetail } from 'app/store/core/store.module';
import { userAccess } from 'app/store/common/commonStore.module';
import { MODULE_KEY } from 'app/constants/module.constant';

import { LocalstorageService } from '@core/services/localstorage.service';

@Component({
    selector: 'app-ask-elie',
    templateUrl: './ask-elie.component.html',
    styleUrls: ['./ask-elie.component.scss']
})
export class AskElieComponent implements OnInit, AfterViewInit, OnDestroy {

    isBotOpen: boolean = false;
    userDetail: User = null;
    showAskelieBot: boolean = false;

    unsubscribe = new Subject();

    constructor(
        private store: Store,
        private _authenticationService: AuthenticationService,
        private localStorage: LocalstorageService
    ) {
    }

    ngOnInit(): void {
        this.store.select(userAccess)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                this.showAskelieBot = resp?.[MODULE_KEY.ASKELIE]?.visibility || false;
            });

        this.store.select(userDetail)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.userDetail = resp;
                } else if (this.isBotOpen) {
                    // this is done to avoid bot staying opened on relogin
                    // else it will rendered opened if it was open before logout
                    this.toggleBot();
                }
            });

        if (this._authenticationService.currentUserValue) {
            this.store.dispatch(new SetUserDetail(this._authenticationService.currentUserValue))
        }
    }

    ngAfterViewInit(): void {
        const script = document.createElement('script');
        script.id = "askelie-script"
        script.src = "./assets/scripts/script.js";
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    toggleBot() {
        this.isBotOpen = !this.isBotOpen;

        if (this.isBotOpen) {
            this.triggerChatbotConversation();
        }
    }

    triggerChatbotConversation() {
        if (this.showAskelieBot && window["user_id"]) {
            const chatMessagesLength = document.getElementById("chats")?.childElementCount;
            if (chatMessagesLength <= 1) {
                const askelieTriggerFunction = window["triggerFunction"];
                if (askelieTriggerFunction && typeof askelieTriggerFunction === 'function') {
                    askelieTriggerFunction();
                }
            }
        }
    }

}
