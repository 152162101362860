<div class="data-table-contaier">
  <section id="ngx-datatable-kitchen-sink">
    <div class="row match-height">
      <div class="col-12">
        <div class="card mb-0">
          <div class="card-body p-{{ removePadding ? 0 : 1 }}">
            <div class="row">
              <div class="col-6 col-md-3 col-xl-2" *ngIf="!hideShowOptions">
                <div class="mb-1">
                  <label class="d-flex align-items-center"
                    >Show
                    <select
                      class="form-control mx-25"
                      [(ngModel)]="pageValue"
                      (change)="handleSizeChange($event.target.value)"
                      [disabled]="loading"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <div
                class="col-12 offset-0 col-md-8 offset-md-1 col-xl-6 offset-xl-4 align-items-center"
                *ngIf="options?.showSearch"
              >
                <app-table-search
                  (triggerSearch)="filterSearchUpdate($event)"
                  (clearSearch)="onClear($event)"
                  [moduleId]="moduleId"
                ></app-table-search>
              </div>
            </div>
            <ngx-datatable
              *blockUI="'data-table'"
              [rows]="rows"
              [columns]="cols"
              [rowHeight]="auto"
              class="bootstrap core-bootstrap"
              [columnMode]="ColumnMode.force"
              [headerHeight]="40"
              [footerHeight]="40"
              [scrollbarH]="true"
              [selectionType]="SelectionType.checkbox"
              [messages]="{
                emptyMessage: 'There are no records...',
                selectedMessage: 'selected'
              }"
              (activate)="onActivate($event)"
              (select)="onSelect($event)"
              [selected]="selected"
              [externalPaging]="externalPaging"
              [count]="page.totalElements"
              [offset]="page.pageNumber"
              [limit]="page.size"
              (page)="handlePageChange($event)"
            >
            </ngx-datatable>

            <div class="row" *ngIf="options && options.showLegend">
              <div
                class="col-12 d-flex justify-content-center align-items-center"
              >
                <span class="py-1"> <span> Risk</span><br /> </span>

                <span class="ml-1 p-1 border rounded">
                  <span>
                    <img src="assets/images/cs/red_circle_original.png" />
                    <span class="ml-1">High</span>
                  </span>

                  <span class="ml-1">
                    <img src="assets/images/cs/orange_circle_original.png" />
                    <span class="ml-1">Medium</span>
                  </span>

                  <span class="ml-1">
                    <img src="assets/images/cs/green_circle.png" />
                    <span class="ml-1">Low</span>
                  </span>
                </span>
              </div>
            </div>
            <br />
            <ng-content></ng-content>
            <div class="row" *ngIf="!(options && options.hideDeleteButton)">
              <div class="col-12 col-md-12 col-xs-12 col-sm-12">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-danger mr-1"
                  [disabled]="this.selected.length === 0"
                  (click)="deleteData()"
                >
                  Delete
                </button>
              </div>
            </div>

            <ng-template
              #headerCheckbox
              ngx-datatable-header-template
              let-value="value"
              let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [checked]="allRowsSelected"
                  (change)="selectFn(!allRowsSelected)"
                  id="headerChkbxRef"
                />
                <label
                  class="custom-control-label"
                  for="headerChkbxRef"
                ></label>
              </div>
            </ng-template>

            <ng-template
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
              let-value="value"
              #cellCheckbox
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)"
                  id="rowChkbxRef{{ rowIndex }}"
                />
                <label
                  class="custom-control-label"
                  for="rowChkbxRef{{ rowIndex }}"
                ></label>
              </div>
            </ng-template>

            <ng-template #risk ngx-datatable-cell-template let-value="value">
              <img
                *ngIf="value === 'NONE'"
                src="assets/images/cs/white-bubble.png"
              />
              <img
                *ngIf="value === 'HIGH'"
                src="assets/images/cs/red_circle_original.png"
              />
              <img
                *ngIf="value === 'MEDIUM'"
                src="assets/images/cs/orange_circle_original.png"
              />
              <img
                *ngIf="value === 'LOW'"
                src="assets/images/cs/green_circle.png"
              />
            </ng-template>

            <ng-template
              ngx-datatable-cell-template
              let-row="row"
              let-rowIndex="rowIndex"
              let-value="value"
              #cellHyperlink
              let-isSelected="isSelected"
            >
              <div class="long-text-container">
                <a href="javascript:void(0);" (click)="editData(row)">
                  {{ value }}
                </a>
              </div>
            </ng-template>

            <ng-template
              #truncatedHyperlink
              ngx-datatable-cell-template
              let-row="row"
              let-rowIndex="rowIndex"
              let-value="value"
              let-isSelected="isSelected"
            >
              <div class="long-text-container">
                <a href="javascript:void(0);" (click)="editData(row)">
                  {{ value ? value.slice(0, 100) + "..." : "" }}
                </a>
              </div>
            </ng-template>

            <ng-template #date ngx-datatable-cell-template let-value="value">
              {{ value | date : "dd/MM/yyyy" }}
            </ng-template>

            <ng-template
              #dateTime
              ngx-datatable-cell-template
              let-value="value"
            >
              {{ value | date : "dd/MM/yyyy, h:mm:ss a" }}
            </ng-template>

            <ng-template
              #amountWithCurrency
              ngx-datatable-cell-template
              let-value="value"
              let-row="row"
            >
              {{ value | currency : row.defaultCurrency || DEFAULT_CURRENCY }}
            </ng-template>

            <ng-template
              #longText
              ngx-datatable-cell-template
              let-value="value"
            >
              <div class="long-text-container">
                {{ value }}
              </div>
            </ng-template>

            <ng-template
              #newLineItemsList
              ngx-datatable-cell-template
              let-value="value"
            >
              <ul class="new-line-items-list">
                <li *ngFor="let item of value || []" class="new">
                  {{ item }}
                </li>
              </ul>
            </ng-template>

            <ng-template
              #truncatedText
              ngx-datatable-cell-template
              let-value="value"
            >
              <div
                class="long-text-container"
                [ngbTooltip]="value"
                container="body"
              >
                <span *ngIf="value">
                  {{ value?.length > 50 ? value.slice(0, 50) + "..." : value }}
                </span>
                <span *ngIf="!value">-</span>
              </div>
            </ng-template>

            <ng-template
              #textInput
              ngx-datatable-cell-template
              let-column="column"
              let-row="row"
              let-rowIndex="rowIndex"
            >
              <div [formGroup]="row">
                <input
                  type="text"
                  class="form-control"
                  [name]="column.prop + '-' + rowIndex"
                  [id]="column.prop + '-' + rowIndex"
                  [placeholder]="column.name"
                  [formControlName]="column.prop"
                  (change)="
                    updateData({
                      key: column.prop,
                      value: $event?.target?.value,
                      index: rowIndex
                    })
                  "
                  [ngClass]="{
                    'is-invalid': formSubmitted && row.controls?.[column.prop]?.errors
                  }"
                  [attr.disabled]="disableCellInputOnEdit && row.id"
                />
              </div>
            </ng-template>

            <ng-template
              #amountInput
              ngx-datatable-cell-template
              let-value="value"
              let-column="column"
              let-row="row"
              let-rowIndex="rowIndex"
            >
              <div [formGroup]="row">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="{{ column.prop + '-currency-addon' }}"
                      >{{ row.defaultCurrency || DEFAULT_CURRENCY }}</span
                    >
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    [name]="column.prop + '-' + rowIndex"
                    [id]="column.prop + '-' + rowIndex"
                    [placeholder]="column.name"
                    [formControlName]="column.prop"
                    (change)="
                      updateData({
                        key: column.prop,
                        value: $event?.target?.value,
                        index: rowIndex
                      })
                    "
                    [ngClass]="{
                    'is-invalid': formSubmitted && row.controls?.[column.prop]?.errors
                    }"
                    [attr.disabled]="disableCellInputOnEdit && row.id"
                  />
                </div>
              </div>
            </ng-template>

            <ng-template
              #selectInput
              ngx-datatable-cell-template
              let-column="column"
              let-row="row"
              let-rowIndex="rowIndex"
            >
              <div [formGroup]="row">
                <ng-select
                  appendTo="body"
                  [id]="column.prop + '-' + rowIndex"
                  [name]="column.prop + '-' + rowIndex"
                  [items]="column.selectOptions || []"
                  bindLabel="name"
                  bindValue="id"
                  [formControlName]="column.prop"
                  (change)="
                    updateData({
                      key: column.prop,
                      value: $event?.id,
                      index: rowIndex
                    })
                  "
                  [ngClass]="{
                    'is-invalid': formSubmitted && row.controls?.[column.prop]?.errors
                  }"
                  [attr.disabled]="disableCellInputOnEdit && row.id"
                  [closeOnSelect]="true"
                >
                </ng-select>
              </div>
            </ng-template>

            <ng-template
              #dateInput
              ngx-datatable-cell-template
              let-column="column"
              let-row="row"
            >
              <div [formGroup]="row">
                <input
                  type="date"
                  class="form-control"
                  [name]="column.prop"
                  [id]="column.prop"
                  [placeholder]="column.name"
                  [formControlName]="column.prop"
                  (change)="
                    updateData({
                      key: column.prop,
                      value: $event?.target?.value,
                      index: rowIndex
                    })
                  "
                  [ngClass]="{
                    'is-invalid': formSubmitted && row.controls?.[column.prop]?.errors
                  }"
                  [attr.disabled]="disableCellInputOnEdit && row.id"
                />
              </div>
            </ng-template>

            <ng-template
              #actionButton
              ngx-datatable-cell-template
              let-column="column"
              let-row="row"
            >
              <div>
                <button
                  *ngIf="!row.disabledActions[column.prop]"
                  type="button"
                  rippleEffect
                  class="btn {{ column.buttonClass || '' }} btn-sm"
                  (click)="column.submitAction(row)"
                >
                  <span *ngIf="column.leftIcon">
                    <i [data-feather]="column.leftIcon"></i>
                    <span class="ml-25">
                      {{ column.prop }}
                    </span>
                  </span>
                  <span *ngIf="column.rightIcon">
                    <span class="mr-25">
                      {{ column.prop }}
                    </span>
                    <i [data-feather]="column.rightIcon"></i>
                  </span>
                  <span *ngIf="!column.leftIcon && !column.rightIcon">
                    {{ column.prop }}
                  </span>
                </button>

                <span *ngIf="row.disabledActions[column.prop]"> - </span>
              </div>
            </ng-template>

            <ng-template
              #speechAndVisionAiActions
              ngx-datatable-cell-template
              let-row="row"
            >
              <div class="btn-group" role="group" aria-label="Basic example">
                <div
                  *ngIf="!!downloadOriginalFile"
                  role="button"
                  class="btn btn-link download-enabled"
                  [ngbTooltip]="'Download Original File' | translate"
                  container="body"
                  (click)="downloadOriginalFile({ e: $event, row })"
                >
                  <span data-feather="download-cloud" size="16"></span>
                </div>
                <div
                  *ngIf="
                    !!downloadTextContent &&
                    !!(row.extractedText || row.speechText)
                  "
                  role="button"
                  class="btn btn-link download-enabled"
                  [ngbTooltip]="'Download Text' | translate"
                  container="body"
                  (click)="downloadTextContent({ e: $event, row })"
                >
                  <span data-feather="download" size="16"></span>
                </div>
                <div
                  *ngIf="!!downloadSummary"
                  role="button"
                  class="btn btn-link download-enabled"
                  [ngbTooltip]="'Download Summary' | translate"
                  container="body"
                  (click)="downloadSummary({ e: $event, row })"
                >
                  <span data-feather="arrow-down" size="16"></span>
                </div>
                <div
                  *ngIf="!!downloadShortSummary"
                  role="button"
                  class="btn btn-link download-enabled"
                  [ngbTooltip]="'Download Short Summary' | translate"
                  container="body"
                  (click)="downloadShortSummary({ e: $event, row })"
                >
                  <span data-feather="arrow-down-circle" size="16"></span>
                </div>
                <button
                  *ngIf="
                    !!(
                      runExtraction &&
                      (row?.sttstate === 'SUMMARY_DONE' ||
                        row?.visionAiState === 'SUMMARY_DONE')
                    )
                  "
                  type="button"
                  class="btn btn-link"
                  [ngbTooltip]="'Run Extraction' | translate"
                  container="body"
                  (click)="runExtraction({ e: $event, row })"
                >
                  <span data-feather="arrow-right-circle" size="16"></span>
                </button>
                <button
                  *ngIf="!!(createNewMatter && row?.extractedEntity)"
                  type="button"
                  class="btn btn-link"
                  [ngbTooltip]="'Create new Matter' | translate"
                  container="body"
                  (click)="createNewMatter({ e: $event, row })"
                >
                  <span data-feather="folder-plus" size="16"></span>
                </button>
                <button
                  *ngIf="!!saveAsNote"
                  type="button"
                  class="btn btn-link"
                  [ngbTooltip]="'Save as Note' | translate"
                  container="body"
                  (click)="saveAsNote({ e: $event, row })"
                >
                  <span data-feather="save" size="16"></span>
                </button>
              </div>
            </ng-template>

            <ng-template
              #actionIcons
              ngx-datatable-cell-template
              let-column="column"
              let-row="row"
            >
              <div class="action-buttons">
                <ng-container *ngFor="let button of column.actionIcons">
                  <button
                    *ngIf="!row.disabledActions[button.title]"
                    [type]="button.type"
                    (click)="button.submitAction(row)"
                    [class]="button.buttonClass"
                    [ngbTooltip]="button.title"
                    [disabled]="button.disabled(row)"
                  >
                    <i [data-feather]="button.icon"></i>
                  </button>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
