import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ResearchAiService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store
    ) {
    }

    public getAllResearchAiModels() {
        return this.http.get(
            this.urlUtil.getUrl('research-ai', 'getAll'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllResearchAiModels error =>", e);
                    return of([]);
                })
            )
    }

    public getResearchAiModelById(id) {
        return this.http.get(
            this.urlUtil.getUrl('research-ai', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        if (resp.data instanceof Array) {
                            return resp.data[0] || {};
                        }
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getResearchAiModelById error =>", e);
                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }

    public addResearchAiModel(dataObject) {
        return this.http.post(
            this.urlUtil.getUrl('research-ai', 'add'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("addResearchAiModel error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataAdd")
                    });
                })
            )
    }

    public updateResearchAiModel(dataObject) {
        return this.http.put(
            this.urlUtil.getUrl('research-ai', 'update').replace('$ID', dataObject.id), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("updateResearchAiModel error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }

    public deleteResearchAiModel(idsArray) {
        return this.http.post(this.urlUtil.getUrl('research-ai', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("deleteResearchAiModel error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataDelete")
                    });
                })
            )
    }

    public addResearchAiSources({ id, sourcesArray }) {
        return this.http.post(
            this.urlUtil.getUrl('research-ai', 'addSources', [
                { key: "id", value: id }
            ]), sourcesArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("addResearchAiSources error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataAdd")
                    });
                })
            )
    }

    public removeResearchAiSources({ id, sourcesArray }) {
        return this.http.post(
            this.urlUtil.getUrl('research-ai', 'removeSources', [
                { key: "id", value: id }
            ]), sourcesArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("removeResearchAiSources error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataDelete")
                    });
                })
            )
    }

    public trainResearchAiModelById(id) {
        return this.http.get(
            this.urlUtil.getUrl('research-ai', 'train').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    return ({
                        status,
                        message: errorMessage || jsonData || this._translateService.instant("FailDataDelete")
                    });
                }),
                catchError((e) => {
                    console.log("trainResearchAiModelById error =>", e);
                    return of({});
                })
            )
    }

    public queryResearchAiModelById(dataObject) {
        return this.http.post(
            this.urlUtil.getUrl('research-ai', 'query'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, data, jsonData, errorMessage } = resp
                    if (status) {
                        return data;
                    } else {
                        this.store.dispatch(new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message: errorMessage || jsonData || this._translateService.instant("ErrorGeneral")
                        }))

                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("queryResearchAiModelById error =>", e);
                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }
}
