import { Component, Input, OnInit } from '@angular/core';
import { FileService } from '@core/services/file.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'app-version-history-modal',
    templateUrl: './version-history-modal.component.html',
    styleUrls: ['./version-history-modal.component.scss']
})
export class VersionHistoryModalComponent implements OnInit {
    @BlockUI('version-history-modal') itemBlockUI: NgBlockUI;
    @Input() data: any = {};

    constructor(
        private _fileService: FileService
    ) { }

    ngOnInit(): void {
    }

    get versionRows() {
        return this.data.versionHistory;
    }

    openFileInNewTab(e, data) {
        // default behaviour is prevented as otherwise it was submitting the form
        e.preventDefault();

        this.itemBlockUI.start()
        this._fileService.openFileInNewTab({ name: data.name, url: data.link.split("/").slice(-1)[0] }, () => {
            this.itemBlockUI.stop()
        });
    }
}
