import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';
import { Page } from 'app/utils/page.util';

@Injectable()
export class ClientsService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store,
        private page: Page
    ) {
    }

    public getAllClients() {
        return this.http.get(
            this.urlUtil.getUrl('clients', 'getAll'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllClients error =>", e);
                    return of([]);
                })
            )
    }

    public getClientById(id) {
        return this.http.get(
            this.urlUtil.getUrl('clients', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getClientById error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }

    public getMattersByClientId(id) {
        return this.http.get(
            this.urlUtil.getUrl('clients', 'mattersByClientId').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getClientById error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }

    public searchByName(name = null) {
        const {currentPage, size} = this.page.getPageAndSize();
        const { key, value } = name;
        return this.http.get(
            this.urlUtil.getUrl('clients', 'searchByName')
                .replace('$KEY', key)
                .replace('$VALUE', value)
                .replace('$PAGE', currentPage)
                .replace('$SIZE', size))
            .pipe(
                map((resp: any) => {
                    if (resp?.status) {
                        const respData = resp.data
                        this.page.setPageData({
                            size: respData.size,
                            totalElements: respData.totalElements,
                            totalPages: respData.totalPages,
                            pageNumber: respData.number
                        })
                        return respData.content;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log(e);
                    return of(null);
                })
            )
    }

    public updateClient({data, reason}) {
        return this.http.put(
            this.urlUtil.getUrl('clients', 'update', [
                { key: 'reason', value: reason }
            ]).replace('$ID', data.id), data)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("updateClient error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }

    public deleteClient(idsArray) {
        return this.http.post(this.urlUtil.getUrl('clients', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("deleteClients error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataDelete")
                    });
                })
            )
    }
}
