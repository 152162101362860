import { IServerResponse } from "app/interface";

export interface RolesState {
    alldRoles: Array<any>;
    roleById: any;
    addRoleResponse: IServerResponse;
    updatedRoleResponse: IServerResponse;
    deletedRoleResponse: IServerResponse;
}

export const initialState: RolesState = {
    alldRoles: null,
    roleById: null,
    addRoleResponse: null,
    updatedRoleResponse: null,
    deletedRoleResponse: null
}
