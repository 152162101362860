<!-- Vertical Menu -->
<ng-container *ngIf="layout === 'vertical' && currentUser">
  <div
    class="d-flex flex-column align-items-center menu-item-profile-container"
  >
    <ul class="flex-grow-1">
      <ng-container *ngFor="let item of menu">
        <!-- section -->
        <li
          core-menu-vertical-section
          *ngIf="
            item.type === 'section' &&
            getCurrentSectionAccess(item.accessKeyName)
          "
          [item]="item"
          class="navigation-header"
        ></li>

        <!-- sub-menu item/collapsible of sections  -->
        <ng-container *ngIf="item.type === 'section'">
          <ng-container *ngFor="let item of item.children">
            <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
            <li
              core-menu-vertical-item
              *ngIf="getCurrentModuleAccess(item.accessKeyName)"
              [item]="item"
              [ngClass]="{ disabled: item.disabled === true }"
              [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
              [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
            >
              <!-- Fix: Required routerLink to identify the active -->
              <span
                [routerLink]="item.openInNewTab ? [] : [item.url]"
                class="d-none"
              ></span>
            </li>
            <!-- collapsible -->
            <li
              core-menu-vertical-collapsible
              *ngIf="
                item.type === 'collapsible' &&
                (item.role
                  ? item.role.includes(currentUser.role)
                  : false || item.role === undefined)
              "
              [item]="item"
              class="nav-item has-sub"
            ></li>
          </ng-container>
        </ng-container>

        <!-- item -->
        <li
          core-menu-vertical-item
          *ngIf="getCurrentModuleAccess(item.accessKeyName)"
          [item]="item"
          [ngClass]="{ disabled: item.disabled === true }"
          [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
          [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        >
          <span
            [routerLink]="item.openInNewTab ? [] : [item.url]"
            class="d-none"
          ></span>
        </li>
        <!-- collapsible -->
        <li
          core-menu-vertical-collapsible
          *ngIf="
            item.type === 'collapsible' &&
            getCurrentModuleAccess(item.accessKeyName)
          "
          [item]="item"
          class="nav-item has-sub"
        ></li>
      </ng-container>
    </ul>

    <!-- Profile Section -->
    <ul class="nav navbar-nav align-items-center mt-auto">
      <li
        ngbDropdown
        class="nav-item dropdown-user"
        id="user-profile-dropdown"
        placement="right-bottom"
        container="body"
      >
        <a
          class="nav-link dropdown-toggle dropdown-user-link"
          id="dropdown-user"
          ngbDropdownToggle
          id="navbarUserDropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <ng-container>
            <span class="avatar" id="profile-avatar"
              ><img
                class="round"
                src="{{
                  this.currentUser.profileImage ||
                    'assets/images/portrait/small/avatar-s-11.png'
                }}"
                alt="avatar"
                height="35px"
                width="35px" /><span class="avatar-status-online"></span
            ></span>
          </ng-container>
        </a>
        <div
          ngbDropdownMenu
          aria-labelledby="navbarUserDropdown"
          class="dropdown-menu dropdown-menu-right pl-1 pr-1"
        >
          <ng-container *ngIf="this.currentUser">
            <span class="user-name font-weight-bolder">
              {{ this.currentUser.firstName ? this.currentUser.firstName : "" }}
              <!-- {{
                this.currentUser.lastName ? ' ' + this.currentUser.lastName : ''
              }} -->
            </span>
            <br />
            <span class="user-status">
              {{ this.currentUser.roleName }} ({{
                this.currentUser.firmName || "Firm Name"
              }})
            </span>
          </ng-container>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem [routerLink]="['/pages/profile']"
            ><span [data-feather]="'user'" [class]="'mr-50'"></span>Profile</a
          >
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem (click)="logout()"
            ><span [data-feather]="'power'" [class]="'mr-50'"></span>Logout</a
          >
        </div>
      </li>
    </ul>
  </div>
</ng-container>
<!--/ Vertical Menu -->
