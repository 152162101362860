import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ErrorInterceptor, ApiPrefixInterceptor, JwtInterceptor, HttpService } from "./helpers";

@NgModule({
    imports: [
        // BrowserModule,
        CommonModule,
        HttpClientModule,
        RouterModule
    ],
    providers: [
        // ErrorInterceptor,
        { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
        // { provide: HttpClient, useClass: HttpService },
    ]
})

export class AuthModule { }
