import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as CommonAction from './actions';
import { CommonService } from "./service";
import { CoreConfig } from "@core/types";
import { IServerResponse } from "app/interface";

@Injectable()
export class CommonEffect {

    constructor(
        private action: Actions,
        private commonService: CommonService
    ) { }

    GetAppConfig = createEffect(() => this.action.pipe(
        ofType(type.GET_APP_CONFIG),
        switchMap(() => this.commonService.getAppConfig()),
        map((data: CoreConfig) => new CommonAction.AppConfig(data)
        )
    ));

    GetUserAccess = createEffect(() => this.action.pipe(
        ofType(type.GET_USER_ACCESS),
        switchMap(() => this.commonService.getUserAccess()),
        map((data: any) => new CommonAction.SetUserAccess(data)
        )
    ));

    uploadFile = createEffect(() => this.action.pipe(
        ofType(type.UPLOAD_FILE),
        switchMap((data: any) => this.commonService.uploadFile(data.payload)),
        map((data: any) => new CommonAction.UploadFileResp(data)
        )
    ));

    downloadFile = createEffect(() => this.action.pipe(
        ofType(type.DOWNLOAD_FILE),
        switchMap((data: any) => this.commonService.downloadFile(data.payload)),
        map((data: any) => new CommonAction.DownloadFileResp(data)
        )
    ));

    getSearchOptions = createEffect(() => this.action.pipe(
        ofType(type.GET_SEARCH_OPTIONS),
        switchMap((data: any) => this.commonService.getSearchOption(data.payload)),
        map((data: any) => new CommonAction.SetSearchOptions(data)
        )
    ));

    GetHelperValues = createEffect(() => this.action.pipe(
        ofType(type.GET_HELPER_VALUES),
        switchMap(() => this.commonService.getHelperValues()),
        map((data: any) => new CommonAction.SetHelperValues(data)
        )
    ));

    GetUnipaasPaymentLink = createEffect(() => this.action.pipe(
        ofType(type.GET_UNIPAAS_PAYMENT_LINK),
        switchMap((data: any) => this.commonService.getUnipaasPaymentLink(data.payload)),
        map((data: IServerResponse) => new CommonAction.SetUnipaasPaymentLinkResponse(data))
    ))
}
