import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { ACCESS } from 'app/constants/access.constant';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService
    ) { }

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authenticationService.currentUserValue;
        const userAccess = this._authenticationService.userAccessValue;

        if (route && route.routeConfig.path === 'profile') {
            if (currentUser) {
                // check if route is restricted by role
                // set userLoggedIn true in app service to start idle timer
                this._authenticationService.setUserLoggedIn(true);
                // authorised so return true
                return true;
            } else {
                this._router.navigate(['/pages/miscellaneous/not-authorized']);
                return false;
            }
        } else {
            if (currentUser && userAccess) {
                // check if route is restricted by role
                if (!(route.data && userAccess[route.data.AccessKeyName] && userAccess[route.data.AccessKeyName][ACCESS.VISIBILITY])) {
                    this._router.navigate(['/pages/miscellaneous/not-authorized']);
                    return false;
                }

                // set userLoggedIn true in app service to start idle timer
                this._authenticationService.setUserLoggedIn(true);

                // authorised so return true
                return true;
            }
        }

        // not logged in so redirect to login page with the return url
        this._router.navigate(['/pages/authentication/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
