export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";

export const SEARCH_NOTIFICATION_BY_ID = "SEARCH_NOTIFICATION_BY_ID";
export const NOTIFICATION_BY_ID = "NOTIFICATION_BY_ID";

export const SEARCH_NOTIFICATION_BY_FIELD = "SEARCH_NOTIFICATION_BY_FIELD";
export const NOTIFICATION_BY_FIELD_LIST = "NOTIFICATION_BY_FIELD_LIST";

export const GET_NOTIFICATION_TEMPLATE_LIST = "GET_NOTIFICATION_TEMPLATE_LIST";
export const NOTIFICATION_TEMPLATE_LIST = "NOTIFICATION_TEMPLATE_LIST";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_RESPONSE = "ADD_NOTIFICATION_RESPONSE";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_RESPONSE = "UPDATE_NOTIFICATION_RESPONSE";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_RESPONSE = "DELETE_NOTIFICATION_RESPONSE";
