import { CoreConfig } from "@core/types";
import { MODULE_ID } from "app/constants/module.constant";
import { IServerResponse } from "app/interface";

export interface IToastr {
    type?: string;
    header?: string;
    message: string;
    positionClass?: string;
    toastClass?: string;
    closeButton?: boolean;
    progressBar?: boolean;
    tapToDismiss?: boolean;
    enableHtml?: false;
    timeOut?: number;
}

export interface SearchParam {
    searchField: string;
    searchValue: string | number;
}

export interface CommonState {
    toasterData: IToastr;
    uploadFileResp: any;
    userAccess: any;
    searchOptions: Array<any>;
    appConfig: CoreConfig;
    tableSearchParam: SearchParam;
    persistedSearch: any;
    helperValues: any;
    unipaasPaymentLinkResponse: IServerResponse
}

export const initialState: CommonState = {
    toasterData: null,
    uploadFileResp: null,
    userAccess: null,
    searchOptions: [],
    appConfig: null,
    tableSearchParam: {
        searchField: 'select',
        searchValue: 'all'
    },
    persistedSearch: {
        [MODULE_ID.FIRMS]: {
            searchField: 'select',
            searchvalue: 'all'
        },
        [MODULE_ID.CLIENTS]: {
            searchField: 'select',
            searchvalue: 'all'
        },
        [MODULE_ID.MATTERS]: {
            searchField: 'select',
            searchvalue: 'all'
        },
        [MODULE_ID.ENQUIRY]: {
            searchField: 'select',
            searchvalue: 'all'
        },
        [MODULE_ID.USERS]: {
            searchField: 'select',
            searchvalue: 'all'
        },
        [MODULE_ID.DOCUMENT_SIGNATURE_TRACKING]: {
            searchField: 'select',
            searchvalue: 'all'
        },
        [MODULE_ID.METERS]: {
            searchField: 'select',
            searchvalue: 'all'
        }
    },
    helperValues: {},
    unipaasPaymentLinkResponse: null
}
