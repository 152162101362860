<div class="modal-header">
  <h4 class="modal-title">{{ "Upload File(s)" | translate }}</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="handleCloseClick()"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'enquiry-modal'"
>
  <div *blockUI="'file-upload-modal'">
    <form [formGroup]="importForm">
      <div class="row">
        <div class="col-12">
          <div class="card rounded-0 mb-0">
            <div class="card-body">
              <h5 class="card-title" *ngIf="title">
                {{ title }}
              </h5>

              <div class="row" *ngIf="showFileTypeOptions">
                <div class="col-12" *ngIf="ReactiveFormControl.fileType">
                  <div class="form-group">
                    <label for="fileType">{{ "File Type" | translate }}</label>
                    <ng-select
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted && ReactiveFormControl.fileType.errors
                      }"
                      id="fileType"
                      name="fileType"
                      [items]="fileTypeOptions"
                      bindLabel="name"
                      bindValue="id"
                      formControlName="fileType"
                      [closeOnSelect]="true"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        formSubmitted && ReactiveFormControl.fileType.errors
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="ReactiveFormControl.fileType.errors.required">
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <app-file-drop-upload
                    inputId="filesToBeUploaded"
                    [selectedFiles]="filesToBeUploaded"
                    (updateSelectedFiles)="onFilesAdd($event)"
                    (removeFile)="onFileRemove($event)"
                    [multiple]="multipleFileSelection"
                  ></app-file-drop-upload>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-right">
                  <button
                    type="button"
                    rippleEffect
                    class="btn btn-primary mr-1"
                    (click)="onSubmitClick()"
                    [disabled]="!filesToBeUploaded?.length"
                  >
                    {{ "Submit" | translate }}
                  </button>
                  <button
                    type="button"
                    rippleEffect
                    class="btn btn-outline-secondary"
                    (click)="handleCloseClick()"
                  >
                    {{ "Cancel" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
