import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { createSelector, StoreModule } from "@ngrx/store";
import { NotificationsEffect } from "./effect";
import { NotificationsReducer } from "./reducer";
import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([NotificationsEffect]),
        StoreModule.forFeature('notifications', NotificationsReducer)
    ]
})

export class NotificationsStoreModule { }

export const notificationsList = (store: any) => store && store.notifications && store.notifications.notificationsList;
export const notificationsByFieldList = (store: any) => store && store.notifications && store.notifications.notificationsByFieldList;
export const notificationsById = (store: any) => store && store.notifications && store.notifications.notificationsById;
export const notificationsTemplateList = (store: any) => store && store.notifications && store.notifications.notificationsTemplateList;
export const addNotificationsResponse = (store: any) => store && store.notifications && store.notifications.addNotificationsResponse;
export const updateNotificationsResponse = (store: any) => store && store.notifications && store.notifications.updateNotificationsResponse;
export const deleteNotificationsResponse = (store: any) => store && store.notifications && store.notifications.deleteNotificationsResponse;

export const notificationsWithNotificationType = createSelector(
    notificationsList, helperValues, (list, values) => {
        const notificationTypes = values?.notificationType || [];
        return (list || []).map(item => {
            const notificationTypeId = item.notificationType;
            return {
                ...item,
                notificationTypeValue: notificationTypes.find(({ id }) => id === notificationTypeId)?.name || '',
            }
        })
    })
