import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as SpeechAiDocumentsAction from './actions';
import { SpeechAiService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class SpeechAiEffect {
    constructor(
        private action: Actions,
        private speechAiService: SpeechAiService
    ) { }

    GetAllSpeechAiDocuments = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_SPEECH_AI_DOCUMENTS),
        switchMap(() => this.speechAiService.getAllSpeechAiDocuments()),
        map((data: Array<any>) => new SpeechAiDocumentsAction.SetAllSpeechAiDocuments(data)
        )
    ));

    GetSpeechAiDocumentById = createEffect(() => this.action.pipe(
        ofType(type.GET_SPEECH_AI_DOCUMENT_BY_ID),
        switchMap((data: any) => this.speechAiService.getSpeechAiDocumentById(data.payload)),
        map((data: any) => new SpeechAiDocumentsAction.SetSpeechAiDocumentById(data)
        )
    ));

    AddSpeechAiDocument = createEffect(() => this.action.pipe(
        ofType(type.ADD_SPEECH_AI_DOCUMENT),
        switchMap((data: any) => this.speechAiService.addSpeechAiDocument(data.payload)),
        map((data: IServerResponse) => new SpeechAiDocumentsAction.AddSpeechAiDocumentResponse(data)
        )
    ));

    DeleteSpeechAiDocument = createEffect(() => this.action.pipe(
        ofType(type.DELETE_SPEECH_AI_DOCUMENT),
        switchMap((data: any) => this.speechAiService.deleteSpeechAiDocument(data.payload)),
        map((data: IServerResponse) => new SpeechAiDocumentsAction.DeleteSpeechAiDocumentResponse(data)
        )
    ));

    SaveSpeechAiDocumentAsNote = createEffect(() => this.action.pipe(
        ofType(type.SAVE_SPEECH_AI_DOCUMENT_AS_NOTE),
        switchMap((data: any) => this.speechAiService.saveSpeechAiDocumentAsNote(data.payload)),
        map((data: IServerResponse) => new SpeechAiDocumentsAction.SaveSpeechAiDocumentAsNoteResponse(data)
        )
    ));

    RunSpeechAiExtractionFromSummary = createEffect(() => this.action.pipe(
        ofType(type.RUN_SPEECH_AI_EXTRACTION_FROM_SUMMARY),
        switchMap((data: any) => this.speechAiService.runSpeechAiExtractionFromSummary(data.payload)),
        map((data: IServerResponse) => new SpeechAiDocumentsAction.RunSpeechAiExtractionFromSummaryResponse(data)
        )
    ));
}
