import { initialState, BillingState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_BILLING_DATA, SET_BILLING_BY_ID, ADD_BILLING_RESPONSE, UPDATE_BILLING_RESPONSE,
    DELETE_BILLING_RESPONSE,
    SET_BILLING_BY_FIELD
} from "./actionTypes";

export function BillingStateReducer(state = initialState, action: Actions): BillingState {
    const { type } = action;

    switch (type) {
        case SET_ALL_BILLING_DATA:
            return Object.assign({}, state, {
                allBillingData: action.payload
            });
        case SET_BILLING_BY_FIELD:
            return Object.assign({}, state, {
                allBillingData: action.payload
            });
        case SET_BILLING_BY_ID:
            return Object.assign({}, state, {
                billingById: action.payload
            });
        case ADD_BILLING_RESPONSE:
            return Object.assign({}, state, {
                addBillingResponse: action.payload
            });
        case UPDATE_BILLING_RESPONSE:
            return Object.assign({}, state, {
                updateBillingResponse: action.payload
            });
        case DELETE_BILLING_RESPONSE:
            return Object.assign({}, state, {
                deleteBillingResponse: action.payload
            });

        default:
            return state;
    }
}
