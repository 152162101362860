import { IServerResponse } from "app/interface";

export interface UsersState {
    allUsers: Array<any>;
    allUsersBySearch: Array<any>;
    userById: any;
    addUserResponse: IServerResponse;
    addGuestUserResponse: IServerResponse;
    updatedUserResponse: IServerResponse;
    deletedUserResponse: IServerResponse;
    userProfileById: any;
    updateUserProfileByIdResponse: IServerResponse
}

export const initialState: UsersState = {
    allUsers: null,
    allUsersBySearch: null,
    userById: null,
    addUserResponse: null,
    addGuestUserResponse: null,
    updatedUserResponse: null,
    deletedUserResponse: null,
    userProfileById: null,
    updateUserProfileByIdResponse: null
}
