import { NgModule } from "@angular/core";
import { MyTaskService } from "./my-tasks/service";

@NgModule({
    providers: [
        MyTaskService
    ]
})

export class HomeServiceModule { }
