import { initialState, RolesState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_ROLES, SET_ROLE_BY_ID, ADD_ROLE_RESPONSE, UPDATE_ROLE_RESPONSE,
    DELETE_ROLE_RESPONSE
} from "./actionTypes";

export function RolesStateReducer(state = initialState, action: Actions): RolesState {
    const { type } = action;

    switch (type) {
        case SET_ALL_ROLES:
            return Object.assign({}, state, {
                allRoles: action.payload
            });
        case SET_ROLE_BY_ID:
            return Object.assign({}, state, {
                roleById: action.payload
            });
        case ADD_ROLE_RESPONSE:
            return Object.assign({}, state, {
                addRoleResponse: action.payload
            });
        case UPDATE_ROLE_RESPONSE:
            return Object.assign({}, state, {
                updateRoleResponse: action.payload
            });
        case DELETE_ROLE_RESPONSE:
            return Object.assign({}, state, {
                deleteRoleResponse: action.payload
            });

        default:
            return state;
    }
}
