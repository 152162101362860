import { AfterContentInit, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cm-file-explorer',
    templateUrl: './cm-file-explorer.component.html',
    styleUrls: ['./cm-file-explorer.component.scss']
})
export class CmFileExplorerComponent implements OnInit, AfterContentInit {

    constructor() { }

    ngOnInit(): void {
    }

    ngAfterContentInit(): void {
        const refreshBtnIcon = document.getElementsByClassName("nxe-arrows-cw");
        if (refreshBtnIcon?.length) {
            const parentNode = (<HTMLElement>refreshBtnIcon[0].parentNode)
            if (parentNode?.click) {
                parentNode.click();
            }
        }
    }
}
