import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TOASTR_TYPE } from 'app/constants/common.constant';
import { PresentToastr } from 'app/store/common/actions';

import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { ManualUpload, ManualUploadResponse, ManualUploadOverride, ManualUploadOverrideResponse, GetMatterById } from 'app/store/operation/matters/actions';
import { manualUploadResponse, manualUploadOverrideResponse } from 'app/store/operation/matters/store.module';
import { checkFilesSize } from 'app/utils/commonFunctions.util';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@core/services/file.service';

@Component({
    selector: 'app-manual-id-address-check-modal',
    templateUrl: './manual-id-address-check-modal.component.html',
    styleUrls: ['./manual-id-address-check-modal.component.scss']
})
export class ManualIdAddressCheckModalComponent implements OnInit, OnDestroy {
    @BlockUI('manual-upload-modal') manualUploadModalBlockUI: NgBlockUI;
    @BlockUI('matters') matterBlockUI: NgBlockUI;

    @Input() manualCheckType: string = "";
    @Input() matterReference: string = "";
    @Input() matterId: string = "";
    @Input() handleCancelClick: Function;

    public showReason: boolean = false;
    public fileToBeUploaded: any = null;
    public matchData: Array<any> = [];
    public reason: string = "";

    private unsubscribe = new Subject();
    constructor(
        private store: Store,
        private _translateService: TranslateService,
        private _fileService: FileService
    ) { }

    ngOnInit(): void {
        this.store
            .select(manualUploadResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                if (response) {
                    this.manualUploadModalBlockUI.stop();
                    const { status, message, data } = response;

                    this.matchData = [];

                    if (status === true) {
                        switch (this.manualCheckType) {
                            case "ID":
                                const { nameValidation } = data;
                                if (nameValidation?.isValidation === false) {
                                    this.matchData = this.matchData.concat({ ...nameValidation, name: 'Name' });
                                    this.showReason = true;
                                } else {
                                    this.store.dispatch(
                                        new PresentToastr({
                                            type: TOASTR_TYPE.SUCCESS,
                                            message: message
                                        })
                                    );
                                    this.handleCancelClick();
                                }
                                break;
                            case "ADDRESS":
                                const { addressValidation } = data;

                                if (addressValidation?.address?.isValidation === false || addressValidation?.name?.isValidation === false) {
                                    if (addressValidation?.address) {
                                        this.matchData = this.matchData.concat({ ...addressValidation.address, name: 'Address' });
                                    };
                                    if (addressValidation?.name) {
                                        this.matchData = this.matchData.concat({ ...addressValidation.name, name: 'Name' });
                                    };
                                    this.showReason = true;
                                } else {
                                    this.store.dispatch(
                                        new PresentToastr({
                                            type: TOASTR_TYPE.SUCCESS,
                                            message: message
                                        })
                                    );
                                    this.handleCancelClick();
                                }
                                break;
                            default:
                                break;
                        }
                    } else {
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: message
                            })
                        );
                    }

                    this.store.dispatch(new ManualUploadResponse(null));
                }
            });

        this.store
            .select(manualUploadOverrideResponse)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.manualUploadModalBlockUI.stop();

                    if (resp.status) {
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.SUCCESS,
                                message: resp.message
                            })
                        );
                        this.handleCancelClick();
                        this.showReason = false;

                        this.matterBlockUI.start();
                        this.store.dispatch(new GetMatterById(this.matterId));
                    } else {
                        this.store.dispatch(
                            new PresentToastr({
                                type: TOASTR_TYPE.ERROR,
                                message: resp.message
                            })
                        );
                    }

                    this.store.dispatch(new ManualUploadOverrideResponse(null));
                }
            });
    }
    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.showReason = false;
        this.fileToBeUploaded = null;
        this.matchData = [];
        this.reason = "";
    }

    get ModalTitle() {
        switch (this.manualCheckType) {
            case "ID":
                return "Manual ID Check";
            case "ADDRESS":
                return "Manual Address Check";
            default:
                return "Manual Check";
        }
    }

    async onDocumentChange({ newFiles, groupName, controlName }) {
        if (newFiles) {
            const filesArray = Array.from(newFiles);
            const { sizeValidity } = checkFilesSize({
                filesObjArr: filesArray,
                upperLimitInMB: 20,
                _translateService: this._translateService
            });

            if (sizeValidity) {
                this.manualUploadModalBlockUI.start();

                const file: any = filesArray[0];
                const uploadResponse: any = await this._fileService
                    .uploadFile(file, this.matterReference, 'NONE')
                    .toPromise();
                let url = '';
                const { status, data } = uploadResponse;
                if (status) {
                    url = data?.id;
                }

                this.fileToBeUploaded = {
                    url, name: file.name
                };
                this.manualUploadModalBlockUI.stop();
            } else {
                this.store.dispatch(
                    new PresentToastr({
                        type: TOASTR_TYPE.WARNING,
                        message: this._translateService.instant(
                            'Files should not be larger than 20MB'
                        )
                    })
                );
            }
        }
        (<HTMLInputElement>document.getElementById(controlName)).value = null;
    }

    onDocumentRemove() {
        this.fileToBeUploaded = null;
    }

    handleSubmit(e) {
        this.manualUploadModalBlockUI.start();

        const dataToBeSubmitted: any = {};
        switch (this.manualCheckType) {
            case "ID":
                dataToBeSubmitted.idDocument = this.fileToBeUploaded;
                dataToBeSubmitted.addressDocument = null;
                break;
            case "ADDRESS":
                dataToBeSubmitted.idDocument = null;
                dataToBeSubmitted.addressDocument = this.fileToBeUploaded;
                break;
            default:
                break;
        }

        this.store.dispatch(new ManualUpload({
            reference: this.matterReference,
            data: dataToBeSubmitted
        }));
    }

    handleOverrideSubmit() {
        this.manualUploadModalBlockUI.start();

        const dataToBeSubmitted = { reason: this.reason, isAddressDocument: false };

        switch (this.manualCheckType) {
            case "ID":
                dataToBeSubmitted.isAddressDocument = false;
                break;
            case "ADDRESS":
                dataToBeSubmitted.isAddressDocument = true;
                break;
            default:
                break;
        }

        this.store.dispatch(new ManualUploadOverride({
            reference: this.matterReference,
            data: dataToBeSubmitted
        }));
    }
}
