import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { UrlUtil } from 'app/utils/url.util';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MyTaskService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store
    ) {}

    public getAllTasks(userId) {
        return this.http
            .get(
                this.urlUtil
                    .getUrl('task', 'getAll')
                    .replace('$USER_ID', userId)
            )
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log('getAllTasks error =>', e);
                    return of([]);
                })
            );
    }

    public getAssignUserList(userId) {
        return this.http
            .get(
                this.urlUtil
                    .getUrl('task', 'assignUserList')
                    .replace('$USER_ID', userId)
            )
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log('getAllTasks error =>', e);
                    return of([]);
                })
            );
    }

    public getAllProjects() {
        return this.http.get(this.urlUtil.getUrl('project', 'getAll')).pipe(
            map((resp: any) => {
                if (resp.status) {
                    return resp.data;
                } else {
                    return [];
                }
            }),
            catchError((e) => {
                console.log('getAllProjects error =>', e);
                return of([]);
            })
        );
    }

    public getTaskById(id) {
        return this.http
            .get(this.urlUtil.getUrl('task', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return { status: true, data: resp.data };
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log('getTaskById error =>', e);

                    this.store.dispatch(
                        new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message:
                                e?.errorMessage ||
                                e?.message ||
                                this._translateService.instant('ErrorGeneral')
                        })
                    );

                    return of({
                        status: false,
                        errorMessage:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('ErrorGeneral')
                    });
                })
            );
    }

    public getTaskChartDataByUserId(userId) {
        return this.http
            .get(
                this.urlUtil
                    .getUrl('task', 'chartData')
                    .replace('$USER_ID', userId)
            )
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return { status: true, data: resp.data };
                    } else {
                        return { status: false };
                    }
                }),
                catchError((e) => {
                    console.log('getTaskChartDataByUserId error =>', e);

                    this.store.dispatch(
                        new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message:
                                e?.errorMessage ||
                                e?.message ||
                                this._translateService.instant('ErrorGeneral')
                        })
                    );

                    return of({
                        status: false,
                        errorMessage:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('ErrorGeneral')
                    });
                })
            );
    }

    public getProjectById(id) {
        return this.http
            .get(
                this.urlUtil.getUrl('project', 'searchById').replace('$ID', id)
            )
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log('getProjectById error =>', e);

                    this.store.dispatch(
                        new PresentToastr({
                            type: TOASTR_TYPE.ERROR,
                            message:
                                e?.errorMessage ||
                                e?.message ||
                                this._translateService.instant('ErrorGeneral')
                        })
                    );

                    return of({});
                })
            );
    }

    public addTask(dataObject) {
        return this.http
            .post(this.urlUtil.getUrl('task', 'add'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant('DataAddSuccess')
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataAdd')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('addTask error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataAdd')
                    });
                })
            );
    }

    public addProject(dataObject) {
        return this.http
            .post(this.urlUtil.getUrl('project', 'add'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant('DataAddSuccess')
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataAdd')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('addProject error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataAdd')
                    });
                })
            );
    }

    public updateTask(dataObject) {
        return this.http
            .put(
                this.urlUtil
                    .getUrl('task', 'update')
                    .replace('$ID', dataObject.id),
                dataObject
            )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant(
                                    'DataUpdateSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataUpdate')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('updateTask error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataUpdate')
                    });
                })
            );
    }

    public updateTaskStatus(dataObject) {
        return this.http
            .put(
                this.urlUtil
                    .getUrl('task', 'updateStatus')
                    .replace('$ID', dataObject.id),
                dataObject
            )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant(
                                    'DataUpdateSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataUpdate')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('updateTaskStatus error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataUpdate')
                    });
                })
            );
    }

    public reassignTask(dataObject) {
        const id = dataObject.id;
        const data = { ...dataObject };
        delete data.id;
        return this.http
            .post(
                this.urlUtil.getUrl('task', 'reassignTask').replace('$ID', id),
                data
            )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant(
                                    'DataUpdateSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataUpdate')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('updateTaskStatus error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataUpdate')
                    });
                })
            );
    }

    public createCalendarEvent(dataObject) {
        const { id, startTime, duration } = dataObject;
        return this.http
            .post(
                this.urlUtil.getUrl('task', 'createCalendarEvent').replace('$ID', id).replace('$DATE', startTime), duration
            )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant(
                                    'DataUpdateSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataUpdate')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('updateTaskStatus error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataUpdate')
                    });
                })
            );
    }

    public updateProject(dataObject) {
        return this.http
            .put(
                this.urlUtil
                    .getUrl('project', 'update')
                    .replace('$ID', dataObject.id),
                dataObject
            )
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                this._translateService.instant(
                                    'DataUpdateSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                errorMessage ||
                                jsonData ||
                                this._translateService.instant('FailDataUpdate')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('updateProject error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataUpdate')
                    });
                })
            );
    }

    public deleteTask(idsArray) {
        return this.http
            .post(this.urlUtil.getUrl('task', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                (errorMessage || '') + (jsonData || '') ||
                                this._translateService.instant(
                                    'DataDeleteSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                (errorMessage || '') + (jsonData || '') ||
                                this._translateService.instant('FailDataDelete')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('deleteTask error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataDelete')
                    });
                })
            );
    }

    public deleteProject(idsArray) {
        return this.http
            .post(this.urlUtil.getUrl('project', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp;
                    if (status) {
                        return {
                            status: status,
                            message:
                                (errorMessage || '') + (jsonData || '') ||
                                this._translateService.instant(
                                    'DataDeleteSuccess'
                                )
                        };
                    } else {
                        return {
                            status: false,
                            message:
                                (errorMessage || '') + (jsonData || '') ||
                                this._translateService.instant('FailDataDelete')
                        };
                    }
                }),
                catchError((e) => {
                    console.log('deleteProject error =>', e);
                    return of({
                        status: false,
                        message:
                            e?.errorMessage ||
                            e?.message ||
                            this._translateService.instant('FailDataDelete')
                    });
                })
            );
    }
}
