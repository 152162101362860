export const MODULE_ACTION = {
    ADD: {
        ID: 'ADD',
        NAME: 'Add',
        ICON: 'plus'
    },
    UPLOAD_CSV: {
        ID: 'UPLOAD_CSV',
        NAME: 'Upload CSV',
        ICON: 'upload'
    },
    EXPORT: {
        ID: 'EXPORT',
        NAME: 'Export',
        ICON: 'download'
    },
    IMPORT: {
        ID: 'IMPORT',
        NAME: 'Import',
        ICON: 'download'
    },
    UPLOAD: {
        ID: 'UPLOAD',
        NAME: 'Upload',
        ICON: 'upload'
    },
    CLEAN: {
        ID: 'CLEAN',
        NAME: 'Clean',
        ICON: 'trash'
    }
}
