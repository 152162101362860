import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as NotificationsAction from './actions';
import { NotificationsService } from "./service";

@Injectable()
export class NotificationsEffect {

    constructor(
        private action: Actions,
        private notificationsService: NotificationsService
    ) { }

    GetNotificationsList = createEffect(() => this.action.pipe(
        ofType(type.GET_NOTIFICATION_LIST),
        switchMap((data) => this.notificationsService.getNotificationsList()),
        map((data: Array<any>) => new NotificationsAction.NotificationsList(data)
        )
    ));

    GetNotificationsTemplateList = createEffect(() => this.action.pipe(
        ofType(type.GET_NOTIFICATION_TEMPLATE_LIST),
        switchMap((data) => this.notificationsService.getNotificationsTemplateList()),
        map((data: Array<any>) => new NotificationsAction.NotificationsTemplateList(data)
        )
    ));

    NotificationsById = createEffect(() => this.action.pipe(
        ofType(type.SEARCH_NOTIFICATION_BY_ID),
        switchMap((data: any) => this.notificationsService.getNotificationsById(data.payload)),
        map((data: Array<any>) => new NotificationsAction.NotificationsById(data)
        )
    ));

    AddNotifications = createEffect(() => this.action.pipe(
        ofType(type.ADD_NOTIFICATION),
        switchMap((data: any) => this.notificationsService.addNotifications(data.payload)),
        map((data: any) => new NotificationsAction.AddNotificationsResponse(data)
        )
    ));

    UpdateNotifications = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_NOTIFICATION),
        switchMap((data: any) => this.notificationsService.updateNotifications(data.payload)),
        map((data: any) => new NotificationsAction.UpdateNotificationsResponse(data)
        )
    ));

    DeleteNotifications = createEffect(() => this.action.pipe(
        ofType(type.DELETE_NOTIFICATION),
        switchMap((data: any) => this.notificationsService.deleteNotifications(data.payload)),
        map((data: any) => new NotificationsAction.DeleteNotificationsResponse(data)
        )
    ));

    SearchNotificationsByFields = createEffect(() => this.action.pipe(
        ofType(type.SEARCH_NOTIFICATION_BY_FIELD),
        switchMap((data: any) => this.notificationsService.searchNotificationsByFields(data.payload)),
        map((data) => new NotificationsAction.NotificationsByFieldList(data)
        )
    ));
}
