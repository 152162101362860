import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { SpeechAiEffect } from "./effect";
import { SpeechAiStateReducer } from "./reducer";

import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([SpeechAiEffect]),
        StoreModule.forFeature('speechAi', SpeechAiStateReducer)
    ]
})

export class SpeechAiStoreModule { }

export const allSpeechAiDocuments = (store: any) => store && store.speechAi && store.speechAi.allSpeechAiDocuments;
export const speechAiDocumentById = (store: any) => store && store.speechAi && store.speechAi.speechAiDocumentById;
export const addSpeechAiDocumentResponse = (store: any) => store && store.speechAi && store.speechAi.addSpeechAiDocumentResponse;
export const deleteSpeechAiDocumentResponse = (store: any) => store && store.speechAi && store.speechAi.deleteSpeechAiDocumentResponse;
export const saveSpeechAiDocumentAsNoteResponse = (store: any) => store && store.speechAi && store.speechAi.saveSpeechAiDocumentAsNoteResponse;
export const runSpeechAiExtractionFromSummaryResponse = (store: any) => store && store.speechAi && store.speechAi.runSpeechAiExtractionFromSummaryResponse;

export const allSpeechAiDocumentsWithStateName = createSelector(
    allSpeechAiDocuments,
    helperValues,
    (documents, helperValues) => {
        const allItems = (documents || []).map((item) => ({
            ...item,
            s2TStateName: (helperValues?.sttstate || []).find(({ id }) => id === item.sttstate)?.name || item.sttstate || "-"
        }))
        return allItems;
    }
)
