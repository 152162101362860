import { initialState, AuditLogState } from "./state";
import { Actions } from './actions';
import { SET_AUDIT_LOGS, DOWNLOAD_AUDIT_LOG_CSV_RESPONSE } from "./actionTypes";
import { sortListDescending } from "app/utils/sort.util";

export function AuditLogReducer(state = initialState, action: Actions): AuditLogState {
    switch (action.type) {
        case SET_AUDIT_LOGS:
            return Object.assign({}, state, {
                auditLogs: action.payload
            });
        // return Object.assign({}, state, {
        //     auditLogs: sortListDescending(action.payload, "eventTime", "number")
        // });
        case DOWNLOAD_AUDIT_LOG_CSV_RESPONSE:
            return Object.assign({}, state, {
                downloadAuditLogCsvResponse: action.payload
            });
        default:
            return state;
    }
}
