import { Injectable } from "@angular/core";
import { URL_CONST } from "@constant"
interface IqueryParam {
    key: string;
    value: string
}
@Injectable()
export class UrlUtil {
    constructor() {
    }

    private createUrl(module: string, submodule: string): string {
        let url = URL_CONST.BASE_URL;
        if (module === "authenticate") {
            if (submodule === "logIn") {
                url += URL_CONST.AUTHENTICATION.LOG_IN;
            } else if (submodule === "forgotPassword") {
                url += URL_CONST.AUTHENTICATION.FORGOT_PASSWORD;
            } else if (submodule === "resetPassword") {
                url += URL_CONST.AUTHENTICATION.RESET_PASSWORD;
            } else if (submodule === "logout") {
                url += URL_CONST.AUTHENTICATION.LOGOUT;
            } else if (submodule === "refreshToken") {
                url += URL_CONST.AUTHENTICATION.REFRESH_TOKEN;
            }
        } else if (module === "config") {
            if (submodule === "appConfig") {
                url += URL_CONST.CONFIG.APP_CONFIG;
            }
        } else if (module === "access") {
            if (submodule === "appAccess") {
                url += URL_CONST.ACCESS.APP_MODULE_ACCESS;
            }
        } else if (module === "profile") {
            if (submodule === "update") {
                url += URL_CONST.PROFILE.UPDATE;
            }
            if (submodule === "userInfo") {
                url += URL_CONST.PROFILE.USER_INFO;
            }
        } else if (module === "common") {
            if (submodule === "helperValues") {
                url += URL_CONST.COMMON.GET_HELPER_VALUES;
            }
        } else if (module === "file") {
            if (submodule === "upload") {
                url += URL_CONST.FILE.UPLOAD;
            } else if (submodule === 'uploadAll') {
                url += URL_CONST.FILE.UPLOAD_ALL;
            } else if (submodule === "download") {
                url += URL_CONST.FILE.DOWNLOAD;
            } if (submodule === "upload-kai") {
                url += URL_CONST.FILE.KAI_UPLOAD;
            } else if (submodule === 'uploadAll-kai') {
                url += URL_CONST.FILE.KAI_UPLOAD_ALL;
            } else if (submodule === "download-kai") {
                url += URL_CONST.FILE.KAI_DOWNLOAD;
            } else if (submodule === "delete") {
                url += URL_CONST.FILE.DELETE;
            }
        } else if (module === "users") {
            if (submodule === "getAll") {
                url += URL_CONST.USERS.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.USERS.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.USERS.ADD
            } else if (submodule === "update") {
                url += URL_CONST.USERS.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.USERS.DELETE
            } else if (submodule === "addGuest") {
                url += URL_CONST.USERS.ADD_GUEST
            } else if (submodule === "getProfileById") {
                url += URL_CONST.USERS.GET_PROFILE_BY_ID
            } else if (submodule === "updateProfileById") {
                url += URL_CONST.USERS.UPDATE_PROFILE_BY_ID
            } else if (submodule === "searchOptions") {
                url += URL_CONST.USERS.SEARCH_OPTIONS
            } else if (submodule === "searchByField") {
                url += URL_CONST.USERS.SEARCH_BY_FIELD
            } else if (submodule === "searchByRole") {
                url += URL_CONST.USERS.SEARCH_BY_ROLE
            }
        } else if (module === "roles") {
            if (submodule === "getAll") {
                url += URL_CONST.ROLES.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.ROLES.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.ROLES.ADD
            } else if (submodule === "update") {
                url += URL_CONST.ROLES.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.ROLES.DELETE
            }
        } else if (module === "firms") {
            if (submodule === "getAll") {
                url += URL_CONST.FIRMS.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.FIRMS.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.FIRMS.ADD
            } else if (submodule === "update") {
                url += URL_CONST.FIRMS.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.FIRMS.DELETE
            } else if (submodule === "testConnectivity") {
                url += URL_CONST.FIRMS.TEST_CONNECTIVITY
            } else if (submodule === "searchOptions") {
                url += URL_CONST.FIRMS.GET_SEARCH_OPTION
            } else if (submodule === "documentSignature") {
                url += URL_CONST.FIRMS.DOCUMENT_SIGNATURE
            } else if (submodule === "sign-document-track-details") {
                url += URL_CONST.FIRMS.DOCUMENT_SIGNATURE_TRACK_DETAILS
            } else if (submodule === 'searchByField') {
                url += URL_CONST.FIRMS.DOCUMENT_SIGNATURE_SEARCH_BY_FIELDS;
            } else if (submodule === "download-sign-document") {
                url += URL_CONST.FIRMS.DOWNLOAD_SIGN_DOCUMENT
            } else if (submodule === "document-sign-search-options") {
                url += URL_CONST.FIRMS.DOWNLOAD_SIGN_SEARCH_OPTIONS
            } else if (submodule === "test-email-connectivity") {
                url += URL_CONST.FIRMS.TEST_EMAIL_CONNECTIVITY
            } else if (submodule === "send-document-signature-reminder") {
                url += URL_CONST.FIRMS.DOCUMENT_SIGNATURE_REMINDER
            } else if (submodule === "leads") {
                url += URL_CONST.FIRMS.GET_LEADS
            } else if (submodule === "leads-download") {
                url += URL_CONST.FIRMS.DOWNLOAD_LEADS
            }
        } else if (module === 'notifications') {
            if (submodule === 'getAll') {
                url += URL_CONST.NOTIFICATIONS.GET_ALL;
            } else if (submodule === 'searchById') {
                url += URL_CONST.NOTIFICATIONS.SEARCH_BY_ID;
            } else if (submodule === 'searchByField') {
                url += URL_CONST.NOTIFICATIONS.SEARCH_BY_FIELDS;
            } else if (submodule === 'template') {
                url += URL_CONST.NOTIFICATIONS.TEMPLATE;
            } else if (submodule === 'add') {
                url += URL_CONST.NOTIFICATIONS.ADD;
            } else if (submodule === 'update') {
                url += URL_CONST.NOTIFICATIONS.UPDATE;
            } else if (submodule === 'delete') {
                url += URL_CONST.NOTIFICATIONS.DELETE;
            } else if (submodule === 'searchOption') {
                url += URL_CONST.NOTIFICATIONS.GET_SEARCH_OPTION;
            } else if (submodule === 'searchByName') {
                url += URL_CONST.NOTIFICATIONS.SEARCH_BY_NAME;
            }
        } else if (module === "auditLogs") {
            if (submodule === "get") {
                url += URL_CONST.AUDIT_LOGS.GET
            } else if (submodule === "filter") {
                url += URL_CONST.AUDIT_LOGS.GET_FILTERED
            } else if (submodule === "downloadCsv") {
                url += URL_CONST.AUDIT_LOGS.DOWNLOAD_CSV
            }
        } else if (module === "enquiry") {
            if (submodule === "getAll") {
                url += URL_CONST.ENQUIRY.GET_ALL
            } else if (submodule === "getById") {
                url += URL_CONST.ENQUIRY.GET_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.ENQUIRY.ADD
            } else if (submodule === "update") {
                url += URL_CONST.ENQUIRY.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.ENQUIRY.DELETE
            } else if (submodule === "getExtractions") {
                url += URL_CONST.ENQUIRY.GET_EXTRACTION
            } else if (submodule === "searchByField") {
                url += URL_CONST.ENQUIRY.SEARCH_BY_FIELD
            } else if (submodule === "searchOptions") {
                url += URL_CONST.ENQUIRY.SEARCH_OPTIONS
            }
        } else if (module === "meters") {
            if (submodule === "getAll") {
                url += URL_CONST.METER.GET_ALL
            } else if (submodule === "getById") {
                url += URL_CONST.METER.GET_BY_ID
            } else if (submodule === "update") {
                url += URL_CONST.METER.UPDATE
            } else if (submodule === "searchByField") {
                url += URL_CONST.METER.SEARCH_BY_FIELD
            } else if (submodule === "searchOptions") {
                url += URL_CONST.METER.SEARCH_OPTIONS
            } else if (submodule === "export") {
                url += URL_CONST.METER.EXPORT
            }
        } else if (module === "task") {
            if (submodule === "getAll") {
                url += URL_CONST.TASK.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.TASK.SEARCH_BY_ID
            } else if (submodule === "chartData") {
                url += URL_CONST.TASK.GET_TASK_CHART_DATA_BY_USER_ID
            } else if (submodule === "add") {
                url += URL_CONST.TASK.ADD
            } else if (submodule === "update") {
                url += URL_CONST.TASK.UPDATE
            } else if (submodule === "updateStatus") {
                url += URL_CONST.TASK.UPDATE_STATUS
            } else if (submodule === "delete") {
                url += URL_CONST.TASK.DELETE
            } else if (submodule === "assignUserList") {
                url += URL_CONST.TASK.ASSIGN_USER_LIST
            } else if (submodule === "reassignTask") {
                url += URL_CONST.TASK.REASSIGN_TASK
            } else if (submodule === "createCalendarEvent") {
                url += URL_CONST.TASK.CREATE_CALENDAR_EVENT
            }
        } else if (module === "project") {
            if (submodule === "getAll") {
                url += URL_CONST.PROJECT.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.PROJECT.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.PROJECT.ADD
            } else if (submodule === "update") {
                url += URL_CONST.PROJECT.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.PROJECT.DELETE
            }
        } else if (module === 'systemCalendar') {
            if (submodule === 'calendarEvent') {
                url += URL_CONST.SYSTEM_CALENDAR.GET_CALENDAR_EVENT;
            } else if (submodule === 'addEvent') {
                url += URL_CONST.SYSTEM_CALENDAR.ADD_CALENDAR_EVENT;
            } else if (submodule === 'updateEvent') {
                url += URL_CONST.SYSTEM_CALENDAR.UPDATE_CALENDAR_EVENT;
            } else if (submodule === 'sync') {
                url += URL_CONST.SYSTEM_CALENDAR.SYNC_CALENDAR_DATA;
            } else if (submodule === 'searchById') {
                url += URL_CONST.SYSTEM_CALENDAR.SEARCH_BY_ID;
            } else if (submodule === 'deleteEvent') {
                url += URL_CONST.SYSTEM_CALENDAR.DELETE;
            } else if (submodule === 'syncCalendar') {
                url += URL_CONST.SYSTEM_CALENDAR.SYNC_CALENDAR;
            }
        } else if (module === "matters") {
            if (submodule === "getAll") {
                url += URL_CONST.MATTERS.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.MATTERS.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.MATTERS.ADD
            } else if (submodule === "update") {
                url += URL_CONST.MATTERS.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.MATTERS.DELETE
            } else if (submodule === "searchOptions") {
                url += URL_CONST.MATTERS.SEARCH_OPTIONS
            } else if (submodule === 'searchByField') {
                url += URL_CONST.MATTERS.SEARCH_BY_FIELD
            } else if (submodule === 'clientsByMatterId') {
                url += URL_CONST.MATTERS.CLIENTS_BY_MATTER_ID
            } else if (submodule === 'getFileComponentData') {
                url += URL_CONST.MATTERS.GET_FILE_COMPONENT_DATA
            } else if (submodule === 'addFileComponentData') {
                url += URL_CONST.MATTERS.ADD_FILE_COMPONENT_DATA
            } else if (submodule === 'updateFileComponentData') {
                url += URL_CONST.MATTERS.UPDATE_FILE_COMPONENT_DATA
            } else if (submodule === 'deleteFileComponentData') {
                url += URL_CONST.MATTERS.DELETE_FILE_COMPONENT_DATA
            } else if (submodule === 'manual-upload') {
                url += URL_CONST.MATTERS.MANUAL_UPLOAD;
            } else if (submodule === 'manual-upload-override') {
                url += URL_CONST.MATTERS.MANUAL_UPLOAD_OVERRIDE;
            }
        } else if (module === "clients") {
            if (submodule === "getAll") {
                url += URL_CONST.CLIENTS.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.CLIENTS.SEARCH_BY_ID
            } else if (submodule === "mattersByClientId") {
                url += URL_CONST.CLIENTS.MATTERS_BY_CLIENT_ID
            } else if (submodule === 'searchByName') {
                url += URL_CONST.CLIENTS.SEARCH_BY_FIELDS
            } else if (submodule === 'searchOptions') {
                url += URL_CONST.CLIENTS.SEARCH_OPTIONS
            } else if (submodule === "update") {
                url += URL_CONST.CLIENTS.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.CLIENTS.DELETE
            }
        } else if (module === "speech-ai") {
            if (submodule === "getAll") {
                url += URL_CONST.SPEECH_AI.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.SPEECH_AI.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.SPEECH_AI.ADD
            } else if (submodule === "delete") {
                url += URL_CONST.SPEECH_AI.DELETE
            } else if (submodule === "saveAsNote") {
                url += URL_CONST.SPEECH_AI.SAVE_AS_NOTE
            } else if (submodule === "run-extraction") {
                url += URL_CONST.SPEECH_AI.RUN_EXTRACTION
            }
        } else if (module === "vision-ai") {
            if (submodule === "getAll") {
                url += URL_CONST.VISION_AI.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.VISION_AI.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.VISION_AI.ADD
            } else if (submodule === "delete") {
                url += URL_CONST.VISION_AI.DELETE
            } else if (submodule === "saveAsNote") {
                url += URL_CONST.VISION_AI.SAVE_AS_NOTE
            } else if (submodule === "run-extraction") {
                url += URL_CONST.VISION_AI.RUN_EXTRACTION
            }
        } else if (module === "research-ai") {
            if (submodule === "getAll") {
                url += URL_CONST.RESEARCH_AI.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.RESEARCH_AI.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.RESEARCH_AI.ADD
            } else if (submodule === "update") {
                url += URL_CONST.RESEARCH_AI.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.RESEARCH_AI.DELETE
            } else if (submodule === "addSources") {
                url += URL_CONST.RESEARCH_AI.ADD_SOURCES
            } else if (submodule === "removeSources") {
                url += URL_CONST.RESEARCH_AI.REMOVE_SOURCES
            } else if (submodule === "train") {
                url += URL_CONST.RESEARCH_AI.TRAIN
            } else if (submodule === "query") {
                url += URL_CONST.RESEARCH_AI.QUERY
            }
        } else if (module === "billing") {
            if (submodule === "getAll") {
                url += URL_CONST.BILLING.GET_ALL
            } else if (submodule === "searchById") {
                url += URL_CONST.BILLING.SEARCH_BY_ID
            } else if (submodule === "add") {
                url += URL_CONST.BILLING.ADD
            } else if (submodule === "update") {
                url += URL_CONST.BILLING.UPDATE
            } else if (submodule === "delete") {
                url += URL_CONST.BILLING.DELETE
            } else if (submodule === "download-billing") {
                url += URL_CONST.BILLING.DOWNLOAD_BILLING
            }
        } else if (module === "dataConnectors") {
            if (submodule === "getAll") {
                url += URL_CONST.DATA_CONNECTORS.GET_ALL
            } else if (submodule === "clean") {
                url += URL_CONST.DATA_CONNECTORS.CLEAN
            } else if (submodule === "upload") {
                url += URL_CONST.DATA_CONNECTORS.UPLOAD
            }
        }
        return url;
    }

    private createQueryParam(queryParam: Array<IqueryParam>) {
        let queryParamUrl = ""
        if (queryParam && queryParam.length) {
            queryParam.forEach((item, index) => {
                if (index === 0) {
                    queryParamUrl += "?";
                }
                queryParamUrl += encodeURIComponent(item.key) + "=" + encodeURIComponent(item.value);
                if (index !== queryParam.length - 1) {
                    queryParamUrl += "&";
                }
            })
        }
        return queryParamUrl;
    }

    public getUrl(module, submodule, queryParam: Array<IqueryParam> = []) {
        return this.createUrl(module, submodule) + this.createQueryParam(queryParam);
    }
}
