import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";
import {
    NOTIFICATION_LIST, GET_NOTIFICATION_LIST, GET_NOTIFICATION_TEMPLATE_LIST, NOTIFICATION_TEMPLATE_LIST,
    SEARCH_NOTIFICATION_BY_ID, NOTIFICATION_BY_ID, ADD_NOTIFICATION, ADD_NOTIFICATION_RESPONSE,
    UPDATE_NOTIFICATION, UPDATE_NOTIFICATION_RESPONSE, DELETE_NOTIFICATION, DELETE_NOTIFICATION_RESPONSE,
    SEARCH_NOTIFICATION_BY_FIELD, NOTIFICATION_BY_FIELD_LIST
} from "./actionTypes";

export class GetNotificationsList implements Action {
    readonly type = GET_NOTIFICATION_LIST;
}

export class NotificationsList implements Action {
    readonly type = NOTIFICATION_LIST;
    readonly payload = []
    constructor(notificationsList: Array<any>) {
        this.payload = notificationsList;
    }
}

export class SearchNotificationsById implements Action {
    readonly type = SEARCH_NOTIFICATION_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class NotificationsById implements Action {
    readonly type = NOTIFICATION_BY_ID;
    readonly payload = null;
    constructor(data) {
        this.payload = data
    }
}

export class SearchNotificationsByField implements Action {
    readonly type = SEARCH_NOTIFICATION_BY_FIELD;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class NotificationsByFieldList implements Action {
    readonly type = NOTIFICATION_BY_FIELD_LIST;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class GetNotificationsTemplateList implements Action {
    readonly type = GET_NOTIFICATION_TEMPLATE_LIST;
}

export class NotificationsTemplateList implements Action {
    readonly type = NOTIFICATION_TEMPLATE_LIST;
    readonly payload = []
    constructor(notificationsTemplateList: Array<any>) {
        this.payload = notificationsTemplateList;
    }
}

export class AddNotifications implements Action {
    readonly type = ADD_NOTIFICATION;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class AddNotificationsResponse implements Action {
    readonly type = ADD_NOTIFICATION_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(resp) {
        this.payload = resp;
    }
}

export class UpdateNotifications implements Action {
    readonly type = UPDATE_NOTIFICATION;
    readonly payload = null;
    constructor(data) {
        this.payload = data;
    }
}

export class UpdateNotificationsResponse implements Action {
    readonly type = UPDATE_NOTIFICATION_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(resp) {
        this.payload = resp;
    }
}

export class DeleteNotifications implements Action {
    readonly type = DELETE_NOTIFICATION;
    readonly payload = null;
    constructor(id: string) {
        this.payload = id;
    }
}

export class DeleteNotificationsResponse implements Action {
    readonly type = DELETE_NOTIFICATION_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(resp) {
        this.payload = resp;
    }
}

export type Actions = GetNotificationsList | NotificationsList | SearchNotificationsById | NotificationsById | GetNotificationsTemplateList | NotificationsTemplateList |
    AddNotifications | AddNotificationsResponse | UpdateNotifications | UpdateNotificationsResponse | DeleteNotifications | DeleteNotificationsResponse
    | SearchNotificationsByField | NotificationsByFieldList;
