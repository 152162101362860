import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    Output,
    EventEmitter
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { formatDate } from '@angular/common';
import { CommonUtil } from 'app/utils/common.util';
import { helperValues } from 'app/store/common/commonStore.module';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ModuleAccessValue } from 'app/interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    GetAssignUserList,
    SetTaskById
} from 'app/store/home/my-tasks/actions';

import { allMatters } from 'app/store/operation/matters/store.module';
import { allBillingData } from 'app/store/tracking/billing/store.module';
import { allEnquiries } from 'app/store/tracking/enquiry/store.module';
import { userList } from 'app/store/home/my-tasks/store.module';
import { ALLOWED_ROLES_IN_ROLE_BASED_TASK } from 'app/constants/common.constant';
import { GetAllEnquiries } from 'app/store/tracking/enquiry/actions';
import { GetAllMatters } from 'app/store/operation/matters/actions';

@Component({
    selector: 'app-add-task-modal',
    templateUrl: './add-task-modal.component.html',
    styleUrls: ['./add-task-modal.component.scss']
})
export class AddTaskModalComponent implements OnInit, OnDestroy {
    @Input() title: String = '';
    @Input() taskDetails: any = {};
    @Input() taskList: Array<any> = [];
    public userList: Array<any> = [];
    public enquiryList: Array<any> = [];
    public caseMatterList: Array<any> = [];
    public billingList: Array<any> = [];
    @Input() moduleAccess: ModuleAccessValue;
    @Input() handleSubmitClick: Function;
    @Input() handleCloseClick: Function;
    @Input() updateTaskDetails: Function;
    @Input() taskForm: FormGroup;

    @Output() destroyAddTaskModal: EventEmitter<void> = new EventEmitter();

    formSubmitted: boolean = false;

    public priorityOptions: Array<any> = [];
    public referenceModuleOptions: Array<any> = [];
    public moduleOptions: Array<any> = [];
    public taskDurationOptions: Array<any> = [];
    public taskStatusOptions: Array<any> = [];
    public typeOptions: Array<any> = [];
    public roleOptions: Array<any> = [];
    public hasLinkedModule: boolean = false;
    public assignSomeOne: boolean = false;
    public hourOptions: any;
    public minuteOptions: any;

    public modalRef: any;

    unsubscribe = new Subject();

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private commonUtil: CommonUtil
    ) {
        this.store.dispatch(
            new GetAssignUserList(this.commonUtil.getUserDetail('id'))
        );
        this.store.dispatch(new GetAllEnquiries());
        this.store.dispatch(new GetAllMatters());
    }

    ngOnInit(): void {
        if (this.taskDetails?.id) {
            const [dueDate, time] = this.commonUtil
                .convertMillisToDateTime(this.taskDetails?.dueDate)
                ?.split('T');

            const hour = `${this.taskDetails?.duration?.hour || 0}`;
            const minute = `${this.taskDetails?.duration?.minute || 0}`;
            const duration = {
                hour: hour,
                minute: minute
            };

            this.taskForm.patchValue({
                ...this.taskDetails,
                dueDate: dueDate,
                duration: duration
            });

            if (this.taskDetails?.module !== 'MISC') {
                this.hasLinkedModule = true;
            }
            if (
                this.taskDetails?.userId !== this.commonUtil.getUserDetail('id')
            ) {
                this.assignSomeOne = true;
            }

            // this.disableForm();
        } else {
            Object.keys(this.taskForm.controls).forEach((field: string) => {
                if (field !== 'status' && field !== 'comment') {
                    this.taskForm.get(field)?.enable();
                } else {
                    this.taskForm.get(field)?.enable();
                }
            });
        }
        const taskType = this.taskDetails?.type || 'USER_BASED';
        this.handleTypeChange(taskType === 'ROLE_BASED');
        this.taskForm
            .get('status')
            .patchValue(this.taskDetails?.status || 'OnTrack');

        this.store
            .select(helperValues)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.priorityOptions = resp?.taskPriority;
                    this.referenceModuleOptions = resp?.taskModule?.filter(
                        ({ id }) => id !== 'MISC'
                    );
                    this.taskStatusOptions = resp?.taskStatus;
                    this.roleOptions =
                        resp?.userRole?.filter(({ id }) =>
                            ALLOWED_ROLES_IN_ROLE_BASED_TASK.includes(id)
                        ) || [];
                    this.typeOptions = [
                        { id: 'USER_BASED', name: 'User Based' },
                        { id: 'ROLE_BASED', name: 'Role Based' }
                    ];
                    this.hourOptions = resp?.taskHour;
                    this.minuteOptions = resp?.taskMinute;
                }
            });

        this.store
            .select(userList)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.userList = this.addNameInObjectArray(resp);
                }
            });
        this.store
            .select(allEnquiries)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.enquiryList = resp;
                }
            });
        this.store
            .select(allMatters)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.caseMatterList = resp;
                }
            });
        this.store
            .select(allBillingData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((resp) => {
                if (resp) {
                    this.billingList = resp;
                }
            });

        const taskModule = this.taskDetails?.module || 'MISC';
        this.taskForm.get('module').patchValue(taskModule);
        this.taskForm
            .get('priority')
            .patchValue(this.taskDetails?.priority || 'LOW');
        if (taskModule === 'MISC') {
            this.handleHasLinkedModule(false);
        } else {
            this.assignModuleOptions(this.taskDetails?.module, false);
            this.taskForm
                .get('moduleId')
                .patchValue(this.taskDetails?.moduleId || null);
        }
    }

    ngOnDestroy(): void {
        this.taskDetails = {};
        this.store.dispatch(new SetTaskById({}));
        this.taskForm.reset();
        this.destroyAddTaskModal.emit();
    }

    get ReactiveFormControl() {
        return this.taskForm.controls;
    }

    get ReactiveFormValues() {
        return this.taskForm.getRawValue();
    }

    get TodaysDate() {
        return formatDate(new Date().getTime(), 'yyyy-MM-dd', 'en');
    }

    CheckDurationError() {
        const hour = Boolean(JSON.parse(this.ReactiveFormValues.duration.hour));
        const minute = Boolean(
            JSON.parse(this.ReactiveFormValues.duration.minute)
        );
        if (!hour && !minute) {
            this.ReactiveFormControl.duration.setErrors({ invalid: true });
            return true;
        } else {
            this.ReactiveFormControl.duration.setErrors(null);
            return false;
        }
    }

    addNameInObjectArray(assignUserList) {
        let tempArr: Array<any> = [];
        let assignUserListTemp: any = {
            MANAGING_PARTNER: [],
            PARTNER: [],
            LAWYER: [],
            PARA_LEGAL: [],
            PERSONAL_ASSISTANT: [],
            COMPLIANCE_OFFICER: [],
            RO_CLIENT: [],
            LEGAL_FIRM_ADMIN: [],
            SYSTEM_ADMIN: []
        };

        Object.keys(assignUserList).forEach((key) => {
            assignUserList[key].forEach((user: any) => {
                const salutationValue = this.commonUtil.getSalutationToShow(user?.salutation);
                assignUserListTemp[key].push({
                    ...user,
                    name:
                        `${salutationValue ? salutationValue + " " : ""}` + `${user.firstName ? `${user.firstName} ` : ''}`,
                    userRole:
                        this.roleOptions.find(({ id }) => id === key).name ||
                        key
                });
            });
        });

        Object.keys(assignUserListTemp).forEach((key) => {
            if (assignUserListTemp[key]) {
                tempArr = [...tempArr, ...assignUserListTemp[key]];
            }
        });

        return tempArr;
    }

    handleDueDateChange(dueDate: any) {
        const fromDate = new Date(this.TodaysDate).getTime();
        const toDate = new Date(dueDate).getTime();

        if (fromDate !== null && toDate !== null && fromDate > toDate) {
            this.ReactiveFormControl.dueDate.setErrors({ invalid: true });
        }
    }

    handleTypeChange(isRoleBase: boolean) {
        if (isRoleBase) {
            this.taskForm.get('type').patchValue('ROLE_BASED');
            this.taskForm.get('role').setValidators(Validators.required);
            this.taskForm.get('role').updateValueAndValidity();
        } else {
            this.taskForm.get('type').patchValue('USER_BASED');
            this.taskForm.patchValue({
                role: null
            });
            this.taskForm.get('role').clearValidators();
            this.taskForm.get('role').updateValueAndValidity();
        }
    }

    handleAssignSelf(isChecked: boolean) {
        this.assignSomeOne = isChecked;
        this.taskForm.get('userId').patchValue(null);
        if (isChecked) {
            this.taskForm.get('userId').setValidators(Validators.required);
            this.taskForm.get('userId').updateValueAndValidity();
        } else {
            this.taskForm.get('userId').clearValidators();
            this.taskForm.get('userId').updateValueAndValidity();
        }
    }

    handleHasLinkedModule(isChecked: boolean) {
        this.hasLinkedModule = isChecked;

        if (isChecked) {
            this.taskForm.patchValue({
                module: null,
                moduleId: null
            });
            this.taskForm.get('moduleId').setValidators(Validators.required);
            this.taskForm.get('moduleId').updateValueAndValidity();
        } else {
            this.taskForm.patchValue({
                module: 'MISC',
                moduleId: null
            });
            this.taskForm.get('moduleId').clearValidators();
            this.taskForm.get('moduleId').updateValueAndValidity();
        }
    }

    onModuleChange(e) {
        this.assignModuleOptions(e?.id, true);
    }

    assignModuleOptions(module, isRefModuleChanged?) {
        if (isRefModuleChanged === true) {
            this.taskForm.get('moduleId').patchValue(null);
        }

        const currentModule = module || this.ReactiveFormValues.module;

        switch (currentModule) {
            case 'ENQUIRY':
                this.moduleOptions = this.addModuleNameInModules(
                    this.enquiryList,
                    'ENQUIRY'
                );
                this.assignDefaultModuleOptions(this.moduleOptions, 'Enquiry');
                break;
            case 'CASE_MATTER':
                this.moduleOptions = this.addModuleNameInModules(
                    this.caseMatterList,
                    'CASE_MATTER'
                );
                this.assignDefaultModuleOptions(this.moduleOptions, 'Matter');
                break;
            case 'BILLING':
                this.moduleOptions = this.addModuleNameInModules(
                    this.billingList,
                    'BILLING'
                );
                this.assignDefaultModuleOptions(this.moduleOptions, 'Billing');
                break;
            case 'MISC':
                this.moduleOptions = [
                    { id: 'Miscelleneous', moduleName: 'Miscelleneous' }
                ];
                break;
            default:
                this.moduleOptions = [];
        }
    }

    assignDefaultModuleOptions(moduleOptions, defaultModuleName) {
        if (!moduleOptions?.length) {
            this.moduleOptions = [
                {
                    id: defaultModuleName,
                    moduleName: defaultModuleName
                }
            ];
        }
    }

    addModuleNameInModules(arr: any, moduleType) {
        if (arr?.length) {
            return arr.map((item) => {
                let moduleName = '';

                switch (moduleType) {
                    case 'CASE_MATTER':
                        const primaryClientName = item?.primaryClientName || '';
                        moduleName = `${primaryClientName} (${item?.matterDetails?.matterReference || 'NA'})`;

                        break;
                    case 'ENQUIRY':
                        const userInfo = item?.enquiryProfile?.userInfo;
                        const salutation = this.commonUtil.getSalutationToShow(userInfo?.salutation);
                        const userName =
                            (salutation ? salutation + " " : "") + userInfo.firstName || '';
                        moduleName = `${userName} (${item?.enquiryReference || 'NA'})`;
                        break;
                    case 'CLIENT':
                        moduleName = item?.name || item?.id;
                        break;
                    case 'BILLING':
                        moduleName = item?.name || item?.id;
                        break;
                    default:
                        return item;
                }

                return {
                    ...item,
                    moduleName
                };
            });
        }

        return arr;
    }

    disableForm() {
        Object.keys(this.taskForm.controls).forEach((field: string) => {
            if (field !== 'status' && field !== 'comment') {
                this.taskForm.get(field)?.disable();
            } else {
                this.taskForm.get(field)?.enable();
            }
        });
    }

    onSubmitClick() {
        this.formSubmitted = true;
        this.CheckDurationError();
        let userId = this.ReactiveFormValues.userId;
        if (!this.assignSomeOne) {
            userId = this.commonUtil.getUserDetail('id');
        }
        let createdBy = this.ReactiveFormValues.createdBy;
        if (!this.ReactiveFormValues.id) {
            createdBy = this.commonUtil.getUserDetail('id');
        }

        this.taskForm.patchValue({
            firmId: this.commonUtil.getUserDetail('firmId'),
            userId: userId,
            createdBy: createdBy
        });

        if (this.taskForm.invalid) {
            return;
        }

        const taskDetails = cloneDeep({ ...this.ReactiveFormValues });
        taskDetails.dueDate = this.commonUtil.convertDateTimeToMillis(
            taskDetails?.dueDate
        );
        this.handleSubmitClick(taskDetails);
    }

    onCancelClick() {
        this.taskForm.reset();
        this.taskDetails = {};
        this.store.dispatch(new SetTaskById({}));

        this.handleCloseClick();
    }

    onDeleteClick(deleteId) { }
}
