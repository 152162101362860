<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">
  <div class="content-header row">
    <div class="content-header-left col-md-9 col-sm-9 col-xs-9 col-12 mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">
          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2>
          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentHeader.actionButton">
      <div class="content-header-right right-align col-md-3 col-xs-3 col-sm-3 col-12">
        <div class="form-group breadcrum-right d-flex justify-content-end align-items-center">
          <!-- Dropdown Button -->
          <div ngbDropdown>
            <button *ngIf="contentHeader?.actionButtonOption?.length" ngbDropdownToggle id="dropdownSettingsDropdown" class="btn-icon btn btn-primary btn-round btn-sm button-equal-height mr-2" type="button" rippleEffect>
              <span [data-feather]="'grid'"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettingsDropdown">
              <ng-template ngFor let-action [ngForOf]="contentHeader.actionButtonOption">
                <a ngbDropdownItem (click)="actionButtonClicked(action.ID)">
                  <span [data-feather]="action.ICON" [class]="mr-50"></span> {{action.NAME}}
                </a>
              </ng-template>
            </div>
          </div>
          <!-- Button -->
          <button *ngIf="contentHeader.linkButton" id="settingsButton" (click)="linkButtonClicked()" class="btn-icon btn btn-primary btn-round btn-sm button-equal-height" type="button" rippleEffect>
            <i class="feather icon-settings"></i>
          </button>
          <button *ngIf="contentHeader.downloadButton" id="settingsButton" (click)="downloadButtonClicked()" class="btn-icon btn btn-primary btn-round btn-sm button-equal-height" type="button" rippleEffect>
            <i class="feather icon-download"></i>
          </button>
        </div>
      </div>
    </ng-container>
    
  </div>
</ng-container>
<!-- app-content-header end -->