import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { VisionAiEffect } from "./effect";
import { VisionAiStateReducer } from "./reducer";

import { helperValues } from "app/store/common/commonStore.module";

@NgModule({
    imports: [
        EffectsModule.forFeature([VisionAiEffect]),
        StoreModule.forFeature('visionAi', VisionAiStateReducer)
    ]
})

export class VisionAiStoreModule { }

export const allVisionAiDocuments = (store: any) => store && store.visionAi && store.visionAi.allVisionAiDocuments;
export const visionAiDocumentById = (store: any) => store && store.visionAi && store.visionAi.visionAiDocumentById;
export const addVisionAiDocumentResponse = (store: any) => store && store.visionAi && store.visionAi.addVisionAiDocumentResponse;
export const deleteVisionAiDocumentResponse = (store: any) => store && store.visionAi && store.visionAi.deleteVisionAiDocumentResponse;
export const saveVisionAiDocumentAsNoteResponse = (store: any) => store && store.visionAi && store.visionAi.saveVisionAiDocumentAsNoteResponse;
export const runVisionAiExtractionFromSummaryResponse = (store: any) => store && store.visionAi && store.visionAi.runVisionAiExtractionFromSummaryResponse;

export const allVisionAiDocumentsWithStateName = createSelector(
    allVisionAiDocuments,
    helperValues,
    (documents, helperValues) => {
        const allItems = (documents || []).map((item) => ({
            ...item,
            visionAiStateName: (helperValues?.visionAiState || []).find(({ id }) => id === item.visionAiState)?.name || item.visionAiState || "-"
        }))
        return allItems;
    }
)
