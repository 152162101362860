import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as ResearchAiModelsAction from './actions';
import { ResearchAiService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class ResearchAiEffect {
    constructor(
        private action: Actions,
        private researchAiService: ResearchAiService
    ) { }

    GetAllResearchAiModels = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_RESEARCH_AI_MODELS),
        switchMap(() => this.researchAiService.getAllResearchAiModels()),
        map((data: Array<any>) => new ResearchAiModelsAction.SetAllResearchAiModels(data)
        )
    ));

    GetResearchAiModelById = createEffect(() => this.action.pipe(
        ofType(type.GET_RESEARCH_AI_MODEL_BY_ID),
        switchMap((data: any) => this.researchAiService.getResearchAiModelById(data.payload)),
        map((data: any) => new ResearchAiModelsAction.SetResearchAiModelById(data)
        )
    ));

    AddResearchAiModel = createEffect(() => this.action.pipe(
        ofType(type.ADD_RESEARCH_AI_MODEL),
        switchMap((data: any) => this.researchAiService.addResearchAiModel(data.payload)),
        map((data: IServerResponse) => new ResearchAiModelsAction.AddResearchAiModelResponse(data)
        )
    ));

    UpdateResearchAiModel = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_RESEARCH_AI_MODEL),
        switchMap((data: any) => this.researchAiService.updateResearchAiModel(data.payload)),
        map((data: IServerResponse) => new ResearchAiModelsAction.UpdateResearchAiModelResponse(data)
        )
    ));

    DeleteResearchAiModel = createEffect(() => this.action.pipe(
        ofType(type.DELETE_RESEARCH_AI_MODEL),
        switchMap((data: any) => this.researchAiService.deleteResearchAiModel(data.payload)),
        map((data: IServerResponse) => new ResearchAiModelsAction.DeleteResearchAiModelResponse(data)
        )
    ));

    AddResearchAiSources = createEffect(() => this.action.pipe(
        ofType(type.ADD_RESEARCH_AI_SOURCES),
        switchMap((data: any) => this.researchAiService.addResearchAiSources(data.payload)),
        map((data: IServerResponse) => new ResearchAiModelsAction.AddResearchAiSourcesResponse(data)
        )
    ));

    RemoveResearchAiSources = createEffect(() => this.action.pipe(
        ofType(type.REMOVE_RESEARCH_AI_SOURCES),
        switchMap((data: any) => this.researchAiService.removeResearchAiSources(data.payload)),
        map((data: IServerResponse) => new ResearchAiModelsAction.RemoveResearchAiSourcesResponse(data)
        )
    ));

    TrainResearchAiModel = createEffect(() => this.action.pipe(
        ofType(type.TRAIN_RESEARCH_AI_MODEL),
        switchMap((data: any) => this.researchAiService.trainResearchAiModelById(data.payload)),
        map((data: IServerResponse) => new ResearchAiModelsAction.TrainResearchAiModelResponse(data)
        )
    ));

    QueryResearchAiModel = createEffect(() => this.action.pipe(
        ofType(type.QUERY_RESEARCH_AI_MODEL),
        switchMap((data: any) => this.researchAiService.queryResearchAiModelById(data.payload)),
        map((data: IServerResponse) => new ResearchAiModelsAction.QueryResearchAiModelResponse(data)
        )
    ));
}
