export const GET_ALL_BILLING_DATA = "GET_ALL_BILLING_DATA";
export const SET_ALL_BILLING_DATA = "SET_ALL_BILLING_DATA";

export const GET_BILLING_BY_ID = "GET_BILLING_BY_ID";
export const SET_BILLING_BY_ID = "SET_BILLING_BY_ID";

export const ADD_BILLING = "ADD_BILLING";
export const ADD_BILLING_RESPONSE = "ADD_BILLING_RESPONSE";

export const UPDATE_BILLING = "UPDATE_BILLING";
export const UPDATE_BILLING_RESPONSE = "UPDATE_BILLING_RESPONSE";

export const DELETE_BILLING = "DELETE_BILLING";
export const DELETE_BILLING_RESPONSE = "DELETE_BILLING_RESPONSE";

export const GET_BILLING_BY_FIELD = "GET_BILLING_BY_FIELD";
export const SET_BILLING_BY_FIELD = "SET_BILLING_BY_FIELD";
