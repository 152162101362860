import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { NgrxServiceModule } from './store/service.module';
import { NgrxStoreModule } from './store/store.module';
import { UtilModule } from './utils/util.module';
import { RoutingModule } from './app.routing';
import { AuthModule } from './auth/auth.module';
import { ChatbotModule } from './chatbot/chatbot.module';
import { SharedModule } from './main/shared/shared.module';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
 import { FeatureModule } from '@feature/feature.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule,
        RoutingModule,
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CardSnippetModule,
        LayoutModule,
        FeatureModule,
        ContentHeaderModule,
        NgrxServiceModule,
        NgrxStoreModule,
        UtilModule,
        ChatbotModule,
        SharedModule,
        NgSelectModule,
        NgIdleKeepaliveModule.forRoot()
    ],

    providers: [
        // ErrorInterceptor,
        // { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        // // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // { provide: HttpClient, useClass: HttpService },
        // // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    ],
    entryComponents: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
