export const GET_ALL_ENQUIRIES = "GET_ALL_ENQUIRIES";
export const SET_ALL_ENQUIRIES = "SET_ALL_ENQUIRIES";

export const GET_ENQUIRY_BY_ID = "GET_ENQUIRY_BY_ID";
export const SET_ENQUIRY_BY_ID = "SET_ENQUIRY_BY_ID";

export const ADD_ENQUIRY = "ADD_ENQUIRY";
export const ADD_ENQUIRY_RESPONSE = "ADD_ENQUIRY_RESPONSE";

export const UPDATE_ENQUIRY = "UPDATE_ENQUIRY";
export const UPDATE_ENQUIRY_RESPONSE = "UPDATE_ENQUIRY_RESPONSE";

export const DELETE_ENQUIRY = "DELETE_ENQUIRY";
export const DELETE_ENQUIRY_RESPONSE = "DELETE_ENQUIRY_RESPONSE";

export const GET_ENQUIRY_EXTRACTIONS = "GET_ENQUIRY_EXTRACTIONS";
export const SET_ENQUIRY_EXTRACTIONS = "SET_ENQUIRY_EXTRACTIONS";

export const GET_ENQUIRY_BY_FIELD = "GET_ENQUIRY_BY_FIELD";
export const SET_ENQUIRY_BY_FIELD = "SET_ENQUIRY_BY_FIELD";

