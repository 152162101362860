import { initialState, ResearchAiState } from "./state";
import { Actions } from './actions';
import {
    SET_ALL_RESEARCH_AI_MODELS, SET_RESEARCH_AI_MODEL_BY_ID,
    ADD_RESEARCH_AI_MODEL_RESPONSE, UPDATE_RESEARCH_AI_MODEL_RESPONSE, DELETE_RESEARCH_AI_MODEL_RESPONSE,
    ADD_RESEARCH_AI_SOURCES_RESPONSE, REMOVE_RESEARCH_AI_SOURCES_RESPONSE, TRAIN_RESEARCH_AI_MODEL_RESPONSE, QUERY_RESEARCH_AI_MODEL_RESPONSE
} from "./actionTypes";

export function ResearchAiStateReducer(state = initialState, action: Actions): ResearchAiState {
    const { type } = action;

    switch (type) {
        case SET_ALL_RESEARCH_AI_MODELS:
            return Object.assign({}, state, {
                allResearchAiModels: action.payload
            });
        case SET_RESEARCH_AI_MODEL_BY_ID:
            return Object.assign({}, state, {
                researchAiModelById: action.payload
            });
        case ADD_RESEARCH_AI_MODEL_RESPONSE:
            return Object.assign({}, state, {
                addResearchAiModelResponse: action.payload
            });
        case UPDATE_RESEARCH_AI_MODEL_RESPONSE:
            return Object.assign({}, state, {
                updateResearchAiModelResponse: action.payload
            });
        case DELETE_RESEARCH_AI_MODEL_RESPONSE:
            return Object.assign({}, state, {
                deleteResearchAiModelResponse: action.payload
            });
        case ADD_RESEARCH_AI_SOURCES_RESPONSE:
            return Object.assign({}, state, {
                addResearchAiSourcesResponse: action.payload
            });
        case REMOVE_RESEARCH_AI_SOURCES_RESPONSE:
            return Object.assign({}, state, {
                removeResearchAiSourcesResponse: action.payload
            });
        case TRAIN_RESEARCH_AI_MODEL_RESPONSE:
            return Object.assign({}, state, {
                trainResearchAiModelResponse: action.payload
            });
        case QUERY_RESEARCH_AI_MODEL_RESPONSE:
            return Object.assign({}, state, {
                queryResearchAiModelResponse: action.payload
            });

        default:
            return state;
    }
}
