import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_CURRENCY } from 'app/constants/common.constant';
@Pipe({
    name: 'currencyFormatter'
})
export class CurrencyFormatterPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param {any[]} items
     * @param {string} searchText
     * @param {string} key
     *
     * @returns {any}
     */
    transform(value: any, currency: string = DEFAULT_CURRENCY): any {
        return currency + (value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
}
