import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TOASTR_TYPE } from 'app/constants/common.constant';
import { DisableToastr } from 'app/store/common/actions';
import { getToastrData } from 'app/store/common/commonStore.module';
import { IToastr } from 'app/store/common/state';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-toastr',
    templateUrl: './toastr.component.html',
    styleUrls: ['./toastr.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToastrComponent implements OnInit {

    private options: GlobalConfig;

    toastrData: IToastr = {
        type: TOASTR_TYPE.SUCCESS,
        header: '',
        message: '',
        positionClass: 'toast-bottom-center',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
        progressBar: false,
        tapToDismiss: false,
        enableHtml: false,
        timeOut: 3000
    }
    constructor(
        private store: Store,
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.store.select(getToastrData)
            .subscribe((resp) => {
                if (resp) {
                    this.toastrData = { ...this.toastrData, ...resp }
                    if (this.toastrData.message) {
                        this.showToastr();
                        this.store.dispatch(new DisableToastr())
                    }
                }
            })
    }

    showToastr() {
        this.toastr[this.toastrData.type](this.toastrData.message, this.toastrData.header, {
            positionClass: this.toastrData.positionClass,
            toastClass: this.toastrData.toastClass,
            closeButton: this.toastrData.closeButton,
            progressBar: this.toastrData.progressBar,
            enableHtml: this.toastrData.enableHtml,
            timeOut: this.toastrData.timeOut
        });
    }

}
