<div class="modal-header bg-primary">
  <h4 class="modal-title text-white">
    {{ "Event" }}
  </h4>
</div>

<div
  class="modal-body p-0"
  tabindex="0"
  ngbAutofocus
  *blockUI="'calendar-event-modal'"
>
  <form class="form">
    <div class="row">
      <div class="col-12">
        <div class="card rounded-0 mb-0">
          <div class="card-body">
            <h5 class="card-title" *ngIf="title">
              {{ title }}
            </h5>

            <fieldset
              [disabled]="!IsEventTabEditable"
              [ngStyle]="{
                'pointer-events': IsEventTabEditable ? 'unset' : 'none'
              }"
            >
              <div class="row" [formGroup]="calendarEventForm">
                <div class="col-12 col-md-6">
                  <div class="form-group required">
                    <label for="title" class="control-label">{{
                      "Title" | translate
                    }}</label>
                    <input
                      id="title"
                      type="text"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted && ReactiveFormControl.title.errors
                      }"
                      class="form-control"
                      placeholder="{{ 'Title' | translate }}"
                      formControlName="title"
                    />
                    <div
                      *ngIf="formSubmitted && ReactiveFormControl.title.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="ReactiveFormControl.title.errors.required">
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="description" class="control-label">{{
                      "Description" | translate
                    }}</label>
                    <textarea
                      id="description"
                      type="text"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          ReactiveFormControl.description.errors
                      }"
                      class="form-control"
                      placeholder="{{ 'Description' | translate }}"
                      formControlName="description"
                    ></textarea>
                    <div
                      *ngIf="
                        formSubmitted && ReactiveFormControl.description.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="ReactiveFormControl.description.errors.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group required">
                    <label for="startTime" class="control-label">{{
                      "Start Date" | translate
                    }}</label>
                    <input
                      id="startTime"
                      type="datetime-local"
                      [attr.min]="currentDateTime"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted && ReactiveFormControl.startTime.errors
                      }"
                      class="form-control mb-1"
                      placeholder="{{ 'Start Date' | translate }}"
                      formControlName="startTime"
                    />
                    <div
                      *ngIf="
                        formSubmitted && ReactiveFormControl.startTime.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="ReactiveFormControl.startTime.errors.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group required">
                    <label for="endTime" class="control-label">{{
                      "End Date" | translate
                    }}</label>
                    <input
                      id="endTime"
                      type="datetime-local"
                      [attr.min]="currentDateTime"
                      [ngClass]="{
                        'is-invalid':
                          (formSubmitted &&
                            ReactiveFormControl.endTime.errors) ||
                          ReactiveFormControl.endTime.errors?.endBeforeStart
                      }"
                      class="form-control mb-1"
                      placeholder="{{ 'End Date' | translate }}"
                      formControlName="endTime"
                    />
                    <div
                      *ngIf="
                        formSubmitted && ReactiveFormControl.endTime.errors
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="ReactiveFormControl.endTime.errors.required">
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                    <div
                      *ngIf="ReactiveFormControl.endTime.errors?.endBeforeStart"
                      class="invalid-feedback"
                    >
                      {{
                        "End Date must be the same or after the Start Date"
                          | translate
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group required">
                    <label for="eventColor" class="control-label">
                      {{ "Event Color" | translate }}
                    </label>

                    <div class="d-flex align-items-center">
                      <ngx-colors
                        ngx-colors-trigger
                        formControlName="eventColor"
                        [format]="'hex'"
                      >
                      </ngx-colors>
                      <span>&nbsp;{{ ReactiveFormValues.eventColor }}</span>
                    </div>

                    <div
                      *ngIf="
                        formSubmitted && ReactiveFormControl.eventColor.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="ReactiveFormControl.eventColor.errors.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group required">
                    <label for="eventType" class="control-label">{{
                      "Event Type" | translate
                    }}</label>
                    <ng-select
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted && ReactiveFormControl.eventType.errors
                      }"
                      id="eventType"
                      name="eventType"
                      [items]="eventTypeOptions"
                      bindLabel="name"
                      bindValue="id"
                      formControlName="eventType"
                      (change)="onEventTypeChange($event)"
                      [closeOnSelect]="true"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        formSubmitted && ReactiveFormControl.eventType.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="ReactiveFormControl.eventType.errors.required"
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-12"
                  formGroupName="meetingDetails"
                  *ngIf="
                    MeetingDetailsFormControl.meetingUrl &&
                    ReactiveFormValues.eventType === 'MEETING'
                  "
                >
                  <div class="form-group">
                    <label for="meetingUrl" class="control-label">
                      {{ "Meeting Url" | translate }}
                    </label>
                    <input
                      id="meetingUrl"
                      type="text"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          MeetingDetailsFormControl.meetingUrl.errors
                      }"
                      placeholder="{{ 'Meeting Url' | translate }}"
                      formControlName="meetingUrl"
                    />
                    <div
                      *ngIf="
                        formSubmitted &&
                        MeetingDetailsFormControl.meetingUrl.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          MeetingDetailsFormControl.meetingUrl.errors.required
                        "
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-12"
                  *ngIf="
                    MeetingDetailsFormControl.attendees &&
                    ReactiveFormValues.eventType === 'MEETING'
                  "
                >
                  <div class="form-group">
                    <label for="attendees" class="control-label">
                      {{ "Attendees" | translate }}
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        [(ngModel)]="attendee"
                        name="attendee"
                        [ngClass]="{
                          'is-invalid':
                            formSubmitted &&
                            MeetingDetailsFormControl.attendees.errors
                        }"
                        class="form-control"
                        placeholder="{{ 'Email Address' | translate }}"
                      />
                      <div class="input-group-prepend">
                        <button
                          type="button"
                          rippleEffect
                          class="btn btn-primary"
                          (click)="addAttendee()"
                        >
                          <i class="feather icon-plus"></i>
                        </button>
                      </div>
                      <div
                        *ngIf="
                          formSubmitted &&
                          MeetingDetailsFormControl.attendees.errors
                        "
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="
                            MeetingDetailsFormControl.attendees.errors.required
                          "
                        >
                          {{ "ErrorRequiredField" | translate }}
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <div *ngFor="let attendee of attendees; let i = index">
                          <div class="d-flex align-items-center">
                            {{ attendee }}
                            <button
                              class="btn btn-link btn-sm text-danger"
                              (click)="removeAttendee($event, i)"
                            >
                              <span data-feather="x-circle"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" [formGroup]="calendarEventForm">
                <div
                  formGroupName="kaiTaskDetails"
                  class="col-12 col-md-6"
                  *ngIf="
                    KaiTaskDetailsFormControl.taskModule &&
                    ReactiveFormValues.eventType === 'KAI_TASK'
                  "
                >
                  <div class="form-group required">
                    <label for="taskModule" class="control-label">{{
                      "Task Module" | translate
                    }}</label>
                    <ng-select
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          KaiTaskDetailsFormControl.taskModule.errors
                      }"
                      id="taskModule"
                      name="taskModule"
                      [items]="taskModuleOptions"
                      bindLabel="name"
                      bindValue="id"
                      formControlName="taskModule"
                      (change)="onTaskModuleChange($event)"
                      [closeOnSelect]="true"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        formSubmitted &&
                        KaiTaskDetailsFormControl.taskModule.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          KaiTaskDetailsFormControl.taskModule.errors.required
                        "
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  formGroupName="kaiTaskDetails"
                  class="col-12 col-md-6"
                  *ngIf="showModuleIdSelection"
                >
                  <div class="form-group required">
                    <label for="moduleId" class="control-label">{{
                      "Module" | translate
                    }}</label>
                    <ng-select
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          KaiTaskDetailsFormControl.moduleId.errors
                      }"
                      id="moduleId"
                      name="moduleId"
                      [items]="moduleOptions"
                      bindLabel="moduleName"
                      bindValue="id"
                      formControlName="moduleId"
                      [closeOnSelect]="true"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        formSubmitted &&
                        KaiTaskDetailsFormControl.moduleId.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          KaiTaskDetailsFormControl.moduleId.errors.required
                        "
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  formGroupName="kaiTaskDetails"
                  class="col-12 col-md-6"
                  *ngIf="
                    KaiTaskDetailsFormControl.taskType &&
                    ReactiveFormValues.eventType === 'KAI_TASK'
                  "
                >
                  <div class="form-group">
                    <label for="taskType" class="control-label">{{
                      "Task Type" | translate
                    }}</label>
                    <input
                      id="taskType"
                      type="text"
                      [ngClass]="{
                        'is-invalid':
                          formSubmitted &&
                          KaiTaskDetailsFormControl.taskType.errors
                      }"
                      class="form-control"
                      placeholder="{{ 'Task Type' | translate }}"
                      formControlName="taskType"
                    />
                    <div
                      *ngIf="
                        formSubmitted &&
                        KaiTaskDetailsFormControl.taskType.errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          KaiTaskDetailsFormControl.taskType.errors.required
                        "
                      >
                        {{ "ErrorRequiredField" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="row mt-1">
              <!-- <div class="col-4" *ngIf="IsEventDeletable">
                <button
                  *ngIf="ReactiveFormValues?.id"
                  type="button"
                  rippleEffect
                  class="btn btn-danger"
                  (click)="onDeleteClick(ReactiveFormValues?.id)"
                >
                  {{ 'Delete' | translate }}
                </button>
              </div> -->
              <div class="col-12 text-right">
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-primary mr-1"
                  (click)="onSubmitClick()"
                  *ngIf="IsEventTabEditable"
                >
                  {{ "Save" | translate }}
                </button>
                <button
                  type="button"
                  rippleEffect
                  class="btn btn-outline-secondary"
                  (click)="onCancelClick()"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
