export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const SET_USER_DETAIL = "SET_USER_DETAIL";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_RESP = "FORGOT_PASSWORD_RESP";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_RESP = "RESET_PASSWORD_RESP";

export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL";
export const UPDATE_USER_DETAIL_RESP = "UPDATE_USER_DETAIL_RESP";

export const LOGOUT = "LOGOUT";
