import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { PresentToastr } from 'app/store/common/actions';
import { TOASTR_TYPE } from 'app/constants/common.constant';

import { UrlUtil } from "app/utils/url.util";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RolesService {
    constructor(
        private http: HttpClient,
        private urlUtil: UrlUtil,
        private _translateService: TranslateService,
        private store: Store
    ) {
    }

    public getAllRoles() {
        return this.http.get(
            this.urlUtil.getUrl('roles', 'getAll'))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return [];
                    }
                }),
                catchError((e) => {
                    console.log("getAllRoles error =>", e);
                    return of([]);
                })
            )
    }

    public getRoleById(id) {
        return this.http.get(
            this.urlUtil.getUrl('roles', 'searchById').replace('$ID', id))
            .pipe(
                map((resp: any) => {
                    if (resp.status) {
                        return resp.data;
                    } else {
                        return {};
                    }
                }),
                catchError((e) => {
                    console.log("getRoleById error =>", e);

                    this.store.dispatch(new PresentToastr({
                        type: TOASTR_TYPE.ERROR,
                        message: e?.errorMessage || e?.message || this._translateService.instant("ErrorGeneral")
                    }))

                    return of({});
                })
            )
    }

    public addRole(dataObject) {
        return this.http.post(
            this.urlUtil.getUrl('roles', 'add'), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataAddSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataAdd")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("addRole error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataAdd")
                    });
                })
            )
    }

    public updateRole(dataObject) {
        return this.http.put(
            this.urlUtil.getUrl('roles', 'update').replace('$ID', dataObject.id), dataObject)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: this._translateService.instant("DataUpdateSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: errorMessage || jsonData || this._translateService.instant("FailDataUpdate")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("updateRole error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataUpdate")
                    });
                })
            )
    }

    public deleteRole(idsArray) {
        return this.http.post(this.urlUtil.getUrl('roles', 'delete'), idsArray)
            .pipe(
                map((resp: any) => {
                    const { status, jsonData, errorMessage } = resp
                    if (status) {
                        return {
                            status: status,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("DataDeleteSuccess")
                        };
                    } else {
                        return ({
                            status: false,
                            message: ((errorMessage || "") + (jsonData || "")) || this._translateService.instant("FailDataDelete")
                        });
                    }
                }),
                catchError((e) => {
                    console.log("deleteRole error =>", e);
                    return of({
                        status: false,
                        message: e?.errorMessage || e?.message || this._translateService.instant("FailDataDelete")
                    });
                })
            )
    }
}
