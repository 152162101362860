export enum Role {
    CLIENT = "CLIENT",
    LAWYER = "LAWYER",
    LEGAL_FIRM_ADMIN = "LEGAL_FIRM_ADMIN",
    MANAGING_PARTNER = "MANAGING_PARTNER",
    PARA_LEGAL = "PARA_LEGAL",
    PARTNER = "PARTNER",
    PERSONAL_ASSISTANT = "PERSONAL_ASSISTANT",
    COMPLIANCE_OFFICER = "COMPLIANCE_OFFICER",
    RO_CLIENT = "RO_CLIENT",
    SYSTEM_ADMIN = "SYSTEM_ADMIN",
}

export enum RoleName {
    CLIENT = "Client",
    LAWYER = "Lawyer",
    LEGAL_FIRM_ADMIN = "Legal Firm Admin",
    MANAGING_PARTNER = "Managing Partner",
    PARA_LEGAL = "Paralegal",
    PARTNER = "Partner",
    PERSONAL_ASSISTANT = "Personal Assistant",
    COMPLIANCE_OFFICER = "Compliance Officer",
    RO_CLIENT = "Guest",
    SYSTEM_ADMIN = "System Admin",
}
