import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DEFAULT_CURRENCY } from 'app/constants/common.constant';
import { Rows, TableEvent, TableOptions } from 'app/model/table.modal';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Page } from '../../../utils/page.util';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DataTableComponent implements OnInit, OnChanges {
    @Input() rows: Array<Rows>;
    @Input() cols: Array<any> = []
    @Input() options: TableOptions = {
        showSearch: true,
        showLegend: false
    };
    @Input() loading: boolean = false;
    @Input() moduleId = null;

    @Input() formSubmitted: boolean = false;
    @Input() disableCellInputOnEdit: boolean = false;
    @Input() externalPaging: boolean = false;

    @Input() downloadOriginalFile: Function;
    @Input() downloadTextContent: Function;
    @Input() downloadSummary: Function;
    @Input() downloadShortSummary: Function;
    @Input() runExtraction: Function;
    @Input() createNewMatter: Function;
    @Input() saveAsNote: Function;

    @Input() hideShowOptions: boolean = false;
    @Input() removePadding: boolean = false;

    @Input() setPage: Function;

    @Output() tableEvent: EventEmitter<{
        type: TableEvent,
        data: any
    }> = new EventEmitter<{
        type: TableEvent,
        data: any
    }>();

    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild('tableRowDetails') tableRowDetails: any;
    @ViewChild('headerCheckbox', { static: true }) headerCheckbox: TemplateRef<any>;
    @ViewChild('cellCheckbox', { static: true }) cellCheckbox: TemplateRef<any>;
    @ViewChild('cellHyperlink', { static: true }) cellHyperlink: TemplateRef<any>;
    @ViewChild('date', { static: true }) date: TemplateRef<any>;
    @ViewChild('risk', { static: true }) risk: TemplateRef<any>;
    @ViewChild('dateTime', { static: true }) dateTime: TemplateRef<any>;
    @ViewChild('amountWithCurrency', { static: true }) amountWithCurrency: TemplateRef<any>;
    @ViewChild('longText', { static: true }) longText: TemplateRef<any>;
    @ViewChild('newLineItemsList', { static: true }) newLineItemsList: TemplateRef<any>;
    @ViewChild('truncatedText', { static: true }) truncatedText: TemplateRef<any>;
    @ViewChild('truncatedHyperlink', { static: true }) truncatedHyperlink: TemplateRef<any>;
    @ViewChild('textInput', { static: true }) textInput: TemplateRef<any>;
    @ViewChild('amountInput', { static: true }) amountInput: TemplateRef<any>;
    @ViewChild('selectInput', { static: true }) selectInput: TemplateRef<any>;
    @ViewChild('dateInput', { static: true }) dateInput: TemplateRef<any>;
    @ViewChild('actionButton', { static: true }) actionButton: TemplateRef<any>;
    @ViewChild('speechAndVisionAiActions', { static: true }) speechAndVisionAiActions: TemplateRef<any>;
    @ViewChild('actionIcons', { static: true }) actionIcons: TemplateRef<any>;


    @BlockUI('data-table') blockUIList: NgBlockUI;

    readonly DEFAULT_CURRENCY = DEFAULT_CURRENCY;

    public selected = [];
    public ColumnMode = ColumnMode;
    public chkBoxSelected = [];
    public SelectionType = SelectionType;

    public _customOffset: any = { value: 0 };
    pageValue: number = 10;
    columnName: any;
    columnValue: any;

    constructor(
        private page: Page,
        private store: Store
    ) {
        this.page.pageNumber = 0;
    }

    ngOnInit(): void {
        this.updateCols();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.loading?.previousValue && changes.loading?.currentValue) {
            this.blockUIList.start();
            this.selected = [];
        }

        if (!!(!changes.loading?.currentValue && changes.loading?.previousValue) || changes.rows?.currentValue?.length > 0) {
            this.blockUIList.stop();
        }
    }

    handlePageChange(data) {
        if (this.setPage && typeof this.setPage === 'function') {
            this.blockUIList.start();
            data.columnName = this.columnName
            data.columnValue = this.columnValue
            this.setPage(data);
        }
    }

    /**
     * called to format the type for column, will attach template if hear or cell template provided
     */
    updateCols(): void {
        this.cols = this.cols.map((col) => {
            return {
                width: 200,
                ...col,
                cellTemplate: col.cellTemplate ? this[col.cellTemplate] : undefined,
                headerTemplate: col.headerTemplate ? this[col.headerTemplate] : undefined
            }
        });
    }

    filterSearchUpdate(event) {
        this.tableEvent.emit({
            type: TableEvent.Search,
            data: event
        })
        this.columnName = event.columnName
        this.columnValue = event.columnValue
        // update the rows
        // this.rows = temp;
        // Whenever the filter changes, always go back to the first page
        if (this.table) {
            this.table.offset = 0;
        }
    }

    onClear(event) {
        this.columnName = 'select'
        this.columnValue = 'all'
        this.tableEvent.emit({
            type: TableEvent.Clear,
            data: event
        })
    }

    /**
     * For ref only, log selected values
     *
     * @param selected
     */
    onSelect({ selected }) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
        // if (this.selected?.length) {
        //     this.selected = [this.selected[this.selected.length - 1]];
        // }
        this.tableEvent.emit({
            type: TableEvent.RowSelected,
            data: this.selected
        })
    }

    /**
     * For ref only, log activate events
     *
     * @param selected
     */
    onActivate(event) {
        // console.log('Activate Event', event);
    }

    editData(data) {
        this.tableEvent.emit({
            type: TableEvent.Edit,
            data: data
        })
    }

    deleteData() {
        this.tableEvent.emit({
            type: TableEvent.Delete,
            data: [...this.selected]
        })

        this.onSelect({ selected: [] });
    }

    updateData(data) {
        this.tableEvent.emit({
            type: TableEvent.UpdateData,
            data: data
        })
    }

    handleSizeChange(data) {
        this.page.size = data
        this.setPage({ offset: this.page.pageNumber, columnName: this.columnName, columnValue: this.columnValue })
    }

    set customOffset(val: any) {
        this._customOffset = val;
    }
    get customOffset(): any {
        return this._customOffset;
    }

    onChangeCheckAll() {
        const selectedRowIds = this.selected?.map(({ id }) => id) || []
        let selectedRows = this.selected
        const currentRows = this.table?.bodyComponent?.temp || []
        const currentRowsNotSelected = currentRows.filter(({ id }) => !selectedRowIds.includes(id))

        if (currentRowsNotSelected.length) {
            selectedRows = selectedRows.concat(currentRowsNotSelected)
        } else {
            const currentRowIds = currentRows.map(({ id }) => id)
            selectedRows = selectedRows.filter(({ id }) => !currentRowIds.includes(id))
        }

        this.onSelect({ selected: selectedRows })
    }

    onPageChange(e) {
        const { offset } = e;
        if (this.customOffset?.value !== offset) {
            this.customOffset = { value: offset };
        }
    }

    get CheckIfAllSelectedOnCurrentPage() {
        const currentRowIds = (this.table?.bodyComponent?.temp || []).map(({ id }) => id)
        const selectedRowIds = (this.selected || []).map(({ id }) => id)

        return selectedRowIds.length > 0 && currentRowIds.every((currentRowId) => selectedRowIds.includes(currentRowId))
    }
}
