<div class="modal-header bg-primary">
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="d-flex align-items-center">
      <h1 class="modal-title text-white mb-0 text-capitalize">
        {{ taskDetails.title }}
      </h1>
    </div>
    <div class="d-flex align-items-center">
      <button
        type="button"
        rippleEffect
        class="btn p-0 m-0 mr-1"
        (click)="onEditClick()"
        *ngIf="IsMyTaskEditable && !taskDetails.notificationTaskId"
      >
        <i data-feather="edit" class="text-white"></i>
      </button>
      <button
        type="button"
        rippleEffect
        class="btn p-0 m-0 mr-1"
        *ngIf="IsMyTaskDeletable && !taskDetails.notificationTaskId"
        (click)="onDeleteClick(taskDetails?.id)"
      >
        <i data-feather="trash-2" class="text-white"></i>
      </button>
      <div ngbDropdown>
        <button type="button" rippleEffect class="btn p-0 m-0 mr-1 custom-more" id="dropdownBasic" ngbDropdownToggle>
          <i data-feather="more-vertical" class="text-white"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic">
          <button class="w-100" (click)="onCreateCalenderEventClick()" ngbDropdownItem>Create a calender event</button>
          <button *ngIf="!taskDetails.notificationTaskId" class="w-100" (click)="onUpdateStatusClick()" ngbDropdownItem>Update status</button>
          <button *ngIf="!taskDetails.notificationTaskId" class="w-100" (click)="onReAssignClick()" ngbDropdownItem>Reassign to someone</button>
        </div>
      </div>
      <button
        type="button"
        rippleEffect
        class="btn p-0 m-0"
        (click)="onCancelClick()"
      >
        <i data-feather="x-circle" class="text-white"></i>
      </button>
    </div>
  </div>
</div>

<div class="modal-body p-0" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <div class="card rounded-0 mb-0">
        <div class="card-body">
          <h6 *ngIf="taskDetails?.description" class="text-capitalize">
            <i data-feather="menu" class="text-primary mr-1"></i>
            {{ taskDetails?.description }}
          </h6>

          <div class="row mt-1">
            <div class="col-12 justify-center" *ngIf="taskDetails?.module">
              <h6>
                <i data-feather="grid" class="text-primary mr-1"></i>
                {{ module }} <span *ngIf="taskDetails?.moduleKey"> ({{ taskDetails?.moduleKey }}) </span>
              </h6>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-6">
              <h6 *ngIf="taskDetails?.priority">
                <i data-feather="circle" class="text-primary mr-1"></i>
                <strong class="text-capitalize">{{ taskDetails?.priority.toLowerCase() }}</strong>
              </h6>
            </div>
            <div class="col-6">
              <h6 *ngIf="taskDetails?.status">
                <i data-feather="activity" class="text-primary mr-1"></i>
                <strong>{{ taskDetails?.status }}</strong>
              </h6>
            </div>
            <div class="col-12" *ngIf="taskDetails?.comment">
              <p class="text-capitalize">
                <i data-feather="info" class="text-primary mr-1"></i>
                {{ taskDetails?.comment }}
              </p>
            </div>
            <div class="col-12">
              <h6>
                <i data-feather="target" class="text-primary mr-1"></i>
                <strong>Due Date: </strong> {{ dueDate }}
              </h6>
            </div>
            <div class="col-12 justify-center">
              <h6>
                <i data-feather="clock" class="text-primary mr-1"></i>
                <strong>Duration: </strong>{{ duration.hour.padStart(2, '0') }} hours to {{ duration.minute.padStart(2, '0') }} minutes
              </h6>
            </div>
          </div>

          <div class="mt-1">
            <i data-feather="user" class="text-primary mr-1"></i>
            <span>
              <strong>Assignor: </strong> <span *ngIf="taskDetails?.createdByEmail"> {{ taskDetails?.createdByEmail }} </span>
            </span>
          </div>
          <div *ngIf="taskDetails?.type === 'USER_BASED'">
            <i data-feather="user" class="text-primary mr-1"></i>
            <span>
              <strong>Assignee: </strong><span *ngIf="taskDetails?.userName">{{ taskDetails?.userName }}</span>
            </span>
          </div>
          <div *ngIf="taskDetails?.type === 'ROLE_BASED'">
            <i data-feather="users" class="text-primary mr-1"></i>
            <span *ngIf="role">
              <strong>{{ role }}</strong>
            </span>
          </div>          
        </div>
      </div>
    </div>
  </div>
</div>
