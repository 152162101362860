import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UrlUtil } from 'app/utils/url.util';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

import { CommonUtil } from 'app/utils/common.util';

@Injectable({ providedIn: 'root' })
export class FileService {
    //public

    //private
    private defaultFirmId = "";

    /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
    constructor(
        private _http: HttpClient,
        private urlUtil: UrlUtil,
        private domSanitizer: DomSanitizer,
        private commonUtil: CommonUtil
    ) {
        this.defaultFirmId = this.commonUtil.getUserDetail("firmId");
    }

    public uploadFile(file: any, matterReference, documentType?) {
        const formData = new FormData()
        formData.append("file", file)
        if (documentType) {
            formData.append("documentType", documentType)
        }

        return this._http
            .post<any>(this.urlUtil.getUrl('file', 'upload'), formData, { headers: { 'matterReference': matterReference } })
            .pipe(
                map((resp: any) => {
                    return (resp);
                }),
                catchError((e) => {
                    console.log("uploadFile catchError", e);
                    return of({
                        status: false,
                        errorMessage: e.message
                    });
                })
            );
    }

    public uploadFiles(files: any, matterReference, documentType?) {
        const formData = new FormData()
        files.forEach((file) => {
            formData.append('files', file);
        })
        if (documentType) {
            formData.append("documentType", documentType)
        }

        return this._http
            .post<any>(this.urlUtil.getUrl('file', 'uploadAll'), formData, { headers: { 'matterReference': matterReference } })
            .pipe(
                map((resp: any) => {
                    return (resp);
                }),
                catchError((e) => {
                    console.log("uploadFile catchError", e);
                    return of({
                        status: false,
                        errorMessage: e.message
                    });
                })
            );
    }

    public getBlobUrl(dataObj: any, callbackFunction?: Function) {
        const { id } = dataObj;
        return this._http
            .get<any>(this.urlUtil.getUrl('file', 'download').replace('$ID', id), { responseType: 'blob' as 'json', observe: "response" })
            .subscribe(
                (response: any) => {
                    const dataType = response.body.type;
                    const url = window.URL.createObjectURL(new Blob([response.body], { type: dataType }));
                    if (callbackFunction) {
                        callbackFunction(url)
                    }
                },
                (err) => {
                    if (callbackFunction) {
                        callbackFunction(null)
                    }
                    console.log("downloadFile error", err)
                }
            )
    }

    public downloadFile(dataObj: any, callbackFunction?: Function) {
        const { name: filename, id } = dataObj;

        return this._http
            .get<any>(this.urlUtil.getUrl('file', 'download').replace('$ID', id), { responseType: 'blob' as 'json', observe: "response" })
            .subscribe(
                (response: any) => {
                    const dataType = response.body.type;

                    let downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob([response.body], { type: dataType }));
                    if (filename) {
                        downloadLink.setAttribute('download', filename);
                    }
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    if (callbackFunction) {
                        callbackFunction()
                    }

                    setTimeout(() => {
                        URL.revokeObjectURL(downloadLink.href);
                        downloadLink.parentNode.removeChild(downloadLink);
                    }, 0);
                },
                (err) => {
                    console.log("downloadFile error", err)
                }
            )
    }

    public downloadBillingFile(dataObj: any, callbackFunction?: Function) {
        const { id } = dataObj;
        return this._http
            .get<any>(this.urlUtil.getUrl('billing', 'download-billing').replace('$ID', id), { responseType: 'blob' as 'json', observe: "response" })
            .subscribe(
                (response: any) => {
                    const dataType = response.body.type;

                    let downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob([response.body], { type: dataType }));

                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    if (callbackFunction) {
                        callbackFunction()
                    }

                    setTimeout(() => {
                        URL.revokeObjectURL(downloadLink.href);
                        downloadLink.parentNode.removeChild(downloadLink);
                    }, 0);
                },
                (err) => {
                    console.log("downloadFile error", err)
                }
            )
    }

    public downloadLeadsFile(dataObj: any, callbackFunction?: Function) {
        const { firmId } = dataObj;
        return this._http
            .get<any>(this.urlUtil.getUrl('firms', 'leads-download').replace('$ID', firmId), { responseType: 'blob' as 'json', observe: "response" })
            .subscribe(
                (response: any) => {
                    const dataType = response.body.type;

                    let downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob([response.body], { type: dataType }));

                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    if (callbackFunction) {
                        callbackFunction()
                    }

                    setTimeout(() => {
                        URL.revokeObjectURL(downloadLink.href);
                        downloadLink.parentNode.removeChild(downloadLink);
                    }, 0);
                },
                (err) => {
                    console.log("downloadFile error", err)
                }
            )
    }

    public importFile(url, formData) {
        return this._http
            .post<any>(url, formData)
            .pipe(
                map((response: any) => {
                    const { status, jsonData, errorMessage } = response
                    if (status) {
                        return ({
                            status: status,
                            message: `${jsonData || ''} ${errorMessage || ''}`.trim() || 'Data imported successfully.'
                        });
                    }

                    return ({
                        status: status,
                        message: errorMessage ? errorMessage : 'Data was not imported.'
                    });
                }),
                catchError((e) => {
                    console.log("importFile error =>", e);
                    return of({
                        status: false,
                        message: 'Data was not imported.'
                    });
                })
            );
    }

    public previewPdfFile(fileObj, fileName) {
        var blob = new Blob([fileObj], { type: 'application/pdf' });
        this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        window.URL.createObjectURL(blob);
        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('target', '_blank');
        downloadLink.setAttribute('name', fileName);
        downloadLink.href = window.URL.createObjectURL(blob);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    public savePdfFile(fileObj, fileName) {
        var blob = new Blob([fileObj], { type: 'application/pdf' });
        saveAs(blob, fileName || 'report.pdf');
    }

    public downloadSignDocument({ id }) {
        return this._http
            .get(this.urlUtil.getUrl('firms', 'download-sign-document').replace('$ID', id), { responseType: 'blob' as 'json', observe: "response" })
            .subscribe(
                (response: any) => {
                    const dataType = response.body.type;

                    let downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob([response.body], { type: dataType }));
                    const filename = this.getFileNameFromContentDispositionHeader(response.headers.get('content-disposition'));
                    if (filename) {
                        downloadLink.setAttribute('download', filename);
                    }
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(downloadLink.href);
                        downloadLink.parentNode.removeChild(downloadLink);
                    }, 0);
                },
                (err) => {
                    console.log("downloadFile error", err)
                }
            )
    }

    getFileNameFromContentDispositionHeader(dispositionHeader) {
        let filename = "";
        if (dispositionHeader && dispositionHeader.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(dispositionHeader);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    // KAi File Methods
    public uploadKaiFile(file: any, containerId?) {
        const formData = new FormData()
        formData.append("file", file)

        return this._http
            .post<any>(this.urlUtil.getUrl('file', 'upload-kai', [
                { key: 'containerId', value: containerId || this.defaultFirmId }
            ]), formData)
            .pipe(
                map((resp: any) => {
                    return (resp);
                }),
                catchError((e) => {
                    console.log("uploadKaiFile catchError", e);
                    return of({
                        status: false,
                        errorMessage: e.message
                    });
                })
            );
    }

    public uploadKaiFiles(files: any, containerId?) {
        const formData = new FormData()
        files.forEach((file) => {
            formData.append('files', file);
        })

        return this._http
            .post<any>(this.urlUtil.getUrl('file', 'uploadAll-kai', [
                { key: 'containerId', value: containerId || this.defaultFirmId }
            ]), formData)
            .pipe(
                map((resp: any) => {
                    return (resp);
                }),
                catchError((e) => {
                    console.log("uploadKaiFiles catchError", e);
                    return of({
                        status: false,
                        errorMessage: e.message
                    });
                })
            );
    }

    public fetchKaiFile(dataObj: any) {
        const { name: filename, id, url } = dataObj;

        return this._http
            .get<any>(this.urlUtil.getUrl('file', 'download-kai').replace('$ID', id ? id : url), { responseType: 'blob' as 'json', observe: "response" })
            // .get<any>(this.urlUtil.getUrl('file', 'download-kai').replace('$ID', id))
            .pipe(
                map((response: any) => {
                    return response;
                }),
                catchError((err) => {
                    console.log("fetchKaiFile error", err)
                    return of(null);
                })
            )
    }

    public deleteFile(dataObj: any) {
        const { name: filename, id, url } = dataObj;

        return this._http
            .delete<any>(this.urlUtil.getUrl('file', 'delete').replace('$ID', id ? id : url))
            // .get<any>(this.urlUtil.getUrl('file', 'download-kai').replace('$ID', id))
            .pipe(
                map((response: any) => {
                    return response;
                }),
                catchError((err) => {
                    console.log("delete file error", err)
                    return of(null);
                })
            )
    }

    public downloadKaiFile(dataObj: any) {
        const { name: filename, id } = dataObj;

        return this._http
            .get<any>(this.urlUtil.getUrl('file', 'download-kai').replace('$ID', id), { responseType: 'blob' as 'json', observe: "response" })
            .subscribe(
                (response: any) => {
                    const dataType = response.body.type;

                    let downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob([response.body], { type: dataType }));
                    if (filename) {
                        downloadLink.setAttribute('download', filename);
                    }
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(downloadLink.href);
                        downloadLink.parentNode.removeChild(downloadLink);
                    }, 0);
                },
                (err) => {
                    console.log("downloadKaiFile error", err)
                }
            )
    }

    public openFileInNewTab(dataObj: any, callbackFunction?: Function) {
        const { name: filename, url } = dataObj;

        const fileExtension = filename.split(".").pop();

        // as these files cannot be opened in browser directly, should be downloaded with proper name
        if (fileExtension.includes("doc") || fileExtension.includes("xl") || fileExtension.includes("ppt")) {
            return this.downloadFile({ name: filename, id: url }, callbackFunction)
        }

        return this._http
            .get<any>(this.urlUtil.getUrl('file', 'download').replace('$ID', url), { responseType: 'blob' as 'json', observe: "response" })
            .subscribe(
                (response: any) => {
                    const dataType = response.body?.type;
                    const documentUrl = window.URL.createObjectURL(new Blob([response.body], { type: dataType || 'application/octet-stream' }));

                    window.open(documentUrl, filename)
                    if (callbackFunction) {
                        callbackFunction()
                    }

                    setTimeout(() => {
                        URL.revokeObjectURL(documentUrl);
                    }, 0);
                },
                (err) => {
                    console.log("openFileInNewTab error", err)
                }
            )
    }
}
