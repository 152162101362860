import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createSelector } from "@ngrx/store";
import { MeterEffect } from "./effect";
import { MeterStateReducer } from "./reducer";
import { helperValues } from "app/store/common/commonStore.module";
import { initialState, MeterState } from "./state";


@NgModule({
    imports: [
        EffectsModule.forFeature([MeterEffect]),
        StoreModule.forFeature('meter', MeterStateReducer)
    ]
})

export class MetersStoreModule { }

export const allMeters = (store: any) => store && store.meter && store.meter.allMeters;
export const meterById = (store: any) => store && store.meter && store.meter.meterById;
export const updateMeterResponse = (store: any) => store && store.meter && store.meter.updateMeterResponse;
export const exportMeterResponse = (store: any) => store && store.meter && store.meter.exportMeterResponse;
export const meterByFields = (store: any) => store && store.meter && store.meter.meterByFields;



export const allMetersWithFullNameAndStage = createSelector(
    meterByFields, helperValues, (list, values) => {
        return (list || []).map(item => {
            return {
                ...item,
            }
        })
    }
)
export const selectMeterState = (state: any) => state.meter;

export const selectExportMeterResponse = createSelector(
    selectMeterState,
    (state: MeterState) => state.exportMeterResponse
);