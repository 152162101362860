export const PAGE_ROUTES = {
    'HOME': '/operation/home',
    'ENQUIRY': '/tracking/enquiry',
    'MATTERS': '/operation/matters',
    'CLIENTS': '/operation/clients',
    'CALENDAR': '/operation/systemCalendar',
    'BILLING': '/tracking/billing',
    'DOCUMENT_SIGNATURE_TRACKING': 'tracking/document-signature-tracking',
    'METERS': '/tracking/meters',
    'AUDIT_LOGS': '/tracking/audit-logs',
    'USERS': '/setup/users',
    'FIRMS': '/setup/firms',
    'NOTIFICATIONS': '/setup/notifications',
    'DATA_CONNECTORS': '/setup/data-connectors',
    'LEADS': '/tracking/leads'
}
