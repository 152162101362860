import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import vis, { Network, DataSet } from 'vis';

@Component({
    selector: 'app-network-vis-tree-chart',
    templateUrl: './network-vis-tree-chart.component.html',
    styleUrls: ['./network-vis-tree-chart.component.scss']
})

export class NetworkVisTreeChartComponent implements AfterViewInit, OnChanges {

    @ViewChild('network') el: ElementRef;
    @Input() nodes: Array<any> = [];
    @Input() edges: Array<any> = [];
    @Input() height: any = "400px";
    @Input() width: any = "300px";
    @Input() options: any = {
        autoResize: true,
        height: '100%',
        // remove comment to enable configuration on UI only 
        // configure: {
        //     enabled: true,
        //     filter: 'physics, edges, nodes, layout',
        //     showButton: true
        // },
        layout: {
            hierarchical: {
                direction: "UD",
                sortMethod: "directed",
                nodeSpacing: 400
            }
        },
        physics: {
            hierarchicalRepulsion: {
                // avoidOverlap: "0.2",
                nodeDistance: 120,
                springConstant: 0
            },
            minVelocity: 0.75,
            solver: "repulsion"
        },
        nodes: {
            shape: 'box'
        },
        edges: {
            smooth: {
                type: "horizontal",
                forceDirection: "vertical"
            }
        }
    };
    @Output() nodeClick = new EventEmitter<{}>();

    constructor() {
    }

    ngAfterViewInit(): void {
        this.createNetwork();
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
        if (this.el && this.el.nativeElement) {
            this.createNetwork();
        }

        // changes.prop contains the old and the new value...
    }

    createNetwork() {
        let network = new Network(this.el.nativeElement, { nodes: new DataSet(this.nodes), edges: new DataSet(this.edges) }, this.options);

        network.on('click', (params) => {
            if (params && params.nodes && params.nodes.length === 1) {
                this.nodeClick.emit({
                    nodeDetail: params,
                    entityDetail: this.nodes.find((item) => item.id === params.nodes[0])
                });
            }
        });
    }
}
