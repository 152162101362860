export const GET_ALL_RESEARCH_AI_MODELS = "GET_ALL_RESEARCH_AI_MODELS";
export const SET_ALL_RESEARCH_AI_MODELS = "SET_ALL_RESEARCH_AI_MODELS";

export const GET_RESEARCH_AI_MODEL_BY_ID = "GET_RESEARCH_AI_MODEL_BY_ID";
export const SET_RESEARCH_AI_MODEL_BY_ID = "SET_RESEARCH_AI_MODEL_BY_ID";

export const ADD_RESEARCH_AI_MODEL = "ADD_RESEARCH_AI_MODEL";
export const ADD_RESEARCH_AI_MODEL_RESPONSE = "ADD_RESEARCH_AI_MODEL_RESPONSE";

export const UPDATE_RESEARCH_AI_MODEL = "UPDATE_RESEARCH_AI_MODEL";
export const UPDATE_RESEARCH_AI_MODEL_RESPONSE = "UPDATE_RESEARCH_AI_MODEL_RESPONSE";

export const DELETE_RESEARCH_AI_MODEL = "DELETE_RESEARCH_AI_MODEL";
export const DELETE_RESEARCH_AI_MODEL_RESPONSE = "DELETE_RESEARCH_AI_MODEL_RESPONSE";

export const ADD_RESEARCH_AI_SOURCES = "ADD_RESEARCH_AI_SOURCES";
export const ADD_RESEARCH_AI_SOURCES_RESPONSE = "ADD_RESEARCH_AI_SOURCES_RESPONSE";

export const REMOVE_RESEARCH_AI_SOURCES = "REMOVE_RESEARCH_AI_SOURCES";
export const REMOVE_RESEARCH_AI_SOURCES_RESPONSE = "REMOVE_RESEARCH_AI_SOURCES_RESPONSE";

export const TRAIN_RESEARCH_AI_MODEL = "TRAIN_RESEARCH_AI_MODEL";
export const TRAIN_RESEARCH_AI_MODEL_RESPONSE = "TRAIN_RESEARCH_AI_MODEL_RESPONSE";

export const QUERY_RESEARCH_AI_MODEL = "QUERY_RESEARCH_AI_MODEL";
export const QUERY_RESEARCH_AI_MODEL_RESPONSE = "QUERY_RESEARCH_AI_MODEL_RESPONSE";
