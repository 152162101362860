import { Action } from "@ngrx/store";
import { IServerResponse } from "app/interface";

import {
    GET_ALL_ROLES, SET_ALL_ROLES, GET_ROLE_BY_ID, SET_ROLE_BY_ID,
    ADD_ROLE, ADD_ROLE_RESPONSE, UPDATE_ROLE, UPDATE_ROLE_RESPONSE,
    DELETE_ROLE, DELETE_ROLE_RESPONSE
} from "./actionTypes"

export class GetAllRoles implements Action {
    readonly type = GET_ALL_ROLES;
}

export class SetAllRoles implements Action {
    readonly type = SET_ALL_ROLES;
    readonly payload = null;
    constructor(data: Array<any>) {
        this.payload = data;
    }
}

export class GetRoleById implements Action {
    readonly type = GET_ROLE_BY_ID;
    readonly payload: string = null;
    constructor(id) {
        this.payload = id
    }
}

export class SetRoleById implements Action {
    readonly type = SET_ROLE_BY_ID;
    readonly payload = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class AddRole implements Action {
    readonly type = ADD_ROLE;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class AddRoleResponse implements Action {
    readonly type = ADD_ROLE_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class UpdateRole implements Action {
    readonly type = UPDATE_ROLE;
    readonly payload = null;
    constructor(dataObject) {
        this.payload = dataObject
    }
}

export class UpdateRoleResponse implements Action {
    readonly type = UPDATE_ROLE_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export class DeleteRole implements Action {
    readonly type = DELETE_ROLE;
    readonly payload: string = null;
    constructor(idsArray) {
        this.payload = idsArray
    }
}

export class DeleteRoleResponse implements Action {
    readonly type = DELETE_ROLE_RESPONSE;
    readonly payload: IServerResponse = null;
    constructor(data: any) {
        this.payload = data;
    }
}

export type Actions = GetAllRoles | SetAllRoles | GetRoleById | SetRoleById |
    AddRole | AddRoleResponse | UpdateRole | UpdateRoleResponse |
    DeleteRole | DeleteRoleResponse;
