import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as type from './actionTypes';
import * as MattersAction from './actions';
import { MattersService } from "./service";
import { IServerResponse } from "app/interface";

@Injectable()
export class MattersEffect {
    constructor(
        private action: Actions,
        private mattersService: MattersService
    ) { }

    GetMatterById = createEffect(() => this.action.pipe(
        ofType(type.GET_MATTER_BY_ID),
        switchMap((data: any) => this.mattersService.getMatterById(data.payload)),
        map((data: any) => new MattersAction.SetMatterById(data)
        )
    ));

    GetClientByMatterId = createEffect(() => this.action.pipe(
        ofType(type.GET_MATTER_CLIENTS_BY_ID),
        switchMap((data: any) => this.mattersService.getClientsByMatterId(data.payload)),
        map((data: any) => new MattersAction.SetClientsByMatterId(data)
        )
    ));

    AddMatter = createEffect(() => this.action.pipe(
        ofType(type.ADD_MATTER),
        switchMap((data: any) => this.mattersService.addMatter(data.payload)),
        map((data: IServerResponse) => new MattersAction.AddMatterResponse(data)
        )
    ));

    UpdateMatter = createEffect(() => this.action.pipe(
        ofType(type.UPDATE_MATTER),
        switchMap((data: any) => this.mattersService.updateMatter(data.payload)),
        map((data: IServerResponse) => new MattersAction.UpdateMatterResponse(data)
        )
    ));

    DeleteMatter = createEffect(() => this.action.pipe(
        ofType(type.DELETE_MATTER),
        switchMap((data: any) => this.mattersService.deleteMatter(data.payload)),
        map((data: IServerResponse) => new MattersAction.DeleteMatterResponse(data)
        )
    ));

    GetAllMatters = createEffect(() => this.action.pipe(
        ofType(type.GET_ALL_MATTERS),
        switchMap((data: any) => this.mattersService.getAllMatters(data.payload)),
        map((data) => new MattersAction.SetAllMatters(data))
    ));

    GetMattersByField = createEffect(() => this.action.pipe(
        ofType(type.GET_MATTER_BY_FIELD),
        switchMap((data: any) => this.mattersService.searchByField(data.payload)),
        map((data) => new MattersAction.SetMattersByField(data))
    ));

    ManualUpload = createEffect(() => this.action.pipe(
        ofType(type.MANUAL_UPLOAD),
        switchMap((data: any) => this.mattersService.manualUpload(data.payload)),
        map((data) => new MattersAction.ManualUploadResponse(data))
    ));

    ManualUploadOverride = createEffect(() => this.action.pipe(
        ofType(type.MANUAL_UPLOAD_OVERRIDE),
        switchMap((data: any) => this.mattersService.manualUploadOverride(data.payload)),
        map((data) => new MattersAction.ManualUploadOverrideResponse(data))
    ));
}
