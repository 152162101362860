import { IServerResponse } from "app/interface";

export interface FirmsState {
    allFirms: Array<any>;
    firmById: any;
    addFirmResponse: IServerResponse;
    updateFirmResponse: IServerResponse;
    deleteFirmResponse: IServerResponse;
    testDocumentSignatureConnectivityResponse: IServerResponse;
    testEmailConnectivityResponse: IServerResponse;
    documentsToBeSigned: Array<any>;
    documentSignatureByFields: IServerResponse;
    sendDocumentSignatureReminderResponse: IServerResponse;
    allLeads: IServerResponse;
}

export const initialState: FirmsState = {
    allFirms: null,
    firmById: null,
    addFirmResponse: null,
    updateFirmResponse: null,
    deleteFirmResponse: null,
    testDocumentSignatureConnectivityResponse: null,
    testEmailConnectivityResponse: null,
    documentsToBeSigned: null,
    documentSignatureByFields: null,
    sendDocumentSignatureReminderResponse: null,
    allLeads: null
}
