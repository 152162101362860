import { FormControl, FormGroup, Validators } from '@angular/forms';

export const getMatterFormFields = (formBuilder, defaultValues?) => {

    return {
        "id": new FormControl(null),
        "firmId": new FormControl(null, Validators.required),
        "lawyerId": new FormControl(null),
        "caseEnquiryId": new FormControl(null, Validators.required),
        "firmName": new FormControl(null),
        "lawyerName": new FormControl(null),
        "enquiryReference": new FormControl(null),
        "clientIds": new FormControl(null, Validators.required),
        "primaryClient": new FormControl(null, Validators.required),
        "secondaryClient": new FormControl(null),
        "primaryClientName": new FormControl(null),
        "secondaryClientName": new FormControl(null),
        "matterDetails": new FormGroup({
            "isLawyerType": new FormControl(null, Validators.required),
            "lawyerType": new FormControl("NONE", Validators.required),
            "jurisdictionType": new FormControl("DO_NOT_KNOW", Validators.required),
            "countryName": new FormControl(null),
            "isOtherPartyDispute": new FormControl(false),
            "isDisputeInfoAvailable": new FormControl(false),
            "disputeInfo": new FormGroup({
                "name": new FormControl(null),
                "address": new FormGroup({
                    "houseNo": new FormControl(null),
                    "street": new FormControl(null),
                    "town": new FormControl(null),
                    "country": new FormControl(null),
                    "postCode": new FormControl(null),
                }),
                "addressText": new FormControl(null)
            }),
            "caseDescription": new FormControl(null, Validators.required),
            "matterReference": new FormControl(null),
            "matterState": new FormControl(null),
            "docSignIssue": new FormControl("NONE"),
            "docSignIssueReason": new FormControl(null),
            "ccDocComponentId": new FormControl(null),
            "signedCCDocReceived": new FormControl(null),
            "tcDocComponentId": new FormControl(null),
            "signedTCDocReceived": new FormControl(null),
            "areaOfLaw": new FormControl("UNKNOWN", Validators.required),
            "corporateLawType": new FormControl(null),
            "employmentLawType": new FormControl(null)
        }),
        "matterStateLogs": new FormControl([], Validators.required),
        "riskAssessmentData": new FormGroup({
            "assessmentState": new FormControl(null),
            "isDiscussedWithOtherFirm": new FormControl(false),
            "isOtherFirmInfoAvailable": new FormControl(false),
            "otherFirmMatterDiscussion": new FormGroup({
                "otherFirmDetails": new FormGroup({
                    "name": new FormControl(null),
                    "contactInfo": new FormGroup({
                        "address": new FormGroup({
                            "houseNo": new FormControl(null),
                            "street": new FormControl(null),
                            "town": new FormControl(null),
                            "country": new FormControl(null),
                            "postCode": new FormControl(null),
                        }),
                        "addressText": new FormControl(null),
                        "email": new FormControl(null),
                        "countryCode": new FormControl(null),
                        "phone": new FormControl(null)
                    })
                }),
                "holdMatterInfo": new FormControl(null),
                "reason": new FormGroup({
                    "reasonType": new FormControl("OTHERS", Validators.required),
                    "details": new FormControl(null)
                }),
                "otherFirmSuccessAdvice": new FormControl("NA", Validators.required),
                "receivedInfoSystemComment": new FormControl(null)
            }),
            "employmentType": new FormControl("EMPLOYED", Validators.required),
            "idDocument": new FormControl(null),
            "idDocumentValidated": new FormControl(false),
            "isIdOverridden": new FormControl(false),
            "overrideIdMsg": new FormControl(""),
            "addressDocument": new FormControl(null),
            "addressDocumentValidated": new FormControl(false),
            "isAddressOverridden": new FormControl(false),
            "overrideAddressMsg": new FormControl(""),
            "documentValidation": new FormGroup({
                "nameValidation": new FormGroup({
                    "isValidation": new FormControl(false),
                    "extractedText": new FormControl(null),
                    "inputText": new FormControl(null),
                    "matchingRatio": new FormControl(0)
                }),
                "addressValidation": new FormGroup({
                    "name": new FormGroup({
                        "isValidation": new FormControl(false),
                        "extractedText": new FormControl(null),
                        "inputText": new FormControl(null),
                        "matchingRatio": new FormControl(0)
                    }),
                    "address": new FormGroup({
                        "isValidation": new FormControl(false),
                        "extractedText": new FormControl(null),
                        "inputText": new FormControl(null),
                        "matchingRatio": new FormControl(0)
                    })
                })
            }),
            "isAllAnswerReceived": new FormControl(false),
            "score": new FormControl(null),
            "riskLevel": new FormControl("NONE"),
            "validatePendingInfo": new FormGroup({
                "completedModules": new FormControl(null),
                "otherFirmConsentStatus": new FormControl("NONE"),
                "otherFirmConsentId": new FormControl(null),
                "otherFirmConsentUpdatedOn": new FormControl(null),
                "defendantTrackStatus": new FormControl("NONE"),
                "thirdPartyConsentId": new FormControl(null),
                "thirdPartyDetails": new FormGroup({
                    "name": new FormControl(null),
                    "email": new FormControl(null),
                    "phone": new FormControl(null)
                }),
                "thirdPartyConsentStatus": new FormControl("NONE"),
                "thirdPartyConsentUpdatedOn": new FormControl(null),
                "defendantInfoBlocker": new FormControl(null),
                "defendantInfoBlockerReason": new FormControl(null),
                "idAddressTrackStatus": new FormControl("NONE"),
                "idAddressInfoBlocker": new FormControl(null),
                "idAddressInfoBlockerReason": new FormControl(null)
            }),
            "sourceOfFunding": new FormControl("ASSISTANCE_FUNDING", Validators.required),
            "riskScoreReason": new FormGroup({})
        }),
        "matterDocuments": new FormControl([]),
        "version": new FormControl(0)
    }
};
