import { CoreMenu } from '@core/types';
import { MODULE_ID, MODULE_KEY, MODULE_TITLE } from 'app/constants/module.constant';

export const menu: CoreMenu[] = [
    {
        id: MODULE_ID.HOME,
        title: MODULE_TITLE.HOME,
        type: 'item',
        icon: 'home',
        accessKeyName: MODULE_KEY.HOME,
        url: 'operation/home'
    },
    {
        id: MODULE_ID.ENQUIRY,
        title: MODULE_TITLE.ENQUIRY,
        type: 'item',
        icon: 'edit',
        accessKeyName: MODULE_KEY.ENQUIRY,
        url: 'tracking/enquiry'
    },
    {
        id: MODULE_ID.MATTERS,
        title: MODULE_TITLE.MATTERS,
        type: 'item',
        icon: 'clipboard',
        accessKeyName: MODULE_KEY.MATTERS,
        url: 'operation/matters'
    },
    {
        id: MODULE_ID.CLIENTS,
        title: MODULE_TITLE.CLIENTS,
        type: 'item',
        icon: 'users',
        accessKeyName: MODULE_KEY.CLIENTS,
        url: 'operation/clients'
    },
    {
        id: MODULE_ID.CALENDAR,
        title: MODULE_TITLE.CALENDAR,
        type: 'item',
        icon: 'calendar',
        accessKeyName: MODULE_KEY.CALENDAR,
        url: 'operation/systemCalendar'
    },
    {
        id: MODULE_ID.BILLING,
        title: MODULE_TITLE.BILLING,
        type: 'item',
        // icon: 'dollar-sign',
        iconText: "£",
        accessKeyName: MODULE_KEY.BILLING,
        url: 'tracking/billing'
    },
    {
        id: MODULE_ID.DOCUMENT_SIGNATURE_TRACKING,
        title: MODULE_TITLE.DOCUMENT_SIGNATURE_TRACKING,
        type: 'item',
        icon: 'file-text',
        accessKeyName: MODULE_KEY.USERS,
        url: 'tracking/document-signature-tracking'
    },
    {
        id: MODULE_ID.METERS,
        title: MODULE_TITLE.METERS,
        type: 'item',
        icon: 'clock',
        accessKeyName: MODULE_KEY.METERS,
        url: 'tracking/meters'
    },
    {
        id: MODULE_ID.AUDIT_LOGS,
        title: MODULE_TITLE.AUDIT_LOGS,
        type: 'item',
        icon: 'book',
        accessKeyName: MODULE_KEY.AUDIT_LOGS,
        url: 'tracking/audit-logs'
    },
    {
        id: MODULE_ID.USERS,
        title: MODULE_TITLE.USERS,
        type: 'item',
        icon: 'users',
        accessKeyName: MODULE_KEY.USERS,
        url: 'setup/users'
    },
    {
        id: MODULE_ID.FIRMS,
        title: MODULE_TITLE.FIRMS,
        type: 'item',
        icon: 'life-buoy',
        accessKeyName: MODULE_KEY.FIRMS,
        url: 'setup/firms'
    },
    {
        id: MODULE_ID.NOTIFICATIONS,
        title: MODULE_TITLE.NOTIFICATIONS,
        type: 'item',
        icon: 'bell',
        accessKeyName: MODULE_KEY.NOTIFICATIONS,
        url: 'setup/notifications'
    },
    {
        id: MODULE_ID.DATA_CONNECTORS,
        title: MODULE_TITLE.DATA_CONNECTORS,
        type: 'item',
        icon: 'share-2',
        // accessKeyName: MODULE_KEY.NOTIFICATIONS,
        accessKeyName: MODULE_KEY.DATA_CONNECTORS,
        url: 'setup/data-connectors'
    },
    {
        id: MODULE_ID.LEADS,
        title: MODULE_TITLE.LEADS,
        type: 'item',
        icon: 'users',
        accessKeyName: MODULE_KEY.LEADS,
        url: 'tracking/leads'
    }
    // {
    //     id: MODULE_ID.VISION_AI,
    //     title: MODULE_TITLE.VISION_AI,
    //     type: 'item',
    //     icon: 'eye',
    //     accessKeyName: MODULE_KEY.VISION_AI,
    //     url: 'utilities/doc-vision'
    // },
    // {
    //     id: MODULE_ID.SPEECH_AI,
    //     title: MODULE_TITLE.SPEECH_AI,
    //     type: 'item',
    //     icon: 'headphones',
    //     accessKeyName: MODULE_KEY.SPEECH_AI,
    //     url: 'utilities/speech-AI'
    // },
    // {
    //     id: MODULE_ID.RESEARCH_AI,
    //     title: MODULE_TITLE.RESEARCH_AI,
    //     type: 'item',
    //     icon: 'file-text',
    //     accessKeyName: MODULE_KEY.RESEARCH_AI,
    //     url: 'utilities/research-AI'
    // }
];
